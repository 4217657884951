import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import FilterListIcon from '@mui/icons-material/FilterList';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { getResponseMessage } from '../responding';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import IconButton from '@mui/material/IconButton';
import moment from 'moment-timezone';
import colors from '../../colors';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`

const DivRow = styled.div`
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: end;
    margin-top:0px;
    margin-left: 0px;
`
const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 40%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    height: calc(100vh - 116px);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    overflow-y: hidden;
    @media only screen and (max-width: 850px) {
    }
`
const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 20px 5%;
    min-height: calc(100vh - 116px - 55px);
    height: max-content;
    overflow: scroll;
    background-color: white;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`


const PricingContaciner = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
  border-radius: 6px;
`

const PricingView = styled.div`
position: relative;
  width: 100%;
  min-height: 100px;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 0px 20px 0px;
  border-radius: 6px;
  border: 1px #e0e0e0 solid;
  border-radius: 10px;
  background-color: ${colors.secondary2};
  @media only screen and (max-width: 900px) {
      width: 100% ;
      padding: 30px 0px;
    }
`


const Pricing = styled.div`
  width: 48%;
  min-height: 100px;
  background-color: ${props => props.primary ? "#63257e1d" : "#00800012"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 0px 00px 0px;
  border-radius: 6px 6px 0px 0px ;
  border-bottom: 5px  solid ${props => props.primary ? colors.primary : "green"};
  /* border-bottom: 5px colors.primary solid; */
  
`

const Title = styled.h1`
  margin: 0px 5px 10px 5px;
  text-align: center;
  width: calc(100% - 10px);
  color: #63257e;
`

const Desc = styled.p`
  margin: 10px 5px 0px 5px;
  text-align: center;
  width: calc(100% - 10px);
  color: #63257e83;
`

const BalanceActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 20px;
`

const BalanceSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  
  @media only screen and (max-width: 900px) {
      width: 100% ;
    }

  @media only screen and (max-width: 700px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`

const BalanceFilter = styled.div`
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`

 // mui config
 function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
// end mui config






export default function Reporting({handleHeader, djezzyCosts, mobilisCosts, ooredooCosts, Language}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)
  const [message, setmessage] = useState([]);
  const [sheduled, setsheduled] = useState([]);
  const [balance, setbalance] = useState([]);
  const [ selected_rows, set_selected_rows] = useState([])
  const [loadingMessage, setloadingMessage] = useState(false)
  const [loadingScheduled, setLoadingScheduled] = useState(false)
  const [loadingBalance, setLoadingBalance] = useState(false);
  const navigate = useNavigate()
  const [senders, setSenders] = useState([]);
 
  
  
  const columnss = [
    // { field: 'id', headerName: 'ID', width: 70 },
   
    {
      field: 'fullName',
      headerName: Language['Full name'],
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.contact){
          return (params.row.contact.first_name ? params.row.contact.first_name : '')  + ' ' +  (params.row.contact.family_name ? params.row.contact.family_name : '') ;
        }else{
          return Language['deleted_contact']
        }
      },
    },
    {
      field: 'society',
      headerName: Language['Society'],
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.contact){
          return params.row.contact.society  ;
        }else{
          return '--'
        }
      },
    },
    {
      field: 'phone number',
      headerName: Language['Phone Number'],
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.contact){
          return params.row.contact.phone_number  ;
        }else{
          return '--'
        }
      },
    },
    { field: 'subject', headerName: Language['Sender'], width: 130 },
    { field: 'message', headerName: Language['Message'], width: 230 },
    { field: 'createdAt', headerName: Language['Created in'], width: 130,
    renderCell: (params) => {
      var date = moment(params.row.createdAt).tz('Africa/Algiers').format()
      const year =  date.slice(0, 4);
      const month =   date.slice(5, 7);
      const day =   date.slice(8, 10);
      const hour =  date.slice(11, 13);
      const minute =  date.slice(14, 16);
      return year + '/' + month + '/' + day + ' ' + hour + ":" + minute ;
    },
    },
    {
      field: 'Repeted',
      headerName: Language['Repeted'],
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.repeted === "no-repeat"){
          return 'no repeate';
        }else{
          return params.row.repeted;
        }
      },
    },
    {
      field: 'date',
      headerName: Language['Send'],
      sortable: false,
      width: 160,
      valueGetter: function(params) {
        return `${params.row.year || ''}/${params.row.month || ''}/${params.row.day || ''}  ${params.row.hour || ''}:00 `;
      }
    },
    {
      field: 'Action',
      headerName: Language['Action'],
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const deleterow = (event) => {
          // handle edit logic here
          event.stopPropagation(); 
          console.log(params.row.id)
          set_selected_rows([params.row.id])
          setDeleteContactModalBody(Language['Are you sure you want to delete this item ?'])
          setOpenDeleteContactModal(true);          
        };
  
        //return  <><DeleteIcon onClick={deleterow} style={{color: 'red'}} className={'ActionIcon'}></DeleteIcon></> ;
        return <Button variant="outlined" onClick={deleterow} color="error" >{Language['delete']}</Button>
      },
    },
  
  ];
  
  
  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
  
    {
      field: 'fullName',
      headerName: Language['Full name'],
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return (params.row.first_name ? params.row.first_name : '' ) + ' ' +  (params.row.family_name ? params.row.family_name : '' );

      },
    },
    {
      field: 'society',
      headerName: Language['Society'],
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return params.row.society ? params.row.society : '' ;
      },
    },
    {
      field: 'phone number',
      headerName: Language['Phone Number'],
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return params.row.phone_number  ;
      },
    },
   
    { field: 'subject', headerName: Language['Sender'], width: 130 },
    { field: 'message', headerName: Language['Message'], width: 230 },
    { field: 'createdAt', headerName: Language['Date'], width: 130,
    renderCell: (params) => {
      const year = moment(params.row.createdAt).tz('Africa/Algiers').format().slice(0, 4);
      const month = moment(params.row.createdAt).tz('Africa/Algiers').format().slice(5, 7);
      const day = moment(params.row.createdAt).tz('Africa/Algiers').format().slice(8, 10);
      const hour = moment(params.row.createdAt).tz('Africa/Algiers').format().slice(11, 13);
      const minute = moment(params.row.createdAt).tz('Africa/Algiers').format().slice(14, 16);
      return year + '/' + month + '/' + day + ' ' + hour + ":" + minute ;
    },
    
    },
   
    {
      field: 'status',
      headerName: Language['Status'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.status === "sent"){
          return <Button variant='outlined' color='success' onClick={(event) => event.stopPropagation()} >{Language['sent']}</Button>;
        } else if(params.row.status === "sending"){
          return <Button variant='outlined' disabled style={{color: '#aba7a7', borderColor: '#cdc7c7'}} endIcon={<CircularProgress style={{color: "#aba7a7", width: '15px', height:'15px'}}></CircularProgress>} onClick={(event) => event.stopPropagation()} >{Language['RESEND']}</Button>;
        }
        else{
          return <Tooltip title={Language['Resend Message']} onClick={(e)=> console.log(e)}><Button variant='outlined' color='error'  endIcon={<RotateRightIcon></RotateRightIcon>} onClick={(event) => {
            event.stopPropagation(); 
            ResendMessage(event, params.row.id)
          }}>{Language['failed']}</Button></Tooltip> ;
        }
        
      },
    },
    
    {
      field: 'description',
      headerName: Language['Description'],
      sortable: false,
      width: 260,
      valueGetter: function(params){
        return `${params.row.description || ''} `
      }
    },
  ];

   
  const balanceColumns = [
    // { field: 'id', headerName: 'ID', width: 70 },
  
    {
      field: 'fullName',
      headerName: Language['Full name'],
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return (params.row.first_name ? params.row.first_name : '')  + ' ' +  (params.row.family_name ? params.row.family_name : '') ;
      },
    },
    {
      field: 'society',
      headerName: Language['Society'],
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.contact){
          return params.row.contact.society  ;
        }else{
          return '--'
        }
      },
    },
    {
      field: 'phone number',
      headerName: Language['Phone Number'],
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.contact){
          return params.row.contact.phone_number  ;
        }else{
          return '--'
        }
      },
    },
   
    { field: 'subject', headerName: Language['Sender'], width: 130 },
    { field: 'message', headerName: Language['Message'], minWidth: 230, flex: 1 },
    // { field: 'operator', headerName: 'Operator', width: 130 },
    // {
    //   field: 'price',
    //   headerName: 'Price',
    //   sortable: false,
    //   width: 130,
    //   disableClickEventBubbling: true,
    //   renderCell: (params) => {
    //     if(params.row.price){
    //       return parseFloat(params.row.price) + ' DA';
    //     }else{
    //       return '--'
    //     }
    //   },
    // },
   
    /*{
      field: 'operator',
      headerName: 'Operator',
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.contact){
          if(params.row.contact.phone_number[4] === "7"){
            return 'djezzy'
          }else if(params.row.contact.phone_number[4] === "6"){
            return 'mobilis'
          }else if(params.row.contact.phone_number[4] === "5"){
            return 'ooredoo'
          }
        }else{
          return '--'
        }
        
      },
    },

    {
      field: 'price',
      headerName: 'Price',
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.contact){
          if(params.row.contact.phone_number[4] === "7"){
            return djezzyCosts + ' DA'
          }else if(params.row.contact.phone_number[4] === "6"){
            return mobilisCosts + ' DA'
          }else if(params.row.contact.phone_number[4] === "5"){
            return ooredooCosts + ' DA'
          }
        }else{
          return '--'
        }
        
      },
    }, */
    
    { field: 'createdAt', headerName: Language['Date'], width: 130,
    renderCell: (params) => {
      var date = moment(params.row.createdAt).tz('Africa/Algiers').format()
      const year = date.slice(0, 4);
      const month = date.slice(5, 7);
      const day = date.slice(8, 10);
      const hour = date.slice(11, 13);
      const minute = date.slice(14, 16);
      return year + '/' + month + '/' + day + ' ' + hour + ":" + minute ;
    },
    
    },
  ];

  const [selectionModel, setSelectionModel] = useState([]);

      const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
        set_selected_rows(newSelection)
      }
   
  const [ exportBtnContent , setExportBtnContent] = useState(<FileUploadIcon style={{fontSize: "x-large"}}/>)
   
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

      const getRowClassName = (params) => {
        const status = params.row.status;
        if (status === 'sent') {
          return 'sent-row';
        } else if (status === 'failed') {
          return 'failed-row';
        } else if (status === 'sending') {
          return 'sending-row';
        }
        return '';
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
      };

      const [openDeleteContactModal, setOpenDeleteContactModal] = React.useState(false);
      const handleCloseDeleteContactModal = () => {
        setOpenDeleteContactModal(false);
      };

      function ResendMessage(event, id){
            setmessage(message.map(item => {
              if (item.id === id) {
                var updatedItem = item
                updatedItem.status = 'sending'
                return updatedItem;
              } else {
                return item;
              }
            }))
          axios.post("/ResendMessage",{
            id
          })
          .then(function (response) {
            console.log(response.data)
            if(response.data.res === 'done'){
              setmessage(message.map(item => {
                if (item.id === id) {
                  return response.data.myMessage;
                } else {
                  return item;
                }
              }))
            }
            else if(response.data.res === 'server_error'){
              setmessage(message.map(item => {
                if (item.id === id) {
                  var updatedItem = item
                  updatedItem.status = 'failed'
                  return updatedItem;
                } else {
                  return item;
                }
              }))
              setAlertMessage(response.data.error)
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
            }
            else if(response.data.res === 'redirect'){
              navigate('/login', { replace: true });
            }
          })
          .catch(function (error) {
            // handle error
            setmessage(message.map(item => {
              if (item.id === id) {
                var updatedItem = item
                updatedItem.status = 'failed'
                return updatedItem;
              } else {
                return item;
              }
            }))
            console.log('this is axios error');
            console.log(error);
            if(error.response){
              if(error.response.status === 401 && error.response.data.response === 'banned'){
                navigate('/app/banned', { replace: true });
              }else{
                setOpenAlert(true)
                setAlertMessage(getResponseMessage(error.response.status))
                setAlertType("error")                
              }

            }else{
              setOpenAlert(true)
              setAlertMessage('Internal server error')
              setAlertType("error")
            }
          })


      }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (link) => {
      if(link === 'account'){
          navigate('/app/profile');
      }
      setAnchorEl(null);
  };


      const [openFilterMessagesModal, setOpenFilterMessagesModal] = React.useState(false);
      const handleCloseFilterMessagesModal = () => {
        setOpenFilterMessagesModal(false);
      };


      const [openAllFilterMessagesModal, setOpenAllFilterMessagesModal] = React.useState(false);
      const handleCloseAllFilterMessagesModal = () => {
        setOpenAllFilterMessagesModal(false);
      };


      const [openFilterScheduledMessagesModal, setOpenFilterScheduledMessagesModal] = React.useState(false);
      const handleCloseFilterScheduledMessagesModal = () => {
        setOpenFilterScheduledMessagesModal(false);
      };


      const [ DeleteContactModalBody, setDeleteContactModalBody] = useState('no item is selected, choose in minimum one item.')
      const [ DeleteContactModalBtn, setDeleteContactModalBtn] = useState('ok')
  

      function deleteItems(items){
        console.log(items)
        if(items.length > 0 ){
          setDeleteContactModalBtn(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
          // delete items
          axios.post("/delete_scheduled", { items})
          .then(function (response) {
            console.log(response.data)
            setDeleteContactModalBtn("delete")
            if(response.data.res === 'done'){
              setsheduled(response.data.message)
              handleCloseDeleteContactModal()
              setAlertMessage('Item deleted successfully')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
            }
            else if(response.data.res === 'server_error'){
              handleCloseDeleteContactModal()
              setAlertMessage('Internal server error')
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
              // redirect to server error page
            }
            else if(response.data.res === 'redirect'){
              navigate('/login', { replace: true });
            }
          })
          .catch(function (error) {
            // handle error
            if(error.response){
              if(error.response.status === 401 && error.response.data.response === 'banned'){
                navigate('/app/banned', { replace: true });
              }else{
                setOpenAlert(true)
                setAlertMessage(getResponseMessage(error.response.status))
                setAlertType("error")
              }

            }else{
              setOpenAlert(true)
              setAlertMessage('An unexpected error has occured')
              setAlertType("error")
            }
          })
        }else{
          handleCloseDeleteContactModal()
        }
      }

      const [totalCredit, setTotalCredit] = useState(0);
      const [totalPrice, setTotalPrice] = useState(0);
      function getReporting(data){
        console.log(data)
        if(data === 'message'){
          setloadingMessage(true)
        }else if(data === 'scheduled'){
          setLoadingScheduled(true)
        }else if(data === 'balance'){
          setLoadingBalance(true)
        }
        axios.post("/getReporting", {
          data, 
        })
        .then(function (response) {
          setLoadingBalance(false)
          setloadingMessage(false)
          setLoadingScheduled(false)
          console.log(response.data)
          if(response.data.res === 'done'){
                // show data
                if(data === 'message'){
                  setmessage(response.data.message)
                }else if(data === 'scheduled'){
                  setsheduled(response.data.message)
                }
                else if(data === 'balance'){
                  setbalance(response.data.message)
                  setTotalCredit(response.data.credit)
                }
                setSenders(response.data.senders)
                
          }
          else if(response.data.res === 'server_error'){
                setAlertMessage('Internal server error')
                setAlertType('error')
                setOpenAlert(true)
                setTimeout(() => {
                  setOpenAlert(false)
                }, 3000);
            // redirect to server error page
          }
          else if(response.data.res === 'redirect'){
            navigate('/login', { replace: true });
          }
        })
        .catch(function (error) {
          // handle error
          setLoadingBalance(false)
          setloadingMessage(false)
          setLoadingScheduled(false)
          console.log('this is axios error');
          console.log(error)
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpenAlert(true)
              setAlertMessage(getResponseMessage(error.response.status))
              setAlertType("error")              
            }

          }else{
            setOpenAlert(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType("error")
          }
        })
      }

      const [filterFrom, setFilterFrom] = useState('');
      const [filterTo, setFilterTo] = useState('');
      const [filterStatus, setfilterStatus] = useState("Life Time")
      
      function filterMessages(type){
        handleCloseFilterMessagesModal()
        setLoadingBalance(true)
        var startAt = '', endAt = ''
        if(type === "advanced"){
          startAt = filterFrom
          endAt = filterTo
          setfilterStatus(filterFrom + ' > ' + filterTo)
        }else{
          setfilterStatus(type)
        }
        axios.post("/filterMessage", {
          type, startAt, endAt, searchKeyword
        })
        .then(function (response) {
          setLoadingBalance(false)
          if(response.data.res === 'done'){
                setbalance(response.data.messages)
          }
          else if(response.data.res === 'server_error'){
            setOpenAlert(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType("error")
            // redirect to server error page
          }
          else if(response.data.res === 'redirect'){
            navigate('/login', { replace: true });
          }
        })
        .catch(function (error) {
          // handle error
          setLoadingBalance(false)
          console.log(error)
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpenAlert(true)
              setAlertMessage(getResponseMessage(error.response.status))
              setAlertType("error")              
            }

          }else{
            setOpenAlert(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType("error")
          }
        })
      }

      function  exportData(rows){
        try {
          if(rows.length > 0){
          console.log(rows)
          setExportBtnContent(<CircularProgress style={{ width: '20px', height:'20px', color: 'white'}}></CircularProgress>)
          axios.post("/exportReport", {
            rows
          })
          .then(function (response) {
            setExportBtnContent(<FileUploadIcon style={{fontSize: "x-lare"}}/>)
            console.log(response)
            if(response.data.res === 'done'){
              setAlertMessage('Exported successfuly')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
              // show data
            }
            else if(response.data.res === 'server_error'){
              setAlertMessage('Internal server error')
                  setAlertType('error')
                  setOpenAlert(true)
                  setTimeout(() => {
                    setOpenAlert(false)
                  }, 3000);
              // redirect to server error page
            }
            else if(response.data.res === 'redirect'){
              //navigate('/login', { replace: true });
            }else{
              // Create a Blob object from the response data
              const blob = new Blob([response.data], { type: 'text/csv' });
  
              // Create a temporary URL for the Blob object
              const url = URL.createObjectURL(blob);
  
              // Create a link element
              const link = document.createElement('a');
  
              // Set the link's href attribute to the temporary URL
              link.href = url;
  
              // Set the link's download attribute to the desired filename
              link.download = 'data.csv';
  
              // Append the link to the DOM
              document.body.appendChild(link);
  
              // Click the link to download the file
              link.click();
  
              // Remove the link from the DOM
              document.body.removeChild(link);
  
              // Revoke the temporary URL to free up memory
              URL.revokeObjectURL(url);
            }
          })
          .catch(function (error) {
            // handle error
            setExportBtnContent(<FileUploadIcon style={{fontSize: "x-lare"}}/>)
            console.log('this is axios error');
            console.log(error);
            if(error.response){
              if(error.response.status === 401 && error.response.data.response === 'banned'){
                navigate('/app/banned', { replace: true });
              }else{
                setOpenAlert(true)
                setAlertMessage(getResponseMessage(error.response.status))
                setAlertType("error")               
              }

            }else{
              setOpenAlert(true)
              setAlertMessage('Internal server error')
              setAlertType("error")
            }
          })
         }else{
            setAlertMessage('No item is checked')
                  setAlertType('error')
                  setOpenAlert(true)
                  setTimeout(() => {
                    setOpenAlert(false)
                  }, 3000);
         }
        } catch (error) {
          console.log(error)
        }
      }
     
     
     
      const [searchKeyword, setSearchKeyword] = useState('');
      function searchMessages(keyword){
        setLoadingBalance(true)
        
        axios.post("/searchMessage", {
          keyword
        })
        .then(function (response) {
          setLoadingBalance(false)
          if(response.data.res === 'done'){
                setbalance(response.data.messages)
                setfilterStatus('Life Time')
          }
          else if(response.data.res === 'server_error'){
            setOpenAlert(true)
            setAlertMessage('error has occured')
            setAlertType("error")
            // redirect to server error page
          }
          else if(response.data.res === 'redirect'){
            navigate('/login', { replace: true });
          }
        })
        .catch(function (error) {
          // handle error
          setLoadingBalance(false)
          console.log(error)
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpenAlert(true)
              setAlertMessage(getResponseMessage(error.response.status))
              setAlertType("error")            
            }

          }else{
            setOpenAlert(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType("error")
          }
        })
      }

      const [sender, setSender] = useState('');
      const [operator, setOperator] = useState('');
      const [status, setstatus] = useState('');
      const [from, setFrom] = useState('');
      const [to, setTo] = useState('');



      const [scheduledSender, setScheduledSender] = useState('');
      const [scheduledOperator, setScheduledOperator] = useState('');
      const [scheduledRepeted, setScheduledRepeted] = useState('');
      const [scheduledFrom, setScheduledFrom] = useState('');
      const [scheduledTo, setScheduledTo] = useState('');
      const [scheduledSentAt, setScheduledSentAt] = useState('');

      function resetFilter(){
        setSender('')
        setOperator('')
        setstatus('')
        setFrom('')
        setTo('')
        filterMessagesAdvanced(true)
      }

      function filterMessagesAdvanced(reset){
          setloadingMessage(true)
          setOpenAllFilterMessagesModal(false)
          console.log(sender, operator, status, from, to)
          var data 
          if(reset){
            data = {sender: '', operator: '', status: '', from: '', to: ''}
            setFilterActive('none')
          }else{
            data = {
              sender, operator, status, from, to
            }
            setFilterActive('block')
          }
          axios.post("/AdvancedMessageFilter", data )
          .then(function (response) {
            setloadingMessage(false)
            if(response.data.res === 'done'){
                  setmessage(response.data.messages)
            }
            else if(response.data.res === 'server_error'){
              setOpenAlert(true)
              setAlertMessage('An unexpected error has occured')
              setAlertType("error")
            }
            else if(response.data.res === 'redirect'){
              navigate('/login', { replace: true });
            }
          })
          .catch(function (error) {
            // handle error
            setloadingMessage(false)
            console.log(error)
            if(error.response){
              if(error.response.status === 401 && error.response.data.response === 'banned'){
                navigate('/app/banned', { replace: true });
              }else{
                setOpenAlert(true)
                setAlertMessage(getResponseMessage(error.response.status))
                setAlertType("error")                
              }

            }else{
              setOpenAlert(true)
              setAlertMessage('An unexpected error has occured')
              setAlertType("error")
            }
          })
      }


      function resetScheduledMessageFilter(){
        setScheduledSender('')
        setScheduledOperator('')
        setScheduledRepeted('')
        setScheduledSentAt('')
        setScheduledFrom('')
        setScheduledTo('')
        filterScheduledMessages(true)
      }

      function filterScheduledMessages(reset){
        setLoadingScheduled(true)
        setOpenFilterScheduledMessagesModal(false)
        //console.log(sender, operator, status, from, to)
        var data 
        if(reset){
          data = {
            scheduledSender: '', scheduledOperator: '', scheduledSentAt: '', scheduledRepeted: '', scheduledFrom: '', scheduledTo: ''
          }
          setFilterScheduledMessageActive('none')
        }else{
          data = {
            scheduledSender, scheduledOperator, scheduledSentAt, scheduledRepeted, scheduledFrom, scheduledTo
          }
          setFilterScheduledMessageActive('block')
        }
        axios.post("/ScheduledMessageFilter", data )
        .then(function (response) {
          setLoadingScheduled(false)
          if(response.data.res === 'done'){
            setsheduled(response.data.messages)
          }
          else if(response.data.res === 'server_error'){
            setOpenAlert(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType("error")
          }
          else if(response.data.res === 'redirect'){
            navigate('/login', { replace: true });
          }
        })
        .catch(function (error) {
          // handle error
          setLoadingScheduled(false)
          console.log(error)
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpenAlert(true)
              setAlertMessage(getResponseMessage(error.response.status))
              setAlertType("error")              
            }
            
          }else{
            setOpenAlert(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType("error")
          }
        })
    }

      const [filterActive, setFilterActive] = useState('none');
      const [filterScheduledMessageActive, setFilterScheduledMessageActive] = useState('none');
      
      
      useEffect(() => {
        handleHeader('reporting')
        getReporting('message')
      }, []);

      useEffect(() => {
        setTimeout(() => {
          getReporting('message')
        }, 3000);
      }, []);

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>{Language['Reporting']}</H1>
            </HeaderTitle>
        </Header>
        <BodyContent>
            
            <Form>
            <Box sx={{ width: '100%', position: 'relative' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={Language['Message']} {...a11yProps(0)} onClick={() => getReporting('message')}/>
                    <Tab label={Language['scheduled']} {...a11yProps(1)} onClick={() => getReporting('scheduled')}/>
                    <Tab label={Language['balance']} {...a11yProps(2)} onClick={() => getReporting('balance')}/>
                    {/* <Tab label="failed" {...a11yProps(2)} onClick={() => getReporting('failed')}/> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} style={{width: '100%'}} id={'panel1'}>
                    <div style={{ height: 'calc(100vh - 300px)' , width: '100%'}}>
                      <Header style={{  width: '100%', margin: '5px 0% ', padding: '0px', border: "none"}}>
                        <DivRow>
                          <Tooltip title={Language['Cancel Filter']}><IconButton    style={{marginLeft: '10px', color: colors.primary, display: `${filterActive}`}} onClick={() => resetFilter()}>  <FilterAltOffIcon></FilterAltOffIcon>  </IconButton></Tooltip>
                          <Tooltip title={Language['Filter']}><Button variant="contained" size='large'  style={{marginLeft: '10px', backgroundColor: colors.primary}} onClick={() => setOpenAllFilterMessagesModal(true)}>  <FilterAltIcon></FilterAltIcon>  </Button></Tooltip>
                          <Tooltip title={Language['Export']}><Button variant="contained" size='large'  style={{marginLeft: '10px'}} onClick={() => exportData(selected_rows)}> {Language[exportBtnContent] ? Language[exportBtnContent] : exportBtnContent } </Button></Tooltip>
                        </DivRow>
                      </Header>
                      <DataGrid
                        getRowClassName={getRowClassName}
                        style={{padding: '0px 0px'}}
                        rows={message}
                        columns={columns}
                        pageSize={50}
                        loading={loadingMessage}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={true}
                        selectionModel={selectionModel}
                        onSelectionModelChange={handleSelectionModelChange}
                        sortModel={[{field: 'createdAt',sort: 'desc'}]}/>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <div style={{ height: 'calc(100vh - 300px)' , width: '100%'}}>
                      <Header style={{  width: '100%', margin: '5px 0% ', padding: '0px', border: "none"}}>
                        <DivRow>
                          <Tooltip title={Language['Cancel Filter']}><IconButton    style={{marginLeft: '10px', color: colors.primary, display: `${filterScheduledMessageActive}`}} onClick={() => resetScheduledMessageFilter()}>  <FilterAltOffIcon></FilterAltOffIcon>  </IconButton></Tooltip>
                          <Tooltip title={Language['Filter']}><Button variant="contained" size='large'  style={{marginLeft: '10px', backgroundColor: colors.primary}} onClick={() => setOpenFilterScheduledMessagesModal(true)}>  <FilterAltIcon></FilterAltIcon>  </Button></Tooltip>
                        </DivRow>
                      </Header>
                      <DataGrid
                        style={{padding: '0px 0px'}}
                        rows={sheduled}
                        columns={columnss}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={false}
                        loading={loadingScheduled}
                        sortModel={[{field: 'createdAt',sort: 'desc'}]}/>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2} style={{overflow: 'hidden'}} >
                  <PricingContaciner style={{display: 'none'}}>
                    <Pricing primary={true}>
                      <Desc>{Language['total costs']}</Desc>
                      <Title>5405.70 DA</Title>
                    </Pricing>
                    <Pricing primary={false}>
                      <Desc>{Language['total message sent']}</Desc>
                      <Title>572</Title>
                    </Pricing>
                  </PricingContaciner>
                  
                  <BalanceActions>
                    <BalanceSearch>
                      <FormControl error={false} variant="standard" style={{width: '100%', minWidth: '300px'}}>
                        <InputLabel htmlFor="email">{Language['Search']}</InputLabel>
                        <Input
                        id="email"
                        defaultValue=""
                        onChange={(e) => {
                          setSearchKeyword(e.target.value)
                          searchMessages(e.target.value)
                        }}
                        placeholder={Language['e.g. "names", "senders", "phone numbers"..']}
                        aria-describedby="email_error"
                        startAdornment={
                          <InputAdornment position="start">
                            < SearchIcon />
                          </InputAdornment>
                        }
                        />
                      </FormControl>
                      <Desc style={{width: 'max-content', minWidth: '200px', alignSelf: 'end'}}>{balance.length} {Language['messages found']}</Desc> 
                    </BalanceSearch>
                    
                  </BalanceActions>


                  <PricingView primary={true}>
                      <Desc style={{color: colors.primary}}>{Language['Credit available']}  </Desc>
                      <Title style={{color: colors.primary}}> {totalCredit}<span style={{fontSize: '20px', fontWeight: '400'}} > {Language['SMS']}</span> </Title>
                      <Button size='small' onClick={handleClick} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" id="basic-button" aria-controls={open ? 'basic-menu' : undefined} startIcon={<CalendarMonthIcon></CalendarMonthIcon>} endIcon={<ArrowDropDownIcon></ArrowDropDownIcon>} variant='contained' style={{width: 'max-content', alignSelf: 'end', margin: '10px', color: 'white', backgroundColor: colors.primary, position: "absolute", right: "0", bottom: "0"}}>{Language[filterStatus] ? Language[filterStatus] : filterStatus }</Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => handleCloseMenu()}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => {handleCloseMenu(); filterMessages('this month')}} >
                            <ListItemIcon>
                              <CalendarMonthIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{Language['This Month']}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => {handleCloseMenu(); filterMessages('this year')}} >
                            <ListItemIcon>
                              <CalendarMonthIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{Language['This Year']}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => {handleCloseMenu(); filterMessages('life time')}} >
                            <ListItemIcon>
                              <CalendarMonthIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{Language['Life Time']}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => {handleCloseMenu(); setOpenFilterMessagesModal(true)}} >
                            <ListItemIcon>
                              <FilterListIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{Language['Advanced Filtering']}</ListItemText>
                            </MenuItem>
                        </Menu>
                  </PricingView>


                  {/* <BalanceActions style={{display: "none"}}>
                    <BalanceFilter>
                    <FormControl variant="standard" style={{width:'30%'}}>
                      <InputLabel id="demo-simple-select-standard-label">Sender</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value="All"
                        defaultValue='All'
                        //onChange={handleChange}
                        label="Age"
                      >
                        <MenuItem value="All">
                          <em>All</em>
                        </MenuItem>
                        <MenuItem value={10}>Mubtakar</MenuItem>
                        <MenuItem value={20}>UNO</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl error={false} variant="standard" style={{width: '40%' , marginLeft: '10px'}}>
                        <InputLabel htmlFor="email"></InputLabel>
                        <Input
                        id="email"
                        defaultValue=""
                        aria-describedby="email_error"
                        type='date'
                        // startAdornment={
                        //   <InputAdornment position="start">
                        //     < SearchIcon />
                        //   </InputAdornment>
                        // }
                        />
                      </FormControl>

                    </BalanceFilter>
                  </BalanceActions> */}
                  
                  <div style={{ height: 'calc(100vh - 300px)' , width: '100%'}}>
                      <DataGrid
                        style={{padding: '0px 0px'}}
                        rows={balance}
                        columns={balanceColumns}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={false}
                        loading={loadingBalance}
                        sortModel={[{field: 'createdAt',sort: 'desc'}]}/>
                  </div>


                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel> */}
                </Box>
            </Form>
        </BodyContent>

        {/* // snackbar */}
        <Snackbar
          open={openAlert}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
            {Language[alertMessage] ? Language[alertMessage] : alertMessage}
          </Alert>
        </Snackbar>

        {/* alert Modal */}
        <Dialog
          open={openDeleteContactModal}
          onClose={handleCloseDeleteContactModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {Language['Delete Contact']}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              
             {Language[DeleteContactModalBody] ? Language[DeleteContactModalBody]  : DeleteContactModalBody}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteContactModal}>{Language['Cancel']}</Button>
            <Button onClick={() => deleteItems(selected_rows)} autoFocus>
            {Language[DeleteContactModalBtn] ? Language[DeleteContactModalBtn]  : DeleteContactModalBtn}
            </Button>
          </DialogActions>
        </Dialog>


          {/* All filters Modal in Message*/}
          <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={openAllFilterMessagesModal}
          onClose={handleCloseAllFilterMessagesModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {Language['Filter Messages']}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {Language['use the fields below to filter messages than press filter button.']}
            </DialogContentText>

            <FormControl error={false} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '5px'}}>
                    {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                    <InputLabel htmlFor="subject">{Language['Sender']}</InputLabel>

                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={sender}
                    onChange={ (e) =>{
                      setSender(e.target.value)
                    }
                    }
                    >
                      <MenuItem value={""}>{Language['All']}</MenuItem>

                    {
                      senders.map(((item) => {
                        return (
                          <MenuItem value={item}>{item}</MenuItem>
                        )
                      }))
                    } 
                    </Select>
                    <FormHelperText id="time_error"></FormHelperText>
            </FormControl>

            <FormControl error={false} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '5px'}}>
                    {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                    <InputLabel htmlFor="subject">{Language['Operator']}</InputLabel>

                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={operator}
                    onChange={ (e) =>{
                      setOperator(e.target.value)
                    }
                    }
                    >
                      <MenuItem value={""}>{"All"}</MenuItem>
                      <MenuItem value={"Djezzy"}>{"Djezzy"}</MenuItem>
                      <MenuItem value={"Mobilis"}>{"Mobilis"}</MenuItem>
                      <MenuItem value={"Ooredoo"}>{"Ooredoo"}</MenuItem>

                    {/* {
                      senders.map(((item) => {
                        return (
                          <MenuItem value={item.source}>{item.source}</MenuItem>
                        )
                      }))
                    } */}
                    </Select>
                    <FormHelperText id="time_error"></FormHelperText>
            </FormControl>

            <FormControl error={false} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '5px'}}>
                    {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                    <InputLabel htmlFor="subject">{Language['Status']}</InputLabel>

                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={status}
                    onChange={ (e) =>{
                      setstatus(e.target.value)
                    }
                    }
                    >
                      <MenuItem value={""}>{Language['All']}</MenuItem>
                      <MenuItem value={"Sent"}>{Language['Sent']}</MenuItem>
                      <MenuItem value={"Failed"}>{Language['Failed']}</MenuItem>

                    {/* {
                      senders.map(((item) => {
                        return (
                          <MenuItem value={item.source}>{item.source}</MenuItem>
                        )
                      }))
                    } */}
                    </Select>
                    <FormHelperText id="time_error"></FormHelperText>
            </FormControl>

            <div className='rowToColumn'>
                <FormControl className='toFullWidth100' error={false} variant="standard" style={{width: '48%', marginTop: '5px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['From']}</p>
                    <Input
                      min="2023-01-01"
                      onChange={(e) => {
                        setFrom(e.target.value)
                      // setFilterFrom(e.target.value)
                      //   setIsErrorTime(false)
                      //   setTimeError('')
                      }}
                      type='date'
                      id=""
                      value={from}
                      aria-describedby="time_error"
                      placeholder=''
                    />
                    <FormHelperText id="time_error"></FormHelperText>
                </FormControl>

                <FormControl className='toFullWidth100' error={false} variant="standard" style={{width: '48%', marginTop: '5px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['To']}</p>
                    <Input
                      onChange={(e) => {
                        setTo(e.target.value)
                        // setFilterTo(e.target.value)
                        // setIsErrorTime(false)
                        // setTimeError('')
                      }}
                      type='date'
                      id=""
                      value={to}
                      aria-describedby="time_error"
                      placeholder=''
                    />
                    <FormHelperText id="time_error"></FormHelperText>
                </FormControl>
            </div>

            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAllFilterMessagesModal}>{Language['Cancel']}</Button>
            <Button onClick={() => filterMessagesAdvanced("")} autoFocus>
            {Language['Filter']}
            </Button>
          </DialogActions>
        </Dialog>


         {/*  filters Modal in scheduled Message*/}
         <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={openFilterScheduledMessagesModal}
          onClose={handleCloseFilterScheduledMessagesModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {Language['Filter scheduled messages']}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {Language['use the fields below to filter messages.']}
            </DialogContentText>

            <FormControl error={false} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '5px'}}>
                    {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                    <InputLabel htmlFor="subject">{Language['Sender']}</InputLabel>

                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={scheduledSender}
                    onChange={ (e) =>{
                      setScheduledSender(e.target.value)
                    }
                    }
                    >
                      <MenuItem value={""}>{Language['All']}</MenuItem>

                    {
                      senders.map(((item) => {
                        return (
                          <MenuItem value={item}>{item}</MenuItem>
                        )
                      }))
                    } 
                    </Select>
                    <FormHelperText id="time_error"></FormHelperText>
            </FormControl>

            <FormControl error={false} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '5px'}}>
                    {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                    <InputLabel htmlFor="subject">{Language['Operator']}</InputLabel>

                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={scheduledOperator}
                    onChange={ (e) =>{
                      setScheduledOperator(e.target.value)
                    }
                    }
                    >
                      <MenuItem value={""}>{Language['All']}</MenuItem>
                      <MenuItem value={"Djezzy"}>{"Djezzy"}</MenuItem>
                      <MenuItem value={"Mobilis"}>{"Mobilis"}</MenuItem>
                      <MenuItem value={"Ooredoo"}>{"Ooredoo"}</MenuItem>

                    {/* {
                      senders.map(((item) => {
                        return (
                          <MenuItem value={item.source}>{item.source}</MenuItem>
                        )
                      }))
                    } */}
                    </Select>
                    <FormHelperText id="time_error"></FormHelperText>
            </FormControl>


            <FormControl  error={false} variant="standard" style={{width: '100%', marginTop: '5px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['Send At']}</p>
                    <Input
                      min="2023-01-01"
                      onChange={(e) => {
                        setScheduledSentAt(e.target.value)
                      // setFilterFrom(e.target.value)
                      //   setIsErrorTime(false)
                      //   setTimeError('')
                      }}
                      type='date'
                      id=""
                      value={scheduledSentAt}
                      aria-describedby="time_error"
                      placeholder=''
                    />
                    <FormHelperText id="time_error"></FormHelperText>
              </FormControl>


            <FormControl error={false} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '5px'}}>
                    {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                    <InputLabel htmlFor="subject">{Language['Repeted']}</InputLabel>

                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={scheduledRepeted}
                    onChange={ (e) =>{
                      setScheduledRepeted(e.target.value)
                    }
                    }
                    >
                      <MenuItem value={""}>{Language['']}{"All"}</MenuItem>
                      <MenuItem value={"no-repeat"}>{Language['no repeat']}</MenuItem>
                      <MenuItem value={"month"}>{Language['month']}</MenuItem>
                      <MenuItem value={"year"}>{Language['year']}</MenuItem>
                   
                    </Select>
                    <FormHelperText id="time_error"></FormHelperText>
            </FormControl>

            


            <div className='rowToColumn'>
                <FormControl className='toFullWidth100' error={false} variant="standard" style={{width: '48%', marginTop: '5px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['From']}</p>
                    <Input
                      min="2023-01-01"
                      onChange={(e) => {
                        setScheduledFrom(e.target.value)
                      // setFilterFrom(e.target.value)
                      //   setIsErrorTime(false)
                      //   setTimeError('')
                      }}
                      type='date'
                      id=""
                      value={scheduledFrom}
                      aria-describedby="time_error"
                      placeholder=''
                    />
                    <FormHelperText id="time_error"></FormHelperText>
                </FormControl>

                <FormControl className='toFullWidth100' error={false} variant="standard" style={{width: '48%', marginTop: '5px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['To']}</p>
                    <Input
                      onChange={(e) => {
                        setScheduledTo(e.target.value)
                        // setFilterTo(e.target.value)
                        // setIsErrorTime(false)
                        // setTimeError('')
                      }}
                      type='date'
                      id=""
                      value={scheduledTo}
                      aria-describedby="time_error"
                      placeholder=''
                    />
                    <FormHelperText id="time_error"></FormHelperText>
                </FormControl>
            </div>

            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFilterScheduledMessagesModal}>{Language['Cancel']}</Button>
            <Button onClick={() => filterScheduledMessages("")} autoFocus>
            {Language['Filter']}
            </Button>
          </DialogActions>
        </Dialog>



         {/* filter Modal in Balance*/}
         <Dialog
          open={openFilterMessagesModal}
          onClose={handleCloseFilterMessagesModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {Language['Filter Messages']}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {Language['use the fields below to filter messages based on the date range you specify.']}
            </DialogContentText>

            <FormControl error={false} variant="standard" style={{width: '100%', marginTop: '30px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['From']}</p>
                    <Input
                      onChange={(e) => {
                        setFilterFrom(e.target.value)
                      //   setIsErrorTime(false)
                      //   setTimeError('')
                      }}
                      type='date'
                      id=""
                      defaultValue=""
                      aria-describedby="time_error"
                      placeholder=''
                    />
                    <FormHelperText id="time_error"></FormHelperText>
                </FormControl>

                <FormControl error={false} variant="standard" style={{width: '100%', marginTop: '30px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['To']}</p>
                    <Input
                      onChange={(e) => {
                        setFilterTo(e.target.value)
                        // setIsErrorTime(false)
                        // setTimeError('')
                      }}
                      type='date'
                      id=""
                      defaultValue=""
                      aria-describedby="time_error"
                      placeholder=''
                    />
                    <FormHelperText id="time_error"></FormHelperText>
                </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFilterMessagesModal}>{Language['Cancel']}</Button>
            <Button onClick={() => filterMessages("advanced")} autoFocus>
            {Language['Filter']}
            </Button>
          </DialogActions>
        </Dialog>

    </Body>
  )
}
