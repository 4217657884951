import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NewClient from './newClient';
import styled from 'styled-components'
import { Alert, Autocomplete, Button, CircularProgress, FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, ListItemIcon, Menu, MenuItem, OutlinedInput, Select, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import MailingConfig from '../serviceEmail/config';
import Config from '../serviceSMS/config';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { AddCircleOutline, AddOutlined, AddToPhotos, AdminPanelSettings, Autorenew, CalendarMonth, ContactMail, ContactMailOutlined, Delete, DeleteOutlineOutlined, EditOutlined, Email, EmailOutlined, FilterList, FilterListOffOutlined, FilterListOutlined, History, KeyboardArrowDown, KeyboardArrowLeft, LaunchOutlined, LocalAtmOutlined, Lock, LockOutlined, Person, ReceiptLongOutlined, Rectangle, Settings, SimCardOutlined, SmsOutlined, Upload } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingContainer from '../reusableComponent/loadingContainer';
import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import colors from '../../colors';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Home  = styled.div`
    width: 100%;
    padding: 0%;
    height: max-content;
    display: flex;
    flex-direction: ${props => props.width > 800? 'row' : 'column'};
    justify-content: ${props => props.width > 800? 'space-between' : 'start'};
    align-items: start;
    flex-wrap:${props => props.width > 800? 'wrap' : 'nowrap'};
`

const ClientInfo = styled.div`
    //min-width: 400px;
    width: ${props => props.width > 800? '40%' : '100%'} ;
    //max-width: 80% !important;
    background-color: whitesmoke;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 0px;
`

const ClientSettings = styled.div`
    //min-width: 400px;
    width: ${props => props.width > 800? '58%' : '100%'} ;
    //background-color: whitesmoke;
    border-radius: 6px;
    margin-top: ${props => props.width > 800? '0px' : '20px'} ;

`

const Content1 = styled.div`
    width: 80%;
    min-width: 300px;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`
const SubContainer = styled.div`
    width: calc(100%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
`

const Headertitle = styled.p`
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    border-left: 5px solid ${colors.primary} ;
    width: calc(100% - 30px);
    margin-bottom: 20px;
`
const Headertitle2 = styled.p`
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    //margin-bottom: 5px;
`

const Headerparagraph2 = styled.p`
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    color: #00000071;
`

const Container2 = styled.div`
    width: calc(100% - 40px);
    min-height: 200px;
    margin: 10px 0px;
    padding: 10px 20px 0px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 1px 0px grey;
`
const Container3 = styled.div`
    width:${props => props.width > 800? 'calc(100% - 40px)' : 'calc(100% - 10px)'} ;
    min-height: 200px;
    margin: 10px 0px;
    padding:${props => props.width > 800? '10px 20px 0px 20px' : '10px 5px 0px 5px'} ;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 1px 0px grey;
`
const ListItemText1 = styled.div`
    width:${props => props.width > 800? '80%' : 'calc( 100% - 60px )'} ;
    //min-width: 180px;
    //max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    //flex-grow: 1;
`

const ListItemText2 = styled.div`
    width: max-content;
    height: 100%;
    display: flex;
    padding: 5px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
`

const ListItemTextPrimary = styled.p`
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const ListItemTextPrimarySpan = styled.p`
    width:${props => props.width > 800? 'max-content' : 'calc( 100% - 30px )'} ;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const ListItemTextSecondary = styled.p`
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #00000071;
    margin-top: -5px;
`

const Header  = styled.div`
    width: calc(100% - 20px);
    padding: 0%;
    display: flex;
    flex-direction: ${props => props.width > 800? 'row' : 'column'};
    justify-content: ${props => props.width > 800? 'space-between' : 'start'};
    align-items: start;
    flex-wrap:${props => props.width > 800? "wrap" : 'nowrap'};
    border-radius: 15px;
    padding: 10px;
`

const SubHeader  = styled.div`
    width: ${props => props.width > 800? '30%' : 'calc(100% - 20px)'};
    margin-bottom: ${props => props.width > 800? '10px' : '10px'};
    min-width: 230px;
    padding: 0%;
    height: 130px;
    display: flex;
    flex-direction: ${props => props.width > 800? 'column' : 'column'};
    justify-content: ${props => props.width > 800? 'start' : 'start'};
    align-items: start;
    flex-wrap:${props => props.width > 800? 'nowrap' : 'nowrap'};
    background-color: #ffffff !important;
    border-radius: 15px;
    padding: 10px;
`


export default function Client({Language}) {

    // mui *************

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const handleClickOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };


    const [openDeleteSMTPConfigModal, setOpenDeleteSMTPConfigModal] = React.useState(false);
    const handleClickOpenDeleteSMTPConfigModal = () => {
        setOpenDeleteSMTPConfigModal(true);
    };
    const handleCloseDeleteSMTPConfigModal = () => {
        setOpenDeleteSMTPConfigModal(false);
    };

    const [openDeleteSenderIdModal, setOpenDeleteSenderIdModal] = React.useState(false);
    const handleClickOpenDeleteSenderIdModal = () => {
        setOpenDeleteSenderIdModal(true);
    };
    const handleCloseDeleteSenderIdModal = () => {
        setOpenDeleteSenderIdModal(false);
    };


    const [openBanModal, setOpenBanModal] = React.useState(false);
    const handleClickOpenBanModal = () => {
        setOpenBanModal(true);
    };
    const handleCloseBanModal = () => {
        setOpenBanModal(false);
    };


    const [openSMTPModal, setOpenSMTPModal] = React.useState(false);
    const handleClickOpenSMTPModal = () => {
        setOpenSMTPModal(true);
    };
    const handleCloseSMTPModal = () => {
        setOpenSMTPModal(false);
    };


    const [openSenderIdModal, setOpenSenderIdModal] = React.useState(false);
    const handleClickOpenSenderIdModal = () => {
        setOpenSenderIdModal(true);
    };
    const handleCloseSenderIdModal = () => {
        setOpenSenderIdModal(false);
    };


    const [openSMTPLimitModal, setOpenSMTPLimitModal] = React.useState(false);
    const handleClickOpenSMTPLimitModal = () => {
        setOpenSMTPLimitModal(true);
    };
    const handleCloseSMTPLimitModal = () => {
        setOpenSMTPLimitModal(false);
    };


    // mui ended *************
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
    const [firstName, setFirstName] = useState({value: '', error: false, helperText: ''});
    const [familyName, setFamilyName] = useState({value: '', error: false, helperText: ''});
    const [phoneNumber, setPhoneNumber] = useState({value: '', error: false, helperText: ''})
    const [society, setSociety] = useState({value: '', error: false, helperText: ''});
    const [societyMail, setSocietyMail] = useState({value: '', error: false, helperText: ''});
    const [societyPhone, setSocietyPhone] = useState({value: '', error: false, helperText: ''});
    const [societyLocation, setSocietyLocation] = useState({value: '', error: false, helperText: ''});
    const [societyBankAccount, setSocietyBankAccount] = useState({value: '', error: false, helperText: ''});
    const [societyPostalAccount, setSocietyPostalAccount] = useState({value: '', error: false, helperText: ''});
    const [societyRC, setSocietyRC] = useState({value: '', error: false, helperText: ''});
    const [societyNIC, setSocietyNIC] = useState({value: '', error: false, helperText: ''});
    const [societyNIF, setSocietyNIF] = useState({value: '', error: false, helperText: ''});
    const [societyAI, setSocietyAI] = useState({value: '', error: false, helperText: ''});
    const [societyWebSite, setSocietyWebSite] = useState({value: '', error: false, helperText: ''});

    const [email, setEmail] = useState({value: '', error: false, helperText: ''});
    const [password, setPassword] = useState({value: '', error: false, helperText: ''});
    const [sendLoginEmail, setSendLoginEmail] = useState(true);

    const [alert, setAlert] = useState({open: false, type: '', value: ''});
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        handleAlertChanges(false, '', 'error')
    };


    const handleFirstNameChange = (e) => {
        setFirstName((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };

    const handleFamilyNameChange = (e) => {
        setFamilyName((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };


    const handleSocietyChange = (e) => {
        setSociety((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };


    const handleEmailChangeChange = (e) => {
        setEmail((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };

    const handlePasswordChangeChange = (e) => {
        setPassword((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };


    function handleAlertChanges(open, value, type){
        setAlert((prevFirstName) => ({
            ...prevFirstName,
            open,
            value,
            type
        }));
        setTimeout(() => {
            setAlert((prevFirstName) => ({
                ...prevFirstName,
                open: false,
                value: '',
                type
            }));  
        }, 3000);
    }
    
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const [filterFrom, setFilterFrom] = useState('');
    const [filterTo, setFilterTo] = useState('');
    const [filterStatus, setfilterStatus] = useState("This month")
  
    const handleCloseMenu = (link) => {
        if(link === 'account'){
            navigate('/app/profile');
        }
        setAnchorEl(null);
    };


    const [openFilterMessagesModal, setOpenFilterMessagesModal] = React.useState(false);
    const handleCloseFilterMessagesModal = () => {
      setOpenFilterMessagesModal(false);
    };

    const [totalSent, setTotalSent] = useState(0);
    const [totlaConsumed, setTotlaConsumed] = useState(0);
    const [totalConsumedWithProfit, setTotalConsumedWithProfit] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [totalSmsInDjezzy, setTotalSmsInDjezzy] = useState(0);
    const [totalSmsInMobilis, setTotalSmsInMobilis] = useState(0);
    const [totalSmsInOoredoo, setTotalSmsInOoredoo] = useState(0);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [allSMS, setAllSMS] = useState([]);

    function filterMessages(type){
        
        handleCloseFilterMessagesModal()
        setLoadingMessages(true)
        var startAt = '', endAt = ''
        if(type === "advanced"){
          startAt = filterFrom
          endAt = filterTo
          setfilterStatus(filterFrom + ' > ' + filterTo)
        }else{
          setfilterStatus(type)
        }
        axios.post("/admin_filterWithDateForClientSMS", {
          id, type, startAt, endAt,
        })
        .then(function (response) {
          setLoadingMessages(false)
  
          if(response.data.response === 'done'){
            setFiltering(false)

                //setbalance(response.data.messages)
            setTotalSent(response.data.sent)
            setTotalProfit(response.data.profit)
            setTotlaConsumed(response.data.consumed)
            setTotalSmsInDjezzy(response.data.djezzySMS)
            setTotalSmsInOoredoo(response.data.ooredooSMS)
            setTotalSmsInMobilis(response.data.mobilisSMS)
            setAllSMS(response.data.myAllSMS)
            // setBalance(response.data.balance)
            setTotalConsumedWithProfit(response.data.consumedWithProfit)
            setOoredooCount(response.data.ooredooCount)
            setMobilisCount(response.data.mobilisCount)
            setDjezzyCount(response.data.djezzyCount)
            setSentCount(response.data.sentCount)
          }
          else if(response.data.response === 'server_error'){
            setAlert((prevFirstName) => ({
                ...prevFirstName,
                open: true,
                value: 'An unexpected error has occured',
                type: 'error'
            }));
            setTimeout(() => {
                setAlert((prevFirstName) => ({
                    ...prevFirstName,
                    open: false,
                    value: '',
                    type
                }));  
            }, 3000);
            // redirect to server error page
          }
          else if(response.data.response === 'redirect'){
            navigate('/login', { replace: true });
          }
        })
        .catch(function (error) {
          // handle error
          setLoadingMessages(false)
          console.log(error)
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
                setAlert((prevFirstName) => ({
                    ...prevFirstName,
                    open: true,
                    value: 'An unexpected error has occured',
                    type: 'error'
                }));
                setTimeout(() => {
                    setAlert((prevFirstName) => ({
                        ...prevFirstName,
                        open: false,
                        value: '',
                        type
                    }));  
                }, 3000);             
            }
          }else{
            setAlert((prevFirstName) => ({
                ...prevFirstName,
                open: true,
                value: 'An unexpected error has occured',
                type: 'error'
            }));
            setTimeout(() => {
                setAlert((prevFirstName) => ({
                    ...prevFirstName,
                    open: false,
                    value: '',
                    type
                }));  
            }, 3000); 
          }
        })
      
      }

      
  
    function handleSubmit(){
        // reset form
        setFirstName((prevFirstName) => ({...prevFirstName, helperText: '', error: false }));
        setFamilyName((prevFirstName) => ({...prevFirstName,  helperText: '',  error: false  }));
        setPhoneNumber((prevFirstName) => ({ ...prevFirstName, helperText: '', error: false }));
        setEmail((prevFirstName) => ({  ...prevFirstName, helperText: '', error: false }));
        setSociety((prevValue) => ({ ...prevValue, helperText: '', error: false }));
        setSocietyLocation((prevValue) => ({ ...prevValue, helperText: '', error: false }));
        setSocietyPhone((prevValue) => ({ ...prevValue, helperText: '', error: false }));
        setSocietyMail((prevValue) => ({ ...prevValue, helperText: '', error: false }));
        setSocietyWebSite((prevValue) => ({ ...prevValue, helperText: '', error: false }));
        setSocietyRC((prevValue) => ({ ...prevValue, helperText: '', error: false }));
        setSocietyAI((prevValue) => ({ ...prevValue, helperText: '', error: false }));
        setSocietyNIF((prevValue) => ({ ...prevValue, helperText: '', error: false }));
        setSocietyNIC((prevValue) => ({ ...prevValue, helperText: '', error: false }));
        setSocietyBankAccount((prevValue) => ({ ...prevValue, helperText: '', error: false }));
        setSocietyPostalAccount((prevValue) => ({ ...prevValue, helperText: '', error: false }));        setLoadingSubmit(true)
        
        axios.post("/admin_edit_client", {
            id, firstName: firstName.value, familyName: familyName.value, phoneNumber: phoneNumber.value, email: email.value, society: society.value, societyLocation: societyLocation.value, societyMail: societyMail.value, societyPhone: societyPhone.value, societyWebSite: societyWebSite.value, societyBankAccount: societyBankAccount.value, societyPostalAccount: societyPostalAccount.value, societyRC: societyRC.value, societyAI: societyAI.value, societyNIF: societyNIF.value, societyNIC: societyNIC.value
        })
            .then(function (response) {
                setLoadingSubmit(false)
                console.log(response.data)
                if(response.data.response === "created"){
                    setClient(response.data.client)
                    handleAlertChanges(true, response.data.message, 'success')
                }
                else if(response.data.response === "input_error") {
                    for(let i = 0; i < response.data.error.length; i++){
                    if(response.data.error[i].path === 'firstName'){
                        setEmail((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }else if(response.data.error[i].path === 'familyName'){
                        setFamilyName((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }
                    else if(response.data.error[i].path === 'phoneNumber'){
                        setPhoneNumber((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }
                    else if(response.data.error[i].path === 'society'){
                        setSociety((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }
                    else if(response.data.error[i].path === 'email'){
                        setEmail((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }
                    }
                }
                else if(response.data.response === "server_error") {
                    handleAlertChanges(true, response.data.message, 'error')
                }
                else{
                    handleAlertChanges(true, 'session ended', 'error')
                    setTimeout(() => {
                     navigate('/admin', { replace: true });
                    }, 2000);
                }
            })
            .catch(function (error) {
                // handle error
                setLoadingSubmit(false)
                handleAlertChanges(true, 'An unexpected error has occured', 'error')
            })
      }


    const location = useLocation();
    const id = new URLSearchParams(location.search).get('id');


     
    // ban user *************
    const [unLimitedBanLoading, setUnLimitedBanLoading] = useState(false);
    const [limitedBanLoading, setLimitedBanLoading] = useState(false);
    const [disabledBan, setDisabledBan] = useState(false);

    function handleBanClient(type){
        if(type === "unlimited"){
            setUnLimitedBanLoading(true)
        }else if(type === 'limited'){
            setLimitedBanLoading('true')
        }
        setDisabledBan(true)
        axios.post("/ban_client", {
            id, type
        })
        .then(function (response) {
            //console.log(response.data)
            handleCloseBanModal()
            if(response.data.response === 'done'){
                setClient(response.data.client)
                setDisabledBan(false)
                setLimitedBanLoading(false)
                setUnLimitedBanLoading(false)
                handleAlertChanges(true, 'Client banned successfully', 'success')
            }else{
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            handleAlertChanges(true, error.message, 'error')
        })

    }

    // unban user
    const [unbanuserLoading, setUnbanuserLoading] = useState(false);

    function handleUnBanClient(){
        setUnbanuserLoading(true)
        axios.post("/ban_client", {
            id, type: 'unban'
        })
        .then(function (response) {
            //console.log(response.data)
            if(response.data.response === 'done'){
                setClient(response.data.client)
                setUnbanuserLoading(false)
                handleAlertChanges(true, 'Client Unbanned successfully', 'success')
            }else{
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            setUnbanuserLoading(false)
            handleAlertChanges(true, error.message, 'error')
        })

    }


    // delete user

    const [loadingDelete, setLoadingDelete] = useState(false);

    function handleDeleteClient(){
        setLoadingDelete(true)
        axios.post("/delete_client", {
            id, 
        })
        .then(function (response) {
            //console.log(response.data)
            setLoadingDelete(false)
            if(response.data.response === 'done'){
                handleAlertChanges(true, 'Client deleted successfully', 'success')
                setTimeout(() => {
                    navigate('/admin/clients', {replace: 'true'})
                }, 1000);
            }else{
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            setLoadingDelete(false)
            handleAlertChanges(true, error.message, 'error')
        })

    }


    // edit subs days

    const [loadingSubs, setLoadingSubs] = useState(false);

    const [SubsDays, setSubsDays] = useState(0)

    function handlesubsDays(){
        setLoadingSubs(true)
        axios.post("/edit_subs_client", {
            id, SubsDays
        })
        .then(function (response) {
            //console.log(response.data)
            setLoadingSubs(false)
            if(response.data.response === 'done'){
                handleAlertChanges(true, 'Subscription days changed successfully', 'success')
                setClient(response.data.client)
                setSubsDays(0)
            }else{
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            setLoadingSubs(false)
            handleAlertChanges(true, error.message, 'error')
        })

    }




    // edit SMTP SOLD 

    const [loadinSmtpSold, setLoadingSmtpSold] = useState(false);

    const [SmtpSold, setSmtpSold] = useState({value: '', error: false, helperText: ''});
    const [selectedSMTP, setSelectedSMTP] = useState();

    function handleSmtpSold(){
        setLoadingSmtpSold(true)
        axios.post("/edit_smtp_sold", {
            id, sold: SmtpSold.value, smtp: selectedSMTP
        })
        .then(function (response) {
            //console.log(response.data)
            setLoadingSmtpSold(false)
            if(response.data.response === 'done'){
                handleAlertChanges(true, 'Sold added successfully', 'success')
                setOpenSMTPModal(false)
                setSmtpConfigs(response.data.smtpConfigs)
                setSmtpSold((prevData) => ({
                    ...prevData,
                    value: '',
                    error: false,
                    helperText: ''
                }));
            }else if (response.data.response === 'input_error'){
                setSmtpSold((prevData) => ({
                    ...prevData,
                    error: true,
                    helperText: response.data.error
                }));
            } else if (response.data.response === 'alert_error'){
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            setLoadingSmtpSold(false)
            handleAlertChanges(true, 'An unexpected error has occured', 'error')
        })
    }


    // edit SenderId SOLD 

    const [loadinSenderIdSold, setLoadingSenderIdSold] = useState(false);

    const [SenderIdSold, setSenderIdSold] = useState({value: '', error: false, helperText: ''});
    const [selectedSenderId, setSelectedSenderId] = useState();

    function handleSenderIdSold(){
        setLoadingSenderIdSold(true)
        axios.post("/edit_senderId_sold", {
            id, sold: SenderIdSold.value, senderId: selectedSenderId
        })
        .then(function (response) {
            //console.log(response.data)
            setLoadingSenderIdSold(false)
            if(response.data.response === 'done'){
                handleAlertChanges(true, 'Sold added successfully', 'success')
                setOpenSenderIdModal(false)
                setSenderIds(response.data.senderIds)
                setSenderIdSold((prevData) => ({
                    ...prevData,
                    value: '',
                    error: false,
                    helperText: ''
                }));
            }else if (response.data.response === 'input_error'){
                setSenderIdSold((prevData) => ({
                    ...prevData,
                    error: true,
                    helperText: response.data.error
                }));
            } else if (response.data.response === 'alert_error'){
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            setLoadingSenderIdSold(false)
            handleAlertChanges(true, 'An unexpected error has occured', 'error')
        })
    }


    // edit SMTP limit 

    const [loadinSmtpLimit, setLoadingSmtpLimit] = useState(false);

    const [SmtpLimit, setSmtpLimit] = useState({value: '', error: false, helperText: ''});

    function handleSmtpLimit(){
        setLoadingSmtpLimit(true)
        axios.post("/edit_smtp_limit", {
            id, limit: SmtpLimit.value, smtp: selectedSMTP
        })
        .then(function (response) {
            //console.log(response.data)
            setLoadingSmtpLimit(false)
            if(response.data.response === 'done'){
                handleAlertChanges(true, 'Limit changed successfully', 'success')
                setOpenSMTPLimitModal(false)
                setSmtpConfigs(response.data.smtpConfigs)
                setSmtpLimit((prevData) => ({
                    ...prevData,
                    value: '',
                    error: false,
                    helperText: ''
                }));
            }else if (response.data.response === 'input_error'){
                setSmtpLimit((prevData) => ({
                    ...prevData,
                    error: true,
                    helperText: response.data.error
                }));
            } else if (response.data.response === 'alert_error'){
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            setLoadingSmtpLimit(false)
            handleAlertChanges(true, 'An unexpected error has occured', 'error')
        })
    }


    // delete SMTP config 

    const [loadinDeleteSmtp, setLoadingDeleteSmtp] = useState(false);

    function handleDeleteSmtp(){
        setLoadingDeleteSmtp(true)
        axios.post("/delete_smtp_admin", {
            id, smtp: selectedSMTP
        })
        .then(function (response) {
            //console.log(response.data)
            setLoadingDeleteSmtp(false)
            if(response.data.response === 'done'){
                handleAlertChanges(true, 'Smtp account deleted successfully', 'success')
                setOpenDeleteSMTPConfigModal(false)
                setSmtpConfigs(response.data.smtpConfigs)
            } else if (response.data.response === 'alert_error'){
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            setLoadingDeleteSmtp(false)
            handleAlertChanges(true, 'An unexpected error has occured', 'error')
        })
    }


    // Create sender id

    const [loadinCreateSenderId, setLoadingCreateSenderId] = useState(false);
    const [SenderId, setSenderId] = useState({value: '', error: false, helperText: ''});
    const [senderIds, setSenderIds] = useState([]);

    function handleCreateSenderId(){
        setLoadingCreateSenderId(true)
        axios.post("/create_sender_id", {
            id, senderId: SenderId.value
        })
        .then(function (response) {
            //console.log(response.data)
            setLoadingCreateSenderId(false)
            if(response.data.response === 'done'){
                handleAlertChanges(true, 'SenderId created successfully', 'success')
                setSenderIds(response.data.senderIds)
                setSenderId((prevData) => ({
                    ...prevData,
                    value: '',
                    error: false,
                    helperText: ''
                }));
            }else if (response.data.response === 'input_error'){
                setSenderId((prevData) => ({
                    ...prevData,
                    error: true,
                    helperText: response.data.error
                }));
            } else if (response.data.response === 'alert_error'){
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            setLoadingCreateSenderId(false)
            handleAlertChanges(true, 'An unexpected error has occured', 'error')
        })
    }


    // delete SMTP config 

    const [loadinDeleteSenderId, setLoadingDeleteSenderId] = useState(false);

    function handleDeleteSenderId(){
        setLoadingDeleteSenderId(true)
        axios.post("/delete_senderId_admin", {
            id, senderId: selectedSenderId
        })
        .then(function (response) {
            //console.log(response.data)
            setLoadingDeleteSenderId(false)
            if(response.data.response === 'done'){
                handleAlertChanges(true, 'Smtp account deleted successfully', 'success')
                setOpenDeleteSenderIdModal(false)
                setSenderIds(response.data.senderIds)
            } else if (response.data.response === 'alert_error'){
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            setLoadingDeleteSenderId(false)
            handleAlertChanges(true, 'An unexpected error has occured', 'error')
        })
    }


    // init page
    const [client, setClient] = useState({});
    const [smtpConfigs, setSmtpConfigs] = useState([]);

    const [ooredooCount, setOoredooCount] = useState(0);
    const [djezzyCount, setDjezzyCount] = useState(0);
    const [mobilisCount, setMobilisCount] = useState(0);
    const [sentCount, setSentCount] = useState(0);

    useEffect(() => {
        axios.post("/get_client", {
            id
        })
        .then(function (response) {
            console.log(response.data.history)
            if(response.data.response === 'done'){
                setClient(response.data.client)
                setSmtpConfigs(response.data.smtpConfigs)
                setSenderIds(response.data.senderIds)
                setSenders(response.data.senderIds)
                setTotalSmsInDjezzy(response.data.djezzySMS)
                setTotalSmsInMobilis(response.data.mobilisSMS)
                setTotalSmsInOoredoo(response.data.ooredooSMS)
                setTotalSent(response.data.sent)
                setTotalConsumedWithProfit(response.data.consumedWithProfit)
                setTotlaConsumed(response.data.consumed)
                setTotalConsumedWithProfit(response.data.consumedWithProfit)
                setTotalProfit(response.data.profit)
                setAllSMS(response.data.messages)
                setHistory(response.data.history)
                setOoredooCount(response.data.ooredooCount)
                setMobilisCount(response.data.mobilisCount)
                setDjezzyCount(response.data.djezzyCount)
                setSentCount(response.data.sentCount)
                var done = 'init'
                if(client.first_name === firstName.value && client.family_name === familyName.value && '0' + client.phoneNumber === phoneNumber.value && client.email === email.value && client.society === society.value && client.societyPhoneNumber === societyPhone.value && client.societyMail === societyMail.value && client.societyLocation === societyLocation.value && client.societyWebSite === societyWebSite.value && client.societyBankAccount === societyBankAccount.value && client.societyPostalAccount === societyPostalAccount.value && client.societyRC === societyRC.value && client.societyAI === societyAI.value && client.societyNIC === societyNIC.value && client.societyNIF === societyNIF.value){
                    done = 'same'
                }else{
                    done = "failed"
                }
                console.log('result: ', done)
                setFirstName((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.first_name,
                    helperText: '',
                    error: false
                }));

                setFamilyName((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.family_name,
                    helperText: '',
                    error: false
                }));

                setEmail((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.email,
                    helperText: '',
                    error: false
                }));

                setPhoneNumber((prevValue) => ({
                    ...prevValue,
                    value: '0' + response.data.client.phoneNumber,
                    helperText: '',
                    error: false
                }));

                setSociety((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.society,
                    helperText: '',
                    error: false
                }));

                setSocietyLocation((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.societyLocation,
                    helperText: '',
                    error: false
                }));

                setSocietyMail((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.societyMail,
                    helperText: '',
                    error: false
                }));

                setSocietyPhone((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.societyPhoneNumber,
                    helperText: '',
                    error: false
                }));

                setSocietyWebSite((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.societyWebSite,
                    helperText: '',
                    error: false
                }));

                setSocietyRC((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.societyRC,
                    helperText: '',
                    error: false
                }));

                setSocietyAI((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.societyAI,
                    helperText: '',
                    error: false
                }));

                setSocietyNIF((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.societyNIF,
                    helperText: '',
                    error: false
                }));

                setSocietyNIC((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.societyNIC,
                    helperText: '',
                    error: false
                }));

                setSocietyBankAccount((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.societyBankAccount,
                    helperText: '',
                    error: false
                }));

                setSocietyPostalAccount((prevValue) => ({
                    ...prevValue,
                    value: response.data.client.societyPostalAccount,
                    helperText: '',
                    error: false
                }));


            }
        })
        .catch(function (error) {
            // handle error
            handleAlertChanges(true, error.message, 'error')
        })
    }, []);

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

    const updateDeviceWidth = () => {
        setDeviceWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener('resize', updateDeviceWidth);
        return () => {
        window.removeEventListener('resize', updateDeviceWidth);
        };
    }, []);

    const [daysLeft, setdaysLeft] = useState(0);
    useEffect(() => {
        const currentDate = new Date();
        const otherDate = new Date(client.expirationDate);
        const utcCurrentDate = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const utcOtherDate = Date.UTC(otherDate.getFullYear(), otherDate.getMonth(), otherDate.getDate());
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const timeDifference = utcOtherDate - utcCurrentDate;
        const daysDifference = Math.floor(timeDifference / millisecondsPerDay);
        console.log(daysDifference)
        setdaysLeft(daysDifference) ;
    }, [client]);

    const [loading, setLoading] = useState(false);

    const [history, setHistory] = useState([]);
    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const columns = [
        // {
        //   field: 'id',
        //   headerName: 'Id',
        //   width: 150,
        // },
        {
          field: 'actor',
          headerName: Language['Actor'],
          width: 120,
          renderCell: (params) => (
            <>
                <Button variant='text' startIcon={capitalize(params.row.actor) === 'Admin' ? <AdminPanelSettings/> : <Person/>} color={capitalize(params.row.actor) === 'Admin' ? 'success' : 'info'} onClick={(e)=> e.stopPropagation()} >{capitalize(params.row.actor)}</Button>
                <span style={{ fontWeight: 600 }}>
                
                </span>
            </>
            
          ),
        },
        {
          field: 'message',
          headerName: Language['Message'],
          flex: 1,
          minWidth: 350,
          renderCell: (params) => (
            <div style={{ whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {params.value}
            </div>
          ),
        },
        {
            field: 'createdAt',
            headerName: Language['Date'],
            width: 160,
            renderCell: (params) => {
                var newDateString = '--T--'
                if(params.row.createdAt){
                    var timestampString = params.row.createdAt;
                    var timestamp = new Date(timestampString);
                    timestamp.setHours(timestamp.getHours() + 1);
                    //var newTimestampString = timestamp.toISOString();
                    newDateString = timestamp.toISOString();
                }
                
                return(
                    <span>{newDateString ? newDateString.split('T')[0] : '--'} {newDateString ? newDateString.split('T')[1].slice(0, 5) : '--'}</span>
                )
            }
        },
    ];

    const smsColumns = [
    
        //   {
        //     field: 'society',
        //     headerName: 'Society',
        //     sortable: false,
        //     width: 150,
        //     valueGetter: (params) =>
        //       `${params.row.contact.society || ''}`,
        // },
          // { field: 'society', headerName: 'Society', width: 150 },
          {
            field: 'createdAT',
            headerName: Language['Date'],
            sortable: false,
            width: 130,
            renderCell: (params) => {
                var originalDate = new Date(params.row.createdAt);
                originalDate.setHours(originalDate.getHours() + 1);
                var newString = originalDate.toISOString();
              return(
                <>{newString.split('T')[0]} {newString.split('T')[1].slice(0, 5)}</>
              )
            }
          },
          { field: 'subject', headerName: Language['Source'], width: 120 },
          { field: 'phone_number', headerName: Language['Phone'], width: 130 },
          { field: 'message', headerName: Language['Message'], width: 150},
          {
            field: 'Consumed',
            headerName: Language['Consumed'],
            sortable: false,
            width: 100,
            renderCell: (params) => {
              return(
                <>{Number(params.row.price) ? Number(params.row.price).toFixed(2) + ' DZD': '--' }</>
              )
            }
           },
           {
            field: 'price',
            headerName: Language['Price'],
            sortable: false,
            width: 100,
            renderCell: (params) => {
              return(
                <>{Number(params.row.priceWithProfit) ? Number(params.row.priceWithProfit).toFixed(2) + ' DZD' : '--' }</>
              )
            }
           },
          // { field: 'count', headerName: 'Count', width: 80 },
        //   {
        //     field: 'count',
        //     headerName: 'Count',
        //     sortable: false,
        //     width: 80,
        //     renderCell: (params) => {
        //       var count = 0
        //       const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
        //           if (arabicRegex.test(params.row.message)) {
        //               if(params.row.message.length > 70){
        //                   count = Math.floor((params.row.message.length / 67 ) + 0.999999999)
        //               }else{
        //                   count = Math.floor((params.row.message.length / 70 ) + 0.999999999)
        //               }
        //           } else {
        //               if(params.row.message.length > 160){
        //                   count = Math.floor((params.row.message.length / 153 ) + 0.999999999)
        //               }else{
        //                   count = Math.floor((params.row.message.length / 160 ) + 0.999999999)
        //               }
        //           }  
        //       return(
        //           <>{count}</>
        //         )
        //     }
        //   },
          // { field: 'status', headerName: 'Status', width: 150 },
          { field: 'description', headerName: Language['Description'], width: 300,  },
        
          // { field: 'date', headerName: 'Date',  width: 150},
        ];

    
    const [sender, setSender] = useState('');
    const [senderObject, setSenderObject] = useState();
    const [operator, setOperator] = useState('');
    const [status, setstatus] = useState('');
    const [senders, setSenders] = useState([]);


    function removeFilter(){
        setFiltering(false)
        setSender('')
        setSenderObject()
        setOperator('')
        setstatus('')
        filterSMS('', '', '')
    }

    const getRowClassName = (params) => {
        const status = params.row.status;
        if (status === 'sent') {
          return 'sent-row1';
        } else if (status === 'failed') {
          return 'failed-row1';
        }
        return '';
    };

    const [openAllFilterMessagesModal, setOpenAllFilterMessagesModal] = React.useState(false);
      const handleCloseAllFilterMessagesModal = () => {
        setOpenAllFilterMessagesModal(false);
    };

    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleSelectionModelChange = (newSelection) => {
      setSelectionModel(newSelection);
      setSelectedRows(newSelection)
    }
    const [exportBtnDisabled, setExportBtnDisabled] = useState(false);

    function  exportMessages(rows){
      try {
        if(rows.length > 0){
        console.log(rows)
        setExportBtnDisabled(true)
        axios.post("/admin_exportReport", {
          rows
        })
        .then(function (response) {
          setExportBtnDisabled(false)
          console.log(response)
          if(response.data.res === 'done'){
            setAlert((prevFirstName) => ({
                ...prevFirstName,
                open: true,
                value: 'Exported successfully',
                type: 'success'
            }));
            setTimeout(() => {
                setAlert((prevFirstName) => ({
                    ...prevFirstName,
                    open: false,
                    value: '',
                    type: ''
                }));
              }, 3000);
            // show data
          }
          else if(response.data.res === 'server_error'){
                setAlert((prevFirstName) => ({
                    ...prevFirstName,
                    open: true,
                    value: 'Internal server error',
                    type: 'error'
                }));
                setTimeout(() => {
                    setAlert((prevFirstName) => ({
                        ...prevFirstName,
                        open: false,
                        value: '',
                        type: ''
                    }));
                  }, 3000);
            // redirect to server error page
          }
          else if(response.data.res === 'redirect'){
            //navigate('/login', { replace: true });
          }else{
            // Create a Blob object from the response data
            const blob = new Blob([response.data], { type: 'text/csv' });

            // Create a temporary URL for the Blob object
            const url = URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');

            // Set the link's href attribute to the temporary URL
            link.href = url;

            // Set the link's download attribute to the desired filename
            link.download = 'data.csv';

            // Append the link to the DOM
            document.body.appendChild(link);

            // Click the link to download the file
            link.click();

            // Remove the link from the DOM
            document.body.removeChild(link);

            // Revoke the temporary URL to free up memory
            URL.revokeObjectURL(url);
          }
        })
        .catch(function (error) {
          // handle error
          setExportBtnDisabled(false)
          console.log('this is axios error');
          console.log(error);
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
                setAlert((prevFirstName) => ({
                    ...prevFirstName,
                    open: true,
                    value: 'An unexpected error has occured',
                    type: 'error'
                }));
                        
            }

          }else{
            setAlert((prevFirstName) => ({
                ...prevFirstName,
                open: true,
                value: 'Internal server error',
                type: 'error'
            }));
          }
        })
       }else{
            setAlert((prevFirstName) => ({
                ...prevFirstName,
                open: true,
                value: 'No item is selected',
                type: 'error'
            }));
            setTimeout(() => {
                setAlert((prevFirstName) => ({
                    ...prevFirstName,
                    open: true,
                    value: '',
                    type: ''
                }));
            }, 3000);
       }
      } catch (error) {
        setExportBtnDisabled(false)
      }
    }

    const [filtering, setFiltering] = useState(false);
    function filterSMS(sender, operator, status) {
        if(status === '' && sender === '' && operator === ''){
            setFiltering(false)
        }else{
            setFiltering(true)
        }
        setLoadingMessages(true)
        handleCloseAllFilterMessagesModal()
        axios.post("/admin_filter_messages_for_client", {
          id, sender, operator, status, startAt: filterFrom, endAt: filterTo, type: filterStatus
        })
        .then(function (response) {
          console.log(response.data)
            setLoadingMessages(false)
            if(response.data.response === 'done'){
              setAllSMS(response.data.messages)
            }
        })
        .catch(function (error) {
            setLoadingMessages(false)
            setAlert((prevFirstName) => ({
                ...prevFirstName,
                open: true,
                value: 'An unexpected error has occured',
                type: 'error'
            }));
            setTimeout(() => {
                setAlert((prevFirstName) => ({
                    ...prevFirstName,
                    open: false,
                    value: '',
                    type: ''
                }));  
            }, 3000); 
        })
      }
  

    if(loading){
        return(
            <CircularProgress/>
        )
    }else{
        return (
            <Home width={deviceWidth}>
                <ClientInfo width={deviceWidth}>
                    <Button style={{alignSelf: 'start', marginLeft: '10px', marginTop: '-10px', color: colors.primary}} variant='text' onClick={()=> navigate('/admin/clients')} startIcon={<KeyboardArrowLeft/>}>{Language['back']}</Button>
                    <Content1>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center'}}>
                            <div style={{width: '100px', height: '100px', borderRadius: '50%', backgroundColor: 'white', marginBottom: '10px', alignItems: 'center', backgroundImage: 'URL("/assets/img/user.png")', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                            <span style={{opacity: '0' }}>.</span>
                            </div>
                            <p style={{margin: '5px 0px', padding: '0px', color: '#000000', fontSize: '18px'}}>{client.first_name === "" && client.family_name === ""? Language['client name'] : client.first_name + ' ' + client.family_name}</p>
                            <p style={{margin: '0px 0px 0px 0px', padding: '0px', color: '#000000a3', fontSize: '15px'}}>{client.email}</p>
                            <p style={{margin: '10px 0px 10px 0px', padding: '0px', color: '#000000a3', fontSize: '15px'}}><Button endIcon={<Lock/>}  onClick={client.ban === true ? handleUnBanClient : handleClickOpenBanModal} disabled={unbanuserLoading} variant='contained' style={{backgroundColor: '#2e88c0', color: 'white'}}>{client.ban === true ? ( unbanuserLoading? Language['unbanning'] + '..' : Language['unban']) : Language['ban']}</Button> <Button  onClick={handleClickOpenDeleteModal} variant='contained' endIcon={<Delete/>} color='error'>{Language['delete']}</Button></p>
                        </div>
                            
                            <Headertitle style={{fontSize: '20px', margin: '0px', width: 'calc(100% - 12px)'}}>{Language['Additional informations']}</Headertitle>
            
                            <TextField
                                style={{width: '48%', marginTop: '20px', height: '65px'}}
                                type='text'
                                name='firstName'
                                id="outlined-required"
                                label={Language['First name']}
                                defaultValue=""
                                value={firstName.value}
                                error={firstName.error}
                                helperText={firstName.helperText}
                                onChange={handleFirstNameChange}
                            />
            
                            <TextField
                                style={{width: '48%', marginTop: '20px', height: '65px'}}
                                name='familyName'
                                type='text'
                                id="outlined-required"
                                label={Language['Family name']}
                                defaultValue=""
                                value={familyName.value}
                                error={familyName.error}
                                helperText={familyName.helperText}
                                onChange={handleFamilyNameChange}
                            />
            
                            <TextField
                                name='email'
                                style={{width: '100%', marginTop: '20px'}}
                                required
                                id="outlined-required"
                                type='email'
                                label={Language['Email']}
                                defaultValue=""
                                value={email.value}
                                error={email.error}
                                helperText={email.helperText}
                                onChange={handleEmailChangeChange}
                            />  
            
                            <TextField
                                style={{width: '100%', marginTop: '20px'}}
                                id="outlined-required"
                                type='text'
                                label={Language['Phone number']}
                                name='phoneNumber'
                                defaultValue=""
                                value={phoneNumber.value}
                                error={phoneNumber.error}
                                helperText={phoneNumber.helperText}
                                onChange={handlePhoneNumberChange}
                            />
            
                            <Headertitle style={{fontSize: '20px', margin: '0px', marginTop: '20px', width: 'calc(100% - 12px)'}}>Society information</Headertitle>

                            <TextField
                                name='society'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['Name']}
                                defaultValue=""
                                value={society.value}
                                error={society.error}
                                helperText={society.helperText}
                                onChange={handleSocietyChange}
                            />
            
                            <TextField
                                name='location'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['Location']}
                                defaultValue=""
                                value={societyLocation.value}
                                error={societyLocation.error}
                                helperText={societyLocation.helperText}
                                onChange={(e)=>{
                                    setSocietyLocation((prevValue) => ({
                                        ...prevValue,
                                        value: e.target.value,
                                        helperText: '',
                                        error: false
                                    }));
                                }}
                            />


                            <TextField
                                name='Website'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['Website']}
                                defaultValue=""
                                value={societyWebSite.value}
                                error={societyWebSite.error}
                                helperText={societyWebSite.helperText}
                                onChange={(e)=>{
                                    setSocietyWebSite((prevValue) => ({
                                        ...prevValue,
                                        value: e.target.value,
                                        helperText: '',
                                        error: false
                                    }));
                                }}
                            />



                            <TextField
                                name='Mail'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['Mail']}
                                defaultValue=""
                                value={societyMail.value}
                                error={societyMail.error}
                                helperText={societyMail.helperText}
                                onChange={(e)=>{
                                    setSocietyMail((prevValue) => ({
                                        ...prevValue,
                                        value: e.target.value,
                                        helperText: '',
                                        error: false
                                    }));
                                }}
                            />



                            <TextField
                                name='Phone'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['Phone']}
                                defaultValue=""
                                value={societyPhone.value}
                                error={societyPhone.error}
                                helperText={societyPhone.helperText}
                                onChange={(e)=>{
                                    setSocietyPhone((prevValue) => ({
                                        ...prevValue,
                                        value: e.target.value,
                                        helperText: '',
                                        error: false
                                    }));
                                }}
                            />

                            <TextField
                                name='Bank account'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['Bank account']}
                                defaultValue=""
                                value={societyBankAccount.value}
                                error={societyBankAccount.error}
                                helperText={societyBankAccount.helperText}
                                onChange={(e)=>{
                                    setSocietyBankAccount((prevValue) => ({
                                        ...prevValue,
                                        value: e.target.value,
                                        helperText: '',
                                        error: false
                                    }));
                                }}
                            />


                            <TextField
                                name='Postal account'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['Postal account']}
                                defaultValue=""
                                value={societyPostalAccount.value}
                                error={societyPostalAccount.error}
                                helperText={societyPostalAccount.helperText}
                                onChange={(e)=>{
                                    setSocietyPostalAccount((prevValue) => ({
                                        ...prevValue,
                                        value: e.target.value,
                                        helperText: '',
                                        error: false
                                    }));
                                }}
                            />


                            <TextField
                                name='Commercial Register'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['Commercial Register No']}
                                defaultValue=""
                                value={societyRC.value}
                                error={societyRC.error}
                                helperText={societyRC.helperText}
                                onChange={(e)=>{
                                    setSocietyRC((prevValue) => ({
                                        ...prevValue,
                                        value: e.target.value,
                                        helperText: '',
                                        error: false
                                    }));
                                }}
                            />


                            <TextField
                                name='AI'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['AI']}
                                defaultValue=""
                                value={societyAI.value}
                                error={societyAI.error}
                                helperText={societyAI.helperText}
                                onChange={(e)=>{
                                    setSocietyAI((prevValue) => ({
                                        ...prevValue,
                                        value: e.target.value,
                                        helperText: '',
                                        error: false
                                    }));
                                }}
                            />

                            <TextField
                                name='NIF'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['NIF']}
                                defaultValue=""
                                value={societyNIF.value}
                                error={societyNIF.error}
                                helperText={societyNIF.helperText}
                                onChange={(e)=>{
                                    setSocietyNIF((prevValue) => ({
                                        ...prevValue,
                                        value: e.target.value,
                                        helperText: '',
                                        error: false
                                    }));
                                }}
                            />
                            

                            <TextField
                                name='NIC'
                                style={{width: '100%', marginTop: '20px'}}
                                type='text'
                                id="outlined-required"
                                label={Language['NIC']}
                                defaultValue=""
                                value={societyNIC.value}
                                error={societyNIC.error}
                                helperText={societyNIC.helperText}
                                onChange={(e)=>{
                                    setSocietyNIC((prevValue) => ({
                                        ...prevValue,
                                        value: e.target.value,
                                        helperText: '',
                                        error: false
                                    }));
                                }}
                            />
            
            
                            <SubContainer style={{justifyContent: 'end'}}>
                                <LoadingButton loading={loadingSubmit} loadingPosition='end' variant='contained' onClick={handleSubmit}
                                    disabled={(client.first_name === firstName.value && client.family_name === familyName.value && '0' +client.phoneNumber === phoneNumber.value && client.email === email.value && client.society === society.value && client.societyPhoneNumber === societyPhone.value && client.societyMail === societyMail.value && client.societyLocation === societyLocation.value && client.societyWebSite === societyWebSite.value && client.societyBankAccount === societyBankAccount.value && client.societyPostalAccount === societyPostalAccount.value && client.societyRC === societyRC.value && client.societyAI === societyAI.value && client.societyNIC === societyNIC.value && client.societyNIF === societyNIF.value) ? true : false}
                                    
                                    style={{backgroundColor: (client.first_name === firstName.value && client.family_name === familyName.value && '0' +client.phoneNumber === phoneNumber.value && client.email === email.value && client.society === society.value && client.societyPhoneNumber === societyPhone.value && client.societyMail === societyMail.value && client.societyLocation === societyLocation.value && client.societyWebSite === societyWebSite.value && client.societyBankAccount === societyBankAccount.value && client.societyPostalAccount === societyPostalAccount.value && client.societyRC === societyRC.value && client.societyAI === societyAI.value && client.societyNIC === societyNIC.value && client.societyNIF === societyNIF.value) ? colors.darkGray : colors.primary , color: 'white'}} >{Language['Save']}
                                </LoadingButton>
                            </SubContainer>
                        </Content1>
                </ClientInfo>
                <ClientSettings className='clientDetails' width={deviceWidth}>
                    <Headertitle style={{fontSize: '20px', margin: '0px', width: 'calc(100% - 12px)'}}>{Language['Settings']}</Headertitle>
            
                    <Box  sx={{ width: deviceWidth > 800 ? 'calc(100% - 40px)' : 'calc(100% - 10px)' , backgroundColor: 'whitesmoke', borderRadius: '6px', padding: deviceWidth > 800 ?  '10px 20px' : '10px 5px' , marginTop: '20px',}}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{overflow: 'scroll'}} scrollButtons="auto" variant="scrollable">
                                <Tab label={deviceWidth > 600 ? Language['subscription'] : (<CalendarMonth />)} {...a11yProps(0)} />
                                <Tab label={deviceWidth > 600 ? Language['Email settings'] : (<EmailOutlined/>) } {...a11yProps(1)} />
                                <Tab label={deviceWidth > 600 ? Language['SMS settings'] : (<SmsOutlined/>)} {...a11yProps(2)} />
                                <Tab label={deviceWidth > 600 ? Language['History'] : (<History />) } {...a11yProps(3)} />
                            </Tabs>
                        </Box>

                        <CustomTabPanel value={value} index={0}>
                        <Container2  width={deviceWidth}>
                                <Headertitle2 style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>{Language['Client Subscription']} <span style={{textAlign: 'right', minWidth: 'max-content', color: 'gray', fontSize: '16px'}}>{Language['Expired in']} {client.expirationDate ? client.expirationDate.split('T')[0] : Language['loading'] + '..'}</ span> </Headertitle2>
                                <Headerparagraph2 style={{width: '100%'}} >{Language['Client subscription and management']}</Headerparagraph2>
            
                                <Headertitle2 style={{width: '100%', fontSize: '65px', fontWeight: '600', textAlign: 'center', color: colors.primary}}>{!isNaN(daysLeft) ?( daysLeft > 0 ? daysLeft : 0) : Language['loading'] +'..'}</Headertitle2>
                                <Headerparagraph2 style={{width: '100%', marginTop: '-10px', textAlign: 'center'}}>{Language['days left']}</Headerparagraph2>
            
            
                                <Headertitle2 style={{width: '100%', borderTop: '1px solid #e6e6e6ba', paddingTop: '10px', marginTop: '20px'}}>{Language['Manage Subscription']}</Headertitle2>
                                <Headerparagraph2 style={{width: '100%'}}>{Language['You can edit subscription days']}</Headerparagraph2>
            
            
                                <TextField
                                    style={{width: '100%', marginTop: '10px'}}
                                    type='number'
                                    name='Email Address'
                                    id="outlined-required"
                                    label={Language['Days']}
                                    defaultValue=""
                                    value={SubsDays}
                                    //error={firstName.error}
                                    //helperText={firstName.helperText}
                                    onChange={(e) => setSubsDays(e.target.value)}
                                />
                                <SubContainer style={{justifyContent: 'end'}}>
                                    <LoadingButton loading={loadingSubs} loadingPosition='center' variant='contained' onClick={handlesubsDays}  style={{backgroundColor: colors.primary, color: loadingSubs ? 'transparent' : 'white'}} >{Language['Add']}</LoadingButton>
                                </SubContainer>
                            </Container2>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1} style={{ padding: '0px 0px'}}>
                            
                            {/* <Container2  width={deviceWidth} style={{display: 'none'}}>
                                <Headertitle2 style={{width: '100%'}}>SMTP Configuration</Headertitle2>
                                <Headerparagraph2 style={{width: '100%'}}>Client can use his own SMTP server</Headerparagraph2>
                                <TextField
                                    style={{width: '100%', marginTop: '10px'}}
                                    type='text'
                                    name='Email Address'
                                    id="outlined-required"
                                    label="Email Address"
                                    defaultValue=""
                                    value={firstName.value}
                                    error={firstName.error}
                                    helperText={firstName.helperText}
                                    onChange={handleFirstNameChange}
                                />
                                <TextField
                                    style={{width: deviceWidth > 800 ?  '70%' : '100%', marginTop: '10px'}}
                                    type='text'
                                    name='Email Address'
                                    id="outlined-required"
                                    label="SMTP server host"
                                    defaultValue=""
                                    value={firstName.value}
                                    error={false}
                                    helperText={''}
                                    onChange={handleFirstNameChange}
                                />
            
                                <FormControl style={{width: deviceWidth > 800 ?  '29%' : '100%', marginTop: '10px'}} >
                                    <InputLabel id="demo-simple-select-label">SMTP server port</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    //value={10}
                                    label="SMTP server port"
                                    onChange={handleChange}
                                    >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
            
                                <TextField
                                    style={{width: deviceWidth > 800 ?  '50%' : '100%', marginTop: '10px'}}
                                    type='text'
                                    name='Email Address'
                                    id="outlined-required"
                                    label="Account username"
                                    defaultValue=""
                                    value={firstName.value}
                                    error={firstName.error}
                                    helperText={firstName.helperText}
                                    onChange={handleFirstNameChange}
                                />
            
                                <TextField
                                    style={{width: deviceWidth > 800 ?  '49%' : '100%', marginTop: '10px'}}
                                    type='text'
                                    name='Email Address'
                                    id="outlined-required"
                                    label="Account password"
                                    defaultValue=""
                                    value={firstName.value}
                                    error={firstName.error}
                                    helperText={firstName.helperText}
                                    onChange={handleFirstNameChange}
                                />
                                <SubContainer style={{justifyContent: 'end'}}>
                                    <LoadingButton loading={loadingSubmit} loadingPosition='end' variant='contained' onClick={handleSubmit}  style={{backgroundColor: colors.primary, color: 'white'}} >Save</LoadingButton>
                                </SubContainer>
                            </Container2>*/}
            
                            <Container3 width={deviceWidth}>
                                <Headertitle2 style={{width: '100%'}}>{Language['SMTP Accounts']}</Headertitle2>
                                <Headerparagraph2 style={{width: '100%'}}>{Language['You can edit or delete accounts']}</Headerparagraph2>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }} style={{}}>
                                    {
                                        smtpConfigs.map((item)=>{
                                            return(
                                                <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', flexWrap: deviceWidth > 800 ?  'nowrap' : 'wrap'}}>
                                                <ListItemAvatar>
                                                <Avatar>
                                                    <Email />
                                                </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText1  width={deviceWidth} >
                                                    <ListItemTextPrimary><ListItemTextPrimarySpan width={deviceWidth}>{item.email_address}</ListItemTextPrimarySpan><IconButton color='error' onClick={()=>{setSelectedSMTP(item.id); setOpenDeleteSMTPConfigModal(true)}}><Delete></Delete></IconButton> </ListItemTextPrimary>
                                                    <ListItemTextSecondary>{item.limit} {Language['email per hour']} <IconButton onClick={()=>{ setSelectedSMTP(item.id); setOpenSMTPLimitModal(true); setSmtpLimit((prevData) => ({...prevData, value: item.limit ,error: false, helperText: ''}));}} style={{fontSize:'10px', padding: '4px'}}><Settings  style={{fontSize:'22px', color: '#2d78a3'}}/> </IconButton> </ListItemTextSecondary>
                                                </ListItemText1>
                    
                                                <FormControl sx={{ m: 0, width: '40%' }} error={password.error} helperText={'sdsd'} style={{width: deviceWidth > 800 ?   '50%' : '100%', marginTop: '20px'}}  variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-password">{Language['Sold Left']}</InputLabel>
                                                    <OutlinedInput
                                                        //name='password'
                                                        id="outlined-adornment-password"
                                                        type={'text'}
                                                        value={item.credit}
                                                        //error={password.error}
                                                        //helperText={'invalid password'}
                                                        //onChange={handlePasswordChangeChange}
                                                        endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                //onClick={()=>generatePassword(12)}
                                                                //onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                variant='contained'
                                                                //style={{backgroundColor: 'green'}}
                                                                color='success'
                                                                onClick={()=>{ setSelectedSMTP(item.id); setOpenSMTPModal(true)}}
                                                                //endIcon={<Settings /> }
                                                                >
                                                                <AddCircleOutline />
                                                            </IconButton>
                                                        </InputAdornment>
                                                        }
                                                        //label="Password"
                                                    />
                                                    <FormHelperText id="outlined-weight-helper-text">{password.helperText}</FormHelperText>
                                                </FormControl>
                                                        
                    
                                            </ListItem>   
                                            )
                                        })
                                    }
                            

                                    
                                    
                                    </List>
                            </Container3>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2} style={{ padding: '0px 0px'}}>
                            
                            <Container2  width={deviceWidth}>
                                <Headertitle2 style={{width: '100%'}}>{Language['SMS Configuration']}</Headertitle2>
                                <Headerparagraph2 style={{width: '100%'}}>{Language['Client can use his own Sender id']}</Headerparagraph2>
                                <TextField
                                    style={{width: '100%', marginTop: '10px'}}
                                    type='text'
                                    name='Sender id'
                                    id="outlined-required"
                                    label={Language['Sender id']}
                                    defaultValue=""
                                    value={SenderId.value}
                                    error={SenderId.error}
                                    helperText={SenderId.helperText}
                                    onChange={(e)=> setSenderId((prevData) => ({
                                        ...prevData,
                                        value: e.target.value,
                                        error: false,
                                        helperText: ''
                                    }))}
                                />
                                <SubContainer style={{justifyContent: 'end'}}>
                                    <LoadingButton loading={loadinCreateSenderId} loadingPosition='end' variant='contained' onClick={handleCreateSenderId}  style={{backgroundColor: colors.primary, color: loadinCreateSenderId? 'transparent' : 'white'}} >{Language['Save']}</LoadingButton>
                                </SubContainer>
                            </Container2>
            
                            <Container3 width={deviceWidth}>
                                <Headertitle2 style={{width: '100%'}}>{Language['SMS senders']}</Headertitle2>
                                <Headerparagraph2 style={{width: '100%'}}>{Language['You can edit or delete accounts']}</Headerparagraph2>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }} style={{}}>

                                {
                                        senderIds.map((item)=>{
                                            return(
                                                <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', flexWrap: deviceWidth > 800 ?  'nowrap' : 'wrap'}}>
                                                <ListItemAvatar>
                                                <Avatar>
                                                    <Email />
                                                </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText1  width={deviceWidth} >
                                                    <ListItemTextPrimary><ListItemTextPrimarySpan width={deviceWidth}>{item.source}</ListItemTextPrimarySpan><IconButton color='error' onClick={()=>{setSelectedSenderId(item.id); setOpenDeleteSenderIdModal(true)}}><Delete></Delete></IconButton> </ListItemTextPrimary>
                                                </ListItemText1>
                    
                                                <FormControl sx={{ m: 0, width: '40%' }} error={password.error}  style={{width: deviceWidth > 800 ?   '50%' : '100%', marginTop: '20px'}}  variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-password">{Language['Sold Left']}</InputLabel>
                                                    <OutlinedInput
                                                        // name='password'
                                                        id="outlined-adornment-password"
                                                        type={'text'}
                                                        value={item.credit}
                                                        //error={password.error}
                                                        //helperText={'invalid password'}
                                                        //onChange={handlePasswordChangeChange}
                                                        endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                //onClick={()=>generatePassword(12)}
                                                                //onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                variant='contained'
                                                                //style={{backgroundColor: 'green'}}
                                                                color='success'
                                                                onClick={()=>{ setSelectedSenderId(item.id); setOpenSenderIdModal(true)}}
                                                                //endIcon={<Settings /> }
                                                                >
                                                                <AddCircleOutline />
                                                            </IconButton>
                                                        </InputAdornment>
                                                        }
                                                        label={Language['Password']}
                                                    />
                                                    <FormHelperText id="outlined-weight-helper-text">{password.helperText}</FormHelperText>
                                                </FormControl>
                                                        
                    
                                            </ListItem>   
                                            )
                                        })
                                    }
                                    
                                </List>
                            </Container3>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                        <div style={{  width: '100%', marginTop: '20px', backgroundColor: 'white'}}>
                            <DataGrid
                                style={{minHeight: '400px', height: 'calc( 100vh - 240px )'}}
                                rows={history}
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 20 },
                                },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection={false}
                                selectionModel={false}
                                sortModel={[{
                                    field: 'createdAt',
                                    sort: 'desc', // or 'desc' for descending order
                                }]}
                                //loading={loadingClients}
                            />
                        </div>
                        </CustomTabPanel>
                    </Box>
                    <Headertitle style={{fontSize: '20px', margin: '0px', width: 'calc(100% - 12px)', marginTop: '20px'}}>{Language['SMS Statistiques']}</Headertitle>
                    <Box  sx={{ width: deviceWidth > 800 ? 'calc(100% - 40px)' : 'calc(100% - 10px)' , backgroundColor: 'whitesmoke', borderRadius: '6px', padding: deviceWidth > 800 ?  '10px 20px' : '10px 5px' , marginTop: '20px',}}>
                        
                        <Header width={deviceWidth}>
                            <SubHeader  width={deviceWidth}>
                                <p style={{margin: '2px 0px 5px 0px', padding: '0px', color: 'gray', fontSize: '16px',  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%", alignItems:' center'}}> <span>{Language['Total Consumed']}</span>  <ReceiptLongOutlined style={{color: "#2e6bd5"}}/>
                                {/* <Button variant='text' color='info' endIcon={<KeyboardArrowDown/>}
                                size='small' onClick={handleClick} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" id="basic-button" aria-controls={open ? 'basic-menu' : undefined}
                                >{filterStatus}</Button>   */}
                                </p>
                                <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={() => handleCloseMenu()}
                                        MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={() => {handleCloseMenu(); filterMessages('this month')}} >
                                        <ListItemIcon>
                                        <CalendarMonth fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{Language['This Month']}</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={() => {handleCloseMenu(); filterMessages('this year')}} >
                                        <ListItemIcon>
                                        <CalendarMonth fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{Language['This Year']}</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={() => {handleCloseMenu(); filterMessages('life time')}} >
                                        <ListItemIcon>
                                        <CalendarMonth fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{Language['Life Time']}</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={() => {handleCloseMenu(); setOpenFilterMessagesModal(true)}} >
                                        <ListItemIcon>
                                        <FilterList fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{Language['Advanced Filtering']}</ListItemText>
                                        </MenuItem>
                                    </Menu>
                                {/* <p style={{margin: '5px 0px', padding: '0px', color: 'gray', fontSize: '16px',  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%"}}> <span>Total Consumed</span> <ReceiptLongOutlined style={{color: "#2e74a0"}}/>  </p> */}
                                <p style={{margin: '25px 0px 25px 0px', padding: '0px', color: 'black', fontSize: '27px', fontWeight: '600'}}>{totlaConsumed} DZD</p>
                                <p style={{margin: '-15px 0px', padding: '0px', color: 'gray', fontSize: '16px'}}><span style={{color: 'black', fontWeight: '600'}}>{totalConsumedWithProfit} DZD</span> {Language['with profit']}</p>
                            </SubHeader>
                            <SubHeader   width={deviceWidth}>
                                <p style={{margin: '2px 0px 5px 0px', padding: '0px', color: 'gray', fontSize: '16px',  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%"}}> <span>{Language['Operators']}</span> <SimCardOutlined style={{color: "#907e1a"}}/> </p>
                                <p style={{margin: '7px 0px 1px 0px', padding: '0px', color: 'black', fontSize: '16px', fontWeight: '400', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><Rectangle style={{backgroundColor: '#13a5f3', color: 'transparent', fontSize: '7px', marginRight: '5px'}}>.</Rectangle> <p style={{width: "80px", margin: '0px', padding: '0px'}}>{Language['All']}</p>  <span style={{color: 'gray'}}>{totalSent} {Language['sent']} / {sentCount} sms</span> </p>
                                <p style={{margin: '1px 0px 1px 0px', padding: '0px', color: 'black', fontSize: '16px', fontWeight: '400', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><Rectangle style={{backgroundColor: 'orange', color: 'transparent', fontSize: '7px', marginRight: '5px'}}>.</Rectangle> <p style={{width: "80px", margin: '0px', padding: '0px'}}>Ooredoo</p>  <span style={{color: 'gray'}}>{totalSmsInOoredoo} {Language['sent']} / {ooredooCount} sms</span> </p>
                                <p style={{margin: '1px 0px', padding: '0px', color: 'black', fontSize: '16px', fontWeight: '400', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><Rectangle style={{backgroundColor: 'green', color: 'transparent', fontSize: '7px', marginRight: '5px'}}>.</Rectangle> <p style={{width: "80px", margin: '0px', padding: '0px'}}>Mobilis</p>  <span style={{color: 'gray'}}>{totalSmsInMobilis} {Language['sent']} / {mobilisCount} sms</span> </p>
                                <p style={{margin: '1px 0px', padding: '0px', color: 'black', fontSize: '16px', fontWeight: '400', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><Rectangle style={{backgroundColor: 'red', color: 'transparent', fontSize: '7px', marginRight: '5px'}}>.</Rectangle> <p style={{width: "80px", margin: '0px', padding: '0px'}}>Djezzy</p>  <span style={{color: 'gray'}}>{totalSmsInDjezzy} {Language['sent']} / {djezzyCount} sms</span> </p>
                            </SubHeader>
                            <SubHeader  width={deviceWidth}>
                                <p style={{margin: '2px 0px 5px 0px', padding: '0px', color: 'gray', fontSize: '16px',  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%"}}> <span>{Language['Total Profit']}</span> <LocalAtmOutlined style={{color: "#069649"}}/> </p>
                                <p style={{margin: '25px 0px', padding: '0px', color: 'black', fontSize: '27px', fontWeight: '600'}}>{totalProfit} DZD</p>
                                <p style={{margin: '-15px 0px', padding: '0px', color: 'gray', fontSize: '16px'}}>{Language['Net profit']}</p>
                            </SubHeader>
                        </Header>


                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center'}}>
                            <p style={{margin: '0px 0px', padding: '0px', color: 'gray', fontSize: '16px'}}>{Language['History of SMS messages']} </p>
                            <div>
                            <Button variant='text' color='info' endIcon={<KeyboardArrowDown/>} style={{color: colors.primary}}
                                size='small' onClick={handleClick} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" id="basic-button" aria-controls={open ? 'basic-menu' : undefined}
                            >{Language[filterStatus] ? Language[filterStatus] : filterStatus}</Button> 
                            <IconButton style={{display: filtering ?  '' : 'none'}} onClick={removeFilter} ><FilterListOffOutlined/></IconButton>
                            <Button variant='contained' onClick={()=> setOpenAllFilterMessagesModal(true)} style={{ marginLeft: '10px', backgroundColor: colors.primary}} startIcon={<FilterListOutlined/>}>{Language['Filter']}</Button>
                            <IconButton  variant='text' disabled={exportBtnDisabled}  onClick={()=> exportMessages(selectedRows)} style={{ marginLeft: '10px', color: colors.primary}}><Upload/></IconButton>
                            </div>
                        </div>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                style={{backgroundColor: 'white'}}
                                getRowClassName={getRowClassName}
                                rows={allSMS}
                                columns={smsColumns}
                                initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                                }}
                                loading={loadingMessages}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection={true}
                                selectionModel={selectionModel}
                                onSelectionModelChange={handleSelectionModelChange}
                                //sortModel={[{field: 'date',sort: 'desc'}]}
                            />
                        </div>


                    </Box>

                </ClientSettings>


                <Snackbar
                    open={alert.open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    >
                    <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                        {Language[alert.value] ? Language[alert.value] : alert.value}
                    </Alert>
                </Snackbar>
        
        
                {/* // moodels ******************* */}
                {/* // delete client modal */}
                <Dialog
                    open={openDeleteModal}
                    onClose={handleCloseDeleteModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {Language['Delete client']}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {Language['Are you sure you want to delete this account? This action is irreversible, and all the client data will be permanently deleted.']}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button variant='text'  color='error' onClick={handleCloseDeleteModal}>{Language['Cancel']}</Button>
                    <LoadingButton loading={loadingDelete} loadingPosition='center' variant='contained' onClick={handleDeleteClient} style={{color: loadingDelete ? 'transparent' : 'white'}}  color='error' autoFocus>{Language['Delete']}</LoadingButton>
                    </DialogActions>
                </Dialog>
        
        
                {/* // ban modal */}
                <Dialog
                    open={openBanModal}
                    onClose={handleCloseBanModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {Language['Ban client']}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {Language['Are you sure you want to ban this account? The client will lose access to their account.']}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button variant='text'  color='error' onClick={handleCloseBanModal} style={{backgroundColor: 'white', color: "#2e88c0"}}>{Language['Cancel']}</Button>
                    <LoadingButton loadingPosition='center' loading={limitedBanLoading} disabled={disabledBan} variant='outlined'  color='error' onClick={()=> handleBanClient('limited')} style={{backgroundColor: 'white', color: limitedBanLoading? 'transparent' : "#2e88c0", borderColor: '#2e88c0'}}>{limitedBanLoading ? Language['Ban in only 3 days'] : Language['Ban in only 3 days']}</LoadingButton>
                    <LoadingButton loadingPosition='center' loading={unLimitedBanLoading} disabled={disabledBan} variant='contained'  onClick={()=> handleBanClient('unlimited')} color='error' style={{backgroundColor: '#2e88c0', color:unLimitedBanLoading ? 'transparent' : "white"}} autoFocus>{unLimitedBanLoading ? Language['Ban'] : Language['Ban']}</LoadingButton>
                    </DialogActions>
                </Dialog>


                {/* // delete smtp config modal */}
                <Dialog
                    open={openDeleteSMTPConfigModal}
                    onClose={handleCloseDeleteSMTPConfigModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {Language['Delete SMTP config']}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {Language['Are you sure you want to delete this SMTP config? This action is irreversible.']}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button variant='text'  color='error' onClick={handleCloseDeleteSMTPConfigModal}>{Language['Cancel']}</Button>
                    <LoadingButton loading={loadinDeleteSmtp} loadingPosition='center' variant='contained' onClick={handleDeleteSmtp} style={{color: loadinDeleteSmtp ? 'transparent' : 'white'}}  color='error' autoFocus>{Language['Delete']}</LoadingButton>
                    </DialogActions>
                </Dialog>


                {/* // delete sender id modal */}
                <Dialog
                    open={openDeleteSenderIdModal}
                    onClose={handleCloseDeleteSenderIdModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {Language['Delete sender id']}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {Language['Are you sure you want to delete this sender id? This action is irreversible.']} 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button variant='text'  color='error' onClick={handleCloseDeleteSenderIdModal}>{Language['Cancel']}</Button>
                    <LoadingButton loading={loadinDeleteSenderId} loadingPosition='center' variant='contained' onClick={handleDeleteSenderId} style={{color: loadinDeleteSenderId ? 'transparent' : 'white'}}  color='error' autoFocus>{Language['Delete']}</LoadingButton>
                    </DialogActions>
                </Dialog>


                {/* // smtp sold modal */}
                <Dialog
                    open={openSMTPModal}
                    onClose={handleCloseSMTPModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle style={{paddingBottom: "0px"}} id="alert-dialog-title">
                    {Language['Add sold']}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {Language['You can add sold easily from here.']}
                    </DialogContentText>
                    <TextField
                            style={{width: '100%', marginTop: '20px', height: '65px'}}
                            type='number'
                            name='SmtpSold'
                            id="outlined-required"
                            label={Language['Sold']}
                            defaultValue=""
                            value={SmtpSold.value}
                            error={SmtpSold.error}
                            helperText={SmtpSold.helperText}
                            onChange={(e)=> {
                                setSmtpSold((prevData) => ({
                                    ...prevData,
                                    value: e.target.value,
                                    error: false,
                                    helperText: ''
                                }));
                            }}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button variant='text'  color='error' onClick={handleCloseSMTPModal} style={{backgroundColor: 'white', color: "#2e88c0"}}>{Language['Cancel']}</Button>
                    <LoadingButton loadingPosition='center' loading={loadinSmtpSold} variant='contained' onClick={handleSmtpSold}   style={{backgroundColor: '#2e88c0', color: loadinSmtpSold ? 'transparent' : "white"}} autoFocus>{Language['Add']}</LoadingButton>
                    </DialogActions>
                </Dialog>


                 {/* // senderId sold modal */}
                 <Dialog
                    open={openSenderIdModal}
                    onClose={handleCloseSenderIdModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle style={{paddingBottom: "0px"}} id="alert-dialog-title">
                    {Language['Add sold']}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {Language['You can add sold easily from here.']}
                    </DialogContentText>
                    <TextField
                            style={{width: '100%', marginTop: '20px', height: '65px'}}
                            type='number'
                            name='SmtpSold'
                            id="outlined-required"
                            label={Language['Sold']}
                            defaultValue=""
                            value={SenderIdSold.value}
                            error={SenderIdSold.error}
                            helperText={SenderIdSold.helperText}
                            onChange={(e)=> {
                                setSenderIdSold((prevData) => ({
                                    ...prevData,
                                    value: e.target.value,
                                    error: false,
                                    helperText: ''
                                }));
                            }}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button variant='text'  color='error' onClick={handleCloseSenderIdModal} style={{backgroundColor: 'white', color: "#2e88c0"}}>{Language['Cancel']}</Button>
                    <LoadingButton loadingPosition='center' loading={loadinSenderIdSold} variant='contained' onClick={handleSenderIdSold}   style={{backgroundColor: '#2e88c0', color: loadinSenderIdSold ? 'transparent' : "white"}} autoFocus>{Language['Add']}</LoadingButton>
                    </DialogActions>
                </Dialog>


                {/* // smtp limit modal */}
                <Dialog
                    open={openSMTPLimitModal}
                    onClose={handleCloseSMTPLimitModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle style={{paddingBottom: "0px"}} id="alert-dialog-title">
                    {Language['SMTP Settings']}{""}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {Language['Please indicate how frequently you would like this server to send emails per hour, this action will apply for all SMTPs in the same host..']}
                    </DialogContentText>
                    <TextField
                            style={{width: '100%', marginTop: '20px', height: '65px'}}
                            type='number'
                            name='Limit per hour'
                            id="outlined-required"
                            label={Language['Limit per hour']}
                            defaultValue=""
                            value={SmtpLimit.value}
                            error={SmtpLimit.error}
                            helperText={SmtpLimit.helperText}
                            onChange={(e)=> {
                                setSmtpLimit((prevData) => ({
                                    ...prevData,
                                    value: e.target.value,
                                    error: false,
                                    helperText: ''
                                }));
                            }}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button variant='text'  color='error' onClick={handleCloseSMTPLimitModal} style={{backgroundColor: 'white', color: "#2e88c0"}}>{Language['Cancel']}</Button>
                    <LoadingButton loadingPosition='center' loading={loadinSmtpLimit} variant='contained' onClick={handleSmtpLimit}   style={{backgroundColor: '#2e88c0', color: loadinSmtpLimit ? 'transparent' : "white"}} autoFocus>{Language['Save']}</LoadingButton>
                    </DialogActions>
                </Dialog>


                 {/* All filters Modal in Message*/}
                    <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={openAllFilterMessagesModal}
                    onClose={handleCloseAllFilterMessagesModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">
                    {Language['Filter Messages']}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        {Language['use the fields below to filter messages than press filter button.']}
                        </DialogContentText>

                        <Autocomplete
                        multiple={false}
                        id="tags-standard"
                        options={senders}
                        value={senderObject}
                        getOptionLabel={(option) => option.source}
                        onChange={(e, newValue)=> {setSender(newValue ? newValue.source : ''); setSenderObject(newValue)}}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            variant="standard"
                            label="Sender ID"
                            placeholder="Sender ID"
                            />
                        )}
                        />
            
                        
            
                        <FormControl error={false} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '5px'}}>
                                {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                                <InputLabel htmlFor="subject">{Language['Operator']}</InputLabel>
                                
                                
                                
                                <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={operator}
                                onChange={ (e) =>{
                                setOperator(e.target.value)
                                }
                                }
                                >
                                <MenuItem value={""}>{Language['All']}</MenuItem>
                                <MenuItem value={"DJEZZY"}>{"Djezzy"}</MenuItem>
                                <MenuItem value={"MOBILIS"}>{"Mobilis"}</MenuItem>
                                <MenuItem value={"OOREDOO"}>{"Ooredoo"}</MenuItem>
            
                                {/* {
                                senders.map(((item) => {
                                    return (
                                    <MenuItem value={item.source}>{item.source}</MenuItem>
                                    )
                                }))
                                } */}
                                </Select>
                                <FormHelperText id="time_error"></FormHelperText>
                        </FormControl>
            
                        <FormControl error={false} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '5px'}}>
                                {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                                <InputLabel htmlFor="subject">{Language['Status']}</InputLabel>
            
                                <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={status}
                                onChange={ (e) =>{
                                setstatus(e.target.value)
                                }
                                }
                                >
                                <MenuItem value={""}>{Language['All']}</MenuItem>
                                <MenuItem value={"sent"}>{Language['Sent']}</MenuItem>
                                <MenuItem value={"failed"}>{Language['Failed']}</MenuItem>
            
                                {/* {
                                senders.map(((item) => {
                                    return (
                                    <MenuItem value={item.source}>{item.source}</MenuItem>
                                    )
                                }))
                                } */}
                                </Select>
                                <FormHelperText id="time_error"></FormHelperText>
                        </FormControl>
            
            
                        
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAllFilterMessagesModal}>{Language['Cancel']}</Button>
                        <Button onClick={() => filterSMS(sender, operator, status)} autoFocus>
                        {Language['Filter']}
                        </Button>
                    </DialogActions>
                    </Dialog>



                    {/* select date modal  */}
            <Dialog
              open={openFilterMessagesModal}
              onClose={handleCloseFilterMessagesModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
              {Language['Filter Messages']}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {Language['use the fields below to filter messages based on the date range you specify.']}
                </DialogContentText>

                <FormControl error={false} variant="standard" style={{width: '100%', marginTop: '30px'}}>
                        <p style={{width: '100%', marginBottom: '0px'}}>{Language['From']}</p>
                        <Input
                          onChange={(e) => {
                            setFilterFrom(e.target.value)
                          //   setIsErrorTime(false)
                          //   setTimeError('')
                          }}
                          type='date'
                          id=""
                          defaultValue=""
                          aria-describedby="time_error"
                          placeholder=''
                        />
                        <FormHelperText id="time_error"></FormHelperText>
                    </FormControl>

                    <FormControl error={false} variant="standard" style={{width: '100%', marginTop: '30px'}}>
                        <p style={{width: '100%', marginBottom: '0px'}}>{Language['To']}</p>
                        <Input
                          onChange={(e) => {
                            setFilterTo(e.target.value)
                            // setIsErrorTime(false)
                            // setTimeError('')
                          }}
                          type='date'
                          id=""
                          defaultValue=""
                          aria-describedby="time_error"
                          placeholder=''
                        />
                        <FormHelperText id="time_error"></FormHelperText>
                    </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseFilterMessagesModal}>{Language['Cancel']}</Button>
                <Button onClick={() => filterMessages("advanced")} autoFocus>
                {Language['Filter']}
                </Button>
              </DialogActions>
            </Dialog>
            </Home>
            
          )
    }
 
}
