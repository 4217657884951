import { Autorenew, CancelOutlined, CancelPresentationOutlined, KeyboardArrowLeft, VisibilityOff } from '@mui/icons-material'
import { Alert, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, Input, InputAdornment, InputLabel, Snackbar, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import colors from '../../colors';

const Container = styled.div`
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
`

const Content = styled.div`
    width: 60%;
    min-width: 300px;
    padding: 10px;
    background-color: whitesmoke;
    border-radius: 5px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //box-shadow: 0px 0px 10px -2px #00000032;
`

const Content1 = styled.div`
    width: 80%;
    min-width: 300px;
    padding: 10px;
    border-radius: 5px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`
const SubContainer = styled.div`
    width: calc(100%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
`

const Headertitle = styled.p`
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    border-left: 5px solid ${colors.primary} ;
    width: calc(100% - 30px);
    margin-bottom: 20px;
`

export default function NewClient({Language}) {

    const generatePassword = (length) => {
        const lowercaseCharset = "abcdefghijklmnopqrstuvwxyz";
        const uppercaseCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numberCharset = "0123456789";
        const specialCharset = '[!@#$%^&*(),.?":{}|<>]';
    
        const getRandomChar = (charset) => {
            const randomIndex = Math.floor(Math.random() * charset.length);
            return charset.charAt(randomIndex);
        };
    
        let newPassword = '';
    
        // Ensure at least one lowercase letter
        newPassword += getRandomChar(lowercaseCharset);
    
        // Ensure at least one uppercase letter
        newPassword += getRandomChar(uppercaseCharset);
    
        // Ensure at least one number
        newPassword += getRandomChar(numberCharset);
    
        // Ensure at least one special character
        newPassword += getRandomChar(specialCharset);
    
        // Fill the rest of the password with random characters
        for (let i = newPassword.length; i < length; i++) {
            const randomCharset = lowercaseCharset + uppercaseCharset + numberCharset + specialCharset;
            newPassword += getRandomChar(randomCharset);
        }
    
        // Shuffle the password to randomize the order of characters
        newPassword = newPassword.split('').sort(() => Math.random() - 0.5).join('');
    
        console.log(newPassword);
        setPassword((prevFirstName) => ({
            ...prevFirstName,
            value: newPassword,
            helperText: '',
            error: false
        }));
    };

    const [firstName, setFirstName] = useState({value: '', error: false, helperText: ''});
    const [familyName, setFamilyName] = useState({value: '', error: false, helperText: ''});
    const [phoneNumber, setPhoneNumber] = useState({value: '', error: false, helperText: ''})
    const [society, setSociety] = useState({value: '', error: false, helperText: ''});
    const [email, setEmail] = useState({value: '', error: false, helperText: ''});
    const [password, setPassword] = useState({value: '', error: false, helperText: ''});
    const [sendLoginEmail, setSendLoginEmail] = useState(true);

    const [alert, setAlert] = useState({open: false, type: '', value: ''});
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        handleAlertChanges(false, '', 'error')
    };


    const handleFirstNameChange = (e) => {
        setFirstName((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };

    const handleFamilyNameChange = (e) => {
        setFamilyName((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };


    const handleSocietyChange = (e) => {
        setSociety((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };


    const handleEmailChangeChange = (e) => {
        setEmail((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };

    const handlePasswordChangeChange = (e) => {
        setPassword((prevFirstName) => ({
            ...prevFirstName,
            value: e.target.value,
            helperText: '',
            error: false
        }));
    };


    function handleAlertChanges(open, value, type){
        setAlert((prevFirstName) => ({
            ...prevFirstName,
            open,
            value,
            type
        }));
    }
    
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const navigate = useNavigate()
  
    function handleSubmit(){
        
        // reset form
        setFirstName((prevFirstName) => ({
            ...prevFirstName,
            helperText: '',
            error: false
        }));
        setFamilyName((prevFirstName) => ({
            ...prevFirstName,
            helperText: '',
            error: false
        }));
        setPhoneNumber((prevFirstName) => ({
            ...prevFirstName,
            helperText: '',
            error: false
        }));
        setSociety((prevFirstName) => ({
            ...prevFirstName,
            helperText: '',
            error: false
        }));
        setEmail((prevFirstName) => ({
            ...prevFirstName,
            helperText: '',
            error: false
        }));
        setPassword((prevFirstName) => ({
            ...prevFirstName,
            helperText: '',
            error: false
        }));


        setLoadingSubmit(true)
        
        axios.post("/admin_create_client", {
            firstName: firstName.value, familyName: familyName.value, phoneNumber: phoneNumber.value, society: society.value, email: email.value, password: password.value, sendLoginEmail
        })
            .then(function (response) {
                setLoadingSubmit(false)
                console.log(response.data)
                if(response.data.response === "created"){
                    handleAlertChanges(true, response.data.message, 'success')
                    setTimeout(() => {
                        navigate('/admin/clients', { replace: true });
                    }, 1000);
                }
                else if(response.data.response === "input_error") {
                    for(let i = 0; i < response.data.error.length; i++){
                    if(response.data.error[i].path === 'firstName'){
                        setEmail((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }else if(response.data.error[i].path === 'familyName'){
                        setFamilyName((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }
                    else if(response.data.error[i].path === 'phoneNumber'){
                        setPhoneNumber((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }
                    else if(response.data.error[i].path === 'society'){
                        setSociety((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }
                    else if(response.data.error[i].path === 'email'){
                        setEmail((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }
                    else if(response.data.error[i].path === 'password'){
                        setPassword((prevFirstName) => ({
                            ...prevFirstName,
                            helperText: Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error,
                            error: true
                        }));
                    }
                    }
                }
                else if(response.data.response === "server_error") {
                    handleAlertChanges(true, response.data.message, 'error')
                }
                else{
                    handleAlertChanges(true, 'Session ended', 'error')
                    setTimeout(() => {
                     navigate('/admin', { replace: true });
                    }, 2000);
                }
            })
            .catch(function (error) {
                // handle error
                setLoadingSubmit(false)
                handleAlertChanges(true, 'An unexpected error has occured', 'error')
            })
      }


  return (
    <Container>

        {/* <Button style={{alignSelf: 'start', marginLeft: '10px', marginTop: '-10px', color: colors.primary}} variant='text' onClick={()=> navigate('/admin')} startIcon={<KeyboardArrowLeft/>}>back</Button> */}
        {/* <Headertitle>Create a new client</Headertitle> */}
        <Content>
            {/* <Headertitle>Create a new client</Headertitle> */}
            
            <Headertitle style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>{Language['Create a new client']} <span style={{flexGrow: 1}}></span> <Button style={{alignSelf: 'start', marginLeft: '10px', margin: '0px 10px', color: colors.primary}} variant='text' onClick={()=> navigate('/admin/clients')} startIcon={<CancelOutlined/>}>{Language['Cancel']}</Button></Headertitle>

            <Content1>
                <Headertitle style={{fontSize: '20px', margin: '0px', width: 'calc(100% - 12px)'}}>{Language['Client details']}</Headertitle>
                

                <TextField
                    style={{width: '48%', marginTop: '20px', height: '65px'}}
                    type='text'
                    name='firstName'
                    id="outlined-required"
                    label={Language['First name']}
                    defaultValue=""
                    value={firstName.value}
                    error={firstName.error}
                    helperText={firstName.helperText}
                    onChange={handleFirstNameChange}
                />

                <TextField
                    style={{width: '48%', marginTop: '20px', height: '65px'}}
                    name='familyName'
                    type='text'
                    id="outlined-required"
                    label={Language['Family name']}
                    defaultValue=""
                    value={familyName.value}
                    error={familyName.error}
                    helperText={familyName.helperText}
                    onChange={handleFamilyNameChange}
                />

                <TextField
                    style={{width: '100%', marginTop: '20px'}}
                    id="outlined-required"
                    type='text'
                    label={Language['Phone number']}
                    name='phoneNumber'
                    defaultValue=""
                    value={phoneNumber.value}
                    error={phoneNumber.error}
                    helperText={phoneNumber.helperText}
                    onChange={handlePhoneNumberChange}
                />

                <TextField
                    name='society'
                    style={{width: '100%', marginTop: '20px'}}
                    type='text'
                    id="outlined-required"
                    label={Language['Society']}
                    defaultValue=""
                    value={society.value}
                    error={society.error}
                    helperText={society.helperText}
                    onChange={handleSocietyChange}
                />

                <Headertitle style={{fontSize: '20px', margin: '0px', width: 'calc(100% - 12px)', marginTop: '20px'}}>{Language['Login credentials']}</Headertitle>

                <TextField
                    name='email'
                    style={{width: '100%', marginTop: '20px'}}
                    required
                    id="outlined-required"
                    type='email'
                    label={Language['Email']}
                    defaultValue=""
                    value={email.value}
                    error={email.error}
                    helperText={email.helperText}
                    onChange={handleEmailChangeChange}
                />  

                

                <FormControl sx={{ m: 0, width: '100%' }} error={password.error}  style={{width: '100%', marginTop: '20px'}}  variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{Language['Password']} *</InputLabel>
                    <OutlinedInput
                        name='password'
                        id="outlined-adornment-password"
                        type={'text'}
                        value={password.value}
                        //error={password.error}
                        //helperText={'invalid password'}
                        onChange={handlePasswordChangeChange}
                        endAdornment={
                        <InputAdornment position="end">
                            <Button
                                aria-label="toggle password visibility"
                                onClick={()=>generatePassword(12)}
                                //onMouseDown={handleMouseDownPassword}
                                edge="end"
                                variant='contained'
                                style={{backgroundColor: 'green'}}
                                endIcon={<Autorenew /> }
                                >
                                {Language['Generate']}
                            </Button>
                        </InputAdornment>
                        }
                        label={Language['Password']}
                    />
                    <FormHelperText id="outlined-weight-helper-text">{password.helperText}</FormHelperText>
                </FormControl>


                <FormControlLabel 
                    control={<Checkbox checked={sendLoginEmail} onChange={(e)=> setSendLoginEmail(e.target.checked)} style={{color: colors.primary}} />} 
                    label={Language['Send login credantials to this email.']}
                    style={{width: '100%', marginTop: '10px'}} 
                />

                <SubContainer style={{justifyContent: 'end'}}>
                    <Button variant='text' onClick={()=> navigate('/admin/clients')} style={{color: colors.primary, marginRight: '10px'}}>{Language['Cancel']}</Button>
                    <LoadingButton loading={loadingSubmit} loadingPosition='center' variant='contained' onClick={handleSubmit}  style={{backgroundColor: colors.primary, color: loadingSubmit ? 'transparent' : 'white'}} >{Language['Create']}</LoadingButton>
                </SubContainer>
            </Content1>
           
        </Content>

        <Snackbar
            open={alert.open}
            autoHideDuration={4000}
            onClose={handleClose}
            >
            <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                {Language[alert.value] ? Language[alert.value] : alert.value}
            </Alert>
        </Snackbar>
  
    </Container>
  )
}
