import React, { useEffect } from 'react'
import { useState } from 'react';


import {  Upload } from 'antd';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Button from '@mui/material/Button';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AlertComp from './alert';
import colors from '../colors';




export default function Feedback({open, setOpen}) {

    const [disableSendFeedbackBtn, setDisableSendFeedbackBtn] = useState(false);
    const [message, setMessage] = useState("");
    const [messageError, setMessageError] = useState("");
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [ openAlert, setOpenAlert] = useState(false)

    const [uploadedImages, setuploadedImages] = useState([]);
    const props = {
        name: 'file',
        multiple: true,
        action: '/upload_feedback_image',
        method: 'post',
        onChange(info) {
          console.log("hello")
          //setUploadCsvBtnDisabled(true)
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
            setuploadedImages([])
            for (let i = 0; i < info.fileList.length; i++) {
                const item = info.fileList[i];
                if(item.response.read === 'true'){
                    console.log(item.response.myImage)
                    setuploadedImages(prevArray => [...prevArray, item.response.myImage])
                }
            }
          }
          if (status === 'done') {
            // setUploadCsvBtnDisabled(false)
            //message.success(`${info.file.name} file uploaded successfully.`);
            console.log(info.file.response)
          } else if (status === 'error') {
            // setUploadCsvBtnDisabled(false)
            console.log('comeonnnn')
            console.log(info.file)
            console.log(`${info.file.name} file upload failed.`);
            if(info.file.response && info.file.response.read === 'false'){
              setAlertMessage(info.file.response.error)
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000); 
            }
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
      };

    // send feedback
    const handleCloseUserModal = () => {
        setOpen(false);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
      };

    function sendFeedback(images, message){
        setDisableSendFeedbackBtn(true)
        axios.post("/sendFeedback", {
          images, message
        })
        .then(function (response) {
          console.log(response)
          setDisableSendFeedbackBtn(false)
          if(response.data.res === 'done'){
            handleCloseUserModal(false)
            setMessage('')
            setOpenAlert(true)
            setAlertMessage('Thanks for your support!')
            setAlertType("success")
            setInterval(() => {
              setOpenAlert(false)
            }, 4000);
          }
          else if(response.data.res === 'server_error'){
            handleCloseUserModal(false)
            setMessage('')
            setOpenAlert(true)
            setAlertMessage('error has occured')
            setAlertType("error")
            setInterval(() => {
              setOpenAlert(false)
            }, 4000);
            // redirect to server error page
          }else if(response.data.res === 'redirect'){
            handleCloseUserModal(false)
            setMessage('')
            setOpenAlert(true)
            setAlertMessage('You must login to your account first')
            setAlertType("error")
            setInterval(() => {
              setOpenAlert(false)
            }, 4000);
            // redirect to server error page
          }else if(response.data.res === 'input_error'){
            for(let i = 0; i < response.data.error.length; i++){
              if(response.data.error[i].path === 'email'){
               
              }
              else{
                setIsErrorMessage(true)
                setMessageError(response.data.error[i].error)
              }
            }
          }
          
        })
        .catch(function (error) {
          // handle error
          setDisableSendFeedbackBtn(false)
          handleCloseUserModal(false)
          setMessage('')
          console.log(error)
          if(error.response){
            setOpenAlert(true)
            setAlertMessage(error.response.statusText)
            setAlertType("error")
          }else{
            setOpenAlert(true)
            setAlertMessage('an error has occured')
            setAlertType("error")
          }
          setInterval(() => {
            setOpenAlert(false)
          }, 4000);
        })
      }

      useEffect(() => {
        if(open === false){
            setuploadedImages([])
        }
      }, [open]);

  return (
    <>
    <Dialog open={open} onClose={handleCloseUserModal}>
        <DialogTitle style={{alignContent: 'center'}}>Send a feedback.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you have any additional feedback or concerns, please don't hesitate to help us to improve our services and ensure we are meeting your needs.
          </DialogContentText>
        
            <FormControl error={isErrorMessage} variant="standard" style={{width: '100%', marginTop: '0px'}}>
                <TextField
                    onKeyDown={ (event) => { if (event.key === 'Enter') { sendFeedback(uploadedImages, message) }}}
                    error={isErrorMessage}
                    aria-describedby="message_error"
                    style={{width: '100%', marginTop: '0px'}}
                    id="standard-multiline-static"
                    label="Message"
                    multiline
                    defaultValue={message}
                    rows={7}
                    variant="standard"
                    onChange={ (e) => {
                      setMessage(e.target.value)
                      setIsErrorMessage(false)
                      setMessageError('')
                    }
                    }
                />
                <FormHelperText id="message_error">{messageError}</FormHelperText>
            </FormControl>
        <DialogContentText style={{marginTop: '20px', marginBottom: '10px'}}>
            You can add some images.
        </DialogContentText>
        <Upload {...props}>
            <Button variant='outlined' style={{ color: colors.primary, borderColor: colors.primary}} startIcon={<FileUploadOutlinedIcon />}>Add Images</Button>
        </Upload>
        
          

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUserModal}>Cancel</Button>
          <Button disabled={disableSendFeedbackBtn} onClick={() => sendFeedback(uploadedImages, message)}>Send</Button>
        </DialogActions>
      </Dialog>

      <AlertComp open={openAlert} message={alertMessage} type={alertType} handleClose={handleClose} ></AlertComp>
    </> 
  )
}
