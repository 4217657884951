import { AccountBalanceOutlined, AccountBalanceWalletOutlined, Add, BalanceOutlined, CalendarMonth, Filter1Outlined, FilterList, FilterListOffOutlined, FilterListOutlined, InfoOutlined, KeyboardArrowDown, LaunchOutlined, LocalAtmOutlined, OpenInBrowser, Payment, ReceiptLongOutlined, SimCardOutlined, Upload } from '@mui/icons-material';
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, IconButton, Input, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import colors from '../../colors';
import axios from 'axios';
import {  alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



export default function History({Language}) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const [openFilterMessagesModal, setOpenFilterMessagesModal] = React.useState(false);
    const handleCloseFilterMessagesModal = () => {
      setOpenFilterMessagesModal(false);
    };

    const [openAddBalanceModal, setOpenAddBalanceModal] = React.useState(false);
    const handleCloseAddBalanceModal = () => {
      setOpenAddBalanceModal(false);
    };

  const handleCloseMenu = (link) => {
    if(link === 'account'){
        navigate('/app/profile');
    }
    setAnchorEl(null);
};

    const [filterFrom, setFilterFrom] = useState('');
    const [filterTo, setFilterTo] = useState('');
    const [filterStatus, setfilterStatus] = useState("This month")
    function filterMessages(type){
        
      handleCloseFilterMessagesModal()
      setLoadingBalance(true)
      setLoadingMessages(true)
      var startAt = '', endAt = ''
      if(type === "advanced"){
        startAt = filterFrom
        endAt = filterTo
        setfilterStatus(filterFrom + ' > ' + filterTo)
      }else{
        setfilterStatus(type)
      }
      axios.post("/admin_filterMainInfo", {
        type, startAt, endAt,
      })
      .then(function (response) {
        setLoadingBalance(false)
        setLoadingMessages(false)
        setFiltering(false)

        if(response.data.response === 'done'){
              //setbalance(response.data.messages)
          setTotalSent(response.data.sent)
          setTotalProfit(response.data.profit)
          setTotlaConsumed(response.data.consumed)
          setTotalSmsInDjezzy(response.data.djezzySMS)
          setTotalSmsInOoredoo(response.data.ooredooSMS)
          setTotalSmsInMobilis(response.data.mobilisSMS)
          setAllSMS(response.data.myAllSMS)
          // setBalance(response.data.balance)
          setBalances(response.data.balances)
          setTotalConsumedWithProfit(response.data.consumedWithProfit)
          setOoredooCount(response.data.ooredooCount)
          setMobilisCount(response.data.mobilisCount)
          setDjezzyCount(response.data.djezzyCount)
          setSentCount(response.data.sentCount)
        }
        else if(response.data.response === 'server_error'){
          setOpenAlert(true)
          setAlertMessage('An unexpected error has occured')
          setAlertType("error")
          // redirect to server error page
        }
        else if(response.data.response === 'redirect'){
          navigate('/login', { replace: true });
        }
      })
      .catch(function (error) {
        // handle error
        setLoadingBalance(false)
        setLoadingMessages(false)
        console.log(error)
        if(error.response){
          if(error.response.status === 401 && error.response.data.response === 'banned'){
            navigate('/app/banned', { replace: true });
          }else{
            setOpenAlert(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType("error")              
          }
        }else{
          setOpenAlert(true)
          setAlertMessage('An unexpected error has occured')
          setAlertType("error")
        }
      })
    
    }

    const [loadingBalance, setLoadingBalance] = useState(false);
    const [balance, setBalance] = useState(0);
    const [balances, setBalances] = useState([]);
    function addBalance(type){
        
      //handleCloseFilterMessagesModal()
      setLoadingBalance(true)
      axios.post("/admin_add_balance", {
        amount, description: amountDescription
      })
      .then(function (response) {
        setLoadingBalance(false)
        if(response.data.response === 'done'){
          setBalance(response.data.balance)
          setBalances(response.data.balances)
          setOpenAddBalanceModal(false)
        }
        else if(response.data.response === 'invalid_params'){
          setOpenAlert(true)
          setAlertMessage(response.data.error)
          setAlertType("error")
        }
        else if(response.data.response === 'redirect'){
          navigate('/admin/login', { replace: true });
        }
      })
      .catch(function (error) {
        // handle error
        setLoadingBalance(false)
        console.log(error)
        setOpenAlert(true)
        setAlertMessage('An unexpected error has occured')
        setAlertType("error")              
      })
    
    }


    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

    const updateDeviceWidth = () => {
        setDeviceWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener('resize', updateDeviceWidth);
        return () => {
        window.removeEventListener('resize', updateDeviceWidth);
        };
    }, []);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };    

    const [openAllFilterMessagesModal, setOpenAllFilterMessagesModal] = React.useState(false);
      const handleCloseAllFilterMessagesModal = () => {
        setOpenAllFilterMessagesModal(false);
    };

    const [sender, setSender] = useState('');
    const [senderObject, setSenderObject] = useState();
    const [operator, setOperator] = useState('');
    const [status, setstatus] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [senders, setSenders] = useState([]);
    const [allSMS, setAllSMS] = useState([]);

    const getRowClassName = (params) => {
        const status = params.row.status;
        if (status === 'sent') {
          return 'sent-row1';
        } else if (status === 'failed') {
          return 'failed-row1';
        }
        return '';
      };

    const [loading, setLoading] = useState(!true);
    const [totalSent, setTotalSent] = useState(0);
    const [totlaConsumed, setTotlaConsumed] = useState(0);
    const [totalConsumedWithProfit, setTotalConsumedWithProfit] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [totalSmsInDjezzy, setTotalSmsInDjezzy] = useState(0);
    const [totalSmsInMobilis, setTotalSmsInMobilis] = useState(0);
    const [totalSmsInOoredoo, setTotalSmsInOoredoo] = useState(0);
    const [ooredooCount, setOoredooCount] = useState(0);
    const [djezzyCount, setDjezzyCount] = useState(0);
    const [mobilisCount, setMobilisCount] = useState(0);
    const [sentCount, setSentCount] = useState(0);
    useEffect(() => {
      axios.post("/admin_home", {
    })
    .then(function (response) {
      console.log(response.data)
        if(response.data.response === 'done'){
          setLoading(false)
          setTotalSent(response.data.sent)
          setTotalProfit(response.data.profit)
          setTotlaConsumed(response.data.consumed)
          setTotalSmsInDjezzy(response.data.djezzySMS)
          setTotalSmsInOoredoo(response.data.ooredooSMS)
          setTotalSmsInMobilis(response.data.mobilisSMS)
          setAllSMS(response.data.myAllSMS)
          setTotalConsumedWithProfit(response.data.consumedWithProfit)
          setBalance(response.data.balance)
          setBalances(response.data.balances)
          setSenders(response.data.senders)
          setOoredooCount(response.data.ooredooCount)
          setMobilisCount(response.data.mobilisCount)
          setDjezzyCount(response.data.djezzyCount)
          setSentCount(response.data.sentCount)
        }
    })
    .catch(function (error) {
        // handleAlertChanges(true, error.message, 'error')
    })
    }, []);

    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [ openAlert, setOpenAlert] = useState(false)

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenAlert(false);
    };

    const [amount, setAmount] = useState(0);
    const [amountDescription, setAmountDescription] = useState('');

    const [loadingMessages, setLoadingMessages] = useState(false);
    const [filtering, setFiltering] = useState(false);

    function filterSMS(sender, operator, status) {
      setFiltering(true)
      setLoadingMessages(true)
      handleCloseAllFilterMessagesModal()
      axios.post("/admin_filter_messages", {
        sender, operator, status, startAt: filterFrom, endAt: filterTo, type: filterStatus
      })
      .then(function (response) {
        console.log(response.data)
          setLoadingMessages(false)
          if(response.data.response === 'done'){
            setAllSMS(response.data.messages)
          }
      })
      .catch(function (error) {
          setLoadingMessages(false)
          setAlertMessage('An unexpected error has occured')
          setAlertType('error')
          setOpenAlert(true)
      })
    }

    function removeFilter(){
      setFiltering(false)
      setSender('')
      setSenderObject()
      setOperator('')
      setstatus('')
      filterSMS('', '', '')
    }

    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleSelectionModelChange = (newSelection) => {
      setSelectionModel(newSelection);
      setSelectedRows(newSelection)
    }
    const [exportBtnDisabled, setExportBtnDisabled] = useState(false);

    function  exportMessages(rows){
      try {
        if(rows.length > 0){
        console.log(rows)
        setExportBtnDisabled(true)
        axios.post("/admin_exportReport", {
          rows
        })
        .then(function (response) {
          setExportBtnDisabled(false)
          console.log(response)
          if(response.data.res === 'done'){
            setAlertMessage('Exported successfully')
            setAlertType('success')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
            // show data
          }
          else if(response.data.res === 'server_error'){
            setAlertMessage('Internal server error')
                setAlertType('error')
                setOpenAlert(true)
                setTimeout(() => {
                  setOpenAlert(false)
                }, 3000);
            // redirect to server error page
          }
          else if(response.data.res === 'redirect'){
            //navigate('/login', { replace: true });
          }else{
            // Create a Blob object from the response data
            const blob = new Blob([response.data], { type: 'text/csv' });

            // Create a temporary URL for the Blob object
            const url = URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');

            // Set the link's href attribute to the temporary URL
            link.href = url;

            // Set the link's download attribute to the desired filename
            link.download = 'data.csv';

            // Append the link to the DOM
            document.body.appendChild(link);

            // Click the link to download the file
            link.click();

            // Remove the link from the DOM
            document.body.removeChild(link);

            // Revoke the temporary URL to free up memory
            URL.revokeObjectURL(url);
          }
        })
        .catch(function (error) {
          // handle error
          setExportBtnDisabled(false)
          console.log('this is axios error');
          console.log(error);
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpenAlert(true)
              setAlertMessage('An unexpected error has occured')
              setAlertType("error")               
            }

          }else{
            setOpenAlert(true)
            setAlertMessage('Internal server error')
            setAlertType("error")
          }
        })
       }else{
          setAlertMessage('No item is selected')
                setAlertType('error')
                setOpenAlert(true)
                setTimeout(() => {
                  setOpenAlert(false)
                }, 3000);
       }
      } catch (error) {
        setExportBtnDisabled(true)
      }
    }

    
const columns = [
    
  //   {
  //     field: 'society',
  //     headerName: 'Society',
  //     sortable: false,
  //     width: 150,
  //     valueGetter: (params) =>
  //       `${params.row.contact.society || ''}`,
  // },
    // { field: 'society', headerName: 'Society', width: 150 },
    { field: 'subject', headerName: Language['Source'], width: 150 },
    { field: 'phone_number', headerName: Language['Phone'], width: 150 },
    { field: 'message', headerName: Language['Message'], flex: 1, minWidth: 150},
    // { field: 'count', headerName: 'Count', width: 80 },
    {
      field: 'count',
      headerName: Language['Count'],
      sortable: false,
      width: 80,
      renderCell: (params) => {
        var count = 0
        const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
            if (arabicRegex.test(params.row.message)) {
                if(params.row.message.length > 70){
                    count = Math.floor((params.row.message.length / 67 ) + 0.999999999)
                }else{
                    count = Math.floor((params.row.message.length / 70 ) + 0.999999999)
                }
            } else {
                if(params.row.message.length > 160){
                    count = Math.floor((params.row.message.length / 153 ) + 0.999999999)
                }else{
                    count = Math.floor((params.row.message.length / 160 ) + 0.999999999)
                }
            }  
        return(
            <>{count}</>
          )
      }
    },
    // { field: 'status', headerName: 'Status', width: 150 },
    { field: 'description', headerName: Language['Description'], flex: 1,  minWidth: 250,  },
    {
        field: 'client_id',
        headerName: Language['Client'],
        sortable: false,
        width: 160,
        renderCell: (params) => {
          return(
            <Button onClick={()=> window.open(`/admin/client?id=${params.row.client_id}`, '_blank')} endIcon={<LaunchOutlined/>}>Client</Button>
          )
        }
    },

    {
      field: 'createdAT',
      headerName: Language['Date'],
      sortable: false,
      width: 160,
      renderCell: (params) => {
        var originalDate = new Date(params.row.createdAt);
        originalDate.setHours(originalDate.getHours() + 1);
        var newString = originalDate.toISOString();
        return(
          <>{newString.split('T')[0]} {newString.split('T')[1].slice(0, 8)}</>
        )
      }
  },
    // { field: 'date', headerName: 'Date',  width: 150},
  ];
  
 
const columnsBalance = [
    { field: 'amount', headerName: Language['Amount (DZD)'], width: 190, sortable: false,
      width: 190,
      renderCell: (params) => {
        const data = params.row.amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        return(
          <span style={{fontWeight: 600}}>{data}</span>
        )
     } 
    },
    { field: 'last_balance', headerName: Language['Last balance (DZD)'], width: 190, sortable: false,
      width: 190,
      renderCell: (params) => {
        const data = params.row.last_balance.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        return(
          <>{data}</>
        )
     } 
    },
    { field: 'new_balance', headerName: Language['New balance (DZD)'], width: 190, sortable: false,
      width: 190,
      renderCell: (params) => {
        const data = params.row.new_balance.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        return(
          <>{data}</>
        )
     } 
    },
    // { field: 'last_balance', headerName: 'Last balance', width: 190,  },
    // { field: 'new_balance', headerName: 'New balance', width: 190 },
    { field: 'description', headerName: Language['Description'], minWidth: 200, flex: 1 },
    { field: 'createdAT',
      headerName: 'Date',
      sortable: false,
      width: 190,
      renderCell: (params) => {
        var originalDate = new Date(params.row.createdAt);
        originalDate.setHours(originalDate.getHours() + 1);
        var newString = originalDate.toISOString();
        return(
          <>{newString.split('T')[0]} {newString.split('T')[1].slice(0, 8)}</>
        )
      } 
    },
];


    if(loading){
      return(
        <Home width={deviceWidth} style={{width: '100%', height: 'calc(100vh - 50px)', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress/>
        </Home>
      )
    }
    else{
      return (
        <Home width={deviceWidth}>
            <Header width={deviceWidth}>
                <SubHeader  width={deviceWidth}>
                    <p style={{margin: '-2px 0px 5px 0px', padding: '0px', color: 'gray', fontSize: '16px',  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%", alignItems:' center'}}> <span>{Language['Total Consumed']}</span> <Button variant='text' color='info' endIcon={<KeyboardArrowDown/>}
                      size='small' onClick={handleClick} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" id="basic-button" aria-controls={open ? 'basic-menu' : undefined}
                    >{Language[filterStatus] ? Language[filterStatus] : filterStatus }</Button>  </p>
                    <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => handleCloseMenu()}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => {handleCloseMenu(); filterMessages('this month')}} >
                            <ListItemIcon>
                              <CalendarMonth fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{Language['This Month']}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => {handleCloseMenu(); filterMessages('this year')}} >
                            <ListItemIcon>
                              <CalendarMonth fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{Language['This Year']}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => {handleCloseMenu(); filterMessages('life time')}} >
                            <ListItemIcon>
                              <CalendarMonth fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{Language['Life Time']}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => {handleCloseMenu(); setOpenFilterMessagesModal(true)}} >
                            <ListItemIcon>
                              <FilterList fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{Language['Advanced Filtering']}</ListItemText>
                            </MenuItem>
                        </Menu>
                    {/* <p style={{margin: '5px 0px', padding: '0px', color: 'gray', fontSize: '16px',  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%"}}> <span>Total Consumed</span> <ReceiptLongOutlined style={{color: "#2e74a0"}}/>  </p> */}
                    <p style={{margin: '25px 0px 25px 0px', padding: '0px', color: 'black', fontSize: '27px', fontWeight: '600'}}>{totlaConsumed} DZD</p>
                    <p style={{margin: '-15px 0px', padding: '0px', color: 'gray', fontSize: '16px'}}><span style={{color: 'black', fontWeight: '600'}}>{totalConsumedWithProfit} DZD</span> {Language['with profit']}</p>
                </SubHeader>
                <SubHeader   width={deviceWidth}>
                    <p style={{margin: '5px 0px', padding: '0px', color: 'gray', fontSize: '16px',  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%"}}> <span>{Language['Operators']}</span> <SimCardOutlined style={{color: "#907e1a"}}/> </p>
                    <p style={{margin: '7px 0px 1px 0px', padding: '0px', color: 'black', fontSize: '16px', fontWeight: '400', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><Rectangle style={{backgroundColor: '#13a5f3'}}>.</Rectangle> <p style={{width: "120px", margin: '0px', padding: '0px'}}>{Language['All']}</p>  <span style={{color: 'gray'}}>{totalSent} {Language['sent']} /  {sentCount} sms </span> </p>
                    <p style={{margin: '1px 0px 1px 0px', padding: '0px', color: 'black', fontSize: '16px', fontWeight: '400', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><Rectangle style={{backgroundColor: 'orange'}}>.</Rectangle> <p style={{width: "120px", margin: '0px', padding: '0px'}}>Ooredoo</p>  <span style={{color: 'gray'}}>{totalSmsInOoredoo} {Language['sent']} /  {ooredooCount} sms</span> </p>
                    <p style={{margin: '1px 0px', padding: '0px', color: 'black', fontSize: '16px', fontWeight: '400', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><Rectangle style={{backgroundColor: 'green'}}>.</Rectangle> <p style={{width: "120px", margin: '0px', padding: '0px'}}>Mobilis</p>  <span style={{color: 'gray'}}>{totalSmsInMobilis} {Language['sent']} /  {mobilisCount} sms</span> </p>
                    <p style={{margin: '1px 0px', padding: '0px', color: 'black', fontSize: '16px', fontWeight: '400', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><Rectangle style={{backgroundColor: 'red'}}>.</Rectangle> <p style={{width: "120px", margin: '0px', padding: '0px'}}>Djezzy</p>  <span style={{color: 'gray'}}>{totalSmsInDjezzy} {Language['sent']} /  {djezzyCount} sms</span> </p>
                </SubHeader>
                <SubHeader  width={deviceWidth}>
                    <p style={{margin: '5px 0px', padding: '0px', color: 'gray', fontSize: '16px',  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%"}}> <span>{Language['Total Profit']}</span> <LocalAtmOutlined style={{color: "#069649"}}/> </p>
                    <p style={{margin: '25px 0px', padding: '0px', color: 'black', fontSize: '27px', fontWeight: '600'}}>{totalProfit} DZD</p>
                    <p style={{margin: '-15px 0px', padding: '0px', color: 'gray', fontSize: '16px'}}>{Language['Net profit']}</p>
                </SubHeader>
                <SubHeader  width={deviceWidth}>
                    <p style={{margin: '5px 0px', padding: '0px', color: 'gray', fontSize: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%",}}><span>{Language['Balance']}</span>  <AccountBalanceWalletOutlined style={{color: "#f57441"}}/> </p>
                    <p style={{margin: '25px 0px 0px 0px', padding: '0px', color: 'black', fontSize: '27px', fontWeight: '600',}}>{balance} DZD</p>
                    <IconButton onClick={()=> setOpenAddBalanceModal(true)} style={{backgroundColor: "#188fea", color: 'whitesmoke', alignSelf: 'end'}}><Add></Add></IconButton>
                </SubHeader>
            </Header>
    
            <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={Language['SMS']} {...a11yProps(0)} />
                <Tab label={Language['Balance']} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center'}}>
                    <p style={{margin: '0px 0px', padding: '0px', color: 'gray', fontSize: '16px'}}>{Language['History of SMS messages']} </p>
                    <div>
                    <IconButton style={{display: filtering ?  '' : 'none'}} onClick={removeFilter} ><FilterListOffOutlined/></IconButton>
                    <Button variant='contained' onClick={()=> setOpenAllFilterMessagesModal(true)} style={{ marginLeft: '10px', backgroundColor: colors.primary}} startIcon={<FilterListOutlined/>}>{Language['Filter']}</Button>
                    <IconButton variant='text' disabled={exportBtnDisabled}  onClick={()=> exportMessages(selectedRows)} style={{ marginLeft: '10px', color: colors.primary}}><Upload/></IconButton>
                    </div>
                </div>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        getRowClassName={getRowClassName}
                        rows={allSMS}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                        }}
                        loading={loadingMessages}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection={true}
                        selectionModel={selectionModel}
                        onSelectionModelChange={handleSelectionModelChange}
                        disableSelectionOnClick
                        //sortModel={[{field: 'date',sort: 'desc'}]}
                    />
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center'}}>
                    <p style={{margin: '0px 0px', padding: '0px', color: 'gray', fontSize: '16px'}}>{Language['History of account balance recharging']}</p>
                    <div>
                    </div>
                </div>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        getRowClassName={getRowClassName}
                        rows={balances}
                        columns={columnsBalance}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                        }}
                        pageSizeOptions={[5, 10]}
                        loading={loadingBalance}
                        checkboxSelection={false}
                        disableSelectionOnClick
                        sortModel={[{field: 'date',sort: 'desc'}]}
                    />
                </div>
            </CustomTabPanel>
            
            </Box>
    
            {/* All filters Modal in Message*/}
            <Dialog
              fullWidth={true}
              maxWidth={'md'}
              open={openAllFilterMessagesModal}
              onClose={handleCloseAllFilterMessagesModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
              {Language['Filter Messages']}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {Language['use the fields below to filter messages than press filter button.']}
                </DialogContentText>

                <Autocomplete
                  multiple={false}
                  id="tags-standard"
                  options={senders}
                  value={senderObject}
                  getOptionLabel={(option) => option.source}
                  onChange={(e, newValue)=> {setSender(newValue ? newValue.source : ''); setSenderObject(newValue)}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={Language['Sender ID']}
                      placeholder={Language['Sender ID']}
                    />
                  )}
                />
    
                
    
                <FormControl error={false} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '5px'}}>
                        {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                        <InputLabel htmlFor="subject">{Language['Operator']}</InputLabel>
                        
                        
                        
                        <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={operator}
                        onChange={ (e) =>{
                          setOperator(e.target.value)
                        }
                        }
                        >
                          <MenuItem value={""}>{Language['All']}</MenuItem>
                          <MenuItem value={"DJEZZY"}>{"Djezzy"}</MenuItem>
                          <MenuItem value={"MOBILIS"}>{"Mobilis"}</MenuItem>
                          <MenuItem value={"OOREDOO"}>{"Ooredoo"}</MenuItem>
    
                        {/* {
                          senders.map(((item) => {
                            return (
                              <MenuItem value={item.source}>{item.source}</MenuItem>
                            )
                          }))
                        } */}
                        </Select>
                        <FormHelperText id="time_error"></FormHelperText>
                </FormControl>
    
                <FormControl error={false} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '5px'}}>
                        {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                        <InputLabel htmlFor="subject">{Language['Status']}</InputLabel>
    
                        <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={status}
                        onChange={ (e) =>{
                          setstatus(e.target.value)
                        }
                        }
                        >
                          <MenuItem value={""}>{Language['All']}</MenuItem>
                          <MenuItem value={"sent"}>{Language['Sent']}</MenuItem>
                          <MenuItem value={"failed"}>{Language['Failed']}</MenuItem>
    
                        {/* {
                          senders.map(((item) => {
                            return (
                              <MenuItem value={item.source}>{item.source}</MenuItem>
                            )
                          }))
                        } */}
                        </Select>
                        <FormHelperText id="time_error"></FormHelperText>
                </FormControl>
    
    
                
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAllFilterMessagesModal}>{Language['Cancel']}</Button>
                <Button onClick={() => filterSMS(sender, operator, status)} autoFocus>
                {Language['Filter']}
                </Button>
              </DialogActions>
            </Dialog>

            {/* select date modal  */}
            <Dialog
              open={openFilterMessagesModal}
              onClose={handleCloseFilterMessagesModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
              {Language['Filter Messages']}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {Language['use the fields below to filter messages based on the date range you specify.']}
                </DialogContentText>

                <FormControl error={false} variant="standard" style={{width: '100%', marginTop: '30px'}}>
                        <p style={{width: '100%', marginBottom: '0px'}}>{Language['From']}</p>
                        <Input
                          onChange={(e) => {
                            setFilterFrom(e.target.value)
                          //   setIsErrorTime(false)
                          //   setTimeError('')
                          }}
                          type='date'
                          id=""
                          defaultValue=""
                          aria-describedby="time_error"
                          placeholder=''
                        />
                        <FormHelperText id="time_error"></FormHelperText>
                    </FormControl>

                    <FormControl error={false} variant="standard" style={{width: '100%', marginTop: '30px'}}>
                        <p style={{width: '100%', marginBottom: '0px'}}>{Language['To']}</p>
                        <Input
                          onChange={(e) => {
                            setFilterTo(e.target.value)
                            // setIsErrorTime(false)
                            // setTimeError('')
                          }}
                          type='date'
                          id=""
                          defaultValue=""
                          aria-describedby="time_error"
                          placeholder=''
                        />
                        <FormHelperText id="time_error"></FormHelperText>
                    </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseFilterMessagesModal}>{Language['Cancel']}</Button>
                <Button onClick={() => filterMessages("advanced")} autoFocus>
                {Language['Filter']}
                </Button>
              </DialogActions>
            </Dialog>


            {/* add balance modal  */}
            <Dialog
              open={openAddBalanceModal}
              onClose={handleCloseAddBalanceModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
              {Language['Add balance']}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {Language["Balance added can't be edited or deleted, click add button to add balance"]}
                </DialogContentText>

                <FormControl error={false} variant="standard" style={{width: '100%', marginTop: '30px'}}>
                        <p style={{width: '100%', marginBottom: '0px'}}>{Language['Amount']} (DZD)</p>
                        <Input
                          onChange={(e) => {
                            if(e.target.value >= 0){
                              setAmount(e.target.value)
                            }
                          //   setIsErrorTime(false)
                          //   setTimeError('')
                          }}
                          value={amount}
                          type='number'
                          id=""
                          defaultValue=""
                          placeholder=''
                        />
                        <FormHelperText id="time_error"></FormHelperText>
                    </FormControl>

                    <FormControl error={false} variant="standard" style={{width: '100%', marginTop: '30px'}}>
                        <p style={{width: '100%', marginBottom: '0px'}}>{Language['Description']}</p>
                        <Input
                          onChange={(e) => {
                            setAmountDescription(e.target.value)
                            // setIsErrorTime(false)
                            // setTimeError('')
                          }}
                          type='text'
                          id=""
                          defaultValue=""
                          aria-describedby="time_error"
                          placeholder=''
                        />
                        <FormHelperText id="time_error"></FormHelperText>
                    </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddBalanceModal}>{Language['Cancel']}</Button>
                <LoadingButton loading={loadingBalance} loadingPosition='center' onClick={() => addBalance("advanced")} autoFocus>
                {Language['Add']}
                </LoadingButton>
              </DialogActions>
            </Dialog>
        

            {/* // snackbar */}
            <Snackbar
              open={openAlert}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
              {Language[alertMessage] ? Language[alertMessage] : alertMessage}
              </Alert>
            </Snackbar>


        </Home>
      )
    }
    
}

const Home  = styled.div`
    width: 100%;
    padding: 0%;
    height: max-content;
    display: flex;
    flex-direction: ${props => props.width > 800? 'column' : 'column'};
    justify-content: ${props => props.width > 800? 'start' : 'start'};
    align-items: start;
    flex-wrap:${props => props.width > 800? 'nowrap' : 'nowrap'};
`

const Header  = styled.div`
    width: calc(100% - 20px);
    padding: 0%;
    display: flex;
    flex-direction: ${props => props.width > 800? 'row' : 'column'};
    justify-content: ${props => props.width > 800? 'space-between' : 'start'};
    align-items: start;
    flex-wrap:${props => props.width > 800? "wrap" : 'nowrap'};
    border-radius: 15px;
    padding: 10px;
`

const SubHeader  = styled.div`
    width: ${props => props.width > 800? '22%' : 'calc(100% - 20px)'};
    margin-bottom: ${props => props.width > 800? '10px' : '10px'};
    min-width: 230px;
    padding: 0%;
    height: 130px;
    display: flex;
    flex-direction: ${props => props.width > 800? 'column' : 'column'};
    justify-content: ${props => props.width > 800? 'start' : 'start'};
    align-items: start;
    flex-wrap:${props => props.width > 800? 'nowrap' : 'nowrap'};
    background-color: whitesmoke;
    border-radius: 15px;
    padding: 10px;
`

const Rectangle = styled.div`
    width: 7px;
    height: 7px;
    color: transparent;
    margin-right: 5px;
`


const rowsBalance = [
    { id: 1, Amount: '7,000.00', lastBalance: '2,200.00', NewBalance: '9,200.00', date: '25/2/2024 12:05',  },
    { id: 2, Amount: '7,000.00', lastBalance: '2,200.00', NewBalance: '9,200.00', date: '25/2/2024 12:05',  },
    { id: 3, Amount: '7,000.00', lastBalance: '2,200.00', NewBalance: '9,200.00', date: '25/2/2024 12:05',  },
    { id: 4, Amount: '7,000.00', lastBalance: '2,200.00', NewBalance: '9,200.00', date: '25/2/2024 12:05',  },
    { id: 5, Amount: '7,000.00', lastBalance: '2,200.00', NewBalance: '9,200.00', date: '25/2/2024 12:05',  },
    { id: 6, Amount: '7,000.00', lastBalance: '2,200.00', NewBalance: '9,200.00', date: '25/2/2024 12:05',  },
];  