import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import AlertComp from '../alert';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Divider from '@mui/material/Divider';
import TuneIcon from '@mui/icons-material/Tune';
import Grid from '@mui/material/Grid';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { getResponseMessage } from '../responding';
import EditIcon from '@mui/icons-material/Edit';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import colors from '../../colors';
import { AttachFileOutlined, Subject, UploadFile } from '@mui/icons-material';



const label = { inputProps: { 'aria-label': 'Switch demo' } };

const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: end;

    }
`

const HeaderSeach = styled.div`
    width: 40%;
    @media only screen and (max-width: 850px) {
        width: 90% !important;
        margin: 0% 5%;
        margin-top: 20px;
    }
`
const DivRow = styled.div`
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: end;
    margin-top:10px;
    margin-left: 0px;
`
const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 40%;
    }
`

const BodyContent = styled.div`
    width: 100%;
    background-color: #f6f7fa;
    height: calc(100vh - 118px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (max-width: 850px) {
      height: calc(100vh - 170px);
    }
`



 
 
 

export default function ContactsComp({handleHeader, Language}) {



  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'first_name', headerName: Language['First name'], width: 130 },
    { field: 'family_name', headerName: Language['Last name'], width: 130 },
    {
      field: 'fullName',
      headerName: Language['Full name'],
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.first_name || ''} ${params.row.family_name || ''}`,
    },
    { field: 'email', headerName: Language['Email'], width: 230 },
    { field: 'phone_number', headerName: Language['Phone'], width: 170 },
    { field: 'society', headerName: Language['Society'], width: 170 },
    {
      field: 'groups',
      headerName: Language['Groups'],
      sortable: false,
      width: 160,
      renderCell: (params) => {
        var group = ''
        params.row.groups.forEach((item) => {
            group += item.title  + ', ' 
        })
        return <Tooltip title={group}><p>{group}</p></Tooltip> 
      },
        
    },
    {
      field: 'Action',
      headerName: Language['Action'],
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params ) => {
         return <div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'row', width: '100%'}}>
                  <Button variant='outlined' style={{marginRight: '10px', color: 'gray', borderColor: 'gray'}} size="small" startIcon={<EditIcon />} onClick={(e) => {e.stopPropagation(); setOpenUpdateContactModal(true); handleUpdate(params.row)}}> {Language['Edit']} </Button >
                </div> 
      },
    },

  ];



  const [ loading, setLoading] = useState(true)
  const [ add_first_name, set_add_first_name] = useState('')
  const [ add_family_name, set_add_family_name] = useState('')
  const [ add_phone_number, set_add_phone_number] = useState('')
  const [ add_email, set_add_email] = useState('')
  const [ add_society, set_add_society] = useState('')

  const [ is_error_first_name, set_is_error_first_name] = useState(false)
  const [ is_error_family_name, set_is_error_family_name] = useState(false)
  const [ is_error_email, set_is_error_email] = useState(false)
  const [ is_error_phone_number, set_is_error_phone_number] = useState(false)
  const [ is_error_society, set_is_error_society] = useState(false)

  const [ first_name_error, set_first_name_error] = useState(false)
  const [ family_name_error, set_family_name_error] = useState(false)
  const [ email_error, set_email_error] = useState(false)
  const [ phone_number_error, set_phone_number_error] = useState(false)
  const [ society_error, set_society_error] = useState(false)

  const [ create_contact_btn_content, set_create_contact_btn_content] = useState('create')
  const [ contact_rows, set_contact_rows] = useState([])

  const [ DeleteContactModalBody, setDeleteContactModalBody] = useState('no item is selected, choose in minimum one item.')
  const [ DeleteContactModalBtn, setDeleteContactModalBtn] = useState('ok')
  const [ exportBtnContent , setExportBtnContent] = useState(<FileUploadIcon style={{fontSize: "x-large"}}/>)

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)

  const [ importConfig, setImportConfig] = useState([])
  useEffect(() => {
    if(importConfig[4] === 'society'){
      set_import_society(4)
    }else{
      set_import_society('')
    }

    if(importConfig[0] === 'first_name'){
      set_import_first_name(0)
    }else{
      set_import_first_name('')
    }

    if(importConfig[1] === 'family_name'){
      set_import_family_name(1)
    }else{
      set_import_family_name('')
    }

    if(importConfig[2] === 'email'){
      set_import_email(2)
    }else{
      set_import_email('')
    }

    if(importConfig[3] === 'phone_number'){
      set_import_phone_number(3)
    }else{
      set_import_phone_number('')
    }

  }, [importConfig]);

  const [import_first_name, set_import_first_name] = React.useState('');
  const [import_family_name, set_import_family_name] = React.useState('');
  const [import_phone_number, set_import_phone_number] = React.useState('');
  const [import_email, set_import_email] = React.useState('');
  const [import_society, set_import_society] = React.useState('');
  const [importAndUpdate, setImportAndUpdate] = React.useState(true);
  const [newGroup, setNewGroup] = useState(false);
  const [ selected_rows, set_selected_rows] = useState([])
  const [ import_list_contact, set_import_list_contact] = useState([])
  const [uploadCsvBtnDisabled, setUploadCsvBtnDisabled] = useState(false);

  const [disabledSaveContactsBtn, setDisabledSaveContactsBtn] = useState(false);

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [groupName, setGroupName] = useState('');

  const [isErrorSelectedGroup, setIsErrorSelectedGroup] = useState(false);
  const [isErrorGroupName, setIsErrorGroupName] = useState(false);

  const [selectedGroupError, setSelectedGroupError] = useState("");
  const [groupNameError, setGroupNameError] = useState("");



  const navigate = useNavigate()

  // andt ui config
const { Dragger } = Upload;
const props = {
  name: 'file',
  multiple: false,
  action: '/upload_import_contact',
  method: 'post',
  onChange(info) {
    console.log("hello")
    setUploadCsvBtnDisabled(true)
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      setUploadCsvBtnDisabled(false)
      //message.success(`${info.file.name} file uploaded successfully.`);
      console.log('here you can do something')
      console.log(info.file.response)
      if(info.file.response.read === "true"){
        setImportConfig(info.file.response.arr[0])
        set_import_list_contact(info.file.response.arr)
        setOpenImportModal(false)
        handleClickOpenImportConfigModal()
        setAlertMessage('file uploaded suuccessfully')
        setAlertType('success')
        setOpenAlert(true)
        setTimeout(() => {
          setOpenAlert(false)
        }, 3000);
      } else if(info.file.response.read === "false" && info.file.response.error === "csv_only"){
        setUploadCsvBtnDisabled(false)
        setOpenImportModal(false)
        handleCloseDeleteContactModal()
        setAlertMessage('must upload a csv file only')
        setAlertType('error')
        setOpenAlert(true)
        setTimeout(() => {
          setOpenAlert(false)
        }, 3000);
      }else {
        setUploadCsvBtnDisabled(false)
        setUploadCsvBtnDisabled(false)  
        setOpenImportModal(false)
        handleCloseDeleteContactModal()
        setAlertMessage('internal server error')
        setAlertType('error')
        setOpenAlert(true)
        setTimeout(() => {
          setOpenAlert(false)
        }, 3000);
      }
      
    } else if (status === 'error') {
      setUploadCsvBtnDisabled(false)
      console.log(info.file)
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};
// end antd ui config

    // mui config

    const [openUpdateContactModal, setOpenUpdateContactModal] = React.useState(false);
    const handleCloseUpdateContactModal = () => {
        setOpenUpdateContactModal(false);
    };


    const [open, setOpen] = React.useState(false);

    const handleClickOpenUserModal = () => {
        setOpen(true);
    };

    const handleCloseUserModal = () => {
        setOpen(false);
    };


    const [openGroupModal, setOpenGroupModal] = React.useState(false);

    const handleClickOpenGroupModal = () => {
        setOpenGroupModal(true);
    };

    const handleCloseGroupModal = () => {
        setOpenGroupModal(false);
    };


    const [openSetGroupModal, setOpenSetGroupModal] = React.useState(false);

    const handleClickOpenSetGroupModal = () => {
        setOpenSetGroupModal(true);
    };

    const handleCloseSetGroupModal = () => {
        setOpenSetGroupModal(false);
        setSelectedGroup('')
        setGroupName('')
        setNewGroup(false)
    };


    const [openImportModal, setOpenImportModal] = React.useState(false);

    const handleClickOpenImportModal = () => {
        setOpenImportModal(true);
    };

    const handleCloseImportModal = () => {
        setOpenImportModal(false);
        setSelectedGroup('')
        setGroupName('')
        setNewGroup(false)
    };

    const [openImportConfigModal, setOpenImportConfigModal] = React.useState(false);

    const handleClickOpenImportConfigModal = () => {
        setOpenImportConfigModal(true);
    };

    const handleCloseImportConfigModal = () => {
        setOpenImportConfigModal(false);
        setSelectedGroup('')
        setGroupName('')
        setNewGroup(false)
    };


    const handleChange = (event, input) => {
      if(input === 'firstName'){
        set_import_first_name(event.target.value)
      } else if(input === 'familyName'){
        set_import_family_name(event.target.value)
      } else if(input === 'email'){
        set_import_email(event.target.value)
      } else if(input === 'phoneNumber'){
        set_import_phone_number(event.target.value)
      } else if(input === 'society'){
        set_import_society(event.target.value)
      } 
    };
    // end mui config


        // new

        const [ update_id, set_update_id] = useState('')
        const [ update_first_name, set_update_first_name] = useState('')
        const [ update_family_name, set_update_family_name] = useState('')
        const [ update_phone_number, set_update_phone_number] = useState('')
        const [ update_email, set_update_email] = useState('')
        const [ update_society, set_update_society] = useState('')
      
      
        const [ is_error_update_first_name, set_is_error_update_first_name] = useState(false)
        const [ is_error_update_family_name, set_is_error_update_family_name] = useState(false)
        const [ is_error_update_email, set_is_error_update_email] = useState(false)
        const [ is_error_update_phone_number, set_is_error_update_phone_number] = useState(false)
        const [ is_error_update_society, set_is_error_update_society] = useState(false)


        const [ update_first_name_error, set_update_first_name_error] = useState(false)
        const [ update_family_name_error, set_update_family_name_error] = useState(false)
        const [ update_email_error, set_update_email_error] = useState(false)
        const [ update_phone_number_error, set_update_phone_number_error] = useState(false)
        const [ update_society_error, set_update_society_error] = useState(false)


        function handleUpdate(item){
          set_update_id(item.id)
          set_update_first_name(item.first_name)
          set_update_family_name(item.family_name)
          set_update_email(item.email)
          set_update_phone_number(item.phone_number)
          set_update_society(item.society)
          set_update_first_name_error('')
          set_update_family_name_error('')
          set_update_email_error('')
          set_update_phone_number_error('')
          set_update_society_error('')
          set_is_error_email(false)
          set_is_error_family_name(false)
          set_is_error_first_name(false)
          set_is_error_phone_number(false)
          set_is_error_society(false)
        }

    function  exportData(rows){
      try {
        if(rows.length > 0){
        console.log(rows)
        setExportBtnContent(<CircularProgress style={{ width: '20px', height:'20px', color: 'white'}}></CircularProgress>)
        axios.post("/export", {
          rows
        })
        .then(function (response) {
          setExportBtnContent(<FileUploadIcon style={{fontSize: "x-lare"}}/>)
          console.log(response)
          if(response.data.res === 'done'){
            setLoading(false)
            set_contact_rows(response.data.contacts)
            handleCloseUserModal()
            setAlertMessage('Exported successfuly')
                setAlertType('success')
                setOpenAlert(true)
                setTimeout(() => {
                  setOpenAlert(false)
                }, 3000);
            // show data
          }
          else if(response.data.res === 'server_error'){
            setLoading(false)
            setAlertMessage('Internal server error')
                setAlertType('error')
                setOpenAlert(true)
                setTimeout(() => {
                  setOpenAlert(false)
                }, 3000);
            // redirect to server error page
          }
          else if(response.data.res === 'input_error'){
            setLoading(false)
            // redirect to server error page
            for( let i = 0; i < response.data.error.length; i++){
              console.log(response.data.error[i])
              if(response.data.error[i].path === 'first_name'){
                set_first_name_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
                set_is_error_first_name(true)
              }
              else if(response.data.error[i].path === 'family_name'){
                set_family_name_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
                set_is_error_family_name(true)
              }    
              else if(response.data.error[i].path === 'email'){
                set_email_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
                set_is_error_email(true)
              } 
              else if(response.data.error[i].path === 'phone_number'){
                set_phone_number_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
                set_is_error_phone_number(true)
              } else if(response.data.error[i].path === 'society'){
                set_society_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
                set_is_error_society(true)
              } 
  
            }
          }
          else if(response.data.res === 'redirect'){
            setLoading(false)
            //navigate('/login', { replace: true });
          }else{
            // Create a Blob object from the response data
            const blob = new Blob([response.data], { type: 'text/csv' });

            // Create a temporary URL for the Blob object
            const url = URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement('a');

            // Set the link's href attribute to the temporary URL
            link.href = url;

            // Set the link's download attribute to the desired filename
            link.download = 'data.csv';

            // Append the link to the DOM
            document.body.appendChild(link);

            // Click the link to download the file
            link.click();

            // Remove the link from the DOM
            document.body.removeChild(link);

            // Revoke the temporary URL to free up memory
            URL.revokeObjectURL(url);
          }
        })
        .catch(function (error) {
          // handle error
          console.log('this is axios error');
          console.log(error);
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpen(true)
              setAlertMessage(getResponseMessage(error.response.status))
              setAlertType("error")              
            }
          }else{
            setOpen(true)
            setAlertMessage('Internal server error')
            setAlertType("error")
          }
        })
       }else{
          setAlertMessage('No item is checked')
                setAlertType('error')
                setOpenAlert(true)
                setTimeout(() => {
                  setOpenAlert(false)
                }, 3000);
       }
      } catch (error) {
        console.log(error)
      }
    }

    const CSVInit = [
      [
        'first_name',
        'family_name',
        'email',
        'phone_number',
        'society'
      ],
      [
        '',
        '',
        '',
        '',
        ''
      ],
    ]

    const downloadCSV = (data, filename) => {
      const csvData = convertArrayToCSV(data);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    };
    
    const convertArrayToCSV = (data) => {
      return data.map(row => row.join(';')).join('\n');
    };


    function preGroupData(rows){
      try {
        if(rows.length > 0){
        handleClickOpenSetGroupModal()
       }else{
          setAlertMessage('No item is checked')
                setAlertType('error')
                setOpenAlert(true)
                setTimeout(() => {
                  setOpenAlert(false)
                }, 3000);
       }
      } catch (error) {
        console.log(error)
      }
    }



    const [disabledGroupContact, setDisabledGroupContact] = useState(false);
    function groupData(rows, isNew, selected, name){
      try {
        if(rows.length > 0){
        console.log(rows, isNew, name, selected)
        setDisabledGroupContact(true)
        axios.post("/groupContact", {
          rows, isNew, selected, name
        })
        .then(function (response) {
          setDisabledGroupContact(false)
          console.log(response)
          console.log(response.data.res === 'server_error')
          if(response.data.res === 'done'){
            set_contact_rows(response.data.contacts)
            setGroups(response.data.groups)
            handleCloseSetGroupModal()
            setOpenAlert(true)
            setAlertMessage('Saved successfully')
            setAlertType("success")
            setInterval(() => {
              setOpenAlert(false) 
            }, 3000);
          }else if(response.data.res === 'input_error'){
            for (let i = 0; i < response.data.error.length; i++) {
              const element = response.data.error[i];
              if(element.path === 'group'){
                setSelectedGroupError(element.error)
                setIsErrorSelectedGroup(true)
              }else if(element.path === 'name'){
                setGroupNameError(element.error)
                setIsErrorGroupName(true)
              }
            }
          }else if(response.data.res === 'server_error'){
            setOpenAlert(true)
            setAlertMessage('Internal server error')
            setAlertType("error")
            setInterval(() => {
              setOpenAlert(false) 
            }, 3000);
          }
          else if(response.data.res === 'redirect'){
            setOpenAlert(true)
            setAlertMessage('Session ended')
            setAlertType("error")
            setInterval(() => {
              setOpenAlert(false) 
              navigate('/login', { replace: true });
            }, 3000);
          }
        })
        .catch(function (error) {
          setDisabledGroupContact(false)
          // handle error
          console.log('this is axios error');
          console.log(error);
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpenAlert(true)
              setAlertMessage(getResponseMessage(error.response.status))
              setAlertType("error")
              setInterval(() => {
                setOpenAlert(false) 
              }, 3000);              
            }

          }else{
            setOpenAlert(true)
            setAlertMessage('Internal server error')
            setAlertType("error")
            setInterval(() => {
              setOpenAlert(false) 
            }, 3000);
          }
        })
       }else{
          setAlertMessage('No item is checked')
                setAlertType('error')
                setOpenAlert(true)
                setTimeout(() => {
                  setOpenAlert(false)
                }, 3000);
       }
      } catch (error) {
        console.log(error)
      }
    }

    const [disabledCreateContact, setDisabledCreateContact] = useState(false);

    function create_Contact(first_name, family_name, email, phone_number, society){
      setDisabledCreateContact(true)
      set_create_contact_btn_content(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
      console.log(first_name, family_name, email, phone_number, society)
      // get data from database
      axios.post("/create_contact", {
        first_name, family_name, email, phone_number, society
      })
      .then(function (response) {
        setDisabledCreateContact(false)
        set_create_contact_btn_content("create")
        console.log(response.data)
        if(response.data.res === 'done'){
          set_add_first_name('')
          set_add_family_name('')
          set_add_email('')
          set_add_phone_number('')
          set_add_society('')
          setLoading(false)
          set_contact_rows(response.data.contacts)
          handleCloseUserModal()
          setAlertMessage('Item added successfuly')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          // show data
        }
        else if(response.data.res === 'server_error'){
          setLoading(false)
          setAlertMessage('Internal server error')
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          // redirect to server error page
        }
        else if(response.data.res === 'input_error'){
          setLoading(false)
          // redirect to server error page
          for( let i = 0; i < response.data.error.length; i++){
            console.log(response.data.error[i])
            if(response.data.error[i].path === 'first_name'){
              set_first_name_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_first_name(true)
            }
            else if(response.data.error[i].path === 'family_name'){
              set_family_name_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_family_name(true)
            }    
            else if(response.data.error[i].path === 'email'){
              set_email_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_email(true)
            } 
            else if(response.data.error[i].path === 'phone_number'){
              set_phone_number_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_phone_number(true)
            } else if(response.data.error[i].path === 'society'){
              set_society_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_society(true)
            } 

          }
        }
        else if(response.data.res === 'redirect'){
          setLoading(false)
          //navigate('/login', { replace: true });
        }
      })
      .catch(function (error) {
        setDisabledCreateContact(false)
        // handle error
        console.log('this is axios error');
        console.log(error);
        if(error.response){
          if(error.response.status === 401 && error.response.data.response === 'banned'){
            navigate('/app/banned', { replace: true });
          }else{
            setOpen(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")            
          }

        }else{
          setOpen(true)
          setAlertMessage('Internal server error')
          setAlertType("error")
        }
      })

      //handleCloseUserModal()
    }

    function handleDeleteContact(){
      console.log(selected_rows)
      if(selected_rows.length === 0 ){
        setAlertMessage('No item is checked')
        setAlertType('error')
        setOpenAlert(true)
        setTimeout(() => {
          setOpenAlert(false)
        }, 3000);
      }else{
        handleClickOpenDeleteContactModal()
      }
    }

    const [selectionModel, setSelectionModel] = useState([]);

      const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
        set_selected_rows(newSelection)
        if(newSelection.length === 0){
          setDeleteContactModalBody(Language['No item is selected, choose in minimum one item'])
          setDeleteContactModalBtn(Language['Ok'])
        }else{
          setDeleteContactModalBody(Language['Are you sure you want to delete this items'])
          setDeleteContactModalBtn(Language['Delete'])
        }
      }

      const [openDeleteContactModal, setOpenDeleteContactModal] = React.useState(false);

      const handleClickOpenDeleteContactModal = () => {
        setOpenDeleteContactModal(true);
      };

      const handleCloseDeleteContactModal = () => {
        setOpenDeleteContactModal(false);
      };


      const [disabledDeleteContact, setDisabledDeleteContact] = useState(false);
      function deleteItems(items){
        console.log(items.length)
        setDisabledDeleteContact(true)
        setLoadingContacts(true)
        if(items.length > 0 ){
          setDeleteContactModalBtn(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
          // delete items
          axios.post("/delete_contact", { items})
          .then(function (response) {
            handleCloseDeleteContactModal()
            setDisabledDeleteContact(false)
            setLoadingContacts(false)
            console.log(response.data)
            setDeleteContactModalBtn("delete")
            if(response.data.res === 'done'){
              setLoading(false)
              set_contact_rows(response.data.contacts)
              handleCloseDeleteContactModal()
              setAlertMessage('Items deleted successfully')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
            }
            else if(response.data.res === 'server_error'){
              setLoading(false)
              handleCloseDeleteContactModal()
              setAlertMessage('Internal server error')
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
              // redirect to server error page
            }
            else if(response.data.res === 'redirect'){
              setLoading(false)
              navigate('/login', { replace: true });
            }
          })
          .catch(function (error) {
            handleCloseDeleteContactModal()
            setDisabledDeleteContact(false)
            setLoadingContacts(false)
            setDeleteContactModalBtn("delete")
            // handle error
            console.log('this is axios error');
            console.log(error);
            if(error.response){
              if(error.response.status === 401 && error.response.data.response === 'banned'){
                navigate('/app/banned', { replace: true });
              }else{
                setOpenAlert(true)
                setAlertMessage(error.response.statusText)
                setAlertType("error")                
              }

            }else{
              setOpenAlert(true)
              setAlertMessage('Internal server error')
              setAlertType("error")
            }
          })
        }else{
          handleCloseDeleteContactModal()
        }
      }

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };


      function import_contacts (contacts, first_name, family_name, phone_number, email, society, importAndUpdate){
        console.log(contacts, first_name, family_name, phone_number, email, society, importAndUpdate)
        setDisabledSaveContactsBtn(true)
        // get data from database
          axios.post("/save_uploaded_contacts",{
            contacts, first_name, family_name, phone_number, email, society, importAndUpdate, newGroup, selectedGroup, groupName
          })
          .then(function (response) {
            setDisabledSaveContactsBtn(false)
            console.log(response.data)
            if(response.data.res === 'done'){
              setLoading(false)
              set_contact_rows(response.data.contacts)
              // show data
              handleCloseImportConfigModal()
              setAlertMessage(response.data.added + ' ' + Language['of'] + ' ' + response.data.total + ' ' + Language['items Added successfully'])
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
            }
            else if(response.data.res === 'input_error'){
              setLoading(false)
              // redirect to server error page
              handleCloseDeleteContactModal()
              setAlertMessage(response.data.error[0].error)
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
            }
            else if(response.data.res === 'server_error'){
              setLoading(false)
              // redirect to server error page
              handleCloseDeleteContactModal()
              setAlertMessage('Internal server error')
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
            }
            else if(response.data.res === 'redirect'){
              setLoading(false)
              navigate('/login', { replace: true });
            }
          })
          .catch(function (error) {
            setDisabledSaveContactsBtn(false)
            // handle error
            console.log('this is axios error');
            console.log(error);
            if(error.response){
              if(error.response.status === 401 && error.response.data.response === 'banned'){
                navigate('/app/banned', { replace: true });
              }else{
                setOpen(true)
                setAlertMessage(getResponseMessage(error.response.status))
                setAlertType("error")                
              }

            }else{
              setOpen(true)
              setAlertMessage('Internal server error')
              setAlertType("error")
            }
          })
      }

      function search_contact(keyword){
        console.log(keyword)
        setLoadingContacts(true)
        axios.post("/search_contact",{
          keyword
        })
        .then(function (response) {
          setLoadingContacts(false)
          console.log(response.data)
          if(response.data.res === 'done'){
            setLoading(false)
            set_contact_rows(response.data.contacts)
  
            // show data
          }
          else if(response.data.res === 'server_error'){
            setLoading(false)
            setAlertMessage('Internal server error')
            setAlertType('error')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
          
            // redirect to server error page
          }
          else if(response.data.res === 'redirect'){
            setLoading(false)
            navigate('/login', { replace: true });
          }
        })
        .catch(function (error) {
          setLoadingContacts(false)
          // handle error
          console.log('this is axios error');
          console.log(error);
          if(error.response.status){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpen(true)
              setAlertMessage(getResponseMessage(error.response.status))
              setAlertType("error")              
            }

          }else{
            setOpen(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType("error")
          }
        })
      }

      //new
      const [ update_contact_btn_content, set_update_contact_btn_content] = useState('update')
      function update_Contact(contact_id, first_name, family_name, email, phone_number, society){
      set_update_contact_btn_content(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
      // get data from database
      setLoadingContacts(true)
      axios.post("/updateContact", {
        contact_id, first_name, family_name, email, phone_number, society
      }).then(function (response) {
        
        console.log(response.data)
        if(response.data.res === 'done'){
          set_update_email('')
          set_update_first_name('')
          set_update_family_name('')
          set_update_phone_number('')
          set_update_society('')
          set_update_contact_btn_content('update')
          handleCloseUpdateContactModal()
          setLoadingContacts(false)
          setLoading(false)
          set_contact_rows(response.data.contacts)
          handleCloseUserModal()
          setAlertMessage('Item updated successfuly')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          // show data
        }
        else if(response.data.res === 'server_error'){
          setLoading(false)
          set_update_contact_btn_content('update')
          handleCloseUpdateContactModal()
          setLoadingContacts(false)
          setAlertMessage(response.data.error)
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          // redirect to server error page
        }
        else if(response.data.res === 'input_error'){
          setLoading(false)
          set_update_contact_btn_content('update')
          setLoadingContacts(false)
          // redirect to server error page
          for( let i = 0; i < response.data.error.length; i++){
            console.log(response.data.error[i])
            if(response.data.error[i].path === 'first_name'){
              set_update_first_name_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_update_first_name(true)
            }
            else if(response.data.error[i].path === 'family_name'){
              set_update_family_name_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_update_family_name(true)
            }    
            else if(response.data.error[i].path === 'email'){
              set_update_email_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_update_email(true)
            } 
            else if(response.data.error[i].path === 'phone_number'){
              set_update_phone_number_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_update_phone_number(true)
            } else if(response.data.error[i].path === 'society'){
              set_update_society_error(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              set_is_error_update_society(true)
            } 

          }
        }
        else if(response.data.res === 'redirect'){
          setLoading(false)
          //navigate('/login', { replace: true });
        }
      })
      .catch(function (error) {
        setDisabledCreateContact(false)
        // handle error
        console.log('this is axios error');
        console.log(error);
        set_update_contact_btn_content('update')
        handleCloseUpdateContactModal()
        setLoadingContacts(false)
        if(error.response){
          if(error.response.status === 401 && error.response.data.response === 'banned'){
            navigate('/app/banned', { replace: true });
          }else{
            setOpen(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")            
          }

        }else{
          setOpen(true)
          setAlertMessage('Internal server error')
          setAlertType("error")
        }
      })
    }

      function deleteGroup(id){
        setLoadingContacts(true)
        axios.post("/delete_group", {
          id
        })
        .then(function (response) {
          setLoadingContacts(false)
          if(response.data.res === 'done'){
                setGroups(response.data.groups)
                set_contact_rows(response.data.contacts)
                setAlertMessage('Deleted successfuly')
                setAlertType('success')
                setOpenAlert(true)
                setTimeout(() => {
                  setOpenAlert(false)
                }, 3000);
            // show data
          }
          else if(response.data.res === 'server_error'){
                setAlertMessage('Internal server error')
                setAlertType('error')
                setOpenAlert(true)
                setTimeout(() => {
                  setOpenAlert(false)
                }, 3000);
            // redirect to server error page
          }
          else if(response.data.res === 'redirect'){
            navigate('/login', { replace: true });
          }
        })
        .catch(function (error) {
          setLoadingContacts(false)
          console.log('this is axios error');
          console.log(error)
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpenAlert(true)
              setAlertMessage(getResponseMessage(error.response.status))
              setAlertType("error")
            }

          }else{
            setOpenAlert(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType("error")
          }
        })
      }


      const [importContacts, setImportContacts] = useState('');
      const [importContactsError, setImportContactsError] = useState('');
      const [isErrorImportContacts, setIsErrorImportContacts] = useState(false);

      function importContactsByText(){
        setDisabledSaveContactsBtn(true)
        // get data from database
          axios.post("/importContactsByText",{
            contacts: importContacts, newGroup, selectedGroup, groupName
          })
          .then(function (response) {
            setDisabledSaveContactsBtn(false)
            console.log(response.data)
            if(response.data.res === 'done'){
              setLoading(false)
              set_contact_rows(response.data.contacts)
              // show data
              handleCloseImportConfigModal()
              handleCloseImportModal()
              setAlertMessage(response.data.successed + ' ' + Language['of'] + ' ' + response.data.total + ' ' + Language['items Added successfully'])
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
            }
            else if(response.data.res === 'input_error'){
              setLoading(false)
              // redirect to server error page
              handleCloseDeleteContactModal()
              setImportContactsError(Language[response.data.error[0].error] ? Language[response.data.error[0].error] : response.data.error[0].error)
              setIsErrorImportContacts(true)
            }
            else if(response.data.res === 'server_error'){
              setLoading(false)
              // redirect to server error page
              handleCloseDeleteContactModal()
              setAlertMessage('Internal server error')
              setAlertType('error')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
            }
            else if(response.data.res === 'redirect'){
              setLoading(false)
              navigate('/login', { replace: true });
            }
          })
          .catch(function (error) {
            setDisabledSaveContactsBtn(false)
            // handle error
            console.log('this is axios error');
            console.log(error);
            if(error.response){
              if(error.response.status === 401 && error.response.data.response === 'banned'){
                navigate('/app/banned', { replace: true });
              }else{
                setOpenAlert(true)
                setAlertMessage(getResponseMessage(error.response.status))
                setAlertType("error")                
              }

            }else{
              setOpenAlert(true)
              setAlertMessage('Internal server error')
              setAlertType("error")
            }
          })


      }

      const [loadingContacts, setLoadingContacts] = useState(false);
    
    useEffect(() => {
      // get data from database
      handleHeader('contacts')
      setLoadingContacts(true)
      axios.post("/contact")
      .then(function (response) {
        setLoadingContacts(false)
        console.log(response.data)
        if(response.data.res === 'done'){
          setLoading(false)
          set_contact_rows(response.data.contacts)
          setGroups(response.data.groups)
        }
        else if(response.data.res === 'server_error'){
          setLoading(false)
          // redirect to server error page
        }
        else if(response.data.res === 'redirect'){
          setLoading(false)
          navigate('/login', { replace: true });
        }
      })
      .catch(function (error) {
        // handle error
        setLoadingContacts(false)
        console.log('this is axios error');
        console.log(error);
        if(error.response){
          if(error.response.status === 401 && error.response.data.response === 'banned'){
            navigate('/app/banned', { replace: true });
          }else{
            setOpen(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")            
          }

        }else{
          setOpen(true)
          setAlertMessage('Internal server error')
          setAlertType("error")
        }
      })
    
    }, [])

    const [filterGroup, setFilterGroup] = useState('all');
    const [displayedContacts, setDisplayedContacts] = useState([]);

    useEffect(() => {
      // setDisplayedContacts(contact_rows)
      if(filterGroup === 'all'){
        setDisplayedContacts(contact_rows)
      }else{
        var data = []
        for (let i = 0; i < contact_rows.length; i++) {
          const contact = contact_rows[i];
          for (let s = 0; s < contact.groups.length; s++) {
            const group = contact.groups[s];
            if(group.id === filterGroup){
              data.push(contact)
            }
          }
        }
        setDisplayedContacts(data)
      }
    }, [contact_rows]);

    useEffect(() => {
      if(filterGroup === 'all'){
        setDisplayedContacts(contact_rows)
      }else{
        var data = []
        for (let i = 0; i < contact_rows.length; i++) {
          const contact = contact_rows[i];
          for (let s = 0; s < contact.groups.length; s++) {
            const group = contact.groups[s];
            if(group.id === filterGroup){
              data.push(contact)
            }
          }
        }
        setDisplayedContacts(data)
      }
    }, [filterGroup]);

    const [importType, setImportType] = useState('file');

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>{Language['Contacts list']}</H1>
                <Tooltip title={Language['add new contact']} >
                  <Button variant="outlined" color="success" style={{marginLeft: '10px', fontSize: "small"}} onClick={handleClickOpenUserModal}><AddIcon></AddIcon> {Language['Add']}   </Button>
                </Tooltip>

                <Tooltip title='import contacts'>
                  <Button variant="contained" color="success" style={{marginLeft: '10px', fontSize: 'small'}} size='small' onClick={handleClickOpenImportModal}>
                    <FileDownloadIcon ></FileDownloadIcon> {Language['import']}
                </Button>
                </Tooltip>
                
            </HeaderTitle>
            <HeaderSeach>
            <FormControl variant="standard" style={{width: "100%"}}>
                <Input
                id="input-with-icon-adornment"
                placeholder='search for contacts'
                startAdornment={
                    <InputAdornment position="start">
                    <SearchIcon />
                    </InputAdornment>
                }
                onChange={(e) => search_contact(e.target.value)}
                />
                    {/* {search_icon}   */}
            </FormControl>
            </HeaderSeach>
        </Header>
        {/* <Header>
            <DivRow>
              <Tooltip title='export contacts'><Button variant="contained"  style={{marginLeft: '0px', fontSize: "small"}} onClick={() => exportData(selected_rows)}> {exportBtnContent} </Button></Tooltip>
              <Tooltip title='delete contacts'><Button variant="contained" color='error' style={{marginLeft: '10px', fontSize: "small"}} onClick={() => handleDeleteContact()} >  <DeleteIcon style={{fontSize: "large"}}/></Button></Tooltip>
            </DivRow>
        </Header> */}

        {/* <div style={{ height: 630, width: '100%', marginTop: '0px' }}>
        <DataGrid
            rows={contact_rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
        />
        </div> */}

        <BodyContent>
        <Header style={{  width: '90%', margin: '0% 5% ', border: "none"}}>
            <DivRow>
              <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              style={{height: "38px", width: '200px', marginRight: '10px'}}
              value={filterGroup}
              onChange={ (e) =>{
                  setFilterGroup(e.target.value)
              }
              }
              >
                <MenuItem selected value={'all'}>{Language['All groups']}</MenuItem>
                { 
                  groups.map(((item) => {
                    return(
                      <MenuItem value={item.id}>{item.title}</MenuItem>
                    )
                  }))
                }
              </Select>
            {/* <Button variant="outlined"  style={{marginLeft: '0px'}}> export <FileUploadIcon /></Button> */}
            <Tooltip title={Language['group contacts']}><Button variant="contained"  style={{marginLeft: '0px', fontSize: "x-small"}} onClick={() => preGroupData(selected_rows)}> <LocalOfferIcon> </LocalOfferIcon> </Button></Tooltip>
            <Tooltip title={Language['export contacts']}><Button variant="contained"  style={{marginLeft: '10px', fontSize: "x-small"}} onClick={() => exportData(selected_rows)}> {exportBtnContent} </Button></Tooltip>
            <Tooltip title={Language['delete contacts']}><Button variant="contained" size='small' color='error' style={{marginLeft: '10px'}} onClick={() => handleDeleteContact()} >  <DeleteIcon style={{fontSize: "large"}}/></Button></Tooltip>
            </DivRow>
        </Header>
        <DataGrid
            style={{ height: 730, width: '94%', margin: '0% 3% 1% 3%', backgroundColor: "white" }}
            rows={displayedContacts}
            columns={columns}
            //pageSize={20}
            //rowsPerPageOptions={[20]}
            checkboxSelection
            selectionModel={selectionModel}
            loading={loadingContacts}
            onSelectionModelChange={handleSelectionModelChange}

        />

        </BodyContent>



                {/* //add user  modal */}
                <Dialog open={open} onClose={handleCloseUserModal}>
              <DialogTitle style={{alignContent: 'center'}}>{Language['Create New Contact']} <span style={{fontWeight: '600'}}>+</span>  </DialogTitle>
              <DialogContent>
                <DialogContentText>
                {Language['fill the field below then press create, this action will create a new contact according to this information.']}
                </DialogContentText>
              
              
                <FormControl error={is_error_society} variant="standard" style={{width: '100%', marginTop: '20px'}}>
                    <InputLabel htmlFor="society">{Language['society']} *</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}}}
                      onChange={(e) => {
                        set_add_society(e.target.value);
                        set_is_error_society(false)
                        set_society_error('')
                      }}
                      type='text'
                      id="society"
                      defaultValue=""
                      aria-describedby="society_error"
                    />
                    <FormHelperText id="society_error">{society_error}</FormHelperText>
                </FormControl>

                <FormControl error={is_error_first_name} variant="standard" style={{width: '100%'}}>
                    <InputLabel htmlFor="first_name">{Language['First Name']}</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}}}
                      onChange={(e) => {
                        set_add_first_name(e.target.value);
                        set_is_error_first_name(false)
                        set_first_name_error('')
                      }}
                      type='text'
                      id="first_name"
                      defaultValue=""
                      aria-describedby="first_name_error"
                    />
                    <FormHelperText id="first_name_error">{first_name_error}</FormHelperText>
                </FormControl>

                <FormControl error={is_error_family_name} variant="standard" style={{width: '100%'}}>
                    <InputLabel htmlFor="family_name">{Language['Family Name']}</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}}}
                      onChange={(e) => {
                        set_add_family_name(e.target.value);
                        set_is_error_family_name(false)
                        set_family_name_error('')
                      }}
                      type='text'
                      id="family_name"
                      defaultValue=""
                      aria-describedby="family_name_error"
                    />
                    <FormHelperText id="family_name_error">{family_name_error}</FormHelperText>
                </FormControl>


                <FormControl error={is_error_phone_number} variant="standard" style={{width: '100%'}}>
                    <InputLabel htmlFor="phone_number">{Language['Phone Number']} *</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}}}
                      onChange={(e) => {
                        set_add_phone_number(e.target.value);
                        set_is_error_phone_number(false)
                        set_phone_number_error('')
                      }}
                      type='text'
                      id="phone_number"
                      defaultValue=""
                      aria-describedby="phone_number_error"
                    />
                    <FormHelperText id="phone_number_error">{phone_number_error}</FormHelperText>
                </FormControl>


                <FormControl error={is_error_email} variant="standard" style={{width: '100%'}}>
                    <InputLabel htmlFor="email">{Language['email']}</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}}}
                      onChange={(e) => {
                        set_add_email(e.target.value);
                        set_is_error_email(false)
                        set_email_error('')
                      }}
                      type='text'
                      id="email"
                      defaultValue=""
                      aria-describedby="email_error"
                    />
                    <FormHelperText id="email_error">{email_error}</FormHelperText>
                </FormControl>

              


                

              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseUserModal}>{Language['Cancel']}</Button>
                <Button disabled={disabledCreateContact} onClick={() => create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}>{Language[create_contact_btn_content] ? Language[create_contact_btn_content] : create_contact_btn_content}</Button>
              </DialogActions>
                </Dialog>


                {/* //groups  modal */}
                <Dialog open={openGroupModal} onClose={handleCloseGroupModal}>
                  <DialogTitle style={{alignContent: 'center'}}>{Language['Edit Groups']}  
                    <DialogContentText>
                    {Language['You can easily delete a group definitly by clicking on the trash icon.']}
                    </DialogContentText>
                  </DialogTitle>
                  <DialogContent>
                    <Grid item xs={12} md={6}>
                        <List dense={false}>
                          {
                            groups.map((item) => {
                              //item.createdAt = item.createdAt.slice(0,10) + '   ' + item.createdAt.slice(11,18)
                              return (
                              <>
                              <ListItem
                                secondaryAction={
                                  <IconButton
                                  onClick={(e, primary) => deleteGroup(item.id)}
                                  edge="end" aria-label="delete">
                                    <DeleteIcon  />
                                  </IconButton>
                                }
                              >
                                <ListItemAvatar>
                                  <Avatar>
                                    <LabelImportantIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={item.title}
                                  secondary={item.contacts.length + ' ' + Language['contacts']}
                                />
                              </ListItem>
                              <Divider />

                              </>
                            )
                              
                            }
                                
                            )
                          }
                        </List>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseGroupModal}>{Language['Done']}</Button>
                  </DialogActions>
                </Dialog>

                {/* //group contact modal */}
                <Dialog open={openSetGroupModal} onClose={handleCloseSetGroupModal} sx={{ m: 1, fontSize: 'larger', width: '100%'}}>
                
              <DialogTitle style={{alignContent: 'center'}}>{Language['Select a group']} <Button endIcon={<TuneIcon></TuneIcon>}  onClick={handleClickOpenGroupModal}  style={{ float: 'right',}}> {Language['edit groups']}  </Button></DialogTitle>
              <DialogContent >
                <DialogContentText>
                {Language['Select one of the previous group to add the selected contacts in one group.']}
                </DialogContentText>
                
                {/* <span style={{textAlign: 'right', width: "100%", float: 'right', margin: '0px', padding: '0px'}}>Create new group <Switch {...label} defaultChecked onChange={(e) => { setImportAndUpdate(e.target.checked)}} /></span> */}

                
                
              <FormControl error={isErrorSelectedGroup} variant="standard" disabled={newGroup} sx={{ m: 1, }} style={{width: '100%', margin: '20px 0px 0px 0px',}}>
                <InputLabel htmlFor="phone_number">{Language['Group']}</InputLabel>

                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    //value={'age'}
                    onChange={ (e) =>{
                        setSelectedGroup(e.target.value)
                        setIsErrorSelectedGroup(false)
                        setSelectedGroupError('')
                        console.log(selectedGroup)
                    }
                    }
                    >
                      <MenuItem value={'non_grouped'}>{Language['none']}</MenuItem>
                      { 
                        groups.map(((item) => {
                          return(
                            <MenuItem value={item.id}>{item.title}</MenuItem>
                          )
                        }))
                      }
                    </Select>
                    <FormHelperText id="time_error">{selectedGroupError}</FormHelperText>
                </FormControl>
                
               

                


                <p style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                  <span style={{color:'gray', margin: '0px 10px', marginBottom: '5px'}}>{Language['or']}</span>
                  <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                </p>

                
                {/* <DialogContentText style={{textAlign: 'right', width: "100%", float: 'right', margin: '0px', padding: '0px', fontSize:'small'}}>
                    Create a new group
                    <Switch {...label} defaultChecked={false} onChange={(e) => { setNewGroup(e.target.checked)}} />
                  </DialogContentText> */}

                <DialogContentText style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                {Language['Create a new group']} 
                  <Switch style={{float: 'right'}} {...label} defaultChecked={newGroup} onChange={(e) => { setNewGroup(e.target.checked)}} />
                </DialogContentText>

                <FormControl error={isErrorGroupName} variant="standard" disabled={!newGroup} style={{width: '100%', margin: '0px 0px 20px 0px'}}>
                    <InputLabel htmlFor="phone_number">{Language['Title']}</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { groupData(selected_rows, newGroup, selectedGroup, groupName)}}}
                      onChange={(e) => {
                        setGroupName(e.target.value);
                        setIsErrorGroupName(false)
                        setGroupNameError('')
                      }}
                      type='text'
                      id="phone_number"
                      defaultValue=""
                      aria-describedby="phone_number_error"
                    />
                    <FormHelperText id="phone_number_error">{groupNameError}</FormHelperText>
                </FormControl>            

              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSetGroupModal}>{Language['Cancel']}</Button>
                <Button disabled={disabledGroupContact} onClick={() => groupData(selected_rows, newGroup, selectedGroup, groupName)}>{Language['Save']}</Button>
              </DialogActions>
                </Dialog>

                  {/* //import users  modal */}
                  <Dialog open={openImportModal} onClose={handleCloseImportModal} fullWidth size={"md"}>
                      <DialogTitle style={{alignContent: 'center', paddingBottom: '10px'}}>{Language['Import New Contacts']}   </DialogTitle>
                      <DialogContent>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', minHeight: '20px', marginTop: '10px', marginBottom: '20px'}}>
                          <Button endIcon={<UploadFile/>} onClick={()=> setImportType('file')} style={{color: importType === 'file' ? 'white' : colors.primary, backgroundColor: importType === 'file' ? colors.primary : 'white', margin: '0px 5px', padding: "2px 10px", border:"1px solid " +colors.primary}}>{Language['file']}</Button>
                          <Button endIcon={<Subject />} onClick={()=> setImportType('text')} style={{color: importType === 'text' ? 'white' : colors.primary, backgroundColor: importType === 'text' ? colors.primary : 'white', margin: '0px 5px', padding: "2px 10px", border:"1px solid " +colors.primary}}>{Language['text']}</Button>
                        </div>
                        <DialogContentText style={{marginBottom: '20px'}}>
                          {importType === 'text' ? Language['import new contacts by copying & pasting lines'] : Language['import new contacts by uploading a csv file']}
                          
                        </DialogContentText>

                        <Dragger style={{marginBottom: '5px', display: importType === 'file' ? 'flex' : 'none'}} {...props} disabled={uploadCsvBtnDisabled}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">{Language['Click or drag file to this area to upload']}</p>
                          <p className="ant-upload-hint">
                          {Language['Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files']}
                          </p>
                        </Dragger>
                        <span style={{color:'gray', display: importType === 'file' ? 'flex' : 'none', margin: '10px 10px 10px 10px', marginBottom: '0px', paddingTop: '0px'}}>{Language['']}Download our <span style={{textDecorationLine: 'underline', cursor: 'pointer', margin: '0px 5px'}} onClick={()=> downloadCSV(CSVInit, 'CSV Template')} >{Language['CSV template']}</span>  {Language['to import your contacts easily.']}</span>


                        {/* <p style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                          <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                          <span style={{color:'gray', margin: '0px 10px', marginBottom: '5px'}}>or</span>
                          <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                        </p> */}

                        {/* <DialogContentText>
                          Or by copying & pasting lines
                        </DialogContentText> */}
                        <FormControl error={isErrorImportContacts} variant="standard" style={{width: '100%', marginTop: '0px', display: importType === 'text' ? 'flex' : 'none'}}>
                          <TextField
                              //onKeyDown={ (event) => { if (event.key === 'Enter') { sendMessage(subject, contacts, message, 'send') }}}
                              
                              error={false}
                              aria-describedby="message_error"
                              style={{width: '100%', marginTop: '0px'}}
                              id="standard-multiline-static"
                              //label="Contacts"
                              multiline
                              rows={4}
                              variant="standard"
                              placeholder={`Example:\nsociety1,07XXXXXXXX\nsociety2,07XXXXXXXX,society2@gmail.com`}
                              onChange={ (e) => {
                                // calcMessage(e.target.value.length)
                                // setMessage(e.target.value)
                                // setIsErrorMessage(false)
                                // setMessageError('')
                                // 
                                setImportContacts(e.target.value)
                                setIsErrorImportContacts(false)
                                setImportContactsError('')
                              }
                              }
                          />
                              <FormHelperText id="message_error">{importContactsError}</FormHelperText>
                          </FormControl>

                          
                          <p style={{textAlign: 'left', width: "100%", marginBottom: '-10px', display: importType === 'text'  ? 'flex' : "none"}} sx={{ m: 1, minWidth: "100%" }}>{Language['Creating and adding in a group']}</p>

                          <Accordion style={{width: "100%", marginTop: '20px', display: importType === 'text'  ? 'block' : "none" }}>
                            <AccordionSummary
                              sx={{m:0, width: "100%" }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                             {Language['Add imported contacts to a group']} 
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl sx={{ m: 0, minWidth: "100%" }} error={isErrorSelectedGroup} variant="standard" disabled={newGroup}  style={{width: '100%', marginTop: '-10px'}}>
                                <InputLabel htmlFor="phone_number">{Language['group']}</InputLabel>

                                    <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    //value={'age'}
                                    onChange={ (e) =>{
                                        if(e.target.value === 'none'){
                                          setSelectedGroup('')
                                        }else{ 
                                          setSelectedGroup(e.target.value)
                                        }
                                        setIsErrorSelectedGroup(false)
                                        setSelectedGroupError('')
                                        console.log(selectedGroup)
                                    }
                                    }
                                    >
                                      <MenuItem value={'none'}>{Language['none']}</MenuItem>
                                      { 
                                        groups.map(((item) => {
                                          return(
                                            <MenuItem value={item.id}>{item.title}</MenuItem>
                                          )
                                        }))
                                      }
                                    </Select>
                                    <FormHelperText id="time_error">{selectedGroupError}</FormHelperText>
                                </FormControl>
                                
                              

                                


                                <p style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                  <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                                  <span style={{color:'gray', margin: '0px 10px', marginBottom: '5px'}}>{Language['or']}</span>
                                  <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                                </p>

                                <DialogContentText style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                {Language['Create a new group']}
                                  <Switch style={{float: 'right'}} {...label} defaultChecked={newGroup} onChange={(e) => { setNewGroup(e.target.checked)}} />
                                </DialogContentText>

                                <FormControl error={isErrorGroupName} variant="standard" disabled={!newGroup} style={{width: '100%', margin: '0px 0px 20px 0px'}}>
                                    <InputLabel htmlFor="phone_number">{Language['Title']}</InputLabel>
                                    <Input
                                      onKeyDown={ (event) => { if (event.key === 'Enter') { groupData(selected_rows, newGroup, selectedGroup, groupName)}}}
                                      onChange={(e) => {
                                        setGroupName(e.target.value);
                                        setIsErrorGroupName(false)
                                        setGroupNameError('')
                                      }}
                                      type='text'
                                      id="phone_number"
                                      defaultValue=""
                                      aria-describedby="phone_number_error"
                                    />
                                    <FormHelperText id="phone_number_error">{groupNameError}</FormHelperText>
                                </FormControl>            
                            </AccordionDetails>
                          </Accordion>

                      </DialogContent>
                      <DialogActions style={{display: importType === 'text'  ? 'flex' : "none"}}>
                        <Button onClick={handleCloseImportModal}>{Language['Cancel']}</Button>
                        <Button onClick={() => importContactsByText()}>{Language['Import']}</Button>
                      </DialogActions>
                  </Dialog>


                  {/* //config import users  modal */}
                    <Dialog open={openImportConfigModal} onClose={handleCloseImportConfigModal}>
                      <DialogTitle style={{alignContent: 'center'}}>{Language['configrations of the imported file.']}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {Language['last step before uploading your contacts, choose the right columns then click import']}
                        </DialogContentText>

                        <p style={{textAlign: 'right', width: "100%", float: 'right', margin: '0px', padding: '0px'}}>{Language['update existing items according the phone number']} <Switch {...label} defaultChecked onChange={(e) => { setImportAndUpdate(e.target.checked)}} /></p>

                        <FormControl sx={{ m: 0, minWidth: "100%" }} style={{marginBottom: '15px'}} >
                          <InputLabel id="demo-select-small">{Language['Society']} *</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            //defaultValue={importConfig5}
                            value={import_society}
                            label="Society"
                            onChange={(e) => handleChange(e, 'society')}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {
                              importConfig.map((item, index) => 
                                (<MenuItem value={index} key={index}>{item}</MenuItem>)
                              )
                            }
                          </Select>
                        </FormControl>


                        <FormControl sx={{ m: 0, minWidth: "100%" }} style={{marginBottom: '15px'}} >
                          <InputLabel id="demo-select-small1">{Language['First Name']}</InputLabel>
                          <Select
                            labelId="demo-select-small1"
                            id="demo-select-small1"
                            value={import_first_name}
                            label="First Name"
                            onChange={(e) => handleChange(e, 'firstName')}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {
                              importConfig.map((item, index) => 
                                (<MenuItem  value={index} key={index}>{item}</MenuItem>)
                              )
                            }
                          </Select>
                        </FormControl>

                        <FormControl sx={{ m: 0, minWidth: "100%" }} style={{marginBottom: '15px'}} >
                          <InputLabel id="demo-select-small">{Language['Family Name']}</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={import_family_name}
                            label="Family Name"
                            onChange={(e) => handleChange(e, 'familyName')}
                          >
                            <MenuItem value="">
                              <em>{Language['None']}</em>
                            </MenuItem>
                            {
                              importConfig.map((item, index) => 
                                (<MenuItem value={index} key={index}>{item}</MenuItem>)
                              )
                            }
                          </Select>
                        </FormControl>

                        <FormControl sx={{ m: 0, minWidth: "100%" }} style={{marginBottom: '15px'}} >
                          <InputLabel id="demo-select-small">{Language['Phone Number']} *</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={import_phone_number}
                            label="Phone (obligated)"
                            onChange={(e) => handleChange(e, 'phoneNumber')}
                          >
                            <MenuItem value="">
                              <em>{Language['None']}</em>
                            </MenuItem>
                            {
                              importConfig.map((item, index) => 
                                (<MenuItem value={index} key={index}>{item}</MenuItem>)
                              )
                            }
                          </Select>
                        </FormControl>


                        <FormControl sx={{ m: 0, minWidth: "100%" }} style={{marginBottom: '15px'}} >
                          <InputLabel id="demo-select-small">{Language['Email']}</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={import_email}
                            label="Email"
                            onChange={(e) => handleChange(e, 'email')}
                          >
                            <MenuItem value="">
                              <em>{Language['None']}</em>
                            </MenuItem>
                            {
                              importConfig.map((item, index) => 
                                (<MenuItem value={index} key={index}>{item}</MenuItem>)
                              )
                            }
                          </Select>
                        </FormControl>

                        <p style={{textAlign: 'left', width: "100%", marginBottom: '-10px'}} sx={{ m: 1, minWidth: "100%" }}>{Language['Creating and adding in a group']}</p>

                        <Accordion style={{width: "100%", marginTop: '20px' }}>
                        <AccordionSummary
                          sx={{m:0, width: "100%" }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {Language['Add imported contacts to a group']}
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormControl sx={{ m: 0, minWidth: "100%" }} error={isErrorSelectedGroup} variant="standard" disabled={newGroup}  style={{width: '100%', marginTop: '-10px'}}>
                            <InputLabel htmlFor="phone_number">{Language['group']}</InputLabel>

                                <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                //value={'age'}
                                onChange={ (e) =>{
                                    if(e.target.value === 'none'){
                                      setSelectedGroup('')
                                    }else{
                                      setSelectedGroup(e.target.value)
                                    }
                                    setIsErrorSelectedGroup(false)
                                    setSelectedGroupError('')
                                    console.log(selectedGroup)
                                }
                                }
                                >
                                  <MenuItem value={'none'}>{Language['none']}</MenuItem>
                                  { 
                                    groups.map(((item) => {
                                      return(
                                        <MenuItem value={item.id}>{item.title}</MenuItem>
                                      )
                                    }))
                                  }
                                </Select>
                                <FormHelperText id="time_error">{selectedGroupError}</FormHelperText>
                            </FormControl>
                            
                          

                            


                            <p style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                              <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                              <span style={{color:'gray', margin: '0px 10px', marginBottom: '5px'}}>{Language['or']}</span>
                              <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                            </p>

                            <DialogContentText style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            {Language['Create a new group']}
                              <Switch style={{float: 'right'}} {...label} defaultChecked={newGroup} onChange={(e) => { setNewGroup(e.target.checked)}} />
                            </DialogContentText>

                            <FormControl error={isErrorGroupName} variant="standard" disabled={!newGroup} style={{width: '100%', margin: '0px 0px 20px 0px'}}>
                                <InputLabel htmlFor="phone_number">{Language['Title']}</InputLabel>
                                <Input
                                  onKeyDown={ (event) => { if (event.key === 'Enter') { groupData(selected_rows, newGroup, selectedGroup, groupName)}}}
                                  onChange={(e) => {
                                    setGroupName(e.target.value);
                                    setIsErrorGroupName(false)
                                    setGroupNameError('')
                                  }}
                                  type='text'
                                  id="phone_number"
                                  defaultValue=""
                                  aria-describedby="phone_number_error"
                                />
                                <FormHelperText id="phone_number_error">{groupNameError}</FormHelperText>
                            </FormControl>            
                        </AccordionDetails>
                      </Accordion>
                
                

                            
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseImportConfigModal}>{Language['Cancel']}</Button>
                        {/* <Button disabled={disabledSaveContactsBtn} onClick={() => import_contacts( import_list_contact, import_first_name, import_family_name, import_phone_number, import_email, import_society, importAndUpdate )}>{create_contact_btn_content}</Button> */}
                        <Button disabled={false} onClick={() => import_contacts( import_list_contact, import_first_name, import_family_name, import_phone_number, import_email, import_society, importAndUpdate )}>{Language[create_contact_btn_content] ? Language[create_contact_btn_content] : create_contact_btn_content  }</Button>
                      </DialogActions>
                  </Dialog>
                
                {/* alert Modal */}
                <Dialog
                  open={openDeleteContactModal}
                  onClose={handleCloseDeleteContactModal}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                  {Language['Delete Contact']}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {DeleteContactModalBody}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDeleteContactModal}>{Language['Cancel']}</Button>
                    <Button disabled={disabledDeleteContact} onClick={() => deleteItems(selected_rows)} autoFocus>
                      {Language[DeleteContactModalBtn] ? Language[DeleteContactModalBtn] : DeleteContactModalBtn }
                    </Button>
                  </DialogActions>
                </Dialog>


                 {/* //update contact  modal */}
                    <Dialog open={openUpdateContactModal} onClose={handleCloseUpdateContactModal}>
                  <DialogTitle style={{alignContent: 'center'}}>{Language['Update Contact']}  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {Language['fill the field below then press update, this action will update this contact according to this information.']}
                    </DialogContentText>
                  
                  
                    <FormControl error={is_error_update_society} variant="standard" style={{width: '100%', marginTop: '20px'}}>
                        <InputLabel htmlFor="society">{Language['society']} *</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}}}
                          onChange={(e) => {
                            set_update_society(e.target.value);
                            set_is_error_update_society(false)
                            set_update_society_error('')
                          }}
                          type='text'
                          id="society"
                          defaultValue={update_society}
                          aria-describedby="society_error"
                        />
                        <FormHelperText id="society_error">{update_society_error}</FormHelperText>
                    </FormControl>


                    <FormControl error={is_error_update_first_name} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="title">{Language['First Name']} </InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}}}
                          onChange={(e) => {
                            set_update_first_name(e.target.value);
                            set_is_error_update_first_name(false)
                            set_update_first_name_error('')
                          }}
                          type='text'
                          id="title"
                          defaultValue={update_first_name}
                          aria-describedby="title_error"
                        />
                        <FormHelperText id="title_error">{update_first_name_error}</FormHelperText>
                    </FormControl>

                    <FormControl error={is_error_update_family_name} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="family_name">{Language['Family Name']} </InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}}}
                          onChange={(e) => {
                            set_update_family_name(e.target.value);
                            set_is_error_update_family_name(false)
                            set_update_family_name_error('')
                          }}
                          type='text'
                          id="family_name"
                          defaultValue={update_family_name}
                          aria-describedby="family_name_error"
                        />
                        <FormHelperText id="family_name_error">{update_family_name_error}</FormHelperText>
                    </FormControl>

                    <FormControl error={is_error_update_phone_number} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="phone_number">{Language['Phone Number']} *</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}}}
                          onChange={(e) => {
                            set_update_phone_number(e.target.value);
                            set_is_error_update_phone_number(false)
                            set_update_phone_number_error('')
                          }}
                          type='text'
                          id="phone_number"
                          defaultValue={update_phone_number}
                          aria-describedby="phone_number_error"
                        />
                        <FormHelperText id="phone_number_error">{update_phone_number_error}</FormHelperText>
                    </FormControl>

                    <FormControl error={is_error_update_email} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="email">{Language['Email']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}}}
                          onChange={(e) => {
                            set_update_email(e.target.value);
                            set_is_error_update_email(false)
                            set_update_email_error('')
                          }}
                          type='text'
                          id="email"
                          defaultValue={update_email}
                          aria-describedby="email_error"
                        />
                        <FormHelperText id="email_error">{update_email_error}</FormHelperText>
                    </FormControl>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseUpdateContactModal}>{Language['Cancel']}</Button>
                    <Button onClick={() => update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}>{Language[update_contact_btn_content] ? Language[update_contact_btn_content] : update_contact_btn_content}</Button>
                  </DialogActions>
                    </Dialog>


      <AlertComp open={openAlert} handleClose={handleClose}   message={Language[alertMessage]} type={alertType} />         
      

    </Body>
  )
}
