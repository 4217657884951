import React, {  useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { getResponseMessage } from './responding';
import colors from '../colors';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`




const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 40%;
    }
`

const P = styled.p`
    margin: 0%;
    padding: 0%;
    font-size: medium;
    font-weight: 400;
    margin-right: 20px;
    color: grey;
    @media only screen and (max-width: 850px) {
      width: 40%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    @media only screen and (max-width: 850px) {
    }
`
const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 2% 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`

const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 10px;
`

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




export default function Profile({email, firstName, familyName, setUserName, setFamilyName, setFirstName, Language}) {
    

    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [ openAlert, setOpenAlert] = useState(false)
    const [ serverError, setServerError] = useState(false)

    const [firstNameEdit, setFirstNameEdit] = useState(firstName);
    const [familyNameEdit, setFamilyNameEdit] = useState(familyName);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPaswword, setConfirmPaswword] = useState(''); 

    const [isErrorFirstName, setIsErrorFirstName] = useState(false);
    const [isErrorFamilyName, setIsErrorFamilyName] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const [isErrorNewPassword, setIsErrorNewPassword] = useState(false);
    const [isErrorConfirmPassword, setIsErrorConfirmPassword] = useState(false);

    const [firstNameError, setFirstNameError] = useState("");
    const [familyNameError, setFamilyNameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate()


const [anchorEl, setAnchorEl] = React.useState(null);
const handleClose = (link) => {
    if(link === 'account'){
        navigate('/app/SMSMarketing/profile');
    }else if(link === 'logout'){
          // get data from database
            axios.post("/logout")
            .then(function (response) {
                console.log(response.data)

                if(response.data.res == 'done'){
                    navigate('/login', { replace: true });
                }else if(response.data.res == 'redirect'){
                    navigate('/login', { replace: true });
                }
                else{
                    setServerError(true)
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if(error.response){
                  if(error.response.status === 401 && error.response.data.response === 'banned'){
                    navigate('/app/banned', { replace: true });
                  }else{
                    setOpenAlert(true)
                    setAlertMessage(error.response.statusText)
                    setAlertType("error")                    
                  }
  
                  }else{
                    setOpenAlert(true)
                    setAlertMessage('An unexpected error has occured')
                    setAlertType("error")
                  }
            })
    }
    setAnchorEl(null);
};

const [disablePersonalSettingBtn, setDisablePersonalSettingBtn] = useState(false);
const [disableSecurityBtn, setDisableSecurityBtn] = useState(false);
const [saveBtnContent, setsaveBtnContent] = useState('save');


function setUserData(firstName, familyName){
    setDisablePersonalSettingBtn(true)
    setIsErrorFirstName(false)
    setIsErrorFamilyName(false)
    setFirstNameError('')
    setFamilyNameError('')
    axios.post("/edit_user_Data", {
      firstName, familyName
    })
    .then(function (response) {
      setDisablePersonalSettingBtn(false)
      setsaveBtnContent('save')
      console.log(response.data)
      if(response.data.res === 'done'){
            setUserName(response.data.firstName + ' ' + response.data.familyName)
            setFamilyName(response.data.familyName)
            setFirstName(response.data.firstName)
            setAlertMessage( 'Saved successfuly')
            setAlertType('success')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
        // show data
      }
      else if(response.data.res === 'server_error'){
            setAlertMessage('An unexpected error has occured')
            setAlertType('error')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
        // redirect to server error page
      }
      else if(response.data.res === 'input_error'){
        // redirect to server error page
        for( let i = 0; i < response.data.error.length; i++){
          console.log(response.data.error[i])
          if(response.data.error[i].path === 'first_name'){
            setFirstNameError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
            setIsErrorFirstName(true)
            console.log('comes')
          }
          else if(response.data.error[i].path === 'family_name'){
            setFamilyNameError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
            setIsErrorFamilyName(true)
          }    
        }
      }
      else if(response.data.res === 'redirect'){
        navigate('/login', { replace: true });
      }
    })
    .catch(function (error) {
      setDisablePersonalSettingBtn(false)
      // handle error
      setsaveBtnContent('save')
      console.log('this is axios error');
      console.log(error)
      if(error.response){
        if(error.response.status === 401 && error.response.data.response === 'banned'){
          navigate('/app/banned', { replace: true });
        }else{
          setOpenAlert(true)
          setAlertMessage( getResponseMessage(error.response.status))
          setAlertType("error")          
        }

      }else{
        setOpenAlert(true)
        setAlertMessage('An unexpected error has occured')
        setAlertType("error")
      }
    })
  }


  function changePassword(password, newPassword, confirmPassword){
    setDisableSecurityBtn(true)
    setIsErrorNewPassword(false)
    setIsErrorPassword(false)
    setIsErrorConfirmPassword(false)
    setPasswordError('')
    setNewPasswordError('')
    setConfirmPasswordError('')
    axios.post("/change_password", {
      password, newPassword, confirmPassword
    })
    .then(function (response) {
      setDisableSecurityBtn(false)
      console.log(response.data)
      if(response.data.res === 'done'){
            //setPassword('')
            //setNewPassword('')
            //setConfirmPaswword('')
            setAlertMessage( 'Saved successfuly')
            setAlertType('success')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
        // show data
      }
      else if(response.data.res === 'server_error'){
            setAlertMessage('An unexpected error has occured')
            setAlertType('error')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
        // redirect to server error page
      }
      else if(response.data.res === 'input_error'){
        // redirect to server error page
        for( let i = 0; i < response.data.error.length; i++){
          console.log(response.data.error[i])
          if(response.data.error[i].path === 'password'){
            setPasswordError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
            setIsErrorPassword(true)
          }
          else if(response.data.error[i].path === 'newPassword'){
            setNewPasswordError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
            setIsErrorNewPassword(true)
          } 
          else if(response.data.error[i].path === 'confirmPassword'){
            setConfirmPasswordError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
            setIsErrorConfirmPassword(true)
          } 
        }
      }
      else if(response.data.res === 'redirect'){
        navigate('/login', { replace: true });
      }
    })
    .catch(function (error) {
      setDisableSecurityBtn(false)
      // handle error
      setsaveBtnContent('Save')
      console.log('this is axios error');
      console.log(error)
      if(error.response){
        if(error.response.status === 401 && error.response.data.response === 'banned'){
          navigate('/app/banned', { replace: true });
        }else{
          setOpenAlert(true)
          setAlertMessage(getResponseMessage(error.response.status))
          setAlertType("error")          
        }
      }else{
        setOpenAlert(true)
        setAlertMessage('An unexpected error has occured')
        setAlertType("error")
      }
    })
  }

    const [value, setValue] = React.useState(0);
    return (
        <Body>
            
            <BodyContent>
                
                <Form  style={{display: 'flex', justifyContent: 'space-between',  flexDirection: 'row', alignItems: "center", flexWrap: "wrap"}}>
                  <div>
                    <H1 style={{margin: '10px 0px', width: "max-content"}}>{firstName} {familyName}</H1>
                    <P style={{margin: '10px 0px', width: "max-content"}}>{email}</P>
                  </div>
                  <Button variant="outlined" onClick={() => handleClose("logout")} disabled={false}  style={{marginLeft: '0px', fontSize: "small", height: "max-content" }}  > {Language['Disconnect']}  </Button>
                </Form>
    
                <Form style={{marginTop: '0px'}}>
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={Language['Personal Information']} {...a11yProps(0)} />
                        <Tab label={Language['Security']} {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      
                      <FormControl error={isErrorFirstName} variant="standard" style={{width: '100%', marginTop: '10px'}}>
                          <InputLabel htmlFor="user">{Language['First Name']}</InputLabel>
                          <Input
                            onKeyDown={ (event) => { if (event.key === 'Enter') { setUserData(firstNameEdit, familyNameEdit)}}}
                            onChange={(e) => {
                                setFirstNameEdit(e.target.value)
                                setFirstNameError('')
                                setIsErrorFirstName(false)
                            }}
                            type='text'
                            id="user"
                            defaultValue={firstName}
                            aria-describedby="user_error"
                          />
                          <FormHelperText id="user_error"> {firstNameError} </FormHelperText>
                      </FormControl>
    
                      <FormControl error={isErrorFamilyName} variant="standard" style={{width: '100%', marginTop: '10px'}}>
                          <InputLabel htmlFor="user">{Language['Family Name']}</InputLabel>
                          <Input
                            onKeyDown={ (event) => { if (event.key === 'Enter') { setUserData(firstNameEdit, familyNameEdit)}}}
                            onChange={(e) => {
                                setFamilyNameEdit(e.target.value)
                                setFamilyNameError('')
                                setIsErrorFamilyName(false)
                            }}
                            type='text'
                            id="user"
                            defaultValue={familyName}
                            aria-describedby="user_error"
                          />
                          <FormHelperText id="user_error"> {familyNameError} </FormHelperText>
                      </FormControl>
    
                      <BtnContainer>
                        <Tooltip title={Language['Save your information']} >
                            <Button variant="contained" disabled={disablePersonalSettingBtn} onClick={() => setUserData(firstNameEdit, familyNameEdit)} style={{marginLeft: '10px', fontSize: "small", backgroundColor: colors.primary}}  > {Language['Save']}  </Button>
                        </Tooltip>
    
                       </BtnContainer>
    
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <FormControl error={isErrorPassword} variant="standard" style={{width: '100%', marginTop: '0px'}}>
                          <InputLabel htmlFor="user">{Language['Actuel Password']}</InputLabel>
                          <Input
                            onKeyDown={ (event) => { if (event.key === 'Enter') { changePassword(password, newPassword, confirmPaswword)}}}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setIsErrorPassword(false)
                                setPasswordError('')
                            }}
                            type='password'
                            id="user"
                            defaultValue=""
                            aria-describedby="user_error"
                          />
                          <FormHelperText id="user_error"> {passwordError} </FormHelperText>
                      </FormControl>
    
                      <FormControl error={isErrorNewPassword} variant="standard" style={{width: '100%', marginTop: '0px'}}>
                          <InputLabel htmlFor="user">{Language['New Password']}</InputLabel>
                          <Input
                            onKeyDown={ (event) => { if (event.key === 'Enter') { changePassword(password, newPassword, confirmPaswword)}}}
                            onChange={(e) => {
                                setNewPassword(e.target.value)
                                setIsErrorNewPassword(false)
                                setNewPasswordError('')
                            }}
                            type='password'
                            id="user"
                            defaultValue=""
                            aria-describedby="user_error"
                          />
                          <FormHelperText id="user_error">{newPasswordError} </FormHelperText>
                      </FormControl>
    

                      <FormControl error={isErrorConfirmPassword} variant="standard" style={{width: '100%', marginTop: '0px'}}>
                          <InputLabel htmlFor="user">{Language['Confirm New Password']}</InputLabel>
                          <Input
                            onKeyDown={ (event) => { if (event.key === 'Enter') { changePassword(password, newPassword, confirmPaswword)}}}
                            onChange={(e) => {
                                setConfirmPaswword(e.target.value)
                                setIsErrorConfirmPassword(false)
                                setConfirmPasswordError('')
                            }}
                            type='password'
                            id="user"
                            defaultValue=""
                            aria-describedby="user_error"
                          />
                          <FormHelperText id="user_error">{confirmPasswordError}</FormHelperText>
                      </FormControl>
    
                      <BtnContainer>
                        <Tooltip title={Language['Save password']} >
                            <Button variant="contained" disabled={disableSecurityBtn} onClick={() => changePassword(password, newPassword, confirmPaswword)}  style={{marginLeft: '10px', fontSize: "small", backgroundColor: colors.primary}}  >  {Language['Save']} </Button>
                        </Tooltip>
    
                       </BtnContainer>
                    </TabPanel>
                  </Box>  
                </Form>
            </BodyContent>
    
              {/* // snackbar */}
              <Snackbar
                open={openAlert}
                autoHideDuration={4000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                  {alertMessage}
                </Alert>
              </Snackbar> 
        </Body>
      )
}