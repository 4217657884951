import { AccessTime, AccountCircle, AccountCircleOutlined, AddCircleOutlineOutlined, Logout, Person, Person4Outlined, PersonOutlined, Settings } from '@mui/icons-material';
import { Alert, Avatar, Button, CircularProgress, ListItemIcon, Select, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DataGrid } from '@mui/x-data-grid';
import { Navigate, Route, Router, Routes, useNavigate } from 'react-router-dom';
import NotFound from '../404';
import UserManagement from './clients';
import HomePage from './home';
import WhiteLogo from '../reusableComponent/logo-white';
import NewClient from './newClient';
import axios from 'axios';
import Loading from '../Loading';
import Client from './client';
import WhiteLogoResponsive from '../reusableComponent/logo-white-responsive';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import History from './history';
import colors from '../../colors';


const Home = styled.div`
    width: 100% !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    overflow-x: hidden;
    overflow-x: hidden;
`

const Navbar = styled.div`
    width: calc(100%);
    height: 50px;
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.primary};
`
const Logo = styled.img`
  width: 120px;
  height: auto;
  align-self: center;
  margin-left: 10px;
`;


const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
`

const Action = styled.p`
    color: white;
    padding: 7px 12px;
    margin: 0px 5px;
    background-color: ${props => props.tab === true ? colors.secondary : '#38084d0'} ;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.5ms ease-in-out;
    &:hover {
        background-color: ${props => props.tab === true ? colors.secondary : '#38084d3c'}  ;
    }
`


const Timer = styled.p`
    color: white;
    padding: 7px 12px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    cursor: pointer;
    transition: 0.5ms ease-in-out;
    margin-right: 10px;
    background-color: ${props => props.tab === true ? colors.secondary : '#38084d0'} ;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.5ms ease-in-out;
    &:hover {
        background-color: ${props => props.tab === true ? colors.secondary : '#38084d3c'}  ;
    }
`

const Content = styled.div`
    width: calc(100% - 40px)  ;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
`
const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`

const Headertitle = styled.p`
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    border-left: 5px solid ${colors.secondary} ;
`




export default function Admin({Language, changeLanguage, languageSelected}) {
    const [currentTime, setCurrentTime] = useState(new Date());

    const [anchorElProfileMenu, setAnchorElProfileMenu] = React.useState(null);
    const openProfileMenu = Boolean(anchorElProfileMenu);
    const handleClickProfileMenu = (event) => {
        setAnchorElProfileMenu(event.currentTarget);
    };
    const handleCloseProfileMenu = () => {
        setAnchorElProfileMenu(null);
    };

    useEffect(() => {
        // Update the current time every second
        const intervalId = setInterval(() => {
        setCurrentTime(new Date());
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures the effect runs only once (on mount)

    // Format the time as HH:MM:SS
    const formattedTime = currentTime.toLocaleTimeString();

    const navigate = useNavigate()

    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [openAlert, setOpenAlert] = React.useState(false);
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    // check login of the admin
    const [loading, setLoading] = useState(true);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    useEffect(() => {
        // get data from database
        axios.post("/check_login_admin")
        .then(function (response) {
          console.log(response.data)
          if(response.data.redirect === false){
            setLoading(false)
            if(response.data.user.first_name === '' &&  response.data.user.family_name === ''){
                setUserName('Admin')
            }else{
                setUserName(response.data.user.first_name + ' '+ response.data.user.family_name)
            }
            setEmail(response.data.user.email)
            console.log(userName)
          }
          else if(response.data.result === 'redirect'){
            navigate('/admin/login', { replace: true });
          }
          else{
            navigate('/admin/login', { replace: true });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          if(error.response){
            setOpenAlert(true)
            setAlertMessage(error.response.statusText)
            setAlertType("error")
          }else{
            setOpenAlert(true)
            setAlertMessage('Internal server error')
            setAlertType("error")
          }
        })
      
    }, [])

    const [tab, setTab] = useState('home');
    useEffect(() => {
        const currentPath = window.location.pathname;
        const pathSegments = currentPath.split('/');
        if(pathSegments[pathSegments.length - 1] === 'admin'){
            setTab('home')
        }else{
            setTab(pathSegments[pathSegments.length - 1])
        }
    }, []);


    // logout function
    const [logoutLoading, setLogoutLoading] = useState(false);

    function handleLogout(){
        setLogoutLoading(true)
        setOpenAlert(true)
        setAlertMessage('Login out..')
        setAlertType('info')
        axios.post("/logout_admin")
        .then(function (response) {
            //console.log(response.data)
            setLogoutLoading(false)
            if(response.data.response === 'done'){
                navigate('/admin/login', {replace: true})
            } else if (response.data.response === 'alert_error'){
                setOpenAlert(true)
                setAlertMessage(response.data.error)
                setAlertType('error')
            }
        })
        .catch(function (error) {
            // handle error
            setLogoutLoading(false)
            setOpenAlert(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType('error')
        })
    }


    
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

    const updateDeviceWidth = () => {
        setDeviceWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener('resize', updateDeviceWidth);
        return () => {
        window.removeEventListener('resize', updateDeviceWidth);
        };
    }, []);

    if(loading){
        return(
            <Loading></Loading>
        )
    }else{
        return (
            <Home>
                <Navbar>
                    {
                        [''].map(()=>{
                            if(deviceWidth > 800) {
                                return(
                                    <WhiteLogo/>
                                )
                                
                            }else{
                                return(
                                    <WhiteLogoResponsive width={deviceWidth}/>
                                )
                            }
                        })
                    }
                    <Actions>
                        <Action onClick={()=> {navigate('/admin/home'); setTab('home')}} tab={tab === 'home' || tab === '' ? true : false} >{Language['Home']}</Action>
                        <Action  onClick={()=> {navigate('/admin/clients'); setTab('clients')}} tab={tab === 'clients' || tab === 'newClient'  ? true : false}>{Language['Clients']}</Action>
                        {/* <Action  onClick={()=> {navigate('/admin/history'); setTab('history')}} tab={tab === 'history' ? true : false}>History</Action> */}
                    </Actions>
                    <div style={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'end', alignItems: 'center'}}>
                    <Timer   onClick={handleClickProfileMenu}  tab={tab === 'profile'  ? true : false}> <AccountCircleOutlined  style={{marginRight: '5px', color: '#ffffff9b', fontSize: deviceWidth > 800 ? '30px' : '30px' }}/> <span style={{display: deviceWidth > 800 ? 'block' : 'none'}} >{userName}</span>   </Timer>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={languageSelected}
                            onChange={(event) => changeLanguage(event.target.value)}
                            // label="Language"
                            style={{height: '40px', marginRight: '10px', backgroundColor: colors.primary, color: 'white', border: 'none'}}
                            >
                            <MenuItem value={'english'}>English</MenuItem>
                            <MenuItem value={'french'}>Français</MenuItem>
                        </Select>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElProfileMenu}
                            open={openProfileMenu}
                            onClose={handleCloseProfileMenu}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem disabled style={{width: "250px"}} onClick={handleCloseProfileMenu}>
                                <ListItemIcon>
                                    <Person fontSize="small" />
                                </ListItemIcon>
                                {Language['Profile']}
                            </MenuItem>
                            <MenuItem disabled style={{width: "250px"}} onClick={handleCloseProfileMenu}>
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                {Language['Settings']}
                            </MenuItem>
                            <MenuItem style={{width: "250px"}} onClick={handleLogout}>
                                <ListItemIcon>
                                    <Logout fontSize="small" /> 
                                </ListItemIcon>
                                {Language['Logout']} 
                            </MenuItem>
                        </Menu>
                    </div>
                    {/* <Timer> <AccessTime style={{marginRight: '10px'}}/>{userName}{formattedTime}  </Timer> */}
                </Navbar>
                
                <Content>
                    <Routes>
                        {/* <Route axact path="/*" element={ < Panel /> } ></Route> */}
                        {/* <Route axact path="/body" element={ < Body /> } ></Route> */}
                        <Route axact path="/"  element={<Navigate Language={Language} to="/admin/home" />} ></Route>
                        <Route axact path="/home" element={ < History Language={Language} /> } ></Route>
                        <Route axact path="/profile" element={ < HomePage Language={Language} /> } ></Route>
                        <Route axact path="/clients" element={ < UserManagement Language={Language} /> } ></Route>
                        <Route axact path="/newClient" element={ < NewClient Language={Language} /> } ></Route>
                        <Route axact path="/client" element={ < Client Language={Language} /> } ></Route>
                        {/* <Route axact path="/history" element={ < History /> } ></Route> */}
                        <Route axact path="/*" element={ < NotFound Language={Language} /> }  ></Route>
                    </Routes>
                </Content>

                <Snackbar
                    open={openAlert}
                    autoHideDuration={4000}
                    onClose={handleCloseAlert}
                >
                    <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                    {Language[alertMessage] ? Language[alertMessage] : alertMessage}
                    </Alert>
                </Snackbar>
            </Home>
        ) 
    }
 
}


