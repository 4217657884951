import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

const Body = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Title = styled.h1`
    font-size: large;
    color: #0f0b0e ;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 30px;
    text-align: center;
`
const SouTitle = styled.p`
    font-size: medium;
    color: #707070;
    margin-top: 0px;
    width: 100%;
    padding-bottom: 20px;
    text-align: center;
`

const MyLink = styled(Link)`
    //text-decoration-line: none;
    color: black;
`


export default function ErrorPage({MyIcon, title, desc, url, urlDesc}) {
  return (
    <Body>
      {MyIcon}
      {/* <MyIcon style={{fontSize: "50px !important"}} /> */}
      <Title>{title}</Title>
      <SouTitle>{desc}<MyLink to={url}>{urlDesc}</MyLink></SouTitle>
    </Body>
  )
}