import { Alert, Button, Snackbar, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import colors from '../../colors';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${colors.primary};
`

const Content = styled.div`
    width: 30%;
    min-width: 350px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    height: max-content;
    min-height: 300px;
    box-shadow: 0px 0px 5px 2px #0000003c;
`

const SubContent = styled.div`
    width: 80%;
    min-width: 320px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    height: max-content;
    flex-grow: 1;
    margin-bottom: 30px;
`

const Title = styled.h1`
    font-size: 25px;
    padding: 5px;
    margin: 0px;
    color: black;
`

const SubTitle = styled.p`
    font-size: 15px;
    padding: 5px;
    color: #000000c7;
    margin: 0px;
    margin-bottom: 20px;
`


export default function LoginAdmin({Language}) {
    const [firstName, setFirstName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [familyNameError, setFamilyNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwrodError, setPasswrodError] = useState('');
    const [passwrod1Error, setPasswrod1Error] = useState('');
    const [isFirstNameError, setIsFirstNameError] = useState(false);
    const [isFamilyNameError, setIsFamilyNameError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [isPassword1Error, setIsPassword1Error] = useState(false);
    const [loadingSubmit, setloadingSubmit] = useState(false);

    const [openAlert, setOpenAlert] = useState(false)
    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    const navigate = useNavigate()

  function handleSubmit(){
    setIsEmailError(false)
    setIsPasswordError(false)
    setIsPassword1Error(false)
    setEmailError('')
    setPasswrodError('')
    setPasswrod1Error('')
    setloadingSubmit(true)

    axios.post("/admin_login", {
      firstName, familyName, email, password, password1
    })
        .then(function (response) {
            setloadingSubmit(false)
          console.log(response.data)
          if(response.data.result === "connected"){
            setOpenAlert(true)
            setAlertMessage(response.data.message)
            setAlertType("success")
            setTimeout(() => {
              navigate('/admin', { replace: true });
            }, 1000);
          }
          else if(response.data.result === "input_error") {
            for(let i = 0; i < response.data.error.length; i++){
              if(response.data.error[i].path === 'email'){
                setIsEmailError(true)
                setEmailError(Language[ response.data.error[i].error] ? Language[ response.data.error[i].error] : response.data.error[i].error)
              }else if(response.data.error[i].path === 'password1'){
                setIsPassword1Error(true)
                setPasswrod1Error(Language[ response.data.error[i].error] ? Language[ response.data.error[i].error] : response.data.error[i].error)
              }
              else if(response.data.error[i].path === 'password'){
                setIsPasswordError(true)
                setPasswrodError(Language[ response.data.error[i].error] ? Language[ response.data.error[i].error] : response.data.error[i].error)
              }
            }
          }
          else if(response.data.result === "server_error") {
            setOpenAlert(true)
            setAlertMessage(response.data.message)
            setAlertType("error")
          }
          else{
            setOpenAlert(true)
            setAlertMessage('Session ended')
            setAlertType("error")
            setTimeout(() => {
              navigate('/login', { replace: true });
            }, 2000);
          }
          
        })
        .catch(function (error) {
            // handle error
            setloadingSubmit(false)
            setOpenAlert(true)
            setAlertMessage('An unexpected error has occured')
            setAlertType("error")
        })
    
  }

  const [status, setStatus] = useState('login');
  const [loading, setLoading] = useState(true);
    useEffect(() => {
        // get data from database
        axios.post("/check_login_admin")
        .then(function (response) {
          console.log(response.data)
          if(response.data.redirect === false){
            setLoading(false)
          }
          else if(response.data.result === 'redirect'){

          }
          else{
            //navigate('/admin/login', { replace: true });
            if(response.data.U === 0){
                setStatus('signup')
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          if(error.response){
            setOpenAlert(true)
            setAlertMessage(error.response.statusText)
            setAlertType("error")
          }else{
            setOpenAlert(true)
            setAlertMessage('Internal server error')
            setAlertType("error")
          }
        })
      
    }, [])

  return (
    <Container>
        <Content>
            <Title style={{textAlign: 'center', width: '100%'}}>{Language['Admin panel']} - {Language[status] ? Language[status] : status } </Title>
            <SubTitle>{Language['Please enter your credentials..']}</SubTitle>
            <SubContent>
                <TextField id="filled-basic" value={firstName} type='text' error={isFirstNameError}  helperText={firstNameError} onChange={(e)=> setFirstName(e.target.value)}   label={Language['First name']} variant="filled" style={{width: '100%', marginTop: '10px', display: status === 'signup' ? 'flex' : 'none'}} />
                <TextField id="filled-basic" value={familyName} type='text' error={isFamilyNameError}  helperText={familyNameError} onChange={(e)=> setFamilyName(e.target.value)}   label={Language['Family name']} variant="filled" style={{width: '100%', marginTop: '10px', display: status === 'signup' ? 'flex' : 'none'}} />
                <TextField id="filled-basic" value={email} type={'email'} error={isEmailError}  helperText={emailError} onChange={(e)=> setEmail(e.target.value)} label={Language['Email'] + ' *'} variant="filled" style={{width: '100%', marginTop: '10px'}} />
                <TextField id="filled-basic" value={password} type='password' error={isPasswordError}  helperText={passwrodError} onChange={(e)=> setPassword(e.target.value)}   label={Language['Password'] + ' *'} variant="filled" style={{width: '100%', marginTop: '10px'}} />
                <TextField id="filled-basic" value={password1} type='password' error={isPassword1Error}  helperText={passwrod1Error} onChange={(e)=> setPassword1(e.target.value)}   label={Language['Confirm password'] + ' *'} variant="filled" style={{width: '100%', marginTop: '10px', display: status === 'signup' ? 'flex' : 'none'}} />
                <LoadingButton loading={loadingSubmit} loadingPosition='end' variant="contained" onClick={()=> handleSubmit()} style={{backgroundColor: loadingSubmit? colors.darkGray : colors.primary, color: 'white', width: '100%', marginTop: '20px', marginBottom: '20px'}}>{Language[status]}</LoadingButton>
            </SubContent>
        </Content>
        <Snackbar
            open={openAlert}
            autoHideDuration={4000}
            onClose={handleClose}
            >
            <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                {Language[alertMessage] ? Language[alertMessage] : alertMessage}
            </Alert>
            </Snackbar>
    </Container>
  )
}
