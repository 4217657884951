import { Engineering } from '@mui/icons-material'
import React from 'react'

export default function Home({Language}) {
  return (
    <div style={{height: 'calc(100vh - 100px )', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Engineering style={{fontSize: '30px'}}/>
        <p style={{textAlign: 'center'}}>{Language['This page is currently under development']} , <br/> {Language['We will be back soon!']}</p>
    </div>
  )
}
