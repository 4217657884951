import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import AlertComp from '../alert';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import LoadingContainer from '../reusableComponent/loadingContainer';
import { useLocation } from 'react-router-dom';
import ErrorPage from '../reusableComponent/errorPage';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { getResponseMessage } from '../responding';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import moment from 'moment-timezone';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const Body = styled.div`
    width: 100%;
    position: relative;
    height: calc(100vh - 60px);
`

const Header = styled.div`
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: start;
    justify-content: space-between;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: end;

    }
`

const RowContainer = styled.div`
    width: max-content;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: start;
`



const HeaderSeach = styled.div`
    width: 40%;
    @media only screen and (max-width: 850px) {
        width: 90% !important;
        margin: 0% 5%;
        margin-top: 20px;
    }
`
const DivRow = styled.div`
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: end;
    margin-top:10px;
    margin-left: 0px;
`
const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: 20px ;
    font-weight: 400;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: 'center';
    @media only screen and (max-width: 850px) {
      width: 100%;
      font-size: 20px;
    }
`

const BodyContent = styled.div`
    width: 100%;
    background-color: #f6f7fa;
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (max-width: 850px) {
      height: calc(100vh - 170px);
    }
`

export default function EditList({handleHeader, handleError, Language}) {

  const columns = [
    
    { field: 'createdAt', headerName: Language['Created At'], width: 200,
      renderCell: (params) => {
        var date = moment(params.row.createdAt).tz('Africa/Algiers').format()
        const year = date.slice(0, 4);
        const month = date.slice(5, 7);
        const day = date.slice(8, 10);
        const hour = date.slice(11, 13);
        const minute = date.slice(14, 16);
        return year + '/' + month + '/' + day + ' ' + hour + ":" + minute ;
      },  },
    {
      field: 'title',
      headerName: Language['Name'],
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.first_name ? params.row.first_name : '' + ' ' + params.row.family_name ? params.row.family_name : ''
    },
    },
    { field: 'society', headerName: Language['Society'], width: 200 },
    { field: 'email', headerName: Language['Email'], width: 200 },
    { field: 'phone_number', headerName: Language['Phone Number'], width: 200 },
    {
      field: 'Action',
      headerName: Language['Action'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params ) => {
         return <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', width: '100%'}}>
                  <Button variant='outlined' startIcon={<EditIcon />} onClick={(e) => {e.stopPropagation(); setOpenUpdateContactModal(true); handleUpdate(params.row)}}>{Language['Edit']}</Button >
                  {/* <IconButton  color='error' onClick={(e) => e.stopPropagation()}> <DeleteIcon /></IconButton> */}
                </div> 
      },
    },
  ];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const [updated_title, set_updated_title] = useState("");
  const [ is_error_updated_title, set_is_error_updated_title] = useState(false)
  const [ updated_title_error, set_updated_title_error] = useState('')



  const [ loading, setLoading] = useState(true)
  const [loadingContacts, setLoadingContacts] = useState(true);
  const [ add_first_name, set_add_first_name] = useState('')
  const [ add_family_name, set_add_family_name] = useState('')
  const [ add_phone_number, set_add_phone_number] = useState('')
  const [ add_email, set_add_email] = useState('')
  const [ add_society, set_add_society] = useState('')

  const [ update_id, set_update_id] = useState('')
  const [ update_first_name, set_update_first_name] = useState('')
  const [ update_family_name, set_update_family_name] = useState('')
  const [ update_phone_number, set_update_phone_number] = useState('')
  const [ update_email, set_update_email] = useState('')
  const [ update_society, set_update_society] = useState('')


  const [ is_error_update_first_name, set_is_error_update_first_name] = useState(false)
  const [ is_error_update_family_name, set_is_error_update_family_name] = useState(false)
  const [ is_error_update_email, set_is_error_update_email] = useState(false)
  const [ is_error_update_phone_number, set_is_error_update_phone_number] = useState(false)
  const [ is_error_update_society, set_is_error_update_society] = useState(false)

  const [ is_error_first_name, set_is_error_first_name] = useState(false)
  const [ is_error_family_name, set_is_error_family_name] = useState(false)
  const [ is_error_email, set_is_error_email] = useState(false)
  const [ is_error_phone_number, set_is_error_phone_number] = useState(false)
  const [ is_error_society, set_is_error_society] = useState(false)

  const [ first_name_error, set_first_name_error] = useState(false)
  const [ family_name_error, set_family_name_error] = useState(false)
  const [ email_error, set_email_error] = useState(false)
  const [ phone_number_error, set_phone_number_error] = useState(false)
  const [ society_error, set_society_error] = useState(false)

  const [ update_first_name_error, set_update_first_name_error] = useState(false)
  const [ update_family_name_error, set_update_family_name_error] = useState(false)
  const [ update_email_error, set_update_email_error] = useState(false)
  const [ update_phone_number_error, set_update_phone_number_error] = useState(false)
  const [ update_society_error, set_update_society_error] = useState(false)

  const [ create_contact_btn_content, set_create_contact_btn_content] = useState('create')
  const [ update_contact_btn_content, set_update_contact_btn_content] = useState('update')
  const [ contact_rows, set_contact_rows] = useState([])

  const [ exportBtnContent , setExportBtnContent] = useState(<FileUploadIcon style={{fontSize: "x-large"}}/>)

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)

  const [ selected_rows, set_selected_rows] = useState([])
  const [found, setFound] = useState(false);

  const [listTitle, setListTitle] = useState('list title');
  const [listContactsCount, setListContactsCount] = useState(0);

  const navigate = useNavigate()


  const [ importConfig, setImportConfig] = useState([])
  const [uploadCsvBtnDisabled, setUploadCsvBtnDisabled] = useState(false);
  const [ import_list_contact, set_import_list_contact] = useState([])
  const [openImportConfigModal, setOpenImportConfigModal] = React.useState(false);
  const [import_first_name, set_import_first_name] = React.useState('');
  const [import_family_name, set_import_family_name] = React.useState('');
  const [import_phone_number, set_import_phone_number] = React.useState('');
  const [import_email, set_import_email] = React.useState('');
  const [import_society, set_import_society] = React.useState('');
  const [importAndUpdate, setImportAndUpdate] = React.useState(true);
  const [disabledSaveContactsBtn, setDisabledSaveContactsBtn] = useState(false);


  const handleClickOpenImportConfigModal = () => {
    setOpenImportConfigModal(true);
};

const handleCloseImportConfigModal = () => {
    setOpenImportConfigModal(false);
};


  const { Dragger } = Upload;
    const props = {
      name: 'file',
      multiple: false,
      action: '/mailing/upload_import_mailing_contacts',
      method: 'post',
      onChange(info) {
        console.log("hello")
        setUploadCsvBtnDisabled(true)
        const { status } = info.file;
        if (status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          setUploadCsvBtnDisabled(false)
          //message.success(`${info.file.name} file uploaded successfully.`);
          console.log(info.file.response)
          if(info.file.response.read === "true"){
            setImportConfig(info.file.response.arr[0])
            set_import_list_contact(info.file.response.arr)
            setOpenImportModal(false)
            handleClickOpenImportConfigModal()
            setAlertMessage('File uploaded successfully')
            setAlertType('success')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
          } else if(info.file.response.read === "false" && info.file.response.error === "csv_only"){
            setUploadCsvBtnDisabled(false)
            setOpenImportModal(false)
            handleCloseDeleteContactModal()
            setAlertMessage('You must upload a csv file only')
            setAlertType('error')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
          }else {
            setUploadCsvBtnDisabled(false)
            setUploadCsvBtnDisabled(false)  
            setOpenImportModal(false)
            handleCloseDeleteContactModal()
            setAlertMessage('Internal server error')
            setAlertType('error')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
          }
          
        } else if (status === 'error') {
          setUploadCsvBtnDisabled(false)
          console.log(info.file)
          message.error(`${info.file.name}` + Language['file upload failed']);
        }
      },
      onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
      },
    };

    function import_contacts (contacts, first_name, family_name, phone_number, email, society, importAndUpdate){
      console.log(contacts, first_name, family_name, phone_number, email, society, importAndUpdate)
      setDisabledSaveContactsBtn(true)
      // get data from database
        axios.post("/mailing/save_uploaded_contacts_by_list",{
          contacts, first_name, family_name, phone_number, email, society, importAndUpdate, ListId: id
        })
        .then(function (response) {
          setDisabledSaveContactsBtn(false)
          console.log(response.data)
          setLoading(false)
          set_contact_rows(response.data.contacts.mailingContacts)
          // show data
          handleCloseImportConfigModal()
          if(response.data.updated && response.data.updated > 0){
            setAlertMessage(response.data.added + ' ' + Language['of'] + ' ' + response.data.total + ' ' + Language['items Added successfully'] + ', ' + response.data.updated + ' ' + Language['already exist.'] )
          }else{
            setAlertMessage(response.data.added + ' ' + Language['of'] + ' ' + response.data.total + ' ' + Language['items Added successfully'])
          }
          setAlertType('success')
          setOpenAlert(true)
          setTimeout(() => {
            setOpenAlert(false)
          }, 3000);
        })
        .catch(function (error) {
          setDisabledSaveContactsBtn(false)
          handleCloseDeleteContactModal()
          setLoading(false)
          handleError(error)
        })


    }

    const handleChange = (event, input) => {
      if(input === 'firstName'){
        set_import_first_name(event.target.value)
      } else if(input === 'familyName'){
        set_import_family_name(event.target.value)
      } else if(input === 'email'){
        set_import_email(event.target.value)
      } else if(input === 'phoneNumber'){
        set_import_phone_number(event.target.value)
      } else if(input === 'society'){
        set_import_society(event.target.value)
      } 
    };

    // mui config

    const [anchorElListContact, setAnchorElListContact] = React.useState(null);
    const openListContact = Boolean(anchorElListContact);
    const handleClickListContact = (event) => {
        setAnchorElListContact(event.currentTarget);
    };
    const handleCloseListContact = () => {
        setAnchorElListContact(null);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpenUserModal = () => {
        setOpen(true);
    };

    const handleCloseUserModal = () => {
        setOpen(false);
        set_add_email('');
        set_is_error_email(false)
        set_email_error('')
    };



    const [openUpdateContactModal, setOpenUpdateContactModal] = React.useState(false);
    const handleCloseUpdateContactModal = () => {
        setOpenUpdateContactModal(false);
    };


    const [openEditListTitleModal, setOpenEditListTitleModal] = React.useState(false);
    const handleCloseEditListTitleModal = () => {
        setOpenEditListTitleModal(false);
    };

    const [openImportModal, setOpenImportModal] = React.useState(false);

    const handleClickOpenImportModal = () => {
        setOpenImportModal(true);
    };

    const handleCloseImportModal = () => {
        setOpenImportModal(false);
    };
    
    // end mui config


    // new
    function handleUpdate(item){
      set_update_id(item.id)
      set_update_first_name(item.first_name)
      set_update_family_name(item.family_name)
      set_update_email(item.email)
      set_update_phone_number(item.phone_number)
      set_update_society(item.society)
    }


    // new
    function exportData(rows){
      try {
        if(rows.length > 0){
          console.log(rows)
          setExportBtnContent(<CircularProgress style={{ width: '20px', height:'20px'}}></CircularProgress>)
          axios.post("/mailing/exportContacts", {
            rows
          })
          .then(function (response) {
            setExportBtnContent(<FileUploadIcon style={{fontSize: "x-lare"}}/>)
            console.log(response)
            
              // Create a Blob object from the response data
              const blob = new Blob([response.data], { type: 'text/csv' });

              // Create a temporary URL for the Blob object
              const url = URL.createObjectURL(blob);

              // Create a link element
              const link = document.createElement('a');

              // Set the link's href attribute to the temporary URL
              link.href = url;

              // Set the link's download attribute to the desired filename
              link.download = 'data.csv';

              // Append the link to the DOM
              document.body.appendChild(link);

              // Click the link to download the file
              link.click();

              // Remove the link from the DOM
              document.body.removeChild(link);

              // Revoke the temporary URL to free up memory
              URL.revokeObjectURL(url);
              
              setLoading(false)
              setAlertMessage('Exported successfully')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          })
          .catch(function (error) {
            // handle error
            setExportBtnContent(<FileUploadIcon style={{fontSize: "x-lare"}}/>)
            handleError(error)
          })
       }else{
          setAlertMessage('No contact is selected')
          setAlertType('error')
          setOpenAlert(true)
          setTimeout(() => {
            setOpenAlert(false)
          }, 3000);
       }
      } catch (error) {
        handleError(error)
      }
    }
    

    // new
    function create_Contact(first_name, family_name, email, phone_number, society){
      set_create_contact_btn_content(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
      console.log(first_name, family_name, email, phone_number, society, id)
      // get data from database
      setLoadingContacts(true)
      axios.post("/mailing/create_mailing_contact_from_list", {
        first_name, family_name, email, phone_number, society, id
      })
      .then(function (response) {
        console.log(response)
          set_create_contact_btn_content("create")
          setLoading(false)
          setLoadingContacts(false)
          set_contact_rows(response.data.list.mailingContacts)
          handleCloseUserModal()
          setAlertMessage('Item added successfully')
          setAlertType('success')
          setOpenAlert(true)
          setTimeout(() => {
            setOpenAlert(false)
          }, 3000);
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        set_create_contact_btn_content("create")
        setLoadingContacts(false)
        if(error.response.status === 400 && error.response.data.response === 'input_error'){
          setLoading(false)
          // redirect to server error page
          for( let i = 0; i < error.response.data.errors.length; i++){
            console.log(error.response.data.errors[i])
            if(error.response.data.errors[i].path === 'first_name'){
              set_first_name_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_first_name(true)
            }
            else if(error.response.data.errors[i].path === 'family_name'){
              set_family_name_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_family_name(true)
            }    
            else if(error.response.data.errors[i].path === 'email'){
              set_email_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_email(true)
            } 
            else if(error.response.data.errors[i].path === 'phone_number'){
              set_phone_number_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_phone_number(true)
            } else if(error.response.data.errors[i].path === 'society'){
              set_society_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_society(true)
            } 

          }
        }else{
          handleError(error)
          handleCloseUserModal()
        }
      })
    }


    // new
    function update_Contact(contact_id, first_name, family_name, email, phone_number, society){
      set_update_contact_btn_content(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
      // get data from database
      setLoadingContacts(true)
      axios.post("/mailing/update_mailing_contact_from_list", {
        contact_id, first_name, family_name, email, phone_number, society, id
      })
      .then(function (response) {
        console.log(response)
          set_update_contact_btn_content("update")
          setLoading(false)
          setLoadingContacts(false)
          set_contact_rows(response.data.list.mailingContacts)
          handleCloseUpdateContactModal()
          setAlertMessage('Item updated successfully')
          setAlertType('success')
          setOpenAlert(true)
          setTimeout(() => {
            setOpenAlert(false)
          }, 3000);
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        set_update_contact_btn_content("update")
        setLoadingContacts(false)
        if(error.response.status === 400 && error.response.data.response === 'input_error'){
          setLoading(false)
          // redirect to server error page
          for( let i = 0; i < error.response.data.errors.length; i++){
            console.log(error.response.data.errors[i])
            if(error.response.data.errors[i].path === 'first_name'){
              set_update_first_name_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_update_first_name(true)
            }
            else if(error.response.data.errors[i].path === 'family_name'){
              set_update_family_name_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_update_family_name(true)
            }    
            else if(error.response.data.errors[i].path === 'email'){
              set_update_email_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_update_email(true)
            } 
            else if(error.response.data.errors[i].path === 'phone_number'){
              set_update_phone_number_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_update_phone_number(true)
            } else if(error.response.data.errors[i].path === 'society'){
              set_update_society_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_update_society(true)
            } 
          }
        }else{
          handleError(error)
          handleCloseUpdateContactModal()
        }
      })

      
    }

    // new
    function update_title(title){
      set_create_contact_btn_content(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
      // get data from database
      axios.post("/mailing/update_list_title", {
        title, id
      })
      .then(function (response) {
        set_create_contact_btn_content("create")
        console.log(response.data)
          handleCloseEditListTitleModal()
          setListTitle(title)
          handleCloseUserModal()
          setAlertMessage('Updated successfully')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
      })
      .catch(function (error) {
        // handle error
        if(error.response.status === 400 && error.response.data.response === 'input_error'){
          setLoading(false)
          // redirect to server error page
          for( let i = 0; i < error.response.data.errors.length; i++){
            if(error.response.data.errors[i].path === 'title'){
              set_updated_title_error(error.response.data.errors[i].error)
              set_is_error_updated_title(true)
            }
          }
        }else{
          handleError(error)
          handleCloseUserModal()
        }
      })

      //handleCloseUserModal()
    }

    // new
    function handleDeleteContact(){
      console.log(selected_rows)
      if(selected_rows.length === 0){
        setAlertMessage('No contact is selected')
        setAlertType('error')
        setOpenAlert(true)
        setTimeout(() => {
          setOpenAlert(false)
        }, 3000);
      }else{
        setDeleteTarget('contacts')
        handleClickOpenDeleteContactModal()
      }
      

    }


    function handleDeleteList(){
      handleClickOpenDeleteContactModal()
    }

    const [selectionModel, setSelectionModel] = useState([]);

      const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
        set_selected_rows(newSelection)
      }

      const [openDeleteContactModal, setOpenDeleteContactModal] = React.useState(false);

      const handleClickOpenDeleteContactModal = () => {
        setOpenDeleteContactModal(true);
      };

      const handleCloseDeleteContactModal = () => {
        setOpenDeleteContactModal(false);
      };


      const [openRemoveContactModal, setOpenRemoveContactModal] = React.useState(false);

      const handleClickOpenRemoveContactModal = () => {
        setOpenRemoveContactModal(true);
      };

      const handleCloseRemoveContactModal = () => {
        setOpenRemoveContactModal(false);
      };

      //new
      const [deleteTarget, setDeleteTarget] = useState('');    
      function deleteItems(items){
        console.log(deleteTarget)
        if(deleteTarget === 'list'){
          deleteList()
        }else if(deleteTarget === 'contacts'){
          deleteContacts()
        }
      }

      // new
      function deleteContacts(){
        console.log('contacts deletion')
          // delete items
          axios.post("/mailing/deleteContactsFromList", { items: selected_rows, id})
          .then(function (response) {
            setLoading(false)
            console.log(response)
            set_contact_rows(response.data.list.mailingContacts)
            handleCloseDeleteContactModal()
            setAlertMessage('Items deleted successfully')
            setAlertType('success')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
          })
          .catch(function (error) {
            setRemoveContactModalBtn("delete")
            console.log(error)
            handleError(error)
          })
      }

      // new
      function deleteList(){
          // delete items
          axios.post("/mailing/deleteList", { items: selected_rows, id})
          .then(function (response) {
              setLoading(false)
              handleCloseDeleteContactModal()
              setAlertMessage('Items deleted successfully')
              setAlertType('success')
              setOpenAlert(true)
              navigate('/app/EmailMarketing/mailing_lists', { replace: true})
          })
          .catch(function (error) {
            setRemoveContactModalBtn("delete")
            handleError(error)
          })
      }

      // new
      const [RemoveContactModalBtn, setRemoveContactModalBtn] = useState('Remove');
      function RemoveItems(){
        setRemoveContactModalBtn(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
          axios.post("/mailing/RemoveContactsFromList", { items: selected_rows, id})
          .then(function (response) {
            setRemoveContactModalBtn("remove")
            setLoading(false)
            console.log(response)
            set_contact_rows(response.data.list.mailingContacts)
            handleCloseRemoveContactModal()
            setAlertMessage('Items deleted successfully')
            setAlertType('success')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
          })
          .catch(function (error) {
            setRemoveContactModalBtn('remove')
            handleCloseRemoveContactModal()
            console.log(error)
            handleError(error)
          })
      }

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };


      
      // new
      function search_contact(keyword){
        console.log(keyword)
        setLoadingContacts(true)
        axios.post("/mailing/search_contact",{
          keyword, id
        })
        .then(function (response) {
            setLoadingContacts(false)
            set_contact_rows(response.data.list.mailingContacts)
        })
        .catch(function (error) {
          // handle error
          setLoadingContacts(false)
          handleError(error)
        })
      }

       // setListId(id)
      //new
      function mailingContactsById(){
        setLoadingContacts(true)
        axios.post("/mailing/getmailingContactsById", { id })
        .then(function (response) {
          console.log(response.data)
            setLoadingContacts(false)
            set_contact_rows(response.data.list.mailingContacts)
            setListContactsCount(response.data.list.mailingContacts.length)
        })
        .catch(function (error) {
          // handle error
          setLoadingContacts(false)
          console.log('this is axios error');
          console.log(error);
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpenAlert(true)
              setAlertMessage(error.response.statusText)
              setAlertType("error")
            }
          }else{
            setOpenAlert(true)
            setAlertMessage('Internal server error')
            setAlertType("error")
          }
          setTimeout(() => {
            setOpenAlert(false)
          }, 3000);
        })
      }

      const [importContacts, setImportContacts] = useState('');
      const [importContactsError, setImportContactsError] = useState('');
      const [isErrorImportContacts, setIsErrorImportContacts] = useState(false);
      
      
      //new
      function importMyContacts(contacts){
        setLoadingContacts(true)
        axios.post("/mailing/importNewContactsFromList", { contacts, id })
        .then(function (response) {
          setLoadingContacts(false)
          set_contact_rows(response.data.list.mailingContacts)
          setOpenAlert(true)
          setOpenImportModal(false)
          setAlertMessage(response.data.successed + ' / ' + response.data.total + ' ' + Language['created'] + ', ' + response.data.found + ' ' + Language['found'] + ', ' + response.data.failed + ' ' + Language['failed'] + '.') 
          setAlertType("success")
          setTimeout(() => {
            setOpenAlert(false)
          }, 3000);
        })
        .catch(function (error) {
          // handle error
          setLoadingContacts(false)
          if(error.response.status === 400 && error.response.data.response === 'input_error'){
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const item = error.response.data.errors[i];
              console.log(item)
              if(item.path === 'contacts'){
                setImportContactsError(Language[item.error] ? Language[item.error] : item.error)
                setIsErrorImportContacts(true)
              }
            }
          }else {
            handleError(error)
          }
          console.log(error);
        })
      }

      // new
      function handleRemoveDataFromList(){
        if(selected_rows.length > 0){
          handleClickOpenRemoveContactModal()
        }else{
          setOpenAlert(true)
          setAlertMessage('No contact is selected') 
          setAlertType("error")
          setTimeout(() => {
            setOpenAlert(false)
          }, 3000);
        }
      }

    // new
    useEffect((e) => { 
      // get data from database
      handleHeader('mailing_lists')
      axios.post("/mailing/get_list", { id })
      .then(function (response) {
        console.log(response.data)
        if(response.data.response === 'done'){
          setLoading(false)
          setFound(true)
          mailingContactsById()
          setListTitle(response.data.list.title)
          //set_contact_rows(response.data.contacts)
        }else if (response.data.response === 'notFound'){
          setLoading(false)
          setFound(false)
        }
      })
      .catch(function (error) {
        // handle error
        setLoading(false)
        handleError(error)
      })
    }, [])

    useEffect(() => {
      if(importConfig[4] === 'society'){
        set_import_society(4)
      }else{
        set_import_society('')
      }
  
      if(importConfig[0] === 'first_name'){
        set_import_first_name(0)
      }else{
        set_import_first_name('')
      }
  
      if(importConfig[1] === 'family_name'){
        set_import_family_name(1)
      }else{
        set_import_family_name('')
      }
  
      if(importConfig[2] === 'email'){
        set_import_email(2)
      }else{
        set_import_email('')
      }
  
      if(importConfig[3] === 'phone_number'){
        set_import_phone_number(3)
      }else{
        set_import_phone_number('')
      }
  
    }, [importConfig]);

    const CSVInit = [
      [
        'first_name',
        'family_name',
        'email',
        'phone_number',
        'society'
      ],
      [
        '',
        '',
        '',
        '',
        ''
      ],
    ]

    const downloadCSV = (data, filename) => {
      const csvData = convertArrayToCSV(data);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    };
    
    const convertArrayToCSV = (data) => {
      return data.map(row => row.join(';')).join('\n');
    };

    if(loading){
      return(
        <Body>
          <LoadingContainer open={true}></LoadingContainer>
        </Body>
      )
    }else if (!loading && found){
      return (
        <Body>
            <Header>
                <HeaderTitle>
                    {/* <H1 style={{fontSize: '25px'}}> <span style={{ fontWeight: '400', minWidth: 'max-content'}}> <Link style={{color: 'black', textDecorationLine: 'none'}} to={"/app/EmailMarketing/mailing_lists"}>Mailing list</Link>  >  </span> <span style={{color: 'gray', marginLeft: '10px'}}> {listTitle} ({listContactsCount})</span>    <IconButton><EditIcon></EditIcon> </IconButton> </H1> */}
                    <H1 style={{fontSize: '20px'}}> 
                        <Link style={{color: 'gray',  alignSelf:'center'}} to={"/app/EmailMarketing/mailing_lists"}>{Language['Mailing list']}</Link>
                        <KeyboardArrowRightIcon style={{color: 'gray', alignSelf:'center'}}></KeyboardArrowRightIcon>
                        <span style={{color: 'black', height: 'min-content !important', marginRight: '10px', alignSelf:'center'}}> {listTitle} ({listContactsCount})</span>
                        <Button 
                          id='basic-button' variant='contained' size='small' 
                          style={{ backgroundColor: '#5a5757', alignSelf: "center"}} 
                          endIcon={<KeyboardArrowDownIcon />} 
                          aria-controls={openListContact ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={openListContact ? 'true' : undefined}
                          onClick={handleClickListContact}
                            ><SettingsIcon /></Button>
                        <Menu
                        
                            id="ActionBtn"
                            anchorEl={anchorElListContact}
                            open={openListContact}
                            onClose={handleCloseListContact}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            
                            <MenuItem  style={{minWidth: "200px"}} onClick={(e) => {handleCloseListContact(e); setOpenEditListTitleModal(true)} }>
                              <ListItemIcon>
                                <EditIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>{Language['Edit list title']}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={(e) => {handleCloseListContact(e); setDeleteTarget('list'); handleDeleteList() }} >
                              <ListItemIcon>
                                <DeleteIcon color='error' fontSize="small" />
                              </ListItemIcon>
                              <ListItemText >{Language['Delete list']}</ListItemText>
                            </MenuItem>
                        </Menu> 
                    </H1>
                    <RowContainer>
                      <Tooltip title={Language['add new contact']} >
                        <Button startIcon={<AddIcon></AddIcon>}  variant="outlined" color="success" style={{marginLeft: '10px', fontSize: "small"}} onClick={handleClickOpenUserModal}> {Language['New']}  </Button>
                      </Tooltip>

                      <Tooltip title={Language['import new contacts']} >
                        {/* <Button onClick={() => console.log(windowWidth)} >width</Button> */}
                        <Button startIcon={<FileDownloadIcon ></FileDownloadIcon>}  variant="contained" color="success" style={{marginLeft: '10px', fontSize: "small"}} onClick={handleClickOpenImportModal}> {Language['import']}  </Button>
                      </Tooltip>
                    </RowContainer>
                    


                </HeaderTitle>
                <HeaderSeach>
                <FormControl variant="standard" style={{width: "100%"}}>
                    <Input
                    id="input-with-icon-adornment"
                    placeholder='search for contacts'
                    startAdornment={
                        <InputAdornment position="start">
                        <SearchIcon />
                        </InputAdornment>
                    }
                    onChange={(e) => search_contact(e.target.value)}
                    />
                        {/* {search_icon}   */}
                </FormControl>
                </HeaderSeach>
            </Header>
           

            <BodyContent>
            <Header style={{  width: '90%', margin: '0% 5% ', border: "none"}}>
                <DivRow>
                  <Tooltip title={Language['Export contacts']}><Button variant="contained"  style={{marginLeft: '10px', fontSize: "x-small"}} onClick={() => exportData(selected_rows)}> {exportBtnContent} </Button></Tooltip>
                  <Tooltip title={Language['Remove from list']}><Button variant="contained" color='error'  style={{marginLeft: '10px', fontSize: "x-small"}} onClick={() => handleRemoveDataFromList()}> <BookmarkRemoveIcon ></BookmarkRemoveIcon> </Button></Tooltip>
                  <Tooltip title={Language['Delete contacts']}><Button variant="contained" size='small' color='error' style={{marginLeft: '10px'}} onClick={() => handleDeleteContact()} >  <DeleteIcon style={{fontSize: "large"}}/></Button></Tooltip>
                </DivRow>
            </Header>
            <DataGrid
                style={{ height: 730, width: '94%', margin: '0% 3% 1% 3%', backgroundColor: "white", }}
                rows={contact_rows}
                columns={columns}
                checkboxSelection={true}
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange}
                loading={loadingContacts}
            />

            </BodyContent>


                    {/* new */}
                    {/* //add contact  modal */}
                    <Dialog open={open} onClose={handleCloseUserModal}>
                    <DialogTitle style={{alignContent: 'center'}}>{Language['Create New Contact']} <span style={{fontWeight: '600'}}>+</span>  </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                    {Language['fill the field below then press create, this action will create a new contact according to this information.']}
                    </DialogContentText>


                    <FormControl error={is_error_society} variant="standard" style={{width: '100%', marginTop: '20px'}}>
                        <InputLabel htmlFor="society">{Language['Society']} </InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}}}
                          onChange={(e) => {
                            set_add_society(e.target.value);
                            set_is_error_society(false)
                            set_society_error('')
                          }}
                          type='text'
                          id="society"
                          defaultValue=""
                          aria-describedby="society_error"
                        />
                        <FormHelperText id="society_error">{society_error}</FormHelperText>
                    </FormControl>

                    <FormControl error={is_error_first_name} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="title">{Language['First Name']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}}}
                          onChange={(e) => {
                            set_add_first_name(e.target.value);
                            set_is_error_first_name(false)
                            set_first_name_error('')
                          }}
                          type='text'
                          id="title"
                          defaultValue=""
                          aria-describedby="title_error"
                        />
                        <FormHelperText id="title_error">{first_name_error}</FormHelperText>
                    </FormControl>

                    <FormControl error={is_error_family_name} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="family_name">{Language['Family Name']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}}}
                          onChange={(e) => {
                            set_add_family_name(e.target.value);
                            set_is_error_family_name(false)
                            set_family_name_error('')
                          }}
                          type='text'
                          id="family_name"
                          defaultValue=""
                          aria-describedby="family_name_error"
                        />
                        <FormHelperText id="family_name_error">{family_name_error}</FormHelperText>
                    </FormControl>






                    <FormControl error={is_error_phone_number} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="phone_number">{Language['Phone Number']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}}}
                          onChange={(e) => {
                            set_add_phone_number(e.target.value);
                            set_is_error_phone_number(false)
                            set_phone_number_error('')
                          }}
                          type='text'
                          id="phone_number"
                          defaultValue=""
                          aria-describedby="phone_number_error"
                        />
                        <FormHelperText id="phone_number_error">{phone_number_error}</FormHelperText>
                    </FormControl>


                    <FormControl error={is_error_email} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="email">{Language['Email']} *</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}}}
                          onChange={(e) => {
                            set_add_email(e.target.value);
                            set_is_error_email(false)
                            set_email_error('')
                          }}
                          type='text'
                          id="email"
                          defaultValue=""
                          aria-describedby="email_error"
                        />
                        <FormHelperText id="email_error">{email_error}</FormHelperText>
                    </FormControl>







                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseUserModal}>{Language['Cancel']}</Button>
                    <Button onClick={() => create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society)}>{Language[create_contact_btn_content] ? Language[create_contact_btn_content] : create_contact_btn_content}{}</Button>
                    </DialogActions>
                    </Dialog>


                          {/* new */}
                    {/* //edit list title */}
                    <Dialog open={openEditListTitleModal} onClose={handleCloseEditListTitleModal}>
                    <DialogTitle style={{alignContent: 'center'}}>{Language['Edit Title']}  </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                    {Language['Once you are satisfied with your new title, press the "update" button to update your list title.']}
                    </DialogContentText>


                    <FormControl error={is_error_updated_title} variant="standard" style={{width: '100%', marginTop: '20px'}}>
                        <InputLabel htmlFor="updated_title">{Language['title']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_title(updated_title)}}}
                          onChange={(e) => {
                            set_updated_title(e.target.value);
                            set_is_error_updated_title(false)
                            set_updated_title_error('')
                          }}
                          type='text'
                          id="title"
                          defaultValue={listTitle}
                          aria-describedby="updated_title_error"
                        />
                        <FormHelperText id="updated_title_error">{updated_title_error}</FormHelperText>
                    </FormControl>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseEditListTitleModal}>{Language['Cancel']}</Button>
                    <Button onClick={() => update_title(updated_title)}>{Language['Update']}</Button>
                    </DialogActions>
                    </Dialog>


                    

                    

                    {/* new */}
                    {/* //import contacts  modal */}
                    <Dialog open={openImportModal} onClose={handleCloseImportModal} fullWidth={true} maxWidth={'md'}>
                        <DialogTitle style={{alignContent: 'center'}}>{Language['Import New Contacts']}   </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            {Language['Add your contacts.']}
                          </DialogContentText>

                          <FormControl error={isErrorImportContacts} variant="standard" style={{width: '100%', marginTop: '0px'}}>
                          <TextField
                              error={isErrorImportContacts}
                              aria-describedby="message_error"
                              style={{width: '100%', marginTop: '0px'}}
                              id="standard-multiline-static"
                              //label="Contacts"
                              multiline
                              rows={7}
                              variant="standard"
                              placeholder={`Example:\nsociety1,society1@gmail.com\nsociety2,society2@gmail.com`}
                              onChange={ (e) => {
                                setImportContacts(e.target.value)
                                setIsErrorImportContacts(false)
                                setImportContactsError('')
                              }
                              }
                          />
                              <FormHelperText id="message_error">{importContactsError}</FormHelperText>
                          </FormControl>

                          <p style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                          <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                          <span style={{color:'gray', margin: '0px 10px', marginBottom: '5px'}}>{Language['or']}</span>
                          <span style={{width: '50%', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                        </p>

                        <Dragger {...props} disabled={uploadCsvBtnDisabled}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{Language['Click or drag file to this area to upload']}</p>
                        <p className="ant-upload-hint">
                        {Language['Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files']}
                        </p>
                      </Dragger>
                      <span style={{color:'gray', margin: '0px 10px 0px 10px', marginBottom: '0px', paddingTop: '0px'}}>{Language['Download our']} <span style={{textDecorationLine: 'underline', cursor: 'pointer'}} onClick={()=> downloadCSV(CSVInit, 'CSV Template')} >{Language['CSV template']}</span>  {Language['to import your contacts easily.']}</span>



                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseImportModal}>{Language['Cancel']}</Button>
                          <Button onClick={() => importMyContacts(importContacts)}>{Language['Import']}</Button>
                        </DialogActions>
                    </Dialog>

                    {/* new */}
                    {/* //update contact  modal */}
                    <Dialog open={openUpdateContactModal} onClose={handleCloseUpdateContactModal}>
                    <DialogTitle style={{alignContent: 'center'}}>{Language['Update Contact']}  </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                      {Language['fill the field below then press update, this action will update this contact according to this information.']}
                    </DialogContentText>


                    <FormControl error={is_error_update_first_name} variant="standard" style={{width: '100%', marginTop: '20px'}}>
                        <InputLabel htmlFor="title">{Language['First Name']} </InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}}}
                          onChange={(e) => {
                            set_update_first_name(e.target.value);
                            set_is_error_update_first_name(false)
                            set_update_first_name_error('')
                          }}
                          type='text'
                          id="title"
                          defaultValue={update_first_name}
                          aria-describedby="title_error"
                        />
                        <FormHelperText id="title_error">{update_first_name_error}</FormHelperText>
                    </FormControl>

                    <FormControl error={is_error_update_family_name} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="family_name">{Language['Family Name']} </InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}}}
                          onChange={(e) => {
                            set_update_family_name(e.target.value);
                            set_is_error_update_family_name(false)
                            set_update_family_name_error('')
                          }}
                          type='text'
                          id="family_name"
                          defaultValue={update_family_name}
                          aria-describedby="family_name_error"
                        />
                        <FormHelperText id="family_name_error">{update_family_name_error}</FormHelperText>
                    </FormControl>



                    <FormControl error={is_error_update_email} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="email">{Language['Email']} *</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}}}
                          onChange={(e) => {
                            set_update_email(e.target.value);
                            set_is_error_update_email(false)
                            set_update_email_error('')
                          }}
                          type='text'
                          id="email"
                          defaultValue={update_email}
                          aria-describedby="email_error"
                        />
                        <FormHelperText id="email_error">{update_email_error}</FormHelperText>
                    </FormControl>


                    <FormControl error={is_error_update_phone_number} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="phone_number">{Language['Phone Number']} </InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}}}
                          onChange={(e) => {
                            set_update_phone_number(e.target.value);
                            set_is_error_update_phone_number(false)
                            set_update_phone_number_error('')
                          }}
                          type='text'
                          id="phone_number"
                          defaultValue={update_phone_number}
                          aria-describedby="phone_number_error"
                        />
                        <FormHelperText id="phone_number_error">{update_phone_number_error}</FormHelperText>
                    </FormControl>





                    <FormControl error={is_error_update_society} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="society">{Language['Society']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}}}
                          onChange={(e) => {
                            set_update_society(e.target.value);
                            set_is_error_update_society(false)
                            set_update_society_error('')
                          }}
                          type='text'
                          id="society"
                          defaultValue={update_society}
                          aria-describedby="society_error"
                        />
                        <FormHelperText id="society_error">{update_society_error}</FormHelperText>
                    </FormControl>



                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseUpdateContactModal}>{Language['Cancel']}</Button>
                    <Button onClick={() => update_Contact(update_id, update_first_name, update_family_name, update_email, update_phone_number, update_society)}>{Language[update_contact_btn_content] ? Language[update_contact_btn_content] : update_contact_btn_content}{update_contact_btn_content}</Button>
                    </DialogActions>
                    </Dialog>
                      
                    
                    {/* //config import users  modal */}
                    <Dialog open={openImportConfigModal} onClose={handleCloseImportConfigModal}>
                      <DialogTitle style={{alignContent: 'center'}}>{Language['Configrations of the imported file']}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                        {Language['last step before uploading your contacts, choose the right columns then click import']}
                        </DialogContentText>

                        <p style={{textAlign: 'right', width: "100%", float: 'right', margin: '0px', padding: '0px'}}>{Language['update existing items according the phone number']} <Switch {...label} defaultChecked onChange={(e) => { setImportAndUpdate(e.target.checked)}} /></p>

                        <FormControl sx={{ m: 1, minWidth: "100%" }} >
                          <InputLabel id="demo-select-small">{Language['Society']}</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={import_society}
                            label={Language['Society']}
                            onChange={(e) => handleChange(e, 'society')}
                          >
                            <MenuItem value="">
                              <em>{Language['None']}</em>
                            </MenuItem>
                            {
                              importConfig.map((item, index) => 
                                (<MenuItem value={index} key={index}>{item}</MenuItem>)
                              )
                            }
                          </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: "100%" }} >
                          <InputLabel id="demo-select-small1">{Language['First Name']}</InputLabel>
                          <Select
                            labelId="demo-select-small1"
                            id="demo-select-small1"
                            value={import_first_name}
                            label={Language['First Name']}
                            onChange={(e) => handleChange(e, 'firstName')}
                          >
                            <MenuItem value="">
                              <em>{Language['None']}</em>
                            </MenuItem>
                            {
                              importConfig.map((item, index) => 
                                (<MenuItem  value={index} key={index}>{item}</MenuItem>)
                              )
                            }
                          </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: "100%" }} >
                          <InputLabel id="demo-select-small">{Language['Family Name']}</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={import_family_name}
                            label={Language['Family Name']}
                            onChange={(e) => handleChange(e, 'familyName')}
                          >
                            <MenuItem value="">
                              <em>{Language['None']}</em>
                            </MenuItem>
                            {
                              importConfig.map((item, index) => 
                                (<MenuItem value={index} key={index}>{item}</MenuItem>)
                              )
                            }
                          </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: "100%" }} >
                          <InputLabel id="demo-select-small">{Language['Phone Number']}</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={import_phone_number}
                            label={Language['Phone (obligated)']}
                            onChange={(e) => handleChange(e, 'phoneNumber')}
                          >
                            <MenuItem value="">
                              <em>{Language['None']}</em>
                            </MenuItem>
                            {
                              importConfig.map((item, index) => 
                                (<MenuItem value={index} key={index}>{item}</MenuItem>)
                              )
                            }
                          </Select>
                        </FormControl>


                        <FormControl sx={{ m: 1, minWidth: "100%" }} >
                          <InputLabel id="demo-select-small">{Language['Email']} *</InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={import_email}
                            label={Language['Email']}
                            onChange={(e) => handleChange(e, 'email')}
                          >
                            <MenuItem value="">
                              <em>{Language['None']}</em>
                            </MenuItem>
                            {
                              importConfig.map((item, index) => 
                                (<MenuItem value={index} key={index}>{item}</MenuItem>)
                              )
                            }
                          </Select>
                        </FormControl>


                      
                            
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseImportConfigModal}>{Language['Cancel']}</Button>
                        <Button disabled={disabledSaveContactsBtn} onClick={() => import_contacts( import_list_contact, import_first_name, import_family_name, import_phone_number, import_email, import_society, importAndUpdate )}>{Language[create_contact_btn_content] ? Language[create_contact_btn_content] :create_contact_btn_content }{create_contact_btn_content}</Button>
                      </DialogActions>
                  </Dialog>
                   

                    {/* new */}
                    {/* alert delete list */}
                    <Dialog
                      fullWidth={true} maxWidth={'xs'}
                      open={openDeleteContactModal}
                      onClose={handleCloseDeleteContactModal}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                      {Language['Delete confirmation']}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        {Language['Are you sure to delete this']} {deleteTarget} ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseDeleteContactModal}>{Language['Cancel']}</Button>
                        <Button onClick={() => deleteItems(deleteTarget)} autoFocus>
                        {Language['Delete']}
                        </Button>
                      </DialogActions>
                    </Dialog>


                    {/* new */}
                    {/* alert remove contacts from list list */}
                    <Dialog
                      fullWidth={true} maxWidth={'xs'}
                      open={openRemoveContactModal}
                      onClose={handleCloseRemoveContactModal}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                      {Language['Remove Contacts']}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        {Language['Are you sure to remove the contacts from this list ?']}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseRemoveContactModal}>{Language['Cancel']}</Button>
                        <Button onClick={() => RemoveItems()} autoFocus>
                        {Language[RemoveContactModalBtn] ? Language[RemoveContactModalBtn] :RemoveContactModalBtn}
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <AlertComp open={openAlert} handleClose={handleClose}  message={Language[alertMessage]} type={alertType} Language={Language} />         
          

        </Body>
      )
    }else{
      return(
        <Body>
          <ErrorPage 
            MyIcon={<ErrorOutlineIcon ></ErrorOutlineIcon>} 
            title={Language['List not found!']} 
            desc={Language['this item may has been deleted, or the url is wrong. check your ']} 
            url={"/app/EmailMarketing/mailing_lists"}
            urlDesc={"mailing lists"}
          ></ErrorPage>
        </Body>
      )
    }

  
}
