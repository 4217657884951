import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import Divider from '@mui/material/Divider';
import { getResponseMessage } from '../responding';
import { AccountBalanceWalletOutlined } from '@mui/icons-material';
import moment from 'moment-timezone';
import colors from '../../colors';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`

const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 40%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    min-height: calc(100vh - 122px - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    padding-bottom: 25px;
    @media only screen and (max-width: 850px) {
    }
`
const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 0px 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    margin-top: 25px;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`


const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 10px;
`




export default function Config({handleHeader, Language}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)

  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [source, setSource] = useState('initialState');
  const [isPasswordError, setisPasswordError] = useState(false);
  const [isUsernameError, setisUsernameError] = useState(false);
  const [isSourceError, setIsSourceError] = useState(false);
  const [usernameerror, setusernameerror] = useState('');
  const [passwordError, setpasswordError] = useState('');
  const [sourceError, setSourceError] = useState('');
  const [saveBtnContent, setsaveBtnContent] = useState('save');
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [senders, setSenders] = useState([]);

  
  const navigate = useNavigate()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  function deleteConfig(id){

    axios.post("/delete_senders", {
      id
    })
    .then(function (response) {
      if(response.data.res === 'done'){
            setSenders(response.data.senders)
            setAlertMessage( 'Deleted successfuly.')
            setAlertType('success')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
        // show data
      }
      else if(response.data.res === 'server_error'){
            setAlertMessage('Internal server error')
            setAlertType('error')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
        // redirect to server error page
      }
      else if(response.data.res === 'redirect'){
        navigate('/login', { replace: true });
      }
    })
    .catch(function (error) {
      console.log('this is axios error');
      console.log(error)
      if(error.response){
        if(error.response.status === 401 && error.response.data.response === 'banned'){
          navigate('/app/banned', { replace: true });
        }else{
          setOpenAlert(true)
          setAlertMessage(getResponseMessage(error.response.status))
          setAlertType("error")
        }
      }else{
        setOpenAlert(true)
        setAlertMessage('An unexpected error has occured')
        setAlertType("error")
      }
    })
  }
    
  function setUserConfig(user, password, source){
    setDisabledBtn(true)
    setsaveBtnContent(<CircularProgress style={{ width: '15px', height:'15px', color: "white",  marginLeft: '10px'}}></CircularProgress>)
    setisPasswordError(false)
    setisUsernameError(false)
    setpasswordError('')
    setusernameerror('')
    axios.post("/set_user_config", {
      user, password, source
    })
    .then(function (response) {
      setDisabledBtn(false)
      setsaveBtnContent('save')
      console.log(response.data)
      if(response.data.res === 'done'){
            setSenders(response.data.senders)
            setAlertMessage( 'Saved successfuly')
            setAlertType('success')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
        // show data
      }
      else if(response.data.res === 'server_error'){
            setAlertMessage('Internal server error')
            setAlertType('error')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
        // redirect to server error page
      }
      else if(response.data.res === 'input_error'){
        // redirect to server error page
        for( let i = 0; i < response.data.error.length; i++){
          console.log(response.data.error[i])
          if(response.data.error[i].path === 'user'){
            setusernameerror(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
            setisUsernameError(true)
          }
          else if(response.data.error[i].path === 'password'){
            setpasswordError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
            setisPasswordError(true)
          }
          else if(response.data.error[i].path === 'source'){
            setSourceError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
            setIsSourceError(true)
          } 
        }
      }
      else if(response.data.res === 'redirect'){
        navigate('/login', { replace: true });
      }
    })
    .catch(function (error) {
      setDisabledBtn(false)
      // handle error
      setsaveBtnContent('save')
      console.log('this is axios error');
      console.log(error)
      if(error.response){
        if(error.response.status === 401 && error.response.data.response === 'banned'){
          navigate('/app/banned', { replace: true });
        }else{
          setOpenAlert(true)
          setAlertMessage(getResponseMessage(error.response.status))
          setAlertType("error")          
        }
      }else{
        setOpenAlert(true)
        setAlertMessage('An unexpected error has occured')
        setAlertType("error")
      }
    })
  }

  function getUserConfig(){
    axios.post("/get_senders")
    .then(function (response) {
      console.log(response.data)
      if(response.data.res === 'done'){
            // show data
            setSenders(response.data.senders)
      }
      else if(response.data.res === 'server_error'){
            setAlertMessage('Internal server error')
            setAlertType('error')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
        // redirect to server error page
      }
      else if(response.data.res === 'redirect'){
        navigate('/login', { replace: true });
      }
    })
    .catch(function (error) {
      // handle error
      console.log('this is axios error');
      console.log(error)
      if(error.response){
        if(error.response.status === 401 && error.response.data.response === 'banned'){
          navigate('/app/banned', { replace: true });
        }else{
          setOpenAlert(true)
          setAlertMessage(getResponseMessage(error.response.status))
          setAlertType("error")          
        }
      }else{
        setOpenAlert(true)
        setAlertMessage('An unexpected error has occured')
        setAlertType("error")
      }
    })
  }


  useEffect(() => {
    handleHeader('config')
    getUserConfig()

  }, []);

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>{Language['Configurations']}</H1>
            </HeaderTitle>
        </Header>
        <BodyContent>
            
            <Form  style={{display: 'none'}}>
            <H1 style={{margin: '10px 0px', width: "max-content"}}>{Language['SMS configuration']}</H1>

            <FormControl error={isSourceError} variant="standard" style={{width: '100%', marginTop: '10px'}}>
                <InputLabel htmlFor="user">{Language['Source']} *</InputLabel>
                <Input
                  onKeyDown={ (event) => { if (event.key === 'Enter') { setUserConfig(username, password, source)}}}
                  onChange={(e) => {
                    setSource(e.target.value)
                    setIsSourceError(false)
                    setSourceError('')
                  }}
                  type='text'
                  id="user"
                  defaultValue=""
                  aria-describedby="user_error"
                />
                <FormHelperText id="user_error">{sourceError}</FormHelperText>
            </FormControl>


            <FormControl error={isUsernameError} variant="standard" style={{width: '100%', marginTop: '10px'}}>
                    <InputLabel htmlFor="user">{Language['Username']} *</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { setUserConfig(username, password, source)}}}
                      onChange={(e) => {
                        setusername(e.target.value)
                        setisUsernameError(false)
                        setusernameerror('')
                      }}
                      type='text'
                      id="user"
                      defaultValue=""
                      aria-describedby="user_error"
                    />
                    <FormHelperText id="user_error">{usernameerror}</FormHelperText>
                </FormControl>

                <FormControl error={isPasswordError} variant="standard" style={{width: '100%', marginTop: '10px'}}>
                    <InputLabel htmlFor="password">{Language['Password']} *</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { setUserConfig(username, password, source)}}}
                      onChange={(e) => {
                        setpassword(e.target.value)
                        setisPasswordError(false)
                        setpasswordError('')
                      }}
                      type='password'
                      id="password"
                      defaultValue=""
                      aria-describedby="password_error"
                    />
                    <FormHelperText id="password_error">{passwordError}</FormHelperText>
                </FormControl>

                <BtnContainer>
                    <Tooltip title='Save Icosnet Confirgurations' >
                        <Button variant="contained" disabled={disabledBtn}  style={{marginLeft: '10px', fontSize: "small", backgroundColor: colors.primary}} onClick={() => { setUserConfig(username, password, source) }} >{Language[saveBtnContent] ? Language[saveBtnContent] : saveBtnContent}  {saveBtnContent} </Button>
                    </Tooltip>

                </BtnContainer>
            </Form>

            <Form style={{minHeight: '200px'}}>
                <Grid item xs={12} md={6}>
                    <H1 style={{margin: '10px 0px', width: "max-content"}}>{Language['SMS senders']}</H1>
                    <List dense={false}>
                      {
                        senders.map((item) => {
                          var date = moment(item.createdAt).tz('Africa/Algiers').format()
                          var displayedDate = date.slice(0,10) + '   ' + date.slice(11,16)
                          return (
                          <>
                          <ListItem
                            secondaryAction={
                              <>
                                <Button startIcon={<AccountBalanceWalletOutlined />} style={{color: colors.primary, borderColor: colors.primary}} variant='outlined' color='info'>
                                  {item.credit} sms
                                </Button>
                              </>
                              
                              // <IconButton style={{display: 'none'}} disabled onClick={(e, primary) => deleteConfig(item.id)} edge="end" aria-label="delete">
                              //   <DeleteIcon  />
                              // </IconButton>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <LabelImportantIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={item.source}
                              secondary={displayedDate}
                            />
                          </ListItem>
                          <Divider />

                          </>
                        )
                          
                        }
                            
                        )
                      }
                    </List>
                </Grid>
            </Form>
        </BodyContent>

          {/* // snackbar */}
          <Snackbar
            open={openAlert}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
              {Language[alertMessage] ? Language[alertMessage] : alertMessage}
            </Alert>
          </Snackbar>
    </Body>
  )
}
