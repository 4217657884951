import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import WidgetsIcon from '@mui/icons-material/Widgets';
//import Contacts from './contacts';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ContactsComp from '../serviceSMS/contacts';
import { getResponseMessage } from '../responding';
import { HeadsetMic, Language } from '@mui/icons-material';
import Feedback from '../feedback';
import colors from '../../colors';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Home = styled.div`
    width: 100µ;
`


const Header = styled.div`
    width: 90%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${colors.primary};
    padding: 10px 5%;
    align-items: center;
    
`
const HeaderLogo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const HeaderTitle = styled.h1`
    margin: 5px;
    padding: 0px;
    font-size: larger;
    color: white;
    font-weight: 400;
`


const HeaderLinks = styled.div`
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media only screen and (max-width: 850px) {
        display: none;
    }
`

const HeaderLink = styled(Link)`
    text-decoration-line: none;
    padding:  10px;
    margin: 0px 10px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color: ${colors.secondary};
    }
    @media only screen and (max-width: 850px) {
        color: #353535;
        width: 100% !important;
        display: flex;
    }
`
const PersonIconStyled = styled(PersonIcon)`
    color: white;
    &:hover {
        cursor: pointer;
    }
    @media only screen and (max-width: 850px) {
        display: none !important;
    }
`




const Title1 = styled.h1`
    font-size: xx-large;
    margin: 0%;
    padding: 0%;

`

const Title2 = styled(Title1)`
    font-size: large;
    padding: 5px 5px 0px 15px;
`
const Title4 = styled(Title1)`
    font-size: small;
    padding: 5px 5px 20px 15px;
    color: gray;
    font-weight: 400;
`

const Name = styled.h1`
  font-size: large;
  color: black;
  padding: 10px 10px 3px 10px ;
  margin: 0px;
  margin-top: 10px;
  font-weight: 400;
  text-align: center;

`

const Email = styled.p`
  font-size: small;
  color: grey;
  padding: 0px 10px;
  margin: 0px;
  margin-bottom: 10px;
  text-align: center;
`

const MainBodyContent = styled.div`
    width: 100%;
    height: max-content;
`

const SideBarIcon = styled(ViewStreamIcon)`
    font-size: large;
    color: white;
    //cursor: pointer;
    //pointer-events: visiblePainted;
    display: none !important;
    padding: 10px;
    @media only screen and (max-width: 850px) {
        display: inline-block !important;
        pointer-events: visible;
    }
`
const Center = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`
const MyAvater = styled(Avatar)`
  font-size: 30px !important;
  width: 80px !important;
  height: 80px !important;
`

const SupportIcon = styled(HeadsetMic)`
    color: white;
    font-size: 26px !important;
    margin-right: 5px;
    &:hover {
        cursor: pointer;
    }
    @media only screen and (max-width: 850px) {
        display: none !important;
    }
`


export default function Contacts({Language}) {

    const navigate = useNavigate()
    const [ loading, setLoading] = useState(true)
    const [ serverError, setServerError] = useState(false)
    const [ userName, setUserName] = useState(' your name')
    const [ email, setEmail] = useState('user@gmail.com')
    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [openAlert, setOpenAlert] = React.useState(false);
    const [firstName, setFirstName] = useState('');
    const [familyName, setFamilyName] = useState('');
  
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenAlert(false);
    };

    function handleHeader(item){
      var items = []
      items = document.querySelectorAll('.clicked_link')
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        element.classList.remove('clicked_link')
        
      }
      // if(items.length > 0){
      //   items.map((item) => {
      //     item.classList.remove('clicked_link')
      //   })
      // }
      const myItem = '.' + item
      console.log("myElement is : ",myItem)
      const myElement = document.querySelector(myItem)
      if(myElement){
             console.log("myElement is : ",myElement)
             myElement.classList.add('clicked_link')
      }
      
    }
    
    // mui config
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (link) => {
        if(link === 'account'){
            navigate('/app/profile');
        }else if(link === 'logout'){
              // get data from database
                axios.post("/logout")
                .then(function (response) {
                    console.log(response.data)

                    if(response.data.res === 'done'){
                        navigate('/login', { replace: true });
                    }else if(response.data.res === 'redirect'){
                        navigate('/login', { replace: true });
                    }
                    else{
                        setServerError(true)
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    if(error.response){
                        setOpenAlert(true)
                        setAlertMessage(getResponseMessage(error.response.status))
                        setAlertType("error")
                      }else{
                        setOpenAlert(true)
                        setAlertMessage('Internal server error')
                        setAlertType("error")
                      }
                })
        }
        setAnchorEl(null);
    };

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

      const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >

          <Center>
            <MyAvater>{firstName[0]}{familyName[0]}</MyAvater>
          </Center>
          <Name> {firstName} {familyName}</Name>
          <Email>{email}</Email>
          <List>
              
          </List>
          <Divider />
          <List>
              <ListItem disablePadding >
                <HeaderLink className='headerLink' to={'/app'}>
                    <ListItemIcon>
                        <WidgetsIcon />
                    </ListItemIcon>
                    {Language['Apps']} </HeaderLink>
              </ListItem>
              <ListItem disablePadding >
                <HeaderLink className='headerLink' to={'profile'}>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    {Language['Profile']}</HeaderLink>
              </ListItem>
              <ListItem disablePadding >
                <HeaderLink className='headerLink' onClick={ () => handleClose('logout')}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    {Language['Logout']}</HeaderLink>
              </ListItem>
              
          </List>
        </Box>
      );
    /* end mui config */
  
    function handleHeader(item){
      var items = []
      items = document.querySelectorAll('.clicked_link')
      console.log(items)
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        element.classList.remove('clicked_link')
        
      }
      
      const myItem = '.' + item
      console.log(myItem)
      const myElement = document.querySelector(myItem)
      if(myElement){
             myElement.classList.add('clicked_link')
      }
      
    }

    let location = useLocation().pathname.split('/')
    useEffect(() => {
      console.log('location', location[location.length-1])
      handleHeader(location[location.length-1])
    }, [ useLocation() ]);


  

    useEffect(() => {
        // get data from database
        axios.post("/check_login")
        .then(function (response) {
          console.log(response.data)
          if(response.data.redirect === false){
            setLoading(false)
            setUserName(response.data.user.first_name + ' '+ response.data.user.family_name)
            setFirstName(response.data.user.first_name)
            setFamilyName(response.data.user.family_name)
            setEmail(response.data.user.email)
            console.log(userName)
          }
          else if(response.data.result === 'redirect'){
            navigate('/app', { replace: true });
          }
          else{
            navigate('/login', { replace: true });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          if(error.response){
            if(error.response.status === 401 && error.response.data.response === 'banned'){
              navigate('/app/banned', { replace: true });
            }else{
              setOpenAlert(true)
              setAlertMessage(getResponseMessage(error.response.status))
              setAlertType("error")
            }
          }else{
            setOpenAlert(true)
            setAlertMessage('Internal server error')
            setAlertType("error")
          }
        })
      
      }, [])


    if(loading){
        return(
            <Loading></Loading>
        )
    }else{
        return (
            <Home>
                <Header>
                    <HeaderLogo>
                        <Link to={'/app'}><WidgetsIcon style={{ color: 'white'}} sx={{ fontSize: 50 }} /></Link>
                        
                        <HeaderTitle >{Language['Contacts']}</HeaderTitle>
                    </HeaderLogo>
                    <HeaderLinks className='headerLinks'>
                        <HeaderLink to={''}  className={"contacts clicked_link"}>{Language['Contacts']}</HeaderLink>
                    </HeaderLinks>

                    <SideBarIcon onClick={toggleDrawer('left', true)}>{'left'}</SideBarIcon>
                    
                    <Drawer
                        anchor={'left'}
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                    >
                        {list('left')}
                    </Drawer>
        
                    
                    <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', alignItems: 'center'}}>
                      <SupportIcon 
                            className='headerUserIcon'
                            fontSize="large"
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={()=>setOpenFeedbackModal(true)}
                    ></SupportIcon>
                    <PersonIconStyled 
                            className='headerUserIcon'
                            fontSize="large"
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                    ></PersonIconStyled>
                    </div>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => handleClose()}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            
                            <Title2 style={{width: '300px'}}>{userName} </Title2>
                            <Title4>{email}</Title4>
                            {/* <MenuItem onClick={handleClose}></MenuItem> */}
                            <MenuItem onClick={() => handleClose('account')} >{Language['My account']}</MenuItem>
                            <MenuItem onClick={ () => handleClose('logout')}>{Language['Logout']}</MenuItem>
                        </Menu>
                </Header>
        
                <MainBodyContent>
                  <ContactsComp Language={Language} handleHeader={handleHeader}></ContactsComp>
                        {/* <Routes> */}
                            {/* <Route exact path='/' element={<HomeSend setPage={setPage} handleHeader={handleHeader} />} ></Route>
                            <Route exact path='/contacts' element={<Contacts setPage={setPage} handleHeader={handleHeader} />} ></Route>
                            <Route exact path='/reporting' element={<Reporting setPage={setPage} handleHeader={handleHeader} />} ></Route>
                            <Route exact path='/config' element={<Config setPage={setPage} handleHeader={handleHeader} />} ></Route>
                            <Route exact path='/profile' element={<Profile setPage={setPage}  handleHeader={handleHeader} handleLogout={handleClose} setUserName={setUserName} userName={userName} setEmail={setEmail} email={email} familyNameG={familyName} setFamilyNameG={setFamilyName} firstNameG={firstName} setFirstNameG={setFirstName} />} ></Route> */}
                            {/* <Route axact path="/*" element={ < NotFound /> }  ></Route> */}
                        {/* </Routes> */}
                </MainBodyContent>
                <Snackbar
                  open={openAlert}
                  autoHideDuration={4000}
                  onClose={handleCloseAlert}
                >
                  <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
                    {Language[alertMessage] ? Language[alertMessage] : alertMessage}
                  </Alert>
                </Snackbar>
                <Feedback open={openFeedbackModal} setOpen={setOpenFeedbackModal}></Feedback>

            </Home>
          )
    }

  
}
