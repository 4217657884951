import React from 'react'
import styled from 'styled-components'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import colors from '../colors';

const Body = styled.div`
    width: calc(100% - 20px);
    height: 100vh;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center
`

const Title = styled.h1`
    width: 100%;
    text-align: center;
    font-size: 50px;
    color: ${colors.primary};
    margin: 20px 0px;
    padding: 0px;
`

const Desc = styled.p`
    width: 100%;
    text-align: center;
    font-size: large;
    color: #000000d0;
    margin: 5px 0px;
    padding: 0px;
`

const MyIcon = styled(ErrorOutlineIcon)`
    align-self: center;
    font-size: 100px !important;
    color: ${colors.primary};
    margin: 5px 0px;
    padding: 0px;
`


export default function NotFound({Language}) {
  return (
    <Body>
        <MyIcon></MyIcon>
        <Title>{Language['URL Not Found!'] ? Language['URL Not Found!'] : 'URL Not Found!' }</Title>
        <Desc>{Language['The requested url not found, it seems like it changed or deleted.'] ? Language['The requested url not found, it seems like it changed or deleted.'] : 'The requested url not found, it seems like it changed or deleted.' }</Desc>
    </Body>
  )
}
