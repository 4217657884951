import React, {useState, useEffect} from 'react'
import axios from 'axios';
import styled from 'styled-components'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { getResponseMessage } from './responding';
import colors from '../colors';



const MyLink = styled(Link)`
    //text-decoration-line: none;
    color: black;
`


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Body = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: row;
`;

const BodyLogin = styled.div`
  width: 50%;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 900px) {
      width: 100% ;
      
    }
`;
const BodyLoginContent = styled.div`
  width: 60%;
  min-height: 300px;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  height: 80%;
  margin-top: 20%;
  max-width: 400px;
  padding: 10px;
  @media only screen and (max-width: 900px) {
      width: 80% ;
      margin-top: 20%;
      background-color: white;
    }
`;

const BodyImage = styled.div`
  width: 42%;
  padding: 15px 4%;
  height: calc(100vh - 30px);
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center ;
  //background-image: url("/assets/img/login6.png") ;
  background-position: center;
  background-size: cover;
  background-color: ${colors.primary};
  @media only screen and (max-width: 900px) {
      display:none ;
    }
`;


const Title = styled.h1`
  font-size: x-large;
  padding: 0px 0px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 0%;
`;

const Paragr = styled.p`
  margin: 0%;
  padding: 10px 0px;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  width: 120px;
  height: auto;
  align-self: center;
  
`;
// import logo from './assets/img/logo192.png'

export default function InitInfo({setOpenTermsModal}) {

  const [btnContent, setBtnContent] = useState('save')
  const [isFirstNameError, setIsFirstNameError] = useState(false)
  const [isFamilyNameError, setIsFamilyNameError] = useState(false)
  const [firstNameError, setFirstNameError] = useState('')
  const [familyNameError, setFamilyNameError] = useState('')
  const [firstName, setFirstName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [disabledBtn, setDisabledBtn] = useState(false);

  function createAccount(familyName, firstName){
    setBtnContent(<CircularProgress style={{color: 'white', width: '30px', height:'30px'}}></CircularProgress>)
    setIsFirstNameError(false)
    setIsFamilyNameError(false)
    setFirstNameError('')
    setFamilyNameError('')
    setDisabledBtn(true)

    axios.post("/init_info", {
      familyName, firstName
    })
        .then(function (response) {
          // handle success
          //setSousCategory(response.data.souscategories)
          setDisabledBtn(false)
          setBtnContent('save')
          console.log(response.data)
          if(response.data.result === "done"){
            setOpen(true)
            setAlertMessage(response.data.message)
            setAlertType("success")
            setTimeout(() => {
              navigate('/app', { replace: true });
            }, 1000);
          }
          else if(response.data.result === "input_error") {
            for(let i = 0; i < response.data.error.length; i++){
              if(response.data.error[i].path === 'first_name'){
                setIsFirstNameError(true)
                setFirstNameError(response.data.error[i].error)
              }
              else{
                setIsFamilyNameError(true)
                setFamilyNameError(response.data.error[i].error)
              }
            }
          }
          else if(response.data.res === "server_error") {
            setOpen(true)
            setAlertMessage(response.data.message)
            setAlertType("error")
          }
          else{
            setOpen(true)
            setAlertMessage('session ended')
            setAlertType("error")
            setTimeout(() => {
              navigate('/login', { replace: true });
            }, 2000);
          }
          
        })
        .catch(function (error) {
          setDisabledBtn(false)
          // handle error
          console.log(error);
          if(error.response){
            setOpen(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")
          }else{
            setOpen(true)
            setAlertMessage('internal server error')
            setAlertType("error")
          }

        })
    
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  


  useEffect(() => {
    // get data from database
    axios.post("/is_init_account")
    .then(function (response) {
      console.log(response.data)
      if(response.data.result === "done"){
        setLoading(false)
      }
      else if(response.data.result === 'redirect'){
        navigate('/app', { replace: true });
      }
      else{
        navigate('/login', { replace: true });
      }
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      if(error.response){
        setOpen(true)
        setAlertMessage(getResponseMessage(error.response.status))
        setAlertType("error")
      }else{
        setOpen(true)
        setAlertMessage('internal server error')
        setAlertType("error")
      }
    })
  
  }, [])

 
if(!loading) {
  return (
    <Body>
        <BodyLogin>
            <BodyLoginContent id='BodyLoginContent' >
                <Logo src={"./assets/img/logo192.png"} />
                
                <Title>Last Step!</Title>
                <Paragr style={{width: "100%", textAlign: 'left', color: 'grey'}} >Add your personal information.</Paragr>

                <FormControl error={isFirstNameError} variant="standard">
                    <InputLabel htmlFor="first_Name">First name</InputLabel>
                    <Input
                    onKeyDown={ (event) => { if (event.key === 'Enter') { createAccount(familyName, firstName)}}}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setIsFirstNameError(false)
                      setFirstNameError('')
                    }}
                    id="first_Name"
                    defaultValue=""
                    aria-describedby="first_Name_error"
                    />
                    <FormHelperText id="first_Name_error">{firstNameError}</FormHelperText>
                </FormControl>

                <FormControl error={isFamilyNameError} variant="standard">
                    <InputLabel htmlFor="family_Name">Family name</InputLabel>
                    <Input
                    onKeyDown={ (event) => { if (event.key === 'Enter') { createAccount(familyName, firstName)}}}
                    onChange={(e) => {
                      setFamilyName(e.target.value);
                      setFamilyNameError('')
                      setIsFamilyNameError(false)
                    }}
                    id="family_Name"
                    defaultValue=""
                    aria-describedby="family_Name_error"
                    />
                    <FormHelperText id="family_Name_error">{familyNameError}</FormHelperText>
                </FormControl>

                <Button variant="contained" disableElevation disabled={disabledBtn}  style={{marginTop: '10px', backgroundColor: colors.primary}} onClick={() => createAccount(familyName, firstName)}>
                      {btnContent}
                </Button>
                
                <Snackbar
                  open={open}
                  autoHideDuration={4000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                  </Alert>
                </Snackbar>
            </BodyLoginContent>
        </BodyLogin>
        <BodyImage>
          <Logo style={{alignSelf: 'center', width: '70px'}} src={"./assets/img/logo192white.png"} />
          <div style={{justifyContent: 'center'}}>
            <Title style={{color: 'white', fontSize: '40px', textAlign: 'left', width: '100%', marginTop: '0px'}}>Let us help you grow your business</Title>
            <Paragr style={{color: '#ffffffcc', fontSize: '17px', width: "100%"}}>We offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.</Paragr>
          </div>
          <>
          <Paragr style={{color: 'white', fontSize: '15px', width: "100%", textAlign:'center', margin: 0}}><MyLink style={{color: "white"}} onClick={()=> setOpenTermsModal(true)} >Our Conditions & Terms.</MyLink></Paragr>
          </>
        </BodyImage>
    </Body>
  )
}
 
}
