const colors = {
    primary: '#d6001c',
    primaryDisabled: '#5449ce83',
    secondary: '#a10419',
    secondary2: '#F7B3CC',
    secondaryBG: '#060042',
    darkGray: '#5A5A5A',
    gray: '#D1D3D4',
    yellow: '#FFF572',
    orange: '#FEBD3D',
}


export default colors