import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Editor from '../editor';
import MyQuillEditor from './textEditor';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { Box, Typography } from '@mui/material';
import { LoadingButton, TabContext, TabList } from '@mui/lab';
import { AttachFile, Close, Code, Edit, RemoveRedEye, ScheduleSend, Send, SendAndArchive, SendOutlined } from '@mui/icons-material';
import colors from '../../colors';
import MyQuillEditorAdvanced from './textEditor';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: end;

    }
`

const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 100%;
    }
`
const BodyContent = styled.div`
    width: 100%;
    background-color: #f6f7fa;
    //min-height: 320px;
    min-height: calc(100vh - 115px);
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: start;
`
const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 25px 5%;
    background-color: white;
    min-height: 80%;
    height: max-content;
    min-height: 300px;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
`

const HeaderLink = styled(Link)`
    cursor: pointer;
    color: white;
`

const textareaStyle = {
  fontFamily: 'monospace',
  fontSize: '14px',
  width: ' calc(100% - 16px)',
  minHeight: '200px',
  border: '1px solid #ccc',
  padding: '8px',
  resize: 'vertical', // Allow vertical resizing
  overflow: 'auto', // Add scrollbar if content exceeds dimensions
  whiteSpace: 'pre-line'
};

export default function HomeSendEmail({djezzyCosts, mobilisCosts, ooredooCosts, handleError, userName, DraggedElements, setDraggedElements, mycode, setMycode, handleHeader, Language}) {


    // mui config

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const [openCreateQuickMailingModal, setOpenCreateQuickMailingModal] = React.useState(false);
    const handleCloseCreateQuickMailingModal = () => {
        setOpenCreateQuickMailingModal(false);
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpenPragrammeMessageModal = () => {
        setOpen(true);
    };
    const handleClosePragrammeMessageModal = () => {
        setOpen(false);
    };


    
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenAlert(false);
    };


    // end mui config

    const navigate = useNavigate()

    const [time, setTime] = useState('');
    const [repeted, setRepeted] = useState('no-repeat');
    const [hour, setHour] = useState('00');

    const [isErrorTime, setIsErrorTime] = useState(false);
    const [isErrorRepeted, setIsErrorRepeted] = useState(false);
    const [isErrorHour, setIsErrorHour] = useState(false);


    const [repetedError, setRepetedError] = useState('');
    const [timeError, setTimeError] = useState('');
    const [hourError, setHourError] = useState('');


    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [ openAlert, setOpenAlert] = useState(false)
  
    const [ableToSend, setableToSend] = useState(false);

    function getOptionLabel(option){
        return option.title + ' (' + option.mailingContacts.length + ' contacts)'
    }


    
  const [ add_group, set_add_group] = useState('')
  const [ add_first_name, set_add_first_name] = useState('')
  const [ add_family_name, set_add_family_name] = useState('')
  const [ add_phone_number, set_add_phone_number] = useState('')
  const [ add_email, set_add_email] = useState('')
  const [ add_society, set_add_society] = useState('')

  const [ is_error_group, set_is_error_group] = useState(false)
  const [ is_error_first_name, set_is_error_first_name] = useState(false)
  const [ is_error_family_name, set_is_error_family_name] = useState(false)
  const [ is_error_email, set_is_error_email] = useState(false)
  const [ is_error_phone_number, set_is_error_phone_number] = useState(false)
  const [ is_error_society, set_is_error_society] = useState(false)

  const [ group_error, set_group_error] = useState(false)
  const [ first_name_error, set_first_name_error] = useState(false)
  const [ family_name_error, set_family_name_error] = useState(false)
  const [ email_error, set_email_error] = useState(false)
  const [ phone_number_error, set_phone_number_error] = useState(false)
  const [ society_error, set_society_error] = useState(false)
  
  const [ create_contact_btn_content, set_create_contact_btn_content] = useState('create')
  const [disabledCreateContact, setDisabledCreateContact] = useState(false);


  function create_Contact(first_name, family_name, email, phone_number, society, group, showByGroup){
    setDisabledCreateContact(true)
    set_create_contact_btn_content(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
    console.log(first_name, family_name, email, phone_number, society)
    // get data from database
    axios.post("/mailing/create_quick_mailing", {
      first_name, family_name, email, phone_number, society, group
    })
    .then(function (response) {
      setDisabledCreateContact(false)
      set_create_contact_btn_content("create")
      //.log(contacts)
      //console.log(response.data.newItem)
      //setSelectContactsOptions(response.data.contacts)
      //setGroups(response.data.groups)
      setMailingLists(response.data.mailingLists)
      setOpenCreateQuickMailingModal(false)
      setAlertMessage('Item added successfuly')
      setAlertType('success')
      setOpenAlert(true)
      setTimeout(() => {
        setOpenAlert(false)
      }, 3000);
    })
    .catch(function (error) {
      console.log(error)
      set_create_contact_btn_content("create")
      setDisabledCreateContact(false)
      // handle error
      if(error.response.status === 400 && error.response.data.response === 'input_error'){
        for (let i = 0; i < error.response.data.errors.length; i++) {
          const item = error.response.data.errors[i];
          console.log(item)
          if(item.path === 'first_name'){
            set_first_name_error(Language[item.error] ? Language[item.error] : item.error)
            set_is_error_first_name(true)
          }
          else if(item.path === 'group'){
            set_group_error(Language[item.error] ? Language[item.error] : item.error)
            set_is_error_group(true)
          } 
          else if(item.path === 'family_name'){
            set_family_name_error(Language[item.error] ? Language[item.error] : item.error)
            set_is_error_family_name(true)
          }    
          else if(item.path === 'email'){
            set_email_error(Language[item.error] ? Language[item.error] : item.error)
            set_is_error_email(true)
          } 
          else if(item.path === 'phone_number'){
            set_phone_number_error(Language[item.error] ? Language[item.error] : item.error)
            set_is_error_phone_number(true)
          } else if(item.path === 'society'){
            set_society_error(Language[item.error] ? Language[item.error] : item.error)
            set_is_error_society(true)
          } 
        }
      }else {
        handleError(error)
      }
    })

    //handleCloseUserModal()
  }


   

    // new ****************************

    const [emails, setEmails] = useState([]);
    const [MailingLists, setMailingLists] = useState([]);

    const [email, setEmail] = useState("");
    const [emailerror, setEmailerror] = useState("");
    const [isEmailerror, setIsEmailerror] = useState(false);

    const [name, setName] = useState(userName);
    const [nameerror, setNameerror] = useState("");
    const [isnameerror, setIsNameerror] = useState(false);

    const [replyTo, setReplyTo] = useState('');
    const [ReplyToerror, setReplyToerror] = useState("");
    const [isReplyToerror, setIsReplyToerror] = useState(false);

    const [selectedMailingLists, setSelectedMailingLists] = useState([]);
    const [selectedMailingListsError, setSelectedMailingListsError] = useState('');
    const [isErrorSelectedMailingLists, setIsErrorSelectedMailingLists] = useState(false);

    const [subject, setSubject] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [isErrorSubject, setIsErrorSubject] = useState(false);


    const [disableCodeSendBtn, setDisableCodeSendBtn] = useState(false);
    const [disableDesignSendBtn, setDisableDesignSendBtn] = useState(false);
    const [disableCodeScheduleBtn, setDisableCodeScheduleBtn] = useState(false);
    const [disableDesignScheduleBtn, setDisableDesignScheduleBtn] = useState(false);
    const [editorType, setEditorType] = useState('text');

    function sendMails (type, page, actionType){
      const myList = []
      for (let i = 0; i < selectedMailingLists.length; i++) {
        const list = selectedMailingLists[i];
        myList.push({id: list.id})
      }

      if(type === "code"){
        setDisableCodeSendBtn(true)
      }else{
        setDisableDesignSendBtn(true)
      }

      setLoadingAction(true)
      setLoadingActionType(actionType)

      axios.post("/mailing/sendEmail", {
        page, mailingLists: myList, email, subject, name, replyTo, files:uploadedFiles
      })
      .then(function (response) {
        setLoadingAction(false)
        setLoadingActionType('')
        if(type === "code"){
          setDisableCodeSendBtn(false)
        }else{
          setDisableDesignSendBtn(false)
        }
        console.log(response.data)
        setAlertMessage(response.data.message)      
        setOpenAlert(true)
        setAlertType("info")
        navigate('/app/EmailMarketing/reporting?newItem='+ response.data.mailId, { replace: false });
        setTimeout(() => {
          setOpenAlert(false)
        }, 3000);
          // diplsay data
      })
      .catch(function (error) {
        setLoadingAction(false)
        setLoadingActionType('')
        if(type === "code"){
          setDisableCodeSendBtn(false)
        }else{
          setDisableDesignSendBtn(false)
        }
        // handle error
        if(error.response.status === 400 && error.response.data.response === 'input_error'){
          for (let i = 0; i < error.response.data.errors.length; i++) {
            const item = error.response.data.errors[i];
            console.log(item)
            if(item.path === 'mailingLists'){
              setSelectedMailingListsError(Language[item.error] ? Language[item.error] : item.error)
              setIsErrorSelectedMailingLists(true)
            }else if(item.path === 'email'){
              setEmailerror(Language[item.error] ? Language[item.error] : item.error)
              setIsEmailerror(true)
            }else if(item.path === 'subject'){
              setSubjectError(Language[item.error] ? Language[item.error] : item.error)
              setIsErrorSubject(true)
            }else if(item.path === 'replyTo'){
              setReplyToerror(Language[item.error] ? Language[item.error] : item.error)
              setIsReplyToerror(true)
            }else if(item.path === 'name'){
              setNameerror(Language[item.error] ? Language[item.error] : item.error)
              setIsNameerror(true)
            }
          }
        }else {
          handleError(error)
        }
      })
    }


    const [code, setCode] = useState("");
    function pre_programmeMessage (type, page, actionType){
      setCode(page)
      console.log(page)
      // sera changé
      if(type === "code"){
        setDisableCodeSendBtn(true)
      }else{
        setDisableDesignSendBtn(true)
      }

      var mailingLists = []
      for (let i = 0; i < selectedMailingLists.length; i++) {
        const item = selectedMailingLists[i];
        mailingLists.push(item.id)
      }
      setLoadingAction(true)
      setLoadingActionType(actionType)
      axios.post("/mailing/checkInputs", {
        page, mailingLists, email, subject, name, replyTo
      })
      .then(function (response) {
        setLoadingAction(false)
        setLoadingActionType('')
        if(type === "code"){
          setDisableCodeSendBtn(false)
        }else{
          setDisableDesignSendBtn(false)
        }
        console.log(response.data)
        // open dialog
        handleClickOpenPragrammeMessageModal()
      })
      .catch(function (error) {
        // handle error
        setLoadingAction(false)
        setLoadingActionType('')
        if(type === "code"){
          setDisableCodeSendBtn(false)
        }else{
          setDisableDesignSendBtn(false)
        }
        if(error.response.status === 400 && error.response.data.response === 'input_error'){
          for (let i = 0; i < error.response.data.errors.length; i++) {
            const item = error.response.data.errors[i];
            console.log(item)
            if(item.path === 'mailingLists'){
              setSelectedMailingListsError(item.error)
              setIsErrorSelectedMailingLists(true)
            }else if(item.path === 'email'){
              setEmailerror(Language[item.error] ? Language[item.error] : item.error)
              setIsEmailerror(true)
            }else if(item.path === 'subject'){
              setSubjectError(Language[item.error] ? Language[item.error] : item.error)
              setIsErrorSubject(true)
            }else if(item.path === 'replyTo'){
              setReplyToerror(Language[item.error] ? Language[item.error] : item.error)
              setIsReplyToerror(true)
            }else if(item.path === 'name'){
              setNameerror(Language[item.error] ? Language[item.error] : item.error)
              setIsNameerror(true)
            }
          }
        }else {
          handleError(error)
        }
      })
    }

    const [disableScheduledBtn, setDisableScheduledBtn] = useState(false);
    function programmeMessage (){
      var mailingLists = []
      for (let i = 0; i < selectedMailingLists.length; i++) {
        const item = selectedMailingLists[i];
        mailingLists.push(item.id)
      }
      setDisableScheduledBtn(true)
      setLoadingAction(true)
      axios.post("/mailing/scheduleEmail", {
        code, mailingLists, email, time, repeted, hour, subject, name, replyTo, files: uploadedFiles
      })
      .then(function (response) {
        setDisableScheduledBtn(false)
        console.log(response.data)
        handleClosePragrammeMessageModal()
        setAlertMessage(response.data.message)      
        setOpenAlert(true)
        navigate('/app/EmailMarketing/reporting?newItem='+ response.data.mailId + '&s=true', { replace: false });
        setAlertType("success")
        setTimeout(() => {
          setOpenAlert(false)
        }, 3000);
          // diplsay data
      })
      .catch(function (error) {
        // handle error
        setDisableScheduledBtn(false)
        console.log(error)
        if(error.response.status === 400 && error.response.data.response === 'input_error'){
          for (let i = 0; i < error.response.data.errors.length; i++) {
            const item = error.response.data.errors[i];
            console.log(item)
            if(item.path === 'mailingLists'){
              setSelectedMailingListsError(Language[item.error] ? Language[item.error] : item.error)
              setIsErrorSelectedMailingLists(true)
              handleClosePragrammeMessageModal()
            }else if(item.path === 'email'){
              setEmailerror(Language[item.error] ? Language[item.error] : item.error)
              setIsEmailerror(true)
              handleClosePragrammeMessageModal()
            }else if(item.path === 'subject'){
              setSubjectError(Language[item.error] ? Language[item.error] : item.error)
              setIsErrorSubject(true)
              handleClosePragrammeMessageModal()
            }else if(item.path === 'date'){
              setTimeError(Language[item.error] ? Language[item.error] : item.error)
              setIsErrorTime(true)
            }else if(item.path === 'time'){
              setHourError(Language[item.error] ? Language[item.error] : item.error)
              setIsErrorHour(true)
            }else if(item.path === 'repeted'){
              setRepetedError(Language[item.error] ? Language[item.error] : item.error)
              setIsErrorRepeted(true)
            }else if(item.path === 'replyTo'){
              setReplyToerror(Language[item.error] ? Language[item.error] : item.error)
              setIsReplyToerror(true)
              handleClosePragrammeMessageModal()
            }else if(item.path === 'name'){
              setNameerror(Language[item.error] ? Language[item.error] : item.error)
              setIsNameerror(true)
              handleClosePragrammeMessageModal()
            }
          }
        }else {
          handleError(error)
        }
      })
    }
    


    useEffect(() => {
      // get data from database
      handleHeader('SMSMarketing')
      axios.post("/mailing/home")
      .then(function (response) {
          console.log(response.data)
          setEmails(response.data.configs)
          setMailingLists(response.data.mailingLists)
      })
      .catch(function (error) {
        // handle error
          handleError(error)
      })
    
    }, [])

    const [contentText, setContentText] = useState('');
    const [contentCode, setcontentCode] = useState(`<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Document</title>
  </head>
  <body>
      
  </body>
</html>
    `);

    const [page, setPage] = useState('');
    function showmyCode(){
      setOpenDialog(true)
      if(editorType === 'code'){
        setPage(contentCode)
      }else{
        setPage(contentText)
      }
  }

  const [openDialog, setOpenDialog] = React.useState(false);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [loadingAction, setLoadingAction] = useState(false);
    const [loadingActionType, setLoadingActionType] = useState('');

    const fileInputRef = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
    await uploadPhoto(event.target.files[0])
  };

  const handleImageLoad = (event) => {
    const img = event.target;
    if (img.naturalWidth > img.naturalHeight) {
      img.style.width = 'auto';
      img.style.height = '100%';
    } else {
      img.style.width = '100%';
      img.style.height = 'auto';
    }
  };

  const [loadingFile, setLoadingFile] = useState(false);
  async function uploadPhoto(selectedFile) {
    try {
      if(uploadedFiles.length < 10){
        if (selectedFile){
          const formData = new FormData();
          formData.append('file', selectedFile);
          formData.append('name', 'mohamed');
          setLoadingFile(true)
          const response = await axios.post('/mailing/uploadFile', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          setLoadingFile(false)
          console.log(response)
          if(response.data.response === 'done'){
            var data = uploadedFiles
            data.push(response.data.file)
            setUploadedFiles(data)
          }else{
            setOpenAlert(true)
            setAlertMessage(response.data.error)
            setAlertType("error")
          }
          //setImage(response.data.image)
          setSelectedFile(null);
          // Clear the file input
          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }
        }else{
          setLoadingFile(false)
          setOpenAlert(true)
          setAlertMessage('There is no selected file')
          setAlertType("error")
        }
      }else{
        setLoadingFile(false)
        setOpenAlert(true)
        setAlertMessage('Cannot uplaod more than 10 files')
        setAlertType("error")
      }
    } catch (error) {
      setLoadingFile(false)
      setOpenAlert(true)
      setAlertMessage('Internal server error')
      setAlertType("error")
    }
  }

  async function handleDeleteFile(fileRef) {
    try {
        if (fileRef){
          var data = uploadedFiles
          data = data.filter((item) => item.ref !== fileRef)
          setUploadedFiles(data)
          const response = await axios.post('/mailing/deleteFile', {fileRef});
        }else{
          setOpenAlert(true)
          setAlertMessage('There is no selected file')
          setAlertType("error")
        }
    } catch (error) {
      setOpenAlert(true)
      setAlertMessage('Internal server error')
      setAlertType("error")
    }
  }
  

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>{Language['Email Marketing']}</H1>
            </HeaderTitle>
        </Header>
        <BodyContent>
          
            <Form style={{paddingTop :'30px'}}>
            <Collapse in={ableToSend}>
              <Alert
                severity="warning" variant='filled'
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setableToSend(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                  {Language['unable to send message before setting your SMS sending account']} — <HeaderLink to={'config'}>{Language['set my Account!']}</HeaderLink>
              </Alert>
            </Collapse>


                <FormControl  className='toFullWidth100'  error={isnameerror} variant="standard" style={{width: '35%', margin: '0px 5% 0px 0px', marginTop: '0px', marginBottom: '0px'}}>
                   
                   <TextField
                       error={isnameerror}
                       variant="standard"
                       label={Language['From']}
                       placeholder="name"
                       defaultValue={name}
                       onChange={ (e) => {
                         setName(e.target.value)
                         setIsNameerror(false)
                         setNameerror('')
                       }
                       }
                   />
                   <FormHelperText id="first_name_error">{nameerror}</FormHelperText>
               </FormControl>


                <FormControl  className='toFullWidth100' error={isEmailerror} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '60%', margin: '0px', marginTop: '0px'}}>
                    {/* <p style={{width: '100%', marginBottom: '0px'}}>Sender</p> */}
                    <InputLabel htmlFor="email">{Language['Email']}</InputLabel>

                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    //value={'age'}
                    onChange={ (e) =>{
                      setEmail(e.target.value)
                      setIsEmailerror(false)
                      setEmailerror('')
                    }
                    }
                    >
                    
                    {
                      emails.map(((item) => {
                        return (
                          <MenuItem value={item.id}>{item.email_address}</MenuItem>
                        )
                      }))
                    }
                    </Select>
                    <FormHelperText id="time_error">{emailerror}</FormHelperText>
                </FormControl>


                <FormControl className='toFullWidth100' error={isErrorSelectedMailingLists} variant="standard" style={{width: '100%', marginTop: '0px', marginBottom: '0px'}}>
                    <Autocomplete
                    style={{width: '100%', marginTop: '0px'}}
                    multiple
                    noOptionsText={
                            <><p>{Language['no option']}</p> <Button variant='contained' onClick={() => setOpenCreateQuickMailingModal(true)} >{Language['Create Mailing List']}</Button></>
                    }
                    variant="standard"
                    id="tags-outlined"
                    value={selectedMailingLists}
                    options={MailingLists}
                    getOptionLabel={(options) =>  getOptionLabel(options) }
                    filterSelectedOptions
                    onChange={(event, value) => {
                      setSelectedMailingLists(value)
                      setIsErrorSelectedMailingLists(false)
                      setSelectedMailingListsError('')
                    }}
                    renderInput={(params) => (
                    <TextField
                        error={isErrorSelectedMailingLists}
                        {...params}
                        variant="standard"
                        label={Language['To']}
                        placeholder="Mailing Lists"
                        onChange={ () => {
                          setIsErrorSelectedMailingLists(false)
                          setSelectedMailingListsError('')
                        }
                        }
                    />
                    )}
                />
                    <FormHelperText id="first_name_error">{selectedMailingListsError}</FormHelperText>
                </FormControl> 

                <FormControl error={isReplyToerror} variant="standard" style={{width: '100%', marginTop: '0px', marginBottom: '0px'}}>
                   
                   <TextField
                       error={isReplyToerror}
                       variant="standard"
                       label={Language['Reply to (option)']}
                       placeholder="email"
                       onChange={ (e) => {
                         setReplyTo(e.target.value)
                         setIsReplyToerror(false)
                         setReplyToerror('')
                       }
                       }
                   />
                   <FormHelperText id="first_name_error">{ReplyToerror}</FormHelperText>
               </FormControl>

                <FormControl error={isErrorSubject} variant="standard" style={{width: '100%', marginTop: '10px', marginBottom: '20px'}}>
                   
                    <TextField
                        error={isErrorSubject}
                        variant="standard"
                        label={Language['Subject']}
                        placeholder="Subject"
                        onChange={ (e) => {
                          setSubject(e.target.value)
                          setIsErrorSubject(false)
                          setSubjectError('')
                        }
                        }
                    />
                    <FormHelperText id="first_name_error">{subjectError}</FormHelperText>
                </FormControl> 

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%', marginBottom: '5px', marginTop: '-0px'}}  >
                  <p style={{margin: '0px', padding: '0px', color: colors.darkGray}}>{Language['Editor type']}</p>
                  <Edit style={{padding: '5px 10px', marginLeft: '15px', fontSize: '17px', borderRadius: '10px', backgroundColor: editorType === 'text' ? colors.primary : 'white', color: editorType === 'text' ? 'white' : colors.primary, cursor: "pointer", border:'1px solid' + colors.primary}} onClick={()=> setEditorType('text')} />
                  <Code style={{padding: '5px 10px', marginLeft: '5px', fontSize: '17px', borderRadius: '10px', backgroundColor: editorType === 'code' ? colors.primary : 'white', color: editorType === 'code' ? 'white' : colors.primary, border: '1px solid' + colors.primary, cursor: "pointer"}} onClick={()=> setEditorType('code')}/>
                </div> 

                <div style={{width: '100%', display: editorType === 'text' ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'start'}}>
                  <MyQuillEditorAdvanced content={contentText} setContent={setContentText} ></MyQuillEditorAdvanced>
                    
                    <div style={{ marginTop: '0px', width:'100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap'}}>
                      <LoadingButton loading={loadingFile} loadingPosition='start' variant="contained" onClick={() => fileInputRef.current.click()} startIcon={<AttachFile/>} color="secondary"  style={{marginLeft: '0px', fontSize: "small", backgroundColor: colors.primary}} >{Language['Attach files']}</LoadingButton>
                      <input
                        type="file"
                        accept=".pdf, .jpeg, .jpg, .png"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                      />
                      {uploadedFiles.map((file, index)=> {
                        return(
                          <p key={index} style={{margin: '5px 5px', color: colors.secondary, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start'}}><AttachFile style={{marginRight: '5px'}} /> {file.name} <IconButton onClick={()=> handleDeleteFile(file.ref)} style={{padding: '3px', marginLeft: '5px'}}><Close style={{cursor: 'pointer', marginLeft: '0px', color: 'red', padding: '0px'}} /></IconButton>  </p>
                        )
                      })}
                    </div>   
                  
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%'}}  >

                    <IconButton  onClick={() => showmyCode()} style={{color: 'white', backgroundColor: colors.primary, marginRight: '5px', height: 'min-content'}}><RemoveRedEye style={{fontSize: "17px"}}/></IconButton>
                    <LoadingButton loading={(loadingAction && loadingActionType === 'program_text' ? true : false)} disabled={loadingAction} loadingPosition='end' variant='outlined' onClick={() => { pre_programmeMessage('code', contentText, 'program_text')}} style={{backgroundColor: 'white', color: colors.primary, borderColor: colors.primary, marginRight: '10px'}} endIcon={<ScheduleSend/>} >{Language['Program']}</LoadingButton>
                    <LoadingButton variant='contained' loading={(loadingAction && loadingActionType === 'send_text' ? true : false)} loadingPosition='end' disabled={loadingAction} onClick={()=>sendMails('code', contentText, 'send_text')} style={{backgroundColor: colors.primary, color: 'white'}} endIcon={<Send/>} >{Language['Send']}</LoadingButton>
                  </div> 
                </div>


                <div style={{width: '100%', display: editorType === 'code' ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'start'}}>
                  <textarea
                    style={{...textareaStyle}}
                    id="code"
                    label={Language['Code']}
                    multiline
                    rows={7} // adjust as needed
                    value={contentCode}
                    onChange={(e) => setcontentCode(e.target.value)}
                    variant="outlined"
                    //fullWidth
                  />
                  <div style={{ marginTop: '10px', width:'100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap'}}>
                      <LoadingButton loading={loadingFile} loadingPosition='start' variant="contained" onClick={() => fileInputRef.current.click()} startIcon={<AttachFile/>} color="secondary"  style={{marginLeft: '0px', fontSize: "small", backgroundColor: colors.primary}} >{Language['Attach files']}</LoadingButton>
                      <input
                        type="file"
                        accept=".pdf, .jpeg, .jpg, .png"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                      />
                      {uploadedFiles.map((file, index)=> {
                        return(
                          <p key={index} style={{margin: '5px 5px', color: colors.secondary, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start'}}><AttachFile style={{marginRight: '5px'}} /> {file.name} <IconButton onClick={()=> handleDeleteFile(file.ref)} style={{padding: '3px', marginLeft: '5px'}}><Close style={{cursor: 'pointer', marginLeft: '0px', color: 'red', padding: '0px'}} /></IconButton>  </p>
                        )
                      })}
                    </div>  
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%', marginTop: '10px'}}  >
                    <IconButton  onClick={() => showmyCode()} style={{color: colors.primary, backgroundColor: colors.secondary, marginRight: '5px', height: 'min-content'}}><RemoveRedEye style={{fontSize: "17px"}}/></IconButton>
                    <LoadingButton loading={(loadingAction && loadingActionType === 'program_code' ? true : false)} disabled={loadingAction} loadingPosition='end' variant='outlined' onClick={() => { pre_programmeMessage('code', contentCode, 'program_code')}} style={{backgroundColor: 'white', color: colors.primary, borderColor: colors.primary, marginRight: '10px'}} endIcon={<ScheduleSend/>} >{Language['Program']}</LoadingButton>
                    <LoadingButton loading={(loadingAction && loadingActionType === 'sent_code' ? true : false)} disabled={loadingAction} loadingPosition='end' variant='contained' onClick={()=>sendMails('code', contentCode, 'sent_code')} style={{backgroundColor: colors.primary, color: 'white'}} endIcon={<Send/>} >{Language['Send']}</LoadingButton>
                  </div> 
                </div>
  
                

                {/* <Editor 
                    sendMails={sendMails} 
                    pre_programmeMessage={pre_programmeMessage}
                    disableDesignScheduleBtn={disableDesignScheduleBtn}
                    disableCodeScheduleBtn={disableCodeScheduleBtn}
                    disableCodeSendBtn={disableCodeScheduleBtn}
                    disableDesignSendBtn={disableDesignSendBtn}
                    DraggedElements={DraggedElements} 
                    setDraggedElements={setDraggedElements}
                    mycode={mycode} 
                    setMycode={setMycode}
                ></Editor> */}

                


                


            </Form>
        </BodyContent>

         {/* //  message programming modal */}
         <Dialog open={open} onClose={handleClosePragrammeMessageModal}>
              <DialogTitle style={{alignContent: 'center'}}> <span>{Language['Pragramme a message']}</span>   </DialogTitle>
              <DialogContent>
                <DialogContentText>
                {Language['fill the field below then press programme, this action will send your message at the selected time.']}
                </DialogContentText>

                <FormControl error={isErrorTime} variant="standard" style={{width: '100%', marginTop: '30px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['select date']}</p>
                    <Input
                      onChange={(e) => {
                        setTime(e.target.value)
                        setIsErrorTime(false)
                        setTimeError('')
                      }}
                      type='date'
                      id=""
                      defaultValue=""
                      aria-describedby="time_error"
                      placeholder=''
                    />
                    <FormHelperText id="time_error">{timeError}</FormHelperText>
                </FormControl>

                <FormControl error={isErrorHour} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '30px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['Time']}</p>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    defaultValue={hour}
                    //value={'age'}
                    onChange={ (e) =>{
                        setHour(e.target.value)
                        setIsErrorHour(false)
                        setHourError('')
                    }
                    }
                    >
                    <MenuItem value={"00"}>00:00</MenuItem>
                    <MenuItem value={"01"}>01:00</MenuItem>
                    <MenuItem value={"02"}>02:00</MenuItem>
                    <MenuItem value={"03"}>03:00</MenuItem>
                    <MenuItem value={"04"}>04:00</MenuItem>
                    <MenuItem value={"05"}>05:00</MenuItem>
                    <MenuItem value={"06"}>06:00</MenuItem>
                    <MenuItem value={"07"}>07:00</MenuItem>
                    <MenuItem value={"08"}>08:00</MenuItem>
                    <MenuItem value={"09"}>09:00</MenuItem>
                    <MenuItem value={"10"}>10:00</MenuItem>
                    <MenuItem value={"11"}>11:00</MenuItem>
                    <MenuItem value={"12"}>12:00</MenuItem>
                    <MenuItem value={"13"}>13:00</MenuItem>
                    <MenuItem value={"14"}>14:00</MenuItem>
                    <MenuItem value={"15"}>15:00</MenuItem>
                    <MenuItem value={"16"}>16:00</MenuItem>
                    <MenuItem value={"17"}>17:00</MenuItem>
                    <MenuItem value={"18"}>18:00</MenuItem>
                    <MenuItem value={"19"}>19:00</MenuItem>
                    <MenuItem value={"20"}>20:00</MenuItem>
                    <MenuItem value={"21"}>21:00</MenuItem>
                    <MenuItem value={"22"}>22:00</MenuItem>
                    <MenuItem value={"23"}>23:00</MenuItem>
                
                    </Select>
                    <FormHelperText id="time_error">{hourError}</FormHelperText>
                </FormControl>

                <FormControl error={isErrorRepeted} variant="standard" sx={{ m: 1, minWidth: 120 }} style={{width: '100%', margin: '0px', marginTop: '30px'}}>
                    <p style={{width: '100%', marginBottom: '0px'}}>{Language['Repeted']}</p>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    //value={'age'}
                    onChange={ (e) =>{
                        setRepeted(e.target.value)
                        setIsErrorRepeted(false)
                        setRepetedError('')
                    }
                    }
                    >
                    <MenuItem value="no-repeat">
                        <em>{Language['None']}</em>
                    </MenuItem>
                    <MenuItem value={"month"}>{Language['monthly']}</MenuItem>
                    <MenuItem value={"year"}>{Language['yearly']}</MenuItem>
                    </Select>
                    <FormHelperText id="time_error">{repetedError}</FormHelperText>
                </FormControl>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClosePragrammeMessageModal}>{Language['Cancel']}</Button>
                <Button disabled={disableScheduledBtn} onClick={() => programmeMessage()} >{Language['programme']}</Button>
              </DialogActions>
                </Dialog>



                 {/* //add user  modal */}
                 <Dialog open={openCreateQuickMailingModal} onClose={handleCloseCreateQuickMailingModal}>
                  <DialogTitle style={{alignContent: 'center'}}>{Language['Create New Mailing List']} <span style={{fontWeight: '600'}}>+</span>  </DialogTitle>
                  <DialogContent>
                    
                    <DialogContentText style={{marginBottom: '20px'}}>
                    {Language['This is a quick way to mailing contact and assemble it in a new mailing list.']}
                    </DialogContentText>
                    <FormControl error={is_error_group} variant="standard" style={{width: '100%', marginTop: '0px', marginBottom: '20px'}}>
                      <InputLabel htmlFor="group">{Language['Mailing list']} *</InputLabel>
                      <Input
                        onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group)}}}
                        onChange={(e) => {
                          set_add_group(e.target.value);
                          set_is_error_group(false)
                          set_group_error('')
                        }}
                        type='text'
                        id="group"
                        defaultValue={add_group}
                        aria-describedby="group_error"
                      />
                      <FormHelperText id="group_error">{group_error}</FormHelperText>
                    </FormControl>
                    <p style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                      <span style={{width: '30%', minWidth: '100px', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                      <span style={{color:'gray', margin: '0px 10px', marginBottom: '5px'}}>{Language['Mailing contact']}</span>
                      <span style={{width: '30%', minWidth: '100px', height: '1px', backgroundColor:'#c8c8c8'}}></span>
                    </p>


                    <FormControl error={is_error_society} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="society">{Language['Society']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group)}}}
                          onChange={(e) => {
                            set_add_society(e.target.value);
                            set_is_error_society(false)
                            set_society_error('')
                          }}
                          type='text'
                          id="society"
                          defaultValue={add_society}
                          aria-describedby="society_error"
                        />
                        <FormHelperText id="society_error">{society_error}</FormHelperText>
                    </FormControl> 
                    
                    <FormControl error={is_error_first_name} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="first_name">{Language['First Name']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group)}}}
                          onChange={(e) => {
                            set_add_first_name(e.target.value);
                            set_is_error_first_name(false)
                            set_first_name_error('')
                          }}
                          type='text'
                          id="first_name"
                          defaultValue={add_first_name}
                          aria-describedby="first_name_error"
                        />
                        <FormHelperText id="first_name_error">{first_name_error}</FormHelperText>
                    </FormControl>

                    <FormControl error={is_error_family_name} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="family_name">{Language['Family Name']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group)}}}
                          onChange={(e) => {
                            set_add_family_name(e.target.value);
                            set_is_error_family_name(false)
                            set_family_name_error('')
                          }}
                          type='text'
                          id="family_name"
                          defaultValue={add_family_name}
                          aria-describedby="family_name_error"
                        />
                        <FormHelperText id="family_name_error">{family_name_error}</FormHelperText>
                    </FormControl>


                    <FormControl error={is_error_phone_number} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="phone_number">{Language['Phone Number']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group)}}}
                          onChange={(e) => {
                            set_add_phone_number(e.target.value);
                            set_is_error_phone_number(false)
                            set_phone_number_error('')
                          }}
                          type='text'
                          id="phone_number"
                          defaultValue={add_phone_number}
                          aria-describedby="phone_number_error"
                        />
                        <FormHelperText id="phone_number_error">{phone_number_error}</FormHelperText>
                    </FormControl>


                    <FormControl error={is_error_email} variant="standard" style={{width: '100%'}}>
                        <InputLabel htmlFor="email">{Language['Email']} *</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group)}}}
                          onChange={(e) => {
                            set_add_email(e.target.value);
                            set_is_error_email(false)
                            set_email_error('')
                          }}
                          type='text'
                          id={add_email}
                          defaultValue=""
                          aria-describedby="email_error"
                        />
                        <FormHelperText id="email_error">{email_error}</FormHelperText>
                    </FormControl>

                   

                  

                    

                    

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseCreateQuickMailingModal}>{Language['Cancel']}</Button>
                    <Button disabled={disabledCreateContact} onClick={() => create_Contact(add_first_name, add_family_name, add_email, add_phone_number, add_society, add_group)}>{Language[create_contact_btn_content] ? Language[create_contact_btn_content] : create_contact_btn_content}</Button>
                  </DialogActions>
                </Dialog>


                <Dialog
                  fullWidth={true}
                  maxWidth={'lg'}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  style={{minHeight: '50vh'}}
                >
                  <DialogContent style={{padding: '15px', backgroundColor: 'whitesmoke'}}>
                      <div style={{width: '100%',}} dangerouslySetInnerHTML={{ __html: page }} />
                  </DialogContent>
                  <DialogActions style={{backgroundColor: 'whitesmoke', display: 'flex', flexDirection: 'row', justifyContent: 'end', width: 'calc(100% - 20px )', direction: 'ltr'}}>
                    <Button onClick={handleCloseDialog}>Close</Button>
                  </DialogActions>
                </Dialog>



                <Snackbar
                  open={openAlert}
                  autoHideDuration={4000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                  {Language[alertMessage] ? Language[alertMessage] : alertMessage}
                  </Alert>
                </Snackbar>
    </Body>
  )
}

