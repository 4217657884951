import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const MyQuillEditorAdvanced = ({ content, setContent }) => {
  const [quillRef, setQuillRef] = useState(null);

  useEffect(() => {
    if (quillRef) {
      // Access the Quill editor instance
      const editor = quillRef.getEditor();

      // Listen for the 'text-change' event to update the component state with the modified content
      editor.on('text-change', () => {
        setContent(editor.root.innerHTML);
      });
    }
  }, [quillRef, setContent]);

  /*const handleChange = async (value, delta, source, editor) => {
    if (source === 'user') {
      const imageDelta = delta.ops.find(op => op.insert && op.insert.image);

      if (imageDelta) {
        const imageUrl = imageDelta.insert.image;

        try {
          const formData = new FormData();
          formData.append('image', imageUrl);

          const response = await axios.post('/admin/uploadPhoto', formData);
          const uploadedImageUrl = response.data.imageUrl;

          // Access the Quill editor instance
          const quillEditor = quillRef.getEditor();

          // Insert the uploaded image into the editor
          quillEditor.clipboard.dangerouslyPasteHTML(
            quillEditor.getLength(),
            `<img src="${uploadedImageUrl}" alt="Uploaded Image">`
          );
        } catch (error) {
          console.error('Image upload failed', error);
        }
      }
    }
  };*/


  const handleChange = async (value, delta, source, editor) => {
    if (source === 'user') {
      const quillEditor = quillRef.getEditor();
      const insertedImages = quillEditor.container.querySelectorAll('img');
  
      insertedImages.forEach(async img => {
        const imageUrl = img.getAttribute('src');
  
        // Check if the image source is base64
        if (imageUrl.startsWith('data:image')) {
          try {
            const formData = new FormData();
            formData.append('image', imageUrl);
  
            const response = await axios.post('/mailing/uploadImage', formData);
            const uploadedImageUrl = response.data.imageUrl;
  
            // Replace the base64 image with the uploaded image URL
            img.setAttribute('src', uploadedImageUrl);
          } catch (error) {
            console.error('Image upload failed', error);
          }
        }

        
      });
    }
  
    // Update the component state with the modified content
    setContent(editor.getHTML());
  };
  
  


  return (
    <ReactQuill
      ref={(ref) => setQuillRef(ref)}
      style={{ height: '217px', marginBottom: '10px', display: 'flex', flexDirection: 'column-reverse', borderTop: '1px solid lightgray', overflow:'hidden' }}
      theme="snow"
      value={content}
      onChange={handleChange}
      modules={{
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image' ],
          ['clean'],
        ],
      }}
      formats={[
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet',
        'link', 'image', 'video',
      ]}
    />
  );
};

export default MyQuillEditorAdvanced;
