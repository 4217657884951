import React from 'react'
import styled from 'styled-components'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { DangerousOutlined, DoNotDisturb, DoNotDisturbOutlined, Logout } from '@mui/icons-material';
import { Button, Snackbar } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

import MuiAlert from '@mui/material/Alert';
import colors from '../colors';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Body = styled.div`
    width: calc(100% - 20px);
    height: 100vh;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center
`

const Title = styled.h1`
    width: 100%;
    text-align: center;
    font-size: 30px;
    color: #000000;
    margin: 20px 0px 0px 0px;
    padding: 0px;
`

const Desc = styled.p`
    width: 100%;
    text-align: center;
    font-size: 19px;
    color: #000000aa;
    margin: 5px 0px;
    padding: 0px;
`

const MyIcon = styled(DangerousOutlined)`
    align-self: center;
    font-size: 70px !important;
    color: #ffffff;
    background-color: #da2518;
    border-radius: 100%;
    margin: 5px 0px;
    padding: 15px;
`


export default function Banned({Language}) {
    const navigate = useNavigate()
    const [LoadingLogout, setLoadingLogout] = useState(false)
    const [ alertMessage, setAlertMessage] = useState('')
    const [ alertType, setAlertType] = useState('')
    const [openAlert, setOpenAlert] = React.useState(false);
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    function logout(){
        setLoadingLogout(true)
        axios.post("/logout")
                .then(function (response) {
                    console.log(response.data)
                    setLoadingLogout(false)
                    if(response.data.res === 'done'){
                        navigate('/login', { replace: true });
                    }else if(response.data.res === 'redirect'){
                        navigate('/login', { replace: true });
                    }
                    else{
                        setOpenAlert(true)
                        setAlertType('error')
                        setAlertMessage('An unexpected error has occured')
                    }
                })
                .catch(function (error) {
                    // handle error
                    setLoadingLogout(false)
                    setOpenAlert(true)
                    setAlertType('error')
                    setAlertMessage('An unexpected error has occured')
                })
    }
  return (
    <Body>
        <MyIcon></MyIcon>
        <Title>{Language['Account Suspended']}</Title>
        <Desc>{Language['We regret to inform you that your account has been suspended.']}</Desc>
        <LoadingButton  variant='outlined' startIcon={<Logout />} style={{width: 'max-content', alignSelf: 'center',  color: colors.primary, borderColor: colors.primary, marginTop: '30px'}} onClick={() => logout()}>{Language['Logout']}</LoadingButton >
        {/* // snackbar */}
        <Snackbar
            open={openAlert}
            autoHideDuration={4000}
            onClose={handleCloseAlert}
        >
            <Alert onClose={handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
            {Language[alertMessage] ? Language[alertMessage] : alertMessage}
            </Alert>
        </Snackbar> 
    </Body>
  )
}
