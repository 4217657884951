import styled from 'styled-components'
import { DataGrid } from '@mui/x-data-grid';
import { Route, Router, Routes, useNavigate } from 'react-router-dom';
import NotFound from '../404';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar } from '@mui/material';
import { AddCircleOutlineOutlined, DeleteOutlineOutlined, LaunchOutlined, RemoveOutlined, RemoveRedEyeOutlined } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import colors from '../../colors';


const Container = styled.div`
    width: 100% !important;
    height: calc(100vh - 110px) ;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    overflow-x: auto;
    overflow-y: hidden;
`

const Navbar = styled.div`
    width: calc(100%);
    height: 50px;
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.primary};
`
const Logo = styled.img`
  width: 120px;
  height: auto;
  align-self: center;
  margin-left: 10px;
`;


const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
`

const Action = styled.p`
    color: white;
    padding: 7px 12px;
    background-color: #38084db3;
    border-radius: 3px;
`


const Timer = styled.p`
    color: white;
    padding: 7px 12px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Content = styled.div`
    width: calc(100% - 40px)  ;
    padding: 20px;
    display: flex;
    flex-direction: column;
`
const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`

const Headertitle = styled.p`
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    border-left: 5px solid ${colors.primary} ;
`

export default function UserManagement({Language}) {

    const navigate = useNavigate()
    const [alert, setAlert] = useState({open: false, type: '', value: ''});
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        handleAlertChanges(false, '', 'error')
    };

    const [selectedClient, setSelectedClient] = useState();

    const columns = [
      // {
      //   field: 'id',
      //   headerName: 'Id',
      //   width: 150,
      // },
      {
        field: 'society',
        headerName: Language['Society'],
        width: 160,
        renderCell: (params) => (
          <span style={{ fontWeight: 600 }}>
            {capitalize(params.row.society)}
          </span>
        ),
      },
      {
          field: 'full name',
          headerName: Language['Client'],
          width: 160,
          renderCell: (params) => (
            <span style={{ fontWeight: 400 }}>
              {capitalize(params.row.first_name)} {capitalize(params.row.family_name)}
            </span>
          ),
      },
      {
        field: 'email',
        headerName: Language['Email'],
        width: 240,
      },
      {
        field: 'phoneNumber',
        headerName: Language['Phone number'],
        width: 140,
        renderCell: (params) => (
          <span style={{ fontWeight: 600 }}>{formatPhoneNumber(params.value)}</span>
        ),
      },
      
      
      {
        field: 'Days left',
        headerName: Language['Days left'],
        width: 100,
        renderCell: (params) =>  {
          const currentDate = new Date();
          const otherDate = new Date(params.row.expirationDate);
          const utcCurrentDate = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
          const utcOtherDate = Date.UTC(otherDate.getFullYear(), otherDate.getMonth(), otherDate.getDate());
          const millisecondsPerDay = 24 * 60 * 60 * 1000;
          const timeDifference = utcOtherDate - utcCurrentDate;
          const daysDifference = Math.floor(timeDifference / millisecondsPerDay);
          return(
            <p>{daysDifference > 0 ? daysDifference : '0'}</p>
          )
        } 
    },
      {
          field: 'Experation date',
          headerName: Language['Expiration date'],
          width: 160,
          renderCell: (params) => (
              <span>{params.row.expirationDate ? params.row.expirationDate.split('T')[0] : '--'}</span>
          ),
      },
      {
        field: 'Status',
        headerName: Language['Status'],
        width: 120,
        renderCell: (params) =>  {
          const currentDate = new Date();
          const otherDate = new Date(params.row.expirationDate);
          const utcCurrentDate = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
          const utcOtherDate = Date.UTC(otherDate.getFullYear(), otherDate.getMonth(), otherDate.getDate());
          const millisecondsPerDay = 24 * 60 * 60 * 1000;
          const timeDifference = utcOtherDate - utcCurrentDate;
          const daysDifference = Math.floor(timeDifference / millisecondsPerDay);
          return(
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <Button disabled={false} variant='contained' color={params.row.ban ? 'error' : (daysDifference > 0 ? 'success' : 'warning')} >{params.row.ban ? Language['Banned'] : (daysDifference > 0 ? Language['Active'] : Language['Expired'])}</Button>
            </div>
          )
        } 
    },
      {
          field: 'Action',
          headerName: Language['Actions'],
          width: 130,
          renderCell: (params) => (
              <div  style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <IconButton variant={'outlined'} style={{color: '#286cc6'}} onClick={()=> navigate('/admin/client?id='+ params.row.id)} ><LaunchOutlined/></IconButton>
                <IconButton variant={'outlined'} onClick={() => {handleClickOpenDeleteModal(); setSelectedClient(params.row.id)}} style={{color: '#d23434'}} ><DeleteOutlineOutlined/></IconButton>
              </div>
          ),
      },
  ];

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const handleClickOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };


    const [loadingDelete, setLoadingDelete] = useState(false);

    function handleDeleteClient(){
        setLoadingDelete(true)
        axios.post("/delete_client", {
            id: selectedClient, 
        })
        .then(function (response) {
            //console.log(response.data)
            setLoadingDelete(false)
            if(response.data.response === 'done'){
                setClients(response.data.clients)
                setOpenDeleteModal(false)
                handleAlertChanges(true, 'Client deleted successfully', 'success')
                setTimeout(() => {
                    navigate('/admin/clients', {replace: 'true'})
                }, 1000);
            }else{
                handleAlertChanges(true, response.data.error, 'error')
            }
        })
        .catch(function (error) {
            // handle error
            setLoadingDelete(false)
            handleAlertChanges(true, error.message, 'error')
        })

    }
  
    function handleAlertChanges(open, value, type){
        setAlert((prevFirstName) => ({
            ...prevFirstName,
            open,
            value,
            type
        }));
    }
    const [clients, setClients] = useState([]);
    const [loadingClients, setLoadingClients] = useState(false);

    useEffect(() => {
        // get data from database
        setLoadingClients(true)
        axios.post("/get_clients")
        .then(function (response) {
          setLoadingClients(false)
          console.log(response.data)
          setLoadingClients(false)
          if(response.data.response === 'done'){
            setClients(response.data.clients)
          }else if(response.data.response === 'server_error'){
            handleAlertChanges(true, response.data.message, 'error')
          }
          else{
            navigate('/admin/login', { replace: true });
          }
        })
        .catch(function (error) {
          setLoadingClients(false)
          handleAlertChanges(true, 'An unexpected error has occured', 'error')
        })
      
    }, [])

  return (
    <Container>
            <Header>
                <Headertitle>{Language['Clients management']}</Headertitle>
                <Button onClick={()=> navigate('/admin/newClient')} endIcon={<AddCircleOutlineOutlined/>} variant='contained' style={{backgroundColor: colors.primary, color: 'white'}} >{Language['Create']}</Button>
            </Header>
            <div style={{ flexGrow: '1', width: '100%'}}>
                <DataGrid
                    rows={clients}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection={false}
                    selectionModel={false}
                    loading={loadingClients}
                />
            </div>
            <Snackbar
                open={alert.open}
                autoHideDuration={4000}
                onClose={handleClose}
                >
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                  {Language[alert.value] ? Language[alert.value] : alert.value}
                </Alert>
            </Snackbar>

            {/* // delete client modal */}
            <Dialog
                    open={openDeleteModal}
                    onClose={handleCloseDeleteModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {Language['Delete client']}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {Language['Are you sure you want to delete this account? This action is irreversible, and all the client data will be permanently deleted.']}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button variant='text'  color='error' onClick={handleCloseDeleteModal}>{Language['Cancel']}</Button>
                    <LoadingButton loading={loadingDelete} loadingPosition='center' variant='contained' onClick={handleDeleteClient} style={{color: loadingDelete ? 'transparent' : 'white'}}  color='error' autoFocus>{Language['Delete']}</LoadingButton>
                    </DialogActions>
                </Dialog>
    </Container>
  )
}



  

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleanedPhoneNumber = String(phoneNumber).replace(/\D/g, ''); // Remove non-digit characters
    //console.log(cleanedPhoneNumber)
    if (cleanedPhoneNumber.length === 9) {
      // Format the number with spaces and a leading '0'
      return `0${cleanedPhoneNumber.substr(0, 1)} ${cleanedPhoneNumber.substr(1, 2)} ${cleanedPhoneNumber.substr(3, 2)} ${cleanedPhoneNumber.substr(5, 2)} ${cleanedPhoneNumber.substr(7, 2)} ${cleanedPhoneNumber.substr(9, 2)}`;
    } else {
      // Return the original value if it doesn't match the expected pattern
      return phoneNumber;
    }
  };
