import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Loading from './Loading';
import ServerError from './serverError';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import GroupIcon from '@mui/icons-material/Group';
import EmailIcon from '@mui/icons-material/Email';
import { Routes, Route } from 'react-router-dom';
import Profile from './profile';
import NotFound from './404';
import { Button, Select } from '@mui/material';
import { CalendarToday, WalletOutlined } from '@mui/icons-material';
import colors from '../colors';


const Body = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
`
const Header = styled.div`
    width: 90%;
    height: 70px;
    padding: 5px 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f3f3;
`
const BodyHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0px;
    background-color: ${colors.primary};
    /* background-image: url('../assets/img/login5.png'); */
    background-size: cover;
    background-position: center; 
`

const BodyContent = styled.div`
    width: 70%;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    margin: 20px 0px;
`
const Service = styled.div`
    width: 30%;
    min-width:;
    min-width: ${props => props.width < 700 ? '100% !important' : ' 200px !important'};

    /* max-width: 30%; */
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 2px 2px whitesmoke;
    padding: 0px 0px;
    cursor: pointer;
    transition: 0.3s;
    margin: 5px 10px;
    &:hover{
        box-shadow: 0px 0px 2px 2px #ececec;
    }

`

const ServiceIcon = styled.div`
    width: max-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.primary};
    
`


const MyMarkChatReadIcon = styled(MarkChatReadIcon)`
    color: white;
    font-size: ${props => props.width < 1024 ? '40px !important' : '50px !important'};
    padding: 0px 10px;
`

const MyGroupIcon = styled(GroupIcon)`
    color: white;
    font-size: ${props => props.width < 1024 ? '40px !important' : '50px !important'};
    padding: 0px 10px;
`

const MyEmailIcon = styled(EmailIcon)`
    color: white;
    font-size: ${props => props.width < 1024 ? '40px !important' : '50px !important'};
    padding: 0px 10px;
`

const ServiceTitle = styled.h1`
    width: 100%;
    margin-left: 10px;
    font-size: large;
    font-weight: 400;
    color: ${colors.secondary} !important;
`


const Logo = styled.img`
    width: 100px;
    height: auto;
`


const Title1 = styled.h1`
    font-size: xx-large;
    margin: 0%;
    padding: 0%;

`

const Title2 = styled(Title1)`
    font-size: large;
    padding: 5px 5px 0px 15px;
`
const Title4 = styled(Title1)`
    font-size: small;
    padding: 5px 5px 20px 15px;
    color: gray;
    font-weight: 400;
`

const Title3 = styled(Title1)`
    font-size: xx-large;
    text-align: center;
    padding-bottom: 20px;
    color: #f3f3f3;
`
const Parag = styled(Title1)`
    font-size: medium;
    text-align: center;
    font-weight: 400;
    color: #f3f3f3;
`
const PersonIconStyled = styled(PersonIcon)`
    &:hover {
        cursor: pointer;
    }
`

export default function Home({Language, changeLanguage, languageSelected}) {
    const navigate = useNavigate()

     // Getting Device Width
     const [windowSize, setWindowSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
      
    useEffect(() => {
        function handleResize() {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        }
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize) };
    }, []);

    // mui config
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = (link) => {
        if(link === 'account'){
            navigate('/app/profile', { replace: false });
        }else if(link === 'logout'){
              // get data from database
                axios.post("/logout")
                .then(function (response) {
                    console.log(response.data)

                    if(response.data.res === 'done'){
                        navigate('/login', { replace: true });
                    }else if(response.data.res === 'redirect'){
                        navigate('/login', { replace: true });
                    }
                    else{
                        setServerError(true)
                    }
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }
        setAnchorEl(null);
    };
    /* end mui config */

    const [ loading, setLoading] = useState(true)
    const [ serverError, setServerError] = useState(false)
    const [ userName, setUserName] = useState('')
    const [ email, setEmail] = useState('')
    const [ firstName, setFirstName] = useState('')
    const [ familyName, setFamilyName] = useState('')
    const [ ExpiredIn, setExpiredIn] = useState('')

    function goToService(service){
        navigate(service);

    }

    

  useEffect(() => {

    // get data from database
  axios.post("/App")
  .then(function (response) {
    //console.log(response.data)

    if(response.data.res === 'done'){
      setLoading(false)
      let name = response.data.user.first_name + ' ' + response.data.user.family_name
      setUserName(name )
      setFamilyName(response.data.user.family_name)
      setFirstName(response.data.user.first_name)
      setEmail(response.data.user.email )
      setExpiredIn(response.data.user.expirationDate)
    }else if(response.data.res === 'redirect'){
        navigate('/login', { replace: true });
    }
    else if(response.data.res === 'banned'){
        navigate('/app/banned', { replace: true });
    }
    else{
        setServerError(true)
    }
  })
  .catch(function (error) {
    if(error.response.status === 401 && error.response.data.response === 'banned'){
        navigate('/app/banned', { replace: true });
    }
  })

}, [])


const MyApp = () => {
    return (
        <>
            <BodyHeader>
                    <Title3>{Language['Welcome']} {firstName} {Language['to Linksy Platform']} </Title3>
                    <Parag>{Language['The best Marketing tool in Algeria!']}</Parag>
            </BodyHeader>
            <Button variant='text' startIcon={<CalendarToday/>} style={{color: colors.primary, borderColor: colors.primary, marginTop: '10px', marginRight: '10px', width: 'max-content', alignSelf: 'end'}} > {Language['Expired in']} {ExpiredIn.slice(0,10).split('-')[2]}-{ExpiredIn.slice(0,10).split('-')[1]}-{ExpiredIn.slice(0,10).split('-')[0]} </Button>

            <BodyContent>
                    <Service  width={windowSize.width} onClick={() => goToService('/app/SMSMarketing')}>
                        <ServiceIcon><MyMarkChatReadIcon width={windowSize.width}></MyMarkChatReadIcon></ServiceIcon>
                        <ServiceTitle>{Language['SMS Marketing']}</ServiceTitle>
                    </Service>
                    <Service  width={windowSize.width} onClick={() => goToService('/app/Contacts')}>
                        <ServiceIcon><MyGroupIcon width={windowSize.width}></MyGroupIcon></ServiceIcon>
                        <ServiceTitle>{Language['Contacts']}</ServiceTitle>
                    </Service>


                    <Service  width={windowSize.width} onClick={() => goToService('/app/EmailMarketing')}>
                        <ServiceIcon><MyEmailIcon width={windowSize.width}></MyEmailIcon></ServiceIcon>
                        <ServiceTitle>{Language['Email Marketing']}</ServiceTitle>
                    </Service>
            </BodyContent>
        </>
    )
}


if(!loading && !serverError ){
    const url = '../assets/img/logo192.png'
    return (
        <Body>
            <Header>
                <Logo src={url} onClick={() => { navigate('')}} />
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end'}}>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={languageSelected}
                    onChange={(event) => changeLanguage(event.target.value)}
                    // label="Language"
                    style={{height: '40px', marginRight: '10px'}}
                    >
                    <MenuItem value={'english'}>English</MenuItem>
                    <MenuItem value={'french'}>Français</MenuItem>
                    </Select>
                <PersonIconStyled 
                    fontSize="large"
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                ></PersonIconStyled>
                </div>
                
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose()}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    
                    <Title2 style={{width: '300px'}}>{userName} </Title2>
                    <Title4>{email}</Title4>
                    {/* <MenuItem onClick={handleClose}></MenuItem> */}
                    <MenuItem onClick={() => handleClose('account')} >{Language['My account']}</MenuItem>
                    <MenuItem onClick={ () => handleClose('logout')}>{Language['Logout']}</MenuItem>
                </Menu>
                
            </Header>
            <Routes>
                <Route exact path='/profile' element={<Profile Language={Language} setUserName={setUserName} setFirstName={setFirstName} setFamilyName={setFamilyName} email={email} firstName={firstName} familyName={familyName}  />} ></Route>
                <Route exact path='/' element={<MyApp  />} ></Route>
                <Route exact path='/*' element={<NotFound  />} ></Route>
            </Routes>
        </Body>
    )
}else if(serverError && loading){
    return(
        <ServerError></ServerError>
    )
}
else{
    return(
        <Loading/>
    )
}
}
