import React from 'react'
import styled from 'styled-components'



const Logo = styled.img`
  width: 120px;
  height: auto;
  align-self: center;
  margin-left: 10px;
`;

export default function WhiteLogo() {
  return (
    <Logo src={"/assets/img/logo192white.png"} />
  )
}
