import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import AlertComp from '../alert';
import Tooltip from '@mui/material/Tooltip';

const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: end;

    }
`

const HeaderSeach = styled.div`
    width: 40%;
    @media only screen and (max-width: 850px) {
        width: 90% !important;
        margin: 0% 5%;
        margin-top: 20px;
    }
`
const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 40%;
    }
`

const BodyContent = styled.div`
    width: 100%;
    background-color: #f6f7fa;
    height: calc(100vh - 120px);
    display: flex;
    padding-top: 25px;
    flex-direction: column;
    justify-content: center;
    //position: relative;
    @media only screen and (max-width: 850px) {
      height: calc(100vh - 170px);
    }
`


export default function EmailLists({handleHeader, handleError, Language}) {


  

  const [ loading, setLoading] = useState(true)
  const [ add_title, set_add_title] = useState('')

  const [ is_error_title, set_is_error_title] = useState(false)


  const [ title_error, set_title_error] = useState(false)


  const [ create_contact_btn_content, set_create_contact_btn_content] = useState('create')
  const [createListDisabled, setCreateListDisabled] = useState(false);
  const [ contact_rows, set_contact_rows] = useState([])

  const [ DeleteContactModalBody, setDeleteContactModalBody] = useState('no item is selected, choose in minimum one item.')
  const [ DeleteContactModalBtn, setDeleteContactModalBtn] = useState('ok')

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)


  const [ selected_rows, set_selected_rows] = useState([])


  const navigate = useNavigate()

    // mui config
    const [open, setOpen] = React.useState(false);

    const handleClickOpenUserModal = () => {
        setOpen(true);
    };

    const handleCloseUserModal = () => {
        setOpen(false);
    };

    function getRowClassName(params){
      return "hovering";
    }

    const handleRowClick = (params) => {
      // Navigate to another link when a row is clicked
      navigate(`/app/EmailMarketing/list_details?id=${params.row.id}`);
    }
    // end mui config

    function create_list(title){
      setCreateListDisabled(true)
      set_create_contact_btn_content(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
      // get data from database
      axios.post("/mailing/new_mailing_list", {
        title
      })
      .then(function (response) {
        setCreateListDisabled(false)
        set_create_contact_btn_content("create")
        console.log(response.data)
        setLoading(false)
        set_contact_rows(response.data.lists)
        handleCloseUserModal()
        setAlertMessage('Item added successfuly')
        setAlertType('success')
        setOpenAlert(true)
        setTimeout(() => {
          setOpenAlert(false)
        }, 3000);
      })
      .catch(function (error) {
        // handle error
        set_create_contact_btn_content("create")
        setCreateListDisabled(false)
        handleCloseUserModal()
        setLoading(false)
        if(error.response.status === 400 && error.response.data.response === 'input_error'){
          setLoading(false)
          // redirect to server error page
          for( let i = 0; i < error.response.data.errors.length; i++){
            console.log(error.response.data.errors[i])
            if(error.response.data.errors[i].path === 'title'){
              set_title_error(Language[error.response.data.errors[i].error] ? Language[error.response.data.errors[i].error] : error.response.data.errors[i].error)
              set_is_error_title(true)
            }
          }
        }else{
          handleError(error)
        }
      })

      //handleCloseUserModal()
    }

    const [selectionModel, setSelectionModel] = useState([]);

      const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
        set_selected_rows(newSelection)
        if(newSelection.length === 0){
          setDeleteContactModalBody(Language['No item is selected, choose in minimum one item'])
          setDeleteContactModalBtn(Language['Ok'])
        }else{
          setDeleteContactModalBody(Language['Are you sure you want to delete this item'])
          setDeleteContactModalBtn(Language['Delete'])
        }
      }

      const [openDeleteContactModal, setOpenDeleteContactModal] = React.useState(false);
      const handleCloseDeleteContactModal = () => {
        setOpenDeleteContactModal(false);
      };


      /*function deleteItems(items){
        console.log(items.length)
        if(items.length > 0 ){
          setDeleteContactModalBtn(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
          // delete items
          axios.post("/delete_contact", { items})
          .then(function (response) {
            console.log(response.data)
            setDeleteContactModalBtn("delete")
              setLoading(false)
              set_contact_rows(response.data.contacts)
              handleCloseDeleteContactModal()
              setAlertMessage('Items deleted successfully')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          })
          .catch(function (error) {
            // handle error
            handleError(error)
          })
        }else{
          handleCloseDeleteContactModal()
        }
      }*/

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
      };


     

      function search_lists(keyword){
        console.log(keyword)
        setLoading(true)
        axios.post("/mailing/search_lists",{
          keyword
        })
        .then(function (response) {
          console.log(response.data)
            setLoading(false)
            set_contact_rows(response.data.lists)
        })
        .catch(function (error) {
          // handle error
          setLoading(false)
          handleError(error)
        })
      }

     

    const [titleWidth, setTitleWidth] = useState(200);
    const [columnsWidth, setcolumnsWidth] = useState(200);


    const columns = [
      {
        field: 'title',
        headerName: Language['List'],
        sortable: false,
        width: titleWidth,
        disableClickEventBubbling: true,
        renderCell: (params) => {
            return <h3 >{params.row.title}</h3>;
        },
      },
      { field: 'send', headerName: Language['Send'], width: columnsWidth },
      { field: 'black', headerName: Language['Black List'], width: columnsWidth, 
      renderCell: (params) => {
        const arr = params.row.mailingContacts
        var count = 0;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].blackList === true) {
            count++;
          }
        }
        return count;
      },  },
      { field: 'receiver', headerName: Language['Receiver'], width: columnsWidth,
        sortable: false,
        valueGetter: function(params) {
          return `${params.row.mailingContacts.length || '0'}`;
        }
      }
    ];

    useEffect(() => {
      // get data from database
      handleHeader('mailing_lists')
      axios.post("/mailing/mailing_lists")
      .then(function (response) {
          console.log(response.data)
          setLoading(false)
          set_contact_rows(response.data.lists)
      })
      .catch(function (error) {
        // handle error
        setLoading(false)
        handleError(error)
      })

      function listResizer(data) {
        if(data > 1000){
          const myWidth = (data - (610 + 0.06 * data))
          setTitleWidth(myWidth)
          setcolumnsWidth(200)
          console.log("title : ",myWidth)
        }else{
          setTitleWidth(200)
          setcolumnsWidth(50)
        }
        }
      listResizer(window.innerWidth)
      function handleResize() {
        listResizer(window.innerWidth)
        console.log(window.innerWidth)
      }
  
      window.addEventListener('resize', handleResize);
  
      
    
    
    }, [])

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>{Language['Mailing lists']}</H1>
                <Tooltip title={Language['add new contact']} >
                  <Button startIcon={<AddIcon></AddIcon>} variant="contained" color="success" style={{marginLeft: '10px', fontSize: "small"}} onClick={handleClickOpenUserModal}>{Language['New']}   </Button>
                </Tooltip>

            </HeaderTitle>
            <HeaderSeach>
            <FormControl variant="standard" style={{width: "100%"}}>
                <Input
                id="input-with-icon-adornment"
                placeholder='search for contacts'
                startAdornment={
                    <InputAdornment position="start">
                    <SearchIcon />
                    </InputAdornment>
                }
                onChange={(e) => search_lists(e.target.value)}
                />
                    {/* {search_icon}   */}
            </FormControl>
            </HeaderSeach>
        </Header>
        {/* <Header>
            <DivRow>
              <Tooltip title='export contacts'><Button variant="contained"  style={{marginLeft: '0px', fontSize: "small"}} onClick={() => exportData(selected_rows)}> {exportBtnContent} </Button></Tooltip>
              <Tooltip title='delete contacts'><Button variant="contained" color='error' style={{marginLeft: '10px', fontSize: "small"}} onClick={() => handleDeleteContact()} >  <DeleteIcon style={{fontSize: "large"}}/></Button></Tooltip>
            </DivRow>
        </Header> */}

        {/* <div style={{ height: 630, width: '100%', marginTop: '0px' }}>
        <DataGrid
            rows={contact_rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
        />
        </div> */}

        <BodyContent>
          
        {/* <Header style={{  width: '90%', margin: '0% 5% ', border: "none"}}>
             <DivRow>
            <Tooltip title='group contacts'><Button variant="contained"  style={{marginLeft: '0px', fontSize: "x-small"}} onClick={() => preGroupData(selected_rows)}> <LocalOfferIcon> </LocalOfferIcon> </Button></Tooltip>
            <Tooltip title='export contacts'><Button variant="contained"  style={{marginLeft: '10px', fontSize: "x-small"}} onClick={() => exportData(selected_rows)}> {exportBtnContent} </Button></Tooltip>
            <Tooltip title='delete contacts'><Button variant="contained" size='small' color='error' style={{marginLeft: '10px'}} onClick={() => handleDeleteContact()} >  <DeleteIcon style={{fontSize: "large"}}/></Button></Tooltip>
            </DivRow> 
        </Header> */}
        <DataGrid
            style={{ height: 730, width: '94%', margin: '0% 3% 1% 3%', backgroundColor: "white",
            ".MuiDataGrid-root .MuiDataGrid-cell--header .MuiDataGrid-colCell:first-child .MuiDataGrid-colCellTitle": {
              fontWeight: 600
            } }}
            rows={contact_rows}
            columns={columns}
            loading={loading}
            //pageSize={20}
            //rowsPerPageOptions={[20]}
            checkboxSelection={false}
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            onRowClick={handleRowClick}
            getRowClassName={getRowClassName}
        ></DataGrid>

        </BodyContent>



                {/* //add user  modal */}
                <Dialog open={open} onClose={handleCloseUserModal}>
                  <DialogTitle style={{alignContent: 'center'}}>{Language['Create New Mailing List']} <span style={{fontWeight: '600'}}>+</span>  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                    {Language['fill the field below then press create, this action will create a new Mailing List.']}
                    </DialogContentText>
                  

                    <FormControl error={is_error_title} variant="standard" style={{width: '100%', marginTop: '20px'}}>
                        <InputLabel htmlFor="title">{Language['title']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { create_list(add_title)}}}
                          onChange={(e) => {
                            set_add_title(e.target.value);
                            set_is_error_title(false)
                            set_title_error('')
                          }}
                          type='text'
                          id="title"
                          defaultValue=""
                          aria-describedby="title_error"
                        />
                        <FormHelperText id="title_error">{title_error}</FormHelperText>
                    </FormControl>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseUserModal}>{Language['Cancel']}</Button>
                    <Button disabled={createListDisabled} onClick={() => create_list(add_title)}>{Language[create_contact_btn_content] ? Language[create_contact_btn_content] : create_contact_btn_content}{}</Button>
                  </DialogActions>
                </Dialog>


               
               



                 
                
                {/* alert Modal */}
                {/* <Dialog
                  open={openDeleteContactModal}
                  onClose={handleCloseDeleteContactModal}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Delete Contact
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {DeleteContactModalBody}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDeleteContactModal}>Cancel</Button>
                    <Button onClick={() => deleteItems(selected_rows)} autoFocus>
                      {DeleteContactModalBtn}
                    </Button>
                  </DialogActions>
                </Dialog> */}


      <AlertComp open={openAlert} handleClose={handleClose}  message={alertMessage} type={alertType} Language={Language} />         
      

    </Body>
  )
}
