import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Badge from '@mui/material/Badge';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CachedIcon from '@mui/icons-material/Cached';
import moment from 'moment-timezone';
import colors from '../../colors';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`

const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 40%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    height: calc(100vh - 116px);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    overflow-y: hidden;
    @media only screen and (max-width: 850px) {
    }
`
const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 20px 5%;
    min-height: calc(100vh - 116px - 55px);
    height: max-content;
    overflow: scroll;
    background-color: white;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`


 // mui config
 function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
// end mui config






export default function Reporting({handleHeader, handleError, Language}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)
  const [message, setmessage] = useState([]);
  const [sheduled, setsheduled] = useState([]);
  const [ selected_rows, set_selected_rows] = useState([])
  const [loadingMessage, setloadingMessage] = useState(true)
  const [loadingScheduled, setLoadingScheduled] = useState(true)
  const navigate = useNavigate()

 
  
  // scheduled
  const columnss = [
    { field: 'createdAt', headerName: Language['Date'], width: 200,
      renderCell: (params) => {
        var date = moment(params.row.createdAt).tz('Africa/Algiers').format()
        const year = date.slice(0, 4);
        const month = date.slice(5, 7);
        const day = date.slice(8, 10);
        const hour = date.slice(11, 13);
        const minute = date.slice(14, 16);
        var invisible = true
        if(params.row.id === newItem){
          invisible = false
        }
        return <>
        <Badge color="secondary" variant="dot" invisible={invisible}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
          <p  style={{margin: '5px'}} >{year}/{month}/{day} {hour}:{minute}</p>
        </Badge>
             
            </>;
      },
    },
    {
      field: 'Sender',
      headerName: Language['Sender'],
      sortable: false,
      width: 250,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return params.row.smtpConfig ? params.row.smtpConfig.email_address : ''
      },
    },
    {
      field: 'Subject',
      headerName: Language['Subject'],
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return params.row.subject
      },
    },
    {
      field: 'message',
      headerName: Language['Message'],
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return <Button onClick={(e)=> {e.stopPropagation(); setPage(params.row.message); handleClickOpenDialog()}} variant='outlined' style={{color: colors.primary, borderColor: colors.primary}} startIcon={<RemoveRedEyeIcon></RemoveRedEyeIcon>}>view</Button>  ;
      },
    },
    {
      field: 'Receivers',
      headerName: Language['Receivers ( lists )'],
      sortable: false,
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        var text = ''
        if(params.row.lists){
          for (let i = 0; i < params.row.lists.length; i++) {
            const item = params.row.lists[i];
            text += item + ', '
          }
        }
          return <Tooltip title={text}><p>{text}</p></Tooltip> 
      },
    },
    {
      field: 'Send at',
      headerName: Language['Send at'],
      sortable: false,
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return params.row.year + '/' + params.row.month + '/' + params.row.day + ' ' + params.row.hour + ':00' 
      },
    },
    {
      field: 'Repeted',
      headerName: Language['Repeted'],
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return params.row.repeted 
      },
    },
    {
      field: 'Action',
      headerName: Language['Action'],
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const deleterow = (event, id) => {
          // handle edit logic here
          event.stopPropagation(); 
          set_selected_rows([params.row.id])
          setDeleteContactModalBody('are you sure you want to delete this email?')
          setOpenDeleteContactModal(true);          
        };
  
        //return  <><DeleteIcon onClick={deleterow} style={{color: 'red'}} className={'ActionIcon'}></DeleteIcon></> ;
        return <Button variant="outlined" onClick={(event ) => deleterow(event, params.row.id)} color="error" >{Language['delete']}</Button>
      },
    },  
  ];
  
  const [sentMails, setSentMails] = useState([]);
  const [openedMails, setOpenedMails] = useState([]);
  const [readyMails, setReadyMails] = useState([]);

  const columns = [
    { field: 'createdAt', headerName: Language['Date'], width: 150,
      renderCell: (params) => {
        var date = moment(params.row.createdAt).tz('Africa/Algiers').format()
        const year = date.slice(0, 4);
        const month = date.slice(5, 7);
        const day = date.slice(8, 10);
        const hour = date.slice(11, 13);
        const minute = date.slice(14, 16);
        var invisible = true
        if(params.row.id === newItem){
          invisible = false
        }
        return <>
        <Badge color={"primary"} variant="dot" invisible={invisible}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
          <p  style={{margin: '5px'}} >{year}/{month}/{day} {hour}:{minute}</p>
        </Badge>
             
            </>;
      },
    },
    {
      field: 'Sender',
      headerName: Language['Sender'],
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return params.row.sender
      },
    },
    {
      field: 'Subject',
      headerName: Language['Subject'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return params.row.subject
      },
    },
    {
      field: 'message',
      headerName: Language['Message'],
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return <Button onClick={(e)=> {e.stopPropagation(); setPage(params.row.message); handleClickOpenDialog()}} variant='outlined' style={{color: colors.primary, borderColor: colors.primary}} startIcon={<RemoveRedEyeIcon></RemoveRedEyeIcon>}>view</Button>  ;
      },
    },
    {
      field: 'Process',
      headerName: Language['Process'],
      sortable: false,
      width: 170,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        // var percent = 0
        //   if(params.row.sentMails.length + params.row.readyMails.length > 0){
        //       percent = Math.floor((params.row.sentMails.length * 100) / (params.row.sentMails.length + params.row.readyMails.length))
        //   }
        //   const mywidth = Math.floor((params.row.sentMails.length * 100) / (params.row.sentMails.length + params.row.readyMails.length))
        //   var color = '#c17cda53'
        //   if(mywidth === 100){
        //     color = '#b647ded9'
        //   }

          var process = 0
          if(params.row.sent + params.row.bounced + params.row.pending > 0){
            process = Math.floor(((params.row.sent + params.row.bounced) * 100) / (params.row.sent + params.row.bounced + params.row.pending))
          }
          const mywidth = Math.floor(((params.row.sent + params.row.bounced) * 100) / (params.row.sent + params.row.bounced + params.row.pending))
          var color = colors.gray
          if(mywidth === 100){
            color = colors.orange
          }

          //return  <div style={{width: '100%', height: 'min-content', display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}> <p>{params.row.readyMails.length} <span style={{color: 'gray'}} >({percent}%)</span></p> <p><IconButton onClick={(e) => {e.stopPropagation(); setReadyMails(params.row.readyMails) ;setOpenMessageReadyDialog(true)}} ><ExpandMoreRoundedIcon style={{color: colors.primary}}></ExpandMoreRoundedIcon></IconButton></p></div>  ;
          return <div style={{width: '100%', height: "20px", borderRadius: '6px', overflow: 'hidden', backgroundColor: '#e8e7e9', display: 'flex', flexDirection:'row', justifyContent: 'start', position: 'relative'}}>
            <div style={{width: 'max-content', zIndex: "20",  height: '20px',  display: 'flex', flexDirection:'row', justifyContent: 'center', position: 'absolute', right:'0px', padding: "0px 10px"}} ><span style={{color:'white'}}>{(params.row.sent + params.row.bounced + params.row.pending) } {Language['Emails']}</span></div>
            <div style={{width: mywidth + '%' ,height: '20px', backgroundColor: color, display: 'flex', flexDirection:'row', justifyContent: 'center', position: 'relative'}} ><span style={{color: '#500465', position: "absolute", left: '3px'}}>{process}%</span> </div>
          </div>
      },
    },
    {
      field: 'Sent',
      headerName: Language['Sent'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        // var percent = 0
        // var rebond = []
        // for (let i = 0; i < params.row.sentMails.length; i++) {
        //   const item = params.row.sentMails[i];
        //   if(item.status === 'sent'){
        //     rebond.push(item)
        //   }
        // }
        // if(params.row.sentMails.length + params.row.readyMails.length > 0){
        //     percent = Math.floor((rebond.length * 100) / (params.row.sentMails.length + params.row.readyMails.length))
        // }

        var percent = 0
        var rebond = []
        if((params.row.sent + params.row.bounced + params.row.pending) > 0){
            percent = Math.floor((params.row.sent * 100) / (params.row.sent + params.row.bounced + params.row.pending))
        }
        return <div style={{width: '100%', height: 'min-content', display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}><p style={{marginRight: '10px',}}> {params.row.sent} <span style={{color: 'gray'}} >({percent}%)</span></p> <p><IconButton onClick={(e) => {e.stopPropagation(); getMailDetails(params.row.id, 'sent'); setSentMails([]) ;setOpenMessageSentDialog(true)}} ><ExpandMoreRoundedIcon style={{color: colors.primary}}></ExpandMoreRoundedIcon></IconButton></p>  </div>;
    },
    },
   

     {
      field: 'Pending',
      headerName: Language['Pending'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        var percent = 0
          // if(params.row.sentMails.length + params.row.readyMails.length > 0){
          //     percent = Math.floor((params.row.readyMails.length * 100) / (params.row.sentMails.length + params.row.readyMails.length))
          // }
          if((params.row.pending + params.row.bounced + params.row.sent) > 0){
            percent = Math.floor((params.row.pending * 100) / (params.row.pending + params.row.bounced + params.row.sent))
          }
          return  <div style={{width: '100%', height: 'min-content', display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}> <p>{params.row.pending } <span style={{color: 'gray'}} >({percent}%)</span></p> <p><IconButton onClick={(e) => {e.stopPropagation(); getMailDetails(params.row.id, 'pending');  setReadyMails([]) ;setOpenMessageReadyDialog(true)}} ><ExpandMoreRoundedIcon style={{color: colors.primary}}></ExpandMoreRoundedIcon></IconButton></p></div>  ;
      },
    },
    {
      field: 'Opened',
      headerName: Language['Opened'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          //const count = params.row.sentMails.filter(obj => obj.opened === true).length;
          var percent = 0
          // if(params.row.sentMails.length > 0){
          //   percent = Math.floor((count * 100) / params.row.sentMails.length)
          // }
          if(params.row.sent > 0){
            percent = Math.floor((params.row.opened * 100) / params.row.sent)
          }
          // return  <p>{params.row.opened} <span style={{color: 'gray'}} >({percent}%)</span></p>  ;
          return  <div style={{width: '100%', height: 'min-content', display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}> <p>{params.row.opened } <span style={{color: 'gray'}} >({percent}%)</span></p> <p><IconButton onClick={(e) => {e.stopPropagation(); getMailDetails(params.row.id, 'opened');   setOpenedMails([]) ;setOpenMessageOpenedDialog(true)}} ><ExpandMoreRoundedIcon style={{color: colors.primary}}></ExpandMoreRoundedIcon></IconButton></p></div>  ;

      },
    },
    {
      field: 'impression',
      headerName: Language['Impression'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return params.row.impression
      },
    },
    {
      field: 'Bounced ',
      headerName: Language['Bounced'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          var percent = 0
          var rebond = []
          // for (let i = 0; i < params.row.sentMails.length; i++) {
          //   const item = params.row.sentMails[i];
          //   if(item.status === 'failed'){
          //     rebond.push(item)
          //   }
          // }
          // if(params.row.sentMails.length + params.row.readyMails.length > 0){
          //     percent = Math.floor((rebond.length * 100) / (params.row.sentMails.length + params.row.readyMails.length))
          // }
          if((params.row.sent + params.row.bounced + params.row.pending) > 0){
            percent = Math.floor((params.row.bounced * 100) / (params.row.sent + params.row.bounced + params.row.pending))
        }
          return <div style={{width: '100%', height: 'min-content', display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}><p style={{marginRight: '10px',}}> {params.row.bounced} <span style={{color: 'gray'}} >({percent}%)</span></p> <p><IconButton onClick={(e) => {e.stopPropagation(); getMailDetails(params.row.id, 'failed'); setSentMails([]) ;setOpenMessageSentDialog(true)}} ><ExpandMoreRoundedIcon style={{color: colors.primary}}></ExpandMoreRoundedIcon></IconButton></p>  </div>;
      },
    },
  ];

    const [loadingMailDetails, setLoadingMailDetails] = useState(false);
    function getMailDetails(id, type) {
      if(type === 'pending'){
        setloadingMessage(true)
      }else{
        setLoadingMailDetails(true)
      }
        axios.post("/mailing/getMailDetails", { id, type})
          .then(function (response) {
            if(type === 'pending'){
              setloadingMessage(false)
              setReadyMails(response.data.emails)
            }else if (type === 'opened'){
              setLoadingMailDetails(false)
              setOpenedMails(response.data.emails)            
            }else{
              setLoadingMailDetails(false)
              setSentMails(response.data.emails)            
            }
          })
          .catch(function (error) {
            // handle error
            setLoadingMailDetails(false)
            setloadingMessage(false)
            handleError(error)
          })
    }

  const sentEmailsColumns = [
    { field: 'createdAt', headerName: Language['Date'], width: 200,
      renderCell: (params) => {
        var date = moment(params.row.createdAt).tz('Africa/Algiers').format()
        const year = date.slice(0, 4);
        const month = date.slice(5, 7);
        const day = date.slice(8, 10);
        const hour = date.slice(11, 13);
        const minute = date.slice(14, 16);
        const second = date.slice(17, 19);
      
        return  <p  style={{margin: '5px'}} >{year}/{month}/{day} {hour}:{minute}:{second}</p>
      },
    },
    {
      field: 'Full name',
      headerName: Language['Full name'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.mailingContact){
          return (params.row.mailingContact.first_name ? params.row.mailingContact.first_name : '') + ' ' + (params.row.mailingContact.family_name ?  params.row.mailingContact.family_name : '')
        }else{
          return ''
        }
      },
    },
    {
      field: 'Email',
      headerName: Language['Email'],
      sortable: false,
      width: 250,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.mailingContact){
          return params.row.mailingContact.email
        }else{
          return ''
        }
      },
    },
    {
      field: 'Status',
      headerName: Language['Status'],
      sortable: false,
      width: 170,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          if(params.row.status === 'sent'){
            return <Button variant='outlined' color='success' onClick={(e) => e.stopPropagation()}  >{Language['Sent']}</Button>
          }else{
            return <Button variant='outlined' color='error' onClick={(e) => e.stopPropagation()} style={{color: 'red'}} >{Language['Failed']}</Button>
          }
      },
    },
    {
      field: 'Describtion',
      headerName: Language['Description'],
      sortable: false,
      minwidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return  params.row.desc  ;
      },
    },
  ];

  const openedEmailsColumns = [
    { field: 'createdAt', headerName: Language['Date'], width: 200,
      renderCell: (params) => {
        var date = moment(params.row.createdAt).tz('Africa/Algiers').format()
        const year = date.slice(0, 4);
        const month = date.slice(5, 7);
        const day = date.slice(8, 10);
        const hour = date.slice(11, 13);
        const minute = date.slice(14, 16);
        const second = date.slice(17, 19);
      
        return  <p  style={{margin: '5px'}} >{year}/{month}/{day} {hour}:{minute}:{second}</p>
      },
    },
    {
      field: 'Full name',
      headerName: Language['Full name'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.mailingContact){
          return (params.row.mailingContact.first_name ? params.row.mailingContact.first_name : '') + ' ' + (params.row.mailingContact.family_name ? params.row.mailingContact.family_name : '')
        }else{
          return ''
        }
      },
    },
    {
      field: 'Email',
      headerName: Language['Email'],
      sortable: false,
      width: 250,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.mailingContact){
          return params.row.mailingContact.email
        }else{
          return ''
        }
      },
    },
    {
      field: 'Status',
      headerName: Language['Status'],
      sortable: false,
      width: 170,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          if(params.row.status === 'sent'){
            return <Button variant='outlined' color='success' onClick={(e) => e.stopPropagation()}  >{Language['Sent']}</Button>
          }else{
            return <Button variant='outlined' color='error' onClick={(e) => e.stopPropagation()} style={{color: 'red'}} >{Language['Failed']}</Button>
          }
      },
    },
    {
      field: 'openCount',
      headerName: Language['Count'],
      sortable: false,
      minwidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return  params.row.openedCount  ;
      },
    },
  ];


  const   readyEmailsColumns = [
    { field: 'createdAt', headerName: Language['Date'], width: 200,
      renderCell: (params) => {
        var date = moment(params.row.createdAt).tz('Africa/Algiers').format()
        const year = date.slice(0, 4);
        const month = date.slice(5, 7);
        const day = date.slice(8, 10);
        const hour = date.slice(11, 13);
        const minute = date.slice(14, 16);
        return <p>{year}/{month}/{day} {hour}:{minute}</p>
      },
    },
    {
      field: 'Full name',
      headerName: Language['Full name'],
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.mailingContact){
          return (params.row.mailingContact.first_name ? params.row.mailingContact.first_name : '') + ' ' + (params.row.mailingContact.family_name ? params.row.mailingContact.family_name : '')
        }else{
          return ''
        }
      },
    },
    {
      field: 'Email',
      headerName: Language['Email'],
      sortable: false,
      width: 250,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.mailingContact){
          return params.row.mailingContact.email
        }else{
          return ''
        }
      },
    },
    {
      field: 'Status',
      headerName: Language['Status'],
      sortable: false,
      width: 170,
      disableClickEventBubbling: true,
      renderCell: (params) => {
            return <Button color='primary' onClick={(e) => e.stopPropagation()}  >Pending..</Button>
      },
    },
    {
      field: 'Action',
      headerName: Language['Action'],
      sortable: false,
      width: 170,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if(params.row.status === 'deleting'){
          return  <div style={{width:'100%', display: "flex", flexDirection: 'row', justifyContent: 'center'}}>
                          <Button variant='outlined' color='error' endIcon={<CancelScheduleSendIcon />} disabled >{Language['Deleting']}</Button>
                  </div>
        }else {
          return  <div style={{width:'100%', display: "flex", flexDirection: 'row', justifyContent: 'center'}}>
                          <Button variant='outlined' color='error' endIcon={<CancelScheduleSendIcon />} onClick={(e) => {e.stopPropagation(); cancelPending(params.row.id)}}  >{Language['Cancel']}</Button>
                  </div>
          }
            
      },
    },
  ];

   
  // view message modal
  const [openDialog, setOpenDialog] = React.useState(false);
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // email sent modal
    const [openMessageSentDialog, setOpenMessageSentDialog] = React.useState(false);
    const handleCloseMessageSentDialog = () => {
        setOpenMessageSentDialog(false);
    };

    // email opened modal
    const [openMessageOpenedDialog, setOpenMessageOpenedDialog] = React.useState(false);
    const handleCloseMessageOpenedDialog = () => {
        setOpenMessageOpenedDialog(false);
    };


    // email ready modal
    const [openMessageReadyDialog, setOpenMessageReadyDialog] = React.useState(false);
    const handleCloseMessageReadyDialog = () => {
        getReporting('email')
        setOpenMessageReadyDialog(false);
    };



   
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

      const getRowClassName = (params) => {
        const status = params.row.status;
        if (status === 'sent') {
          return 'sent-row';
        } else if (status === 'failed') {
          return 'failed-row';
        }
        return '';
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
      };

      const [openDeleteContactModal, setOpenDeleteContactModal] = React.useState(false);
      const handleCloseDeleteContactModal = () => {
        setOpenDeleteContactModal(false);
      };






      const [ DeleteContactModalBody, setDeleteContactModalBody] = useState('no item is selected, choose in minimum one item.')
      const [ DeleteContactModalBtn, setDeleteContactModalBtn] = useState('ok')
      const [page, setPage] = useState('');

      function deleteItems(items){
        console.log(items)
        if(items.length > 0 ){
          setDeleteContactModalBtn(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
          // delete items
          axios.post("/mailing/delete_scheduled", { items})
          .then(function (response) {
            setDeleteContactModalBtn("delete")
            setsheduled(response.data.emails)
            handleCloseDeleteContactModal()
            setAlertMessage('Item deleted successfully')
            setAlertType('success')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
          })
          .catch(function (error) {
            // handle error
            handleCloseDeleteContactModal()
            handleError(error)
          })
        }else{
          handleCloseDeleteContactModal()
        }
      }


      function cancelPending(id){
          setDeleteContactModalBtn(<CircularProgress style={{ width: '30px', height:'30px'}}></CircularProgress>)
          setReadyMails(prevMessage => {
            const updatedMessage = [...prevMessage];
            const index = updatedMessage.findIndex(message => message.id === id);
            updatedMessage[index].status = 'deleting';
            return updatedMessage;
          });
          axios.post("/mailing/cancelPending", { id})
          .then(function (response) {
            //console.log(response.data)
              const data = response.data.data
              console.log(data)
              setmessage(prevMessage => {
                const updatedMessage = [...prevMessage];
                const index = updatedMessage.findIndex(message => message.id === data.id);
                updatedMessage[index] = data;
                return updatedMessage;
              });
              //setReadyMails(data.readyMails)
              setAlertMessage('Email canceled successfully')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
          })
          .catch(function (error) {
            // handle error
            setReadyMails(prevMessage => {
              const updatedMessage = [...prevMessage];
              const index = updatedMessage.findIndex(message => message.id === id);
              updatedMessage[index].status = '';
              return updatedMessage;
            });
            handleError(error)
          })
        
      }

      const [disableRefreshBtn, setDisableRefreshBtn] = useState(false);
      function getReporting(data){
        console.log(data)
        if(data === 'message'){
          setloadingMessage(true)
          setDisableRefreshBtn(true)
        }else if(data === 'scheduled'){
          setLoadingScheduled(true)
        }
        axios.post("/mailing/getReporting", {
          data, 
        })
        .then(function (response) {
          setloadingMessage(false)
          setLoadingScheduled(false)
          console.log(response.data)
                // show data
                console.log(response)
                if(data === 'email'){
                  setmessage(response.data.emails)
                  setDisableRefreshBtn(false)
                }else if(data === 'scheduled'){
                  setsheduled(response.data.emails)
                }
        })
        .catch(function (error) {
          // handle error
          setDisableRefreshBtn(false)
          setloadingMessage(false)
          setLoadingScheduled(false)
          handleError(error)
        })
      }

      const [ exportBtnContent , setExportBtnContent] = useState(<FileUploadIcon style={{fontSize: "x-large"}}/>)

      const [selectionModel, setSelectionModel] = useState([]);

      const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
        set_selected_rows(newSelection)
      }

      function  exportData(rows){
        try {
          if(rows.length > 0){
          console.log(rows)
          setExportBtnContent(<CircularProgress style={{ width: '20px', height:'20px', color: 'white'}}></CircularProgress>)
          axios.post("/exportMailReport", {
            rows
          })
          .then(function (response) {
            setExportBtnContent(<FileUploadIcon style={{fontSize: "x-lare"}}/>)
            console.log(response)
            if(response.data.res === 'done'){
              setAlertMessage('Exported successfully')
              setAlertType('success')
              setOpenAlert(true)
              setTimeout(() => {
                setOpenAlert(false)
              }, 3000);
              // show data
            }
            else if(response.data.res === 'server_error'){
              setAlertMessage('Internal server error')
                  setAlertType('error')
                  setOpenAlert(true)
                  setTimeout(() => {
                    setOpenAlert(false)
                  }, 3000);
              // redirect to server error page
            }
            else if(response.data.res === 'redirect'){
              //navigate('/login', { replace: true });
            }else{
              // Create a Blob object from the response data
              const blob = new Blob([response.data], { type: 'text/csv' });
  
              // Create a temporary URL for the Blob object
              const url = URL.createObjectURL(blob);
  
              // Create a link element
              const link = document.createElement('a');
  
              // Set the link's href attribute to the temporary URL
              link.href = url;
  
              // Set the link's download attribute to the desired filename
              link.download = 'data.csv';
  
              // Append the link to the DOM
              document.body.appendChild(link);
  
              // Click the link to download the file
              link.click();
  
              // Remove the link from the DOM
              document.body.removeChild(link);
  
              // Revoke the temporary URL to free up memory
              URL.revokeObjectURL(url);
            }
          })
          .catch(function (error) {
            // handle error
            setExportBtnContent(<FileUploadIcon style={{fontSize: "x-lare"}}/>)
            console.log('this is axios error');
            console.log(error);
            if(error.response){
              if(error.response.status === 401 && error.response.data.response === 'banned'){
                navigate('/app/banned', { replace: true });
              }else{
                setOpenAlert(true)
                //setAlertMessage(getResponseMessage(error.response.status))
                setAlertType("error")               
              }

            }else{
              setOpenAlert(true)
              setAlertMessage('Internal server error')
              setAlertType("error")
            }
          })
         }else{
            setAlertMessage('No item is checked')
                  setAlertType('error')
                  setOpenAlert(true)
                  setTimeout(() => {
                    setOpenAlert(false)
                  }, 3000);
         }
        } catch (error) {
          console.log(error)
        }
      }
     


      const location = useLocation();

      // Access the query parameters using the location object
      const query = new URLSearchParams(location.search);
      const newItem = query.get("newItem");
      const isScheduled = query.get("s");
      
      useEffect((event) => {
        handleHeader('reporting')
        if(newItem && isScheduled){
          handleChange(event, 1)
          getReporting('scheduled')
        }else{
          getReporting('email')
        }
      }, []);

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>{Language['Reporting']}</H1>
            </HeaderTitle>
        </Header>
        <BodyContent>
            
            <Form>
              
              <Box sx={{ width: '100%', position: 'relative' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label={Language['Email']} {...a11yProps(0)} onClick={() => getReporting('email')}/>
                      <Tab label={Language['scheduled']} {...a11yProps(1)} onClick={() => getReporting('scheduled')}/>
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} style={{width: '100%'}} id={'panel1'}>
                     <div style={{ height: 'calc(100vh - 300px)' , width: '100%'}}>
                      <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                        <Tooltip title={Language['Export']}><Button variant="contained" size='large'  style={{marginRight: '10px', marginBottom: "5px", marginTop: '-10px'}} onClick={() => exportData(selected_rows)}>  {exportBtnContent}  </Button></Tooltip>
                        <Button variant='outlined' disabled={disableRefreshBtn} onClick={() => getReporting('email')} style={{color: colors.primary, borderColor: colors.primary, marginBottom: "5px", marginTop: '-10px'}} endIcon={<CachedIcon></CachedIcon>}>{Language['refresh']}</Button>
                      </div>
                      
                      <DataGrid
                        getRowClassName={getRowClassName}
                        style={{padding: '0px 0px'}}
                        rows={message}
                        columns={columns}
                        pageSize={50}
                        loading={loadingMessage}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={true}
                        onSelectionModelChange={handleSelectionModelChange}
                        sortModel={[{field: 'createdAt',sort: 'desc'}]}/>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <div style={{ height: 'calc(100vh - 300px)' , width: '100%'}}>
                      <DataGrid
                        style={{padding: '0px 0px'}}
                        rows={sheduled}
                        columns={columnss}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        checkboxSelection={false}
                        loading={loadingScheduled}
                        sortModel={[{field: 'createdAt',sort: 'desc'}]}/>
                    </div>
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel> */}
              </Box>
            </Form>
        </BodyContent>

        {/* // snackbar */}
        <Snackbar
          open={openAlert}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
            {Language[alertMessage] ? Language[alertMessage] : alertMessage}
          </Alert>
        </Snackbar>

        {/* alert Modal */}
        <Dialog
          open={openDeleteContactModal}
          onClose={handleCloseDeleteContactModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {Language['Delete Email']}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {Language[DeleteContactModalBody] ? Language[DeleteContactModalBody] : DeleteContactModalBody}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteContactModal}>{Language['Cancel']}</Button>
            <Button onClick={() => deleteItems(selected_rows)} autoFocus>
            {Language[DeleteContactModalBtn] ? Language[DeleteContactModalBtn] :DeleteContactModalBtn }{DeleteContactModalBtn}
            </Button>
          </DialogActions>
        </Dialog>




        
        {/* // message view popup ***** */}
        <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box
            style={{minHeight: '80vh', border: '1px whitesmoke solid'}}
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <div style={{width: '100vh'}} dangerouslySetInnerHTML={{ __html: page }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{Language['Close']}</Button>
        </DialogActions>
      </Dialog>


        {/* // email sent popup ***** */}
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={openMessageSentDialog}
          onClose={handleCloseMessageSentDialog}
        >
        <DialogContent>
        <div style={{ height: 'calc(100vh - 300px)' , width: '100%'}}>
          <DataGrid
            getRowClassName={getRowClassName}
            style={{padding: '0px 0px'}}
            rows={sentMails}
            columns={sentEmailsColumns}
            pageSize={50}
            loading={loadingMailDetails}
            rowsPerPageOptions={[50]}
            checkboxSelection={false}
            sortModel={[{field: 'createdAt',sort: 'desc'}]}/>
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageSentDialog}>{Language['Close']}</Button>
        </DialogActions>
      </Dialog>



      {/* // email opened popup ***** */}
      <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={openMessageOpenedDialog}
          onClose={handleCloseMessageOpenedDialog}
        >
        <DialogContent>
        <div style={{ height: 'calc(100vh - 300px)' , width: '100%'}}>
          <DataGrid
            // getRowClassName={getRowClassName}
            style={{padding: '0px 0px'}}
            rows={openedMails}
            columns={openedEmailsColumns}
            pageSize={50}
            loading={loadingMailDetails}
            rowsPerPageOptions={[50]}
            checkboxSelection={false}
            isRowSelectable={false}
            selectionModel={false}
            sortModel={[{field: 'createdAt',sort: 'desc'}]}/>
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageOpenedDialog}>{Language['Close']}</Button>
        </DialogActions>
      </Dialog>


      {/* // email ready popup ***** */}
      <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={openMessageReadyDialog}
          onClose={handleCloseMessageReadyDialog}
        >
        <DialogContent>
        <div style={{ height: 'calc(100vh - 300px)' , width: '100%'}}>
          <DataGrid
            getRowClassName={getRowClassName}
            style={{padding: '0px 0px'}}
            rows={readyMails}
            columns={readyEmailsColumns}
            pageSize={50}
            loading={loadingMessage}
            rowsPerPageOptions={[50]}
            checkboxSelection={false}
            sortModel={[{field: 'createdAt',sort: 'desc'}]}/>
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageReadyDialog}>{Language['Close']}</Button>
        </DialogActions>
      </Dialog>



    </Body>
  )
}
