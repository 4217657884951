import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import { AccountBalanceWalletOutlined } from '@mui/icons-material';
import colors from '../../colors';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Body = styled.div`
    width: 100%;
`

const Header = styled.div`
    height: 35px;
    width: 96%;
    padding: 10px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px #e1e1e1 solid;
    @media only screen and (max-width: 850px) {
        flex-direction: column !important;
    }
`
const HeaderTitle = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    align-self: start;
    align-items: center;
    @media only screen and (max-width: 850px) {
        width: 100% !important;
        flex-direction: row;
        flex-wrap: wrap;
    }
`


const H1 = styled.h1`
    margin: 0%;
    padding: 0%;
    font-size: x-large;
    font-weight: 400;
    margin-right: 20px;
    @media only screen and (max-width: 850px) {
      width: 40%;
    }
`

const BodyContent = styled.div`
    position: relative;
    width: 100%;
    background-color: #f6f7fa;
    min-height: calc(100vh - 122px - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    flex-grow: 3;
    padding-bottom: 25px;
    @media only screen and (max-width: 850px) {
    }
`
const Form = styled.div`
    width: calc(90% - 40px) ;
    margin: 0px 5%;
    background-color: white;
    min-height: max-content;
    box-shadow: 0px 0px 1px 0px grey;
    padding: 10px 20px;
    margin-top: 25px;
    @media only screen and (max-width: 850px) {
      width: calc(90% - 10px) ;
      padding: 10px 5px;

    }
`



const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 10px;
`




export default function MailingConfig({handleHeader, handleError, Language}) {

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)

  const [disabledBtn, setDisabledBtn] = useState(false);


  const [email, setEmail] = useState('');
  const [host, setHost] = useState('');
  const [port, setPort] = useState(Number);
  const [smtpUsername, setSmtpUsername] = useState('');
  const [smtpPassword, setSmtpPassword] = useState('');

  const [emailError, setEmailError] = useState('');
  const [hostError, setHostError] = useState('');
  const [portError, setPortError] = useState('');
  const [smtpUsernameError, setSmtpUsernameError] = useState('');
  const [smtpPasswordError, setSmtpPasswordError] = useState('');

  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorHost, setIsErrorHost] = useState(false);
  const [isErrorPort, setIsErrorPort] = useState(false);
  const [isErrorSmtpUsername, setIsErrorSmtpUsername] = useState(false);
  const [isErrorSmtpPassword, setIsErrorSmtpPassword] = useState(false);
  
  const [limit, setLimit] = useState('');
  const [limitError, setLimitError] = useState("");
  const [isErrorLimit, setIsErrorLimit] = useState(false);

  const [otp, setOtp] = useState('');
  const [isErrorOtp, setIsErrorOtp] = useState(false);
  const [otpError, setOtpError] = useState('');

    const [openModal, setOpenModal] = React.useState(false);
    const handleCloseModal = () => {
      setOpenModal(false);
    };


    const [openModalChangeLimit, setOpenModalChangeLimit] = React.useState(false);
    const handleCloseModalChangeLimit = () => {
      setOpenModalChangeLimit(false);
    };

    
  
  const [dense, setDense] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  function deleteConfig(id){
      console.log(id)
    axios.post("/mailing/deleteConfig", {
      id
    })
    .then(function (response) {
            setConfigs(response.data.configs)
            setAlertMessage( 'Deleted successfully')
            setAlertType('success')
            setOpenAlert(true)
            setTimeout(() => {
              setOpenAlert(false)
            }, 3000);
        // show data
    })
    .catch(function (error) {
      handleError(error)
    })
  }
    

  function getUserConfig(){
    axios.post("/mailing/SMTPConfigs")
    .then(function (response) {
      console.log(response.data)
      setConfigs(response.data.configs)
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      handleError(error)
    })
  }

  //new 
  const [configs, setConfigs] = useState([]);
  const [smtpId, setSmtpId] = useState('');
  function createSmtpAccount(){
    setDisabledBtn(true)
    axios.post("/mailing/createSmtpAccount", {
      email, host, port, smtpUsername, smtpPassword
    })
    .then(function (response) {
      console.log(response)
      setOpenModal(true)
      setSmtpId(response.data.id)
      setDisabledBtn(false)
      /*setConfigs(response.data.configs)
      setOpenAlert(true)
      setAlertMessage('New config added.') 
      setAlertType("info")
      setTimeout(() => {
        setOpenAlert(false)
      }, 3000);*/
    })
    .catch(function (error) {
      // handle error
      setDisabledBtn(false)
      console.log(error)
      if(error.response.status === 400 && error.response.data.response === 'input_error'){
        for (let i = 0; i < error.response.data.errors.length; i++) {
          const item = error.response.data.errors[i];
          console.log(item)
          if(item.path === 'email'){
            setEmailError(Language[item.error] ? Language[item.error] : item.error)
            setIsErrorEmail(true)
          }else if(item.path === 'host'){
            setHostError(Language[item.error] ? Language[item.error] : item.error)
            setIsErrorHost(true)
          }else if(item.path === 'port'){
            setPortError(Language[item.error] ? Language[item.error] : item.error)
            setIsErrorPort(true)
          }else if(item.path === 'smtpUsername'){
            setSmtpUsernameError(Language[item.error] ? Language[item.error] : item.error)
            setIsErrorSmtpUsername(true)
          }
          else if(item.path === 'smtpPassword'){
            setSmtpPasswordError(Language[item.error] ? Language[item.error] : item.error)
            setIsErrorSmtpPassword(true)
          }
        }
      }else {
        handleError(error)
      }
    })
  }

  const [checkOtpBtnDisabled, setCheckOtpBtnDisabled] = useState(false);
  function checkOtp(){
    setCheckOtpBtnDisabled(true)
    axios.post("/mailing/checkSmtpOtp", {
      otp, smtpId
    })
    .then(function (response) {
      console.log(response)
      setOpenModal(false)
      setCheckOtpBtnDisabled(false)
      setConfigs(response.data.configs)
      setOpenAlert(true)
      setAlertMessage('New config added') 
      setAlertType("info")
      setTimeout(() => {
        setOpenAlert(false)
      }, 3000);
    })
    .catch(function (error) {
      // handle error
      setCheckOtpBtnDisabled(false)
      setOpenModal(false)
      console.log(error)
      if(error.response.status === 400 && error.response.data.response === 'input_error'){
        for (let i = 0; i < error.response.data.errors.length; i++) {
          const item = error.response.data.errors[i];
          console.log(item)
          if(item.path === 'otp'){
            setOtpError(item.error)
            setIsErrorOtp(true)
          }
        }
      }else {
        handleError(error)
      }
    })
  }


  const [limitBtnDisabled, setLimitBtnDisabled] = useState(false);
  function applyLimit(){
    setLimitBtnDisabled(true)
    axios.post("/mailing/applyLimit", {
      limit, smtpId
    })
    .then(function (response) {
      console.log(response)
      setOpenModalChangeLimit(false)
      setLimitBtnDisabled(false)
      setConfigs(response.data.configs)
      setOpenAlert(true)
      setAlertMessage('Applyed successfully') 
      setAlertType("success")
      setTimeout(() => {
        setOpenAlert(false)
      }, 3000);
    })
    .catch(function (error) {
      // handle error
      setLimitBtnDisabled(false)
      console.log(error)
      if(error.response.status === 400 && error.response.data.response === 'input_error'){
        for (let i = 0; i < error.response.data.errors.length; i++) {
          const item = error.response.data.errors[i];
          console.log(item)
          if(item.path === 'limit'){
            setLimitError(Language[item.error])
            setIsErrorLimit(true)
          }
        }
      }else {
        setOpenModalChangeLimit(false)
        handleError(error)
      }
    })
  }


  useEffect(() => {
    //handleHeader('config')
    getUserConfig()

  }, []);

  return (
    <Body>
        <Header>
            <HeaderTitle>
                <H1>{Language['Configurations']}</H1>
            </HeaderTitle>
        </Header>
        <BodyContent>
            
            <Form>
            <H1 style={{margin: '10px 0px', width: "max-content"}}>{Language['SMTP configuration']}</H1>
            
            <div style={{display: 'flex', direction: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
              <div  className='rowToColumn' style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", width: '100%'}}>
                  <FormControl className='toFullWidth' error={isErrorEmail} variant="standard" style={{width: '100%', marginTop: '10px'}}>
                    <InputLabel htmlFor="user">{Language['Email Address']}</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { createSmtpAccount()}}}
                      onChange={(e) => {
                        setEmail(e.target.value)
                        setIsErrorEmail(false)
                        setEmailError('')
                        // setSubject(e.target.value)
                        // setIsErrorSubject(false)
                        // setSubjectError('')
                      }}
                      type='text'
                      id="user"
                      defaultValue=""
                      aria-describedby="user_error"
                    />
                    <FormHelperText id="user_error">{emailError}</FormHelperText>
                  </FormControl>


                
              </div>

              <div  className='rowToColumn' style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", width: '100%'}}>
                <FormControl className='toFullWidth' error={isErrorHost} variant="standard" style={{width: '83%', marginTop: '10px'}}>
                          <InputLabel htmlFor="user">{Language['SMTP server host']}</InputLabel>
                          <Input
                            onKeyDown={ (event) => { if (event.key === 'Enter') { createSmtpAccount()}}}
                            onChange={(e) => {
                              setHost(e.target.value)
                              setIsErrorHost(false)
                              setHostError('')
                              // setSubject(e.target.value)
                              // setIsErrorSubject(false)
                              // setSubjectError('')
                            }}
                            type='text'
                            id="user"
                            defaultValue=""
                            aria-describedby="user_error"
                          />
                          <FormHelperText id="user_error">{hostError}</FormHelperText>
                </FormControl>

                <FormControl className='toFullWidth' error={isErrorPort} variant="standard" sx={{ m: 0, minWidth: 120 }} style={{width: '15%', marginTop: '10px'}}>
                <InputLabel id="demo-simple-select-standard-label">{Language['SMTP server port']}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={port}
                  onChange={(e) => {
                    setPort(e.target.value)
                    setIsErrorPort(false)
                    setPortError('')
                  }}
                  label={Language['Port']}
                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={465}>465</MenuItem>
                  <MenuItem value={587}>587</MenuItem>
                  <MenuItem value={2525}>2525</MenuItem>
                  <MenuItem value={8025}>8025</MenuItem>
                </Select>
                <FormHelperText id="password_error">{portError}</FormHelperText>
                </FormControl>


              </div>

              <div className='rowToColumn' style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: "space-between", width: '100%'}}>
                <FormControl className='toFullWidth' error={isErrorSmtpUsername} variant="standard" style={{width: '49%', marginTop: '10px'}}>
                        <InputLabel htmlFor="user">{Language['Account username']}</InputLabel>
                        <Input
                          onKeyDown={ (event) => { if (event.key === 'Enter') { createSmtpAccount()}}}
                          onChange={(e) => {
                            setSmtpUsername(e.target.value)
                            setIsErrorSmtpUsername(false)
                            setSmtpUsernameError('')
                            // setSubject(e.target.value)
                            // setIsErrorSubject(false)
                            // setSubjectError('')
                          }}
                          type='text'
                          id="user"
                          defaultValue=""
                          aria-describedby="user_error"
                        />
                        <FormHelperText id="user_error">{smtpUsernameError}</FormHelperText>
                </FormControl>

                <FormControl className='toFullWidth' error={isErrorSmtpPassword} variant="standard" style={{width: '49%', marginTop: '10px'}}>
                    <InputLabel htmlFor="password">{Language['Account password']}</InputLabel>
                    <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { createSmtpAccount()}}}
                      onChange={(e) => {
                        setSmtpPassword(e.target.value)
                        setIsErrorSmtpPassword(false)
                        setSmtpPasswordError('')
                        // setSubject(e.target.value)
                        // setIsErrorSubject(false)
                        // setSubjectError('')
                      }}
                      type='password'
                      id="password"
                      defaultValue=""
                      aria-describedby="password_error"
                    />
                    <FormHelperText id="password_error">{smtpPasswordError}</FormHelperText>
                </FormControl>
              </div>

              

             

              
            </div>

            

                <BtnContainer>
                    <Tooltip title='Save Mail Confirgurations' >
                        <Button variant="contained" disabled={disabledBtn}  style={{marginLeft: '10px', fontSize: "small", backgroundColor: colors.primary}} onClick={() => { createSmtpAccount() }} >  {Language['save']} </Button>
                    </Tooltip>

                </BtnContainer>
            </Form>

            <Form style={{minHeight: '200px'}}>
                <Grid item xs={12} md={6}>
                    <H1 style={{margin: '10px 0px', width: "max-content"}}>{Language['SMTP Accounts']}</H1>
                    <List dense={dense}>
                      {
                        configs.map((item) => {
                          item.createdAt = item.createdAt.slice(0,10) + '   ' + item.createdAt.slice(11,18)
                          return (
                          <>
                          <ListItem
                            secondaryAction={
                              <>
                                <Button startIcon={<AccountBalanceWalletOutlined />} variant='outlined' color='info' style={{color: colors.primary, borderColor: colors.primary}}>
                                  {item.credit} {Language['email']}
                                </Button>
                                {/* <IconButton disabled style={{display:'none'}} onClick={(e, primary) => deleteConfig(item.id)} edge="end" aria-label="delete">
                                  <DeleteIcon  />
                                </IconButton> */}
                              </>
                              
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <LabelImportantIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <>
                                  {item.email_address}
                                  <IconButton onClick={()=> { setLimit(item.limit); setSmtpId(item.id); setOpenModalChangeLimit(true)}}><SettingsIcon></SettingsIcon></IconButton>
                                </>
                                }
                              secondary={
                                <>
                                  <span><strong>{item.limit} {Language['messages']}</strong> / 1 {Language['hour']}</span>
                                </>
                              }
                            />
                          </ListItem>
                          <Divider />

                          </>
                        )
                          
                        }
                            
                        )
                      }
                      {/* {generate(
                        <ListItem
                          secondaryAction={
                            <IconButton onClick={(e, primary) => console.log(e, primary)} edge="end" aria-label="delete">
                              <DeleteIcon  />
                            </IconButton>
                          }
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <FolderIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Single-line item"
                            secondary={secondary ? 'Secondary text' : "heloo"}
                          />
                        </ListItem>,
                      )} */}
                    </List>
                </Grid>
            </Form>
        </BodyContent>

          {/* // snackbar */}
          <Snackbar
            open={openAlert}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
            {Language[alertMessage] ? Language[alertMessage] : alertMessage}
            </Alert>
          </Snackbar>

          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>{Language['SMTP Verification']}</DialogTitle>
            <DialogContent>
              <DialogContentText>
              {Language['We have send a code to your email address, enter the code to validate your smtp account.']}
              </DialogContentText>
              <FormControl error={isErrorOtp} variant="standard" style={{width: '100%', margin: '0px 0% 0px 0px', marginTop: '   0px', marginBottom: '0px'}}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={Language['Code']}
                  type="number"
                  fullWidth
                  value={otp}
                  variant="standard"
                  inputProps={{
                    maxLength: 5,
                  }}
                  InputProps={{
                    style: {  letterSpacing: "1ch" },
                    inputMode: "numeric",
                    pattern: "\\d{0,5}( \\d{0,5}){0,4}",
                  }}
                  onChange={(e)=> {
                    if(e.target.value.length > 5){
                      setOtp(e.target.value.slice(0,5))
                    }else{
                      setOtp(e.target.value)
                    }
                    setIsErrorOtp(false)
                    setOtpError('')

                  }}
                />
              <FormHelperText id="otp">{otpError}</FormHelperText>
               </FormControl>
              
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>{Language['Cancel']}</Button>
              <Button disabled={checkOtpBtnDisabled} onClick={()=> checkOtp()}>{Language['check']}</Button>
            </DialogActions>
          </Dialog>


          <Dialog open={openModalChangeLimit} onClose={handleCloseModalChangeLimit}>
            <DialogTitle>{Language['SMTP Settings']}</DialogTitle>
            <DialogContent>
              <DialogContentText>
              {Language['Please indicate how frequently you would like this server to send emails per hour, this action will apply for all SMTPs in the same host.']}
              </DialogContentText>
              <FormControl error={isErrorLimit} variant="standard" style={{width: '100%', margin: '0px 0% 0px 0px', marginTop: '   0px', marginBottom: '0px'}}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={Language['Limit per hour']}
                  type="number"
                  fullWidth
                  value={limit}
                  error={isErrorLimit}
                  variant="standard"
                  onChange={(e)=> {
                    setLimit(e.target.value)
                    setIsErrorLimit(false)
                    setLimitError('')
                  }}
                />
              <FormHelperText id="limit">{limitError}</FormHelperText>
               </FormControl>
              
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModalChangeLimit}>{Language['Cancel']}</Button>
              <Button disabled={limitBtnDisabled} onClick={()=> applyLimit()}>{Language['Save']}</Button>
            </DialogActions>
          </Dialog>
    </Body>
  )
}
